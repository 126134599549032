import { fade } from "@ui-lib/core/styles/colorManipulator";

const ComponentName = "UfeInteractionsMenuButton";
const styles = theme => ({
    root: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        cursor: "pointer",
        position: 'relative',
        backgroundColor: fade(theme.palette.common.grey, 0.2),
        padding: '12px',
        "&:hover": {
            backgroundColor: fade(theme.palette.common.grey, 0.3)
        }
    },
    icon: {
        fontSize: "12px"
    },
    arrow: {
        "&:after": {
            border: 'solid transparent',
            content: "''",
            height: 0,
            width: 0,
            position: 'absolute',
            pointerEvents: 'none',
            borderColor: fade(theme.palette.background.default,0),
            borderBottomColor: theme.palette.background.default,
            borderWidth: '10px',
            marginLeft: '-30px',
            top: 'calc(100% - 10px)',
            left: 'calc(100% + 2px)'
        }
    }
});

export { styles };
export { ComponentName };