import React, { Component } from "react";
import UserInfoRender from "./UserInfoRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class UserInfo extends Component {

    state = {
        userInfo : this.replaceUserInfo()
    };

    replaceUserInfo() {
        const {currentLoggedUser, userInfoFields} = this.props;
        let firstFieldsToChange = UserInfo.getProperties(userInfoFields.user_detail_main_label);
        let secondFieldsToChange = UserInfo.getProperties(userInfoFields.user_detail_main_position_label);
        let thirdFieldsToChange = UserInfo.getProperties(userInfoFields.user_detail_secondary_label);

        let user_detail_main_label, user_detail_main_position_label, user_detail_secondary_label = "";
        if (!this.checkIfContainsProperties(firstFieldsToChange)) {
            user_detail_main_label = UserInfo.replaceAllOccurrences(userInfoFields.user_detail_main_label, currentLoggedUser, firstFieldsToChange);
        } else {
            user_detail_main_label = this.replacePropertyOccurrences(userInfoFields.user_detail_main_label, currentLoggedUser, firstFieldsToChange);
        }
        if (!this.checkIfContainsProperties(secondFieldsToChange)) {
            user_detail_main_position_label = UserInfo.replaceAllOccurrences(userInfoFields.user_detail_main_position_label, currentLoggedUser, secondFieldsToChange);
        } else {
            user_detail_main_position_label = this.replacePropertyOccurrences(userInfoFields.user_detail_main_position_label, currentLoggedUser, secondFieldsToChange);
        }
        if (!this.checkIfContainsProperties(thirdFieldsToChange)) {
            user_detail_secondary_label = UserInfo.replaceAllOccurrences(userInfoFields.user_detail_secondary_label, currentLoggedUser, thirdFieldsToChange);
        } else {
            user_detail_secondary_label = this.replacePropertyOccurrences(userInfoFields.user_detail_secondary_label, currentLoggedUser, thirdFieldsToChange);
        }

        return {
            user_detail_main_label, user_detail_main_position_label, user_detail_secondary_label
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentLoggedUser !== this.props.currentLoggedUser) {
            this.setState({userInfo : this.replaceUserInfo()});
        }
    }

    checkIfContainsProperties(array) {
        let found = false;
        array.forEach((val) => {
            if (val.includes("properties"))
                found = true;
        });
        return found;
    }

    static getProperties(key) {
        let regex = /\${/gi, result, indicesFirst = [];
        while ((result = regex.exec(key))) {
            indicesFirst.push(result.index);
        }
        regex = /}/gi;
        let indicesSecond = [];
        while ((result = regex.exec(key))) {
            indicesSecond.push(result.index);
        }
        let finalResults = [];
        for (let i = 0; i < indicesFirst.length; i++) {
            finalResults.push(key.substring(indicesFirst[i] + 2, indicesSecond[i]));
        }
        return finalResults;
    }

    replacePropertyOccurrences(originalString, user, fieldsToChange) {
        for (let i = 0; i < fieldsToChange.length; i++) {
            let key = fieldsToChange[i].split(".");
            let result = "";

            user["properties"].forEach((val) => {
                if (val.name === key[1]) {
                    result = val.value;
                }
            });
            if (result !== "")
                originalString = originalString.replace("${" + fieldsToChange[0] + "}", result);
        }
        return originalString;
    }

    static replaceAllOccurrences(originalString, user, fieldsToChange) {
        for (let i = 0; i < fieldsToChange.length; i++) {
            originalString = originalString.replace("${" + fieldsToChange[i] + "}", user[fieldsToChange[i]]);
        }
        return originalString;
    }

    render() {
        const {currentLoggedUser, isUserInfoPrivate, isPrivate} = this.props;
        const {userInfo} = this.state;

        if (!currentLoggedUser || isPrivate || isUserInfoPrivate)
            return null;

        return (
            <UserInfoRender userInfo={userInfo}/>
        );
    }
}

const mapStateToProps = ({canvas : {userInfoFields, config : {isUserInfoPrivate}}, shell : {isPrivate, currentLoggedUser}}) => ({
    isPrivate,
    isUserInfoPrivate,
    currentLoggedUser,
    userInfoFields
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {},
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);