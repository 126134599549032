import React, { Component } from "react";
import MenuDisplayButtonRender from "./MenuDisplayButtonRender";
import { setJourneyMenuViewMode } from "../../../actions/ui-actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MENU_LAYOUT_GRID, MENU_LAYOUT_LIST } from "../../../config";
import { setLoggedUserPrefs, } from "omni-shell-common/src/actions/shell-actions";
import { UserPreferencesNames } from "omni-shell-common/src/config";

class MenuDisplayButton extends Component {
    //Sets new journeyView Mode on userProperties
    setJourneyViewModeUserPref = (newJourneyViewMode) => {
        const {loggedUserPrefs, services, currentLoggedUser, setLoggedUserPrefs} = this.props;
        let userMenuPref = loggedUserPrefs.properties.find((e) => e.name === UserPreferencesNames.JOURNEY_MENU_VIEW_MODE_NAME);
        if (userMenuPref && userMenuPref.value !== newJourneyViewMode) {

            let journeyMenuViewModePref = {
                name : UserPreferencesNames.JOURNEY_MENU_VIEW_MODE_NAME,
                description : UserPreferencesNames.JOURNEY_MENU_VIEW_MODE_DESCRIPTION,
                value : newJourneyViewMode
            };

            services.UserPreferences.updatePreference(currentLoggedUser.id, journeyMenuViewModePref, loggedUserPrefs.id, userMenuPref.id).then((res) => {
                let newLoggedUserPrefs = Object.assign({}, loggedUserPrefs);
                newLoggedUserPrefs.properties.forEach((e) => {
                    if (e.id === res.data.id) {
                        e.value = res.data.value;
                    }
                });
                setLoggedUserPrefs(newLoggedUserPrefs);
            }).catch((err) => {
                console.log(err);
            });
        }
    };

    toggleViewMode = () => {
        const {journeyMenuViewMode, setJourneyMenuViewMode} = this.props;
        //Sets new journeyView Mode on canvas
        let newJourneyViewMode = journeyMenuViewMode === MENU_LAYOUT_LIST ? MENU_LAYOUT_GRID : MENU_LAYOUT_LIST;
        setJourneyMenuViewMode(newJourneyViewMode);
        this.setJourneyViewModeUserPref(newJourneyViewMode);
    };

    render() {
        const {journeyMenuViewMode, i18nProvider, entitlementsMapper} = this.props;
        return (
            <MenuDisplayButtonRender i18nProvider={i18nProvider} toggleViewMode={this.toggleViewMode} journeyMenuViewMode={journeyMenuViewMode} entitlements={entitlementsMapper}/>
        );
    }
}

const mapStateToProps = ({ui : {journeyMenuViewMode}, shell : {loggedUserPrefs, services, currentLoggedUser, i18nProvider, entitlementsMapper}}) => ({
    journeyMenuViewMode,
    loggedUserPrefs,
    services,
    currentLoggedUser,
    i18nProvider,
    entitlementsMapper
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setJourneyMenuViewMode,
        setLoggedUserPrefs
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(MenuDisplayButton);