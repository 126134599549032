import React, { Component } from "react";
import OpenInteractionActionsRender from "./OpenInteractionActionsRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeInteraction } from "./../../../../../../actions/interactions-actions"
import { setDirtyInteractionModalOpen } from "./../../../../../../actions/ui-actions";
import { Config } from "./../../../../../../config";


class OpenInteractionActions extends Component {

    closeAllInteractions = () => {
        const { sendEventHandler: { close360View }, interactions, journeyHistory, setDirtyInteractionModalOpen, removeInteraction } = this.props;

        const dirtyJourneys = [];
        const journeysToBeNotified = [];
        interactions.forEach(
            obj => {
                let tmpDirtyJourneys = journeyHistory.get(obj.instance_id).filter(journey => journey.isDirty);
                let tmpJourneysToBeNotified = journeyHistory.get(obj.instance_id).filter(journey => journey.shouldBeNotifiedBeforeUnload);
                if (tmpDirtyJourneys.size > 0) dirtyJourneys.push(tmpDirtyJourneys);
                if (tmpJourneysToBeNotified.size > 0) journeysToBeNotified.push(tmpJourneysToBeNotified);
            }
        );
        if (dirtyJourneys.size > 0) {
            setDirtyInteractionModalOpen(true, Config.CLOSE_JOURNEY_SCOPE.ALL_INTERACTIONS);
        } else {
            if (journeysToBeNotified > 0) {
                setDirtyInteractionModalOpen(false, Config.CLOSE_JOURNEY_SCOPE.ALL_INTERACTIONS);
            } else {
                interactions.forEach(interaction => {
                    if (interaction.instance_id !== "default") {
                        let payload = { attendeeDuration: new Date() - interaction.startedTime };
                        close360View(interaction.instance_id, payload).then(res => {
                            removeInteraction(interaction.instance_id);
                        }).catch(err => {
                            console.log("Close Interaction error: " + err);
                            removeInteraction(interaction.instance_id);
                        })
                    }
                })
            }
        }
    }

    render() {
        const { i18nProvider, interactions, interactionsHistory } = this.props;
        
        return <OpenInteractionActionsRender interactionsHistory={interactionsHistory} i18nProvider={i18nProvider} interactions={interactions} closeAction={this.closeAllInteractions} />
    }
}

const mapStateToProps = ({ shell: { i18nProvider }, journeys: { journeyHistory }, interactions: { interactions, interactionsHistory }, canvas: { sendEventHandler } }) => ({ sendEventHandler, journeyHistory, interactions, i18nProvider, interactionsHistory });

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        removeInteraction,
        setDirtyInteractionModalOpen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(OpenInteractionActions);