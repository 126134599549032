import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./CategoryTitleStyles";
import Typography from "@ui-lib/core/Typography";
import Icon from '@ui-lib/core/Icon';
import IconButton from '@ui-lib/core/IconButton';

function CategoryTitleRender({ classes, count, title, toggleVisibility, collapsed, category, id }) {
    return (
        <div id={'UfeMenuJourneyCategoryTitle_' + id} className={classes.root}>
            <IconButton className={collapsed ? classes.toggleIcon : classes.collapsedCategory} onClick={() => toggleVisibility(category)}>
                <Icon className={collapsed ? 'icon-chevron-down' : 'icon-chevron-up'} />
            </IconButton>
            <Typography variant="subtitle2">{title}&nbsp;</Typography>
            <Typography variant="overline" className={classes.countFont}>({count})</Typography>
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(CategoryTitleRender);