import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./AddWidgetButtonStyles";
import Button from "@ui-lib/core/Button";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";

function AddWidgetButtonRender({classes, openAddWidget, i18nProvider, dashboardEditMode}) {
    return (
        <div className={dashboardEditMode ? classNames(classes.root, classes.editMode) : classes.root}>
            <Button id='UfeAddWidgetButton' title={i18nProvider.Texti18n("add_widget_dashboard_button")} onClick={openAddWidget} variant="outlined" className={classes.button}>
                <Icon className={classNames("icon-add", classes.icon)}/>
                {i18nProvider.Labeli18n("add_widget_dashboard_button")}
            </Button>
        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(AddWidgetButtonRender);