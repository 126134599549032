import { setGeoLocation } from "../actions/shell-actions";

class GeoLocationWatcherProvider {

    /*
     * Constructor.
     */
    constructor() {
    }

    setStore(Store) {
        this.store = Store;
    }

    setGeoLocationOnStore = (pos) => {
        let obj = {longitude : pos.coords.longitude, latitude : pos.coords.latitude};
        this.store.dispatch(setGeoLocation(obj));
    };

    setErrorGettingGeoLocation = (err) => {
        let obj = {message : err.message};
        this.store.dispatch(setGeoLocation(obj));
    };

    setWatcher() {
        let default_options = {
            enableHighAccuracy : true,
            timeout : 10000,
            maximumAge : 0
        };
        /**
         this.watcher = setInterval(() => {
            navigator.geolocation.getCurrentPosition(this.setGeoLocationOnStore,this.setErrorGettingGeoLocation,default_options)
        }, 5000);
         */
        this.watcher = navigator.geolocation.watchPosition(this.setGeoLocationOnStore, this.setErrorGettingGeoLocation, default_options);
    }

    stopWatcher() {
        //clearInterval(this.watcher);
        navigator.geolocation.clearWatch(this.watcher);
    }
}

const geoLocationWatcherProvider = new GeoLocationWatcherProvider();
export default geoLocationWatcherProvider;
