import React, { Component } from "react";
import AddWidgetRender from "./AddWidgetRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setAddWidgetModalOpen } from "../../../actions/ui-actions";
import { addWidgets } from "../../../actions/canvas-actions";

class AddWidget extends Component {

    constructor(props) {
        super(props);
        let initialWidgets = this.setAvailableWidgets(props.widgetList);

        this.state = {
            selectedWidgets : [],
            availableWidgets : initialWidgets,
            filteredWidgets : initialWidgets,
            filterName : "",
            filterCategory : "",
        };

        this.addWidgetsToDashboard = this.addWidgetsToDashboard.bind(this);
    }

    setAvailableWidgets = (widgetList) => {
        let result = {};
        widgetList.forEach((widget) => {
            if (this.props.entitlementsVerifier.validateEntitlements(widget.entitlements)) {
                if (result[widget.category]) {
                    result[widget.category].push(widget);
                } else {
                    result[widget.category] = [widget];
                }
            }
        });
        return result;
    };

    filterWidgets = () => {
        let filter_result = Object.assign({}, {}, this.state.availableWidgets);
        if (this.state.filterCategory) {
            var obj = {};
            obj[this.state.filterCategory] = filter_result[this.state.filterCategory];
            filter_result = obj;
        }
        let filteredNameValue = this.state.filterName.trim();
        if (filteredNameValue) {
            let keys = Object.keys(filter_result);
            keys.map(key =>
                filter_result[key] = filter_result[key].filter(widget =>
                    this.props.i18nProvider.Texti18n(widget.info.i18NTitle).toLowerCase().indexOf(filteredNameValue.toLowerCase()) !== -1)
            );
        }
        this.setState({filteredWidgets : filter_result});
    };

    handleSearchClick = () => {
        this.filterWidgets();
    };

    handleClear = () => {
        this.setState({
            filterName : "",
            filterCategory : ""
        }, () => this.filterWidgets());
    };

    handleCategorySelect = (value) => {
        this.setState({
            filterCategory : value
        });
    };

    handleNameFilterChange = (value) => {
        this.setState({
            filterName : value
        });
    };

    closeModal = () => {
        let {setAddWidgetModalOpen} = this.props;
        setAddWidgetModalOpen(false);
    };

    handleWidgetTileClick = widget => {
        const {selectedWidgets} = this.state;
        let result = [];

        if (selectedWidgets.length === 0) {
            result = [widget];
        } else {
            const index = selectedWidgets.indexOf(elem => elem.id === widget.id);
            result = selectedWidgets.slice();
            index === -1 ? result.push(widget) : result.splice(index, 1);
        }

        this.setState({selectedWidgets : result});
    };

    addWidgetsToDashboard = () => {
        if (this.state.selectedWidgets.length) {
            this.props.addWidgets(this.state.selectedWidgets);
        }
        this.setState({selectedWidgets : []}, this.closeModal());
    };

    render() {
        const {filteredWidgets, availableWidgets} = this.state;
        let {isAddWidgetOpen, i18nProvider} = this.props;
        return (
            <AddWidgetRender
                filteredWidgets={filteredWidgets}
                availableWidgets={availableWidgets}
                isAddWidgetOpen={isAddWidgetOpen}
                onClose={this.closeModal}
                maxWidth={"md"}
                fullWidth={false}
                i18nProvider={i18nProvider}
                addWidgetsToDashboard={this.addWidgetsToDashboard}
                handleWidgetTileClick={this.handleWidgetTileClick}
                filteredWidgets={this.state.filteredWidgets}
                handleSearchClick={this.handleSearchClick}
                handleClear={this.handleClear}
                handleCategorySelect={this.handleCategorySelect}
                handleNameFilterChange={this.handleNameFilterChange}
                filterName={this.state.filterName}
                filterCategory={this.state.filterCategory}
            />
        );
    }
}

const mapStateToProps = ({canvas : {sendEventHandler}, ui : {isAddWidgetOpen}, shell : {i18nProvider, services : {EntitlementsVerifier}}, interactions : {widgetList}}) =>
    ({isAddWidgetOpen, i18nProvider, widgetList, entitlementsVerifier : EntitlementsVerifier, sendEventHandler});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setAddWidgetModalOpen,
        addWidgets
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(AddWidget);