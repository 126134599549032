export const SET_SERVICES = "set_services";
export const setServices = services => ({type : SET_SERVICES, services});

export const SET_LOGGED_USER = "set_logged_user";
export const setLoggedUser = userData => ({type : SET_LOGGED_USER, userData});

export const SET_PREVIOUS_LOGGED_USER = "set_previous_logged_user";
export const setPreviousLoggedUser = userData => ({type : SET_PREVIOUS_LOGGED_USER, userData});

export const SET_CANVAS_NAME = "set_canvas_name_journey";
export const setCanvasName = canvasName => ({type : SET_CANVAS_NAME, canvasName});

export const SET_ROOT_PROJECT = "set_root_project";
export const setRootProject = rootProject => ({type : SET_ROOT_PROJECT, rootProject});

export const SET_AUTHENTICATION_TOKENS = "set_authentication_tokens";
export const setAuthenticationTokens = (token, refreshToken) => ({type : SET_AUTHENTICATION_TOKENS, token, refreshToken});

export const SET_I18NPROVIDER = "set_i18nprovider";
export const setI18nProvider = i18nProvider => ({type : SET_I18NPROVIDER, i18nProvider});

export const SET_CONTENT_URL = "set_content_url";
export const setContentUrl = contentUrl => ({type : SET_CONTENT_URL, contentUrl});

export const SET_HOST_PORT = "set_host_port";
export const setHostPort = hostPort => ({type : SET_HOST_PORT, hostPort});

export const SET_RUN_MODE = "set_run_mode";
export const setRunMode = runMode => ({type : SET_RUN_MODE, runMode});

export const SET_SHELL_DEPENDENCY = "set_shell_dependency";
export const setShellDependency = dependency => ({type : SET_SHELL_DEPENDENCY, dependency});

export const SET_CANVAS_DEPENDENCY_RESOLVED = "set_canvas_dependency_resolved";
export const setCanvasDependencyResolved = () => ({type : SET_CANVAS_DEPENDENCY_RESOLVED});

export const SET_CURRENT_ENTITLEMENTS = "set_current_entitlements";
export const setCurrentEntitlements = entitlements => ({type : SET_CURRENT_ENTITLEMENTS, entitlements});

export const ADD_CONTEXT_ENTITLEMENTS = "add_context_entitlements";
export const addContextEntitlements = entitlements => ({type : ADD_CONTEXT_ENTITLEMENTS, entitlements});

export const REMOVE_CONTEXT_ENTITLEMENTS = "remove_context_entitlements";
export const removeContextEntitlements = id => ({type : REMOVE_CONTEXT_ENTITLEMENTS, id});

export const SET_ENTITLEMENTS_MAPPER = "set_entitlements_mapper";
export const setEntitlementsMapper = entitlements => ({type : SET_ENTITLEMENTS_MAPPER, entitlements});

export const SET_GEO_LOCATION = "set_geo_location";
export const setGeoLocation = geolocation => ({type : SET_GEO_LOCATION, geolocation});

export const SET_LOGGED_USER_PREFS = "set_logged_user_prefs";
export const setLoggedUserPrefs = userPrefs => ({type : SET_LOGGED_USER_PREFS, userPrefs});

export const SET_THEME_READY = "SET_THEME_READY";
export const setThemeReady = value => ({type : SET_THEME_READY, value});

export const SET_I18N_READY = "SET_I18N_READY";
export const setI18nReady = value => ({type : SET_I18N_READY, value});

export const SET_UFE_RENDER_STATE = "SET_UFE_RENDER_STATE";
export const setUfeRenderState = value => ({type: SET_UFE_RENDER_STATE, value});

export const SET_OS_LANGUAGE_PREFERENCE = "SET_OS_LANGUAGE_PREFERENCE";

export const SET_PRIVATE_MODE = "set_private_mode";
export const setPrivateMode = (isPrivate) => ({type : SET_PRIVATE_MODE, isPrivate});

export const SET_AVAILABILITY = "set_avalability";
export const setAvailability = (newAvailability) => ({type : SET_AVAILABILITY, newAvailability});

export const TOGGLE_FULL_SCREEN = "TOGGLE_FULL_SCREEN";
export const toggleFullScreen = () => ({type : TOGGLE_FULL_SCREEN});

export const SET_ENTITLEMENTS_URL = "set_entitlements_url"
export const setEntitlementsURL = (entitlementsURL) => ({type : SET_ENTITLEMENTS_URL, entitlementsURL});


export const SET_MENU_FAVOURITE_PREFS = "SET_MENU_FAVOURITE_PREFS"
export const setMenuFavouritePrefs = (menuPrefs) => ({type : SET_MENU_FAVOURITE_PREFS, menuPrefs});

export const ADD_MENU_FAVOURITE_PREFS = "ADD_MENU_FAVOURITE_PREFS";
export const addMenuFavouritePrefs = (value) => ({type: ADD_MENU_FAVOURITE_PREFS, value});

export const REMOVE_MENU_FAVOURITE_PREFS = "REMOVE_MENU_FAVOURITE_PREFS";
export const removeMenuFavouritePrefs = (value) => ({type: REMOVE_MENU_FAVOURITE_PREFS, value});

export const SET_AUTHENTICATION_TYPE = "SET_AUTHENTICATION_TYPE";
export const setAuthenticationType = (value) => ({type: SET_AUTHENTICATION_TYPE, value});

export const SET_SAML_AUTH_LOGOUT_URL = "SET_SAML_AUTH_LOGOUT_URL";
export const setSamlAuthLogoutUrl = (value) => ({type: SET_SAML_AUTH_LOGOUT_URL, value});

