import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./UserNameStyles";
import Typography from '@ui-lib/core/Typography';

function UserNameRender({ classes, currentInteraction }) {
    return (
        <Typography id='UfeContextUserName' className={classes.title} variant="overline">
            {currentInteraction.identifier}<br />
        </Typography>
    );
}

export default withStyles(styles, { name: ComponentName })(UserNameRender);