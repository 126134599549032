import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./EditingDashboardAreaStyles";
import AddWidgetButton from "./AddWidgetButton";
import DiscardChangesButton from "./DiscardChangesButton";
import SaveChangesButton from "./SaveChangesButton";

function JourneyActionsRender({classes, dashboardEditMode}) {
    return (
        <div id='UfeEditDashboardArea' className={classes.root}>
            <DiscardChangesButton/>
            <AddWidgetButton/>
            <SaveChangesButton/>
        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(JourneyActionsRender);