import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./HeaderStyles";
import AppBar from "@ui-lib/core/AppBar";
import Toolbar from "@ui-lib/core/Toolbar";
import Logo from "./Logo";
import UserWidget from "./UserWidget";
import QuickActions from "./QuickActions";
import Context from "./Context";
import GenericExtension from "./GenericExtension"

import { IEntitlementsVerifier } from "omni-shell-common/src/entitlements-verifier";
let EntitlementsVerifier = IEntitlementsVerifier(React);

function HeaderRender({classes, entitlementsMapper, threesixtyConfigs}) {
    return (
        <div className={classes.root}>
            <AppBar position="relative" component={"div"} className={classes.headerAppBar}>
                <Toolbar className={classes.headerToolbar}>
                    <Logo/>
                    {threesixtyConfigs.interactionsLimit > 0 && <EntitlementsVerifier entitlement={entitlementsMapper.header.threesixty}>
                        <Context/>
                    </EntitlementsVerifier>}
                    <EntitlementsVerifier entitlement={entitlementsMapper.header.genericExtensionPoint}>
                        <GenericExtension/>
                    </EntitlementsVerifier>
                    <QuickActions/>
                    <UserWidget/>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(HeaderRender);