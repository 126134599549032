/**
 * New Version Implementation
 */
export const ADD_INTERACTION = 'add_interaction';
export const addInteraction = (context, instance_id) => ({ type: ADD_INTERACTION, context, instance_id });

import { initializeMapEntry, openJourney } from 'omni-shell-common/src/actions/journeys-actions';
export const addInteractionWithJourneys = (context, journeysToOpen) => {
    return dispatch => {
        var instance_id = "_" + Math.random().toString(36).substr(2, 9);
        dispatch(addInteraction(context, instance_id));
        dispatch(toggle360Visibility(true));
        dispatch(initializeMapEntry(instance_id));

        journeysToOpen && journeysToOpen.forEach(elem => {
            dispatch(openJourney(elem.journeyId, instance_id, elem.args))
        });
    }
}

import { closeKeyEntry } from 'omni-shell-common/src/actions/journeys-actions';
export const REMOVE_INTERACTION = 'remove_interaction';
export const removeInteraction = instance_id => {
    return dispatch => {
        dispatch({ type: REMOVE_INTERACTION, instance_id });
        dispatch(closeKeyEntry(instance_id));
    }
}

export const REMOVE_ALL_INTERACTIONS = 'remove_all_interactions';
export const removeAllInteractions = () => ({ type: REMOVE_ALL_INTERACTIONS });

import { changeCurrentJourney } from 'omni-shell-common/src/actions/journeys-actions';
export const SET_CURRENT_INTERACTION = 'set_interaction_selected_id';
export const setCurrentInteraction = instance_id => {
    return dispatch => {
        dispatch({ type: SET_CURRENT_INTERACTION, instance_id });
        dispatch(changeCurrentJourney(null, instance_id));
    }
}

export const UPDATE_CONTEXT_SELECTED = 'update_context_selected';
export const updateContextSelected = context => ({ type: UPDATE_CONTEXT_SELECTED, context });

export const SET_DEFAULT_USER_ENTITLEMENTS = 'set_default_user_entitlements';
export const setDefaultUserEntitlements = (defaultUserEntitlements) => ({ type: SET_DEFAULT_USER_ENTITLEMENTS, defaultUserEntitlements });

export const SET_JOURNEY_DIRTY_ID = "SET_JOURNEY_DIRTY_ID"
export const setJourneyDirtyId = (id) => ({ type: SET_JOURNEY_DIRTY_ID, id });

export const TOGGLE_360_VISIBILITY = 'TOGGLE_360_VISIBILITY';
export const toggle360Visibility = value => ({ type: TOGGLE_360_VISIBILITY, value });

export const SET_INTERACTION_ENTITLEMENTS = 'set_interaction_entitlements';
export const setInteractionEntitlements = (entitlements, instance_id) => ({ type: SET_INTERACTION_ENTITLEMENTS, entitlements });

export const SET_WIDGETS = 'set_widgets';
export const setWidgets = (widgets) => ({ type: SET_WIDGETS, widgets });

export const SET_INTERACTION_TIMER = 'set_interaction_timer';
export const setInteractionTimer = (instance_id, time) => ({ type: SET_INTERACTION_TIMER, instance_id, time });

export const REMOVE_ALL_OPENED_INTERACTIONS = 'remove_all_opened_interactions';
export const removeAllOpenedInteractions = () => ({type:REMOVE_ALL_OPENED_INTERACTIONS});


