import { fade } from "@ui-lib/core/styles/colorManipulator";

const ComponentName = "GenericExtension";
const styles = theme => ({
    root: {},
    iconButton: {
        width:46,
        height:46
    },
    image: {
        width:24,
        height:24
    },
    arrow: {
        "&:after": {
            border: 'solid transparent',
            content: "''",
            height: 0,
            width: 0,
            position: 'absolute',
            pointerEvents: 'none',
            borderColor: 'rgba(136, 183, 213, 0)',
            borderBottomColor: theme.palette.background.default,
            borderWidth: '10px',
            marginLeft: '-30px',
            top: 'calc(100% - 10px)',
            left: 'calc(100%)'
        }
    },
    rootIcon: {
        height: '100%',
        display: 'flex',
        width: 46,
        height: 46,
        alignItems: 'center',
        cursor: "pointer",
        position: 'relative',
        marginLeft: 2,
        marginRight: 2,
        "&:hover": {
            backgroundColor: fade(theme.palette.common.grey, 0.3)
        }
    },
});

export { styles };
export { ComponentName };