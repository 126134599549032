import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./OpenInteractionActionsStyles";
import Button from "@ui-lib/core/Button";
import Icon from '@ui-lib/core/Icon';
import classNames from '@ui-lib/core/classNames';

function OpenInteractionActionsRender({ classes, closeAction, interactions, i18nProvider, interactionsHistory }) {
    return (
        <div id='UfeOpenInteractionsMenuActions' className={classNames(classes.root, {
            [classes.interactionOverflow]: interactionsHistory.size > 5,
            [classes.unfilledList]: interactionsHistory.size <= 5
        })}>
            <Button id='UfeOpenInteractionsMenuActionsButton' fullWidth className={classes.button} color='primary' onClick={closeAction}>
                <Icon className={classNames(classes.icon, "icon-close")} />
                {i18nProvider.Texti18n('close_all') + (interactions.size - 1) + i18nProvider.Texti18n('interactions')}
            </Button>
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(OpenInteractionActionsRender);