import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./JourneyActionsStyles";
import EditDashboardButton from "./EditDashboardButton";
import EditingDashboardArea from "./EditingDashboardArea";

function JourneyActionsRender({classes, dashboardEditMode, isWidgetsEditable}) {
    return (
        <div id='UfeJourneyActions' className={classes.root}>
            {!dashboardEditMode ? <EditDashboardButton/> : <EditingDashboardArea/>} 
        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(JourneyActionsRender);