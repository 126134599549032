const ComponentName = "UfePanelRoot";
const styles = theme => ({
        root : {
            position : "absolute",
            top : 46,
            backgroundColor : theme.palette.grey[50],
            width : "100%",
            minHeight : "230px",
            height : "230px",
            boxShadow : "0px 2px 4px 0px rgba(0,0,0,0.29)",
            padding : 20,
            zIndex: 1600
        },
        buttonClose : {
            position : "absolute",
            top : 0,
            right : 0,
            margin : "20px",
        },
        iconClose : {
            fontSize : "20px",
            height : "20px",
            width : "20px"
        }
    }
);

export { styles };
export { ComponentName };