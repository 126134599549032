/*
 * Class that abstracts storage implementation.
 */
class LocalStorageService {

    /*
     * Constructor.
     */
    constructor() {}

    /*
     * Defines the storage provider to be used in the application.
     * @param storageProvider - instance of the storage provider.
     */
    setLocalStorageProvider(localStorageProvider) {
        this.localStorageProvider = localStorageProvider;
    }

    /*
     * Adds / replaces an entry in the storage.
     * @param key - key of the storage entry.
     * @param value - value to be stored.
     * @return a promise
     */
    set(key, value, toCipher) {
        return this.localStorageProvider.set(key, value,toCipher);
    }

    /**
     * Adds / replaces all entries in the storage.
     * @param {[type]} map object with multiple keys and multiple values
     */
    setAll(map, toCipher) {
        return this.localStorageProvider.setAll(map,toCipher);
    }

    /*
     * Retrieves an entry from the storage.
     * @param key - key of the storage entry.
     * * @return a promise that receives the entry
     */
    get(key) {
        return this.localStorageProvider.get(key);
    }

    /*
     * Removes an entry from the storage.
     * @param key - key of the storage entry.
     * @return a promise
     */
    remove(key) {
        return this.localStorageProvider.remove(key);
    }
}
const localStorageService = new LocalStorageService();
export default localStorageService;
