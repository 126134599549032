import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MenuSearchRender from "./MenuSearchRender";
import { setAllExpanded } from "../../../actions/ui-actions";

class MenuSearch extends Component {

    clearSearch = () => {
        const {changeSearchValue} = this.props;
        changeSearchValue({target : {value : ""}});
    };
    
    textFieldOnFocus = () => {
       this.props.setAllExpanded(true);
    }

    render() {
        const {isSearchOpen, toggleSearchOpen, searchValue, changeSearchValue, i18nProvider} = this.props;
        return (
            <MenuSearchRender isSearchOpen={isSearchOpen}
                              toggleSearchOpen={toggleSearchOpen}
                              searchValue={searchValue}
                              changeSearchValue={changeSearchValue}
                              clearSearch={this.clearSearch}
                              i18nProvider={i18nProvider}
                              textFieldOnFocus={this.textFieldOnFocus}
                              
            />
        );
    }
}

const mapStateToProps = ({shell : {i18nProvider}}) => ({
    i18nProvider
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setAllExpanded
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(MenuSearch);