import { fade } from "@ui-lib/core/styles/colorManipulator";

const ComponentName = "UfeContextAvatar";
const styles = theme => ({
    root : {
    },
    avatar: {
        width: 22,
        height: 22,
        fontSize: 13
    }
});

export { styles };
export { ComponentName };