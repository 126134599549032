import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./MenuPrivateModeStyles";
import MenuIconRoot from "../MenuIconRoot";

function MenuPrivateModeRender({classes, onClick, isPrivate, i18nProvider}) {
    return (
        <MenuIconRoot
            id='UfeMenuIconPrivateMode'
            icon={isPrivate ? "icon-invisible" : "icon-eye"}
            onClick={onClick}
            onClickClosePanel={false}
            title={i18nProvider.Texti18n("private_mode")}
        />
    );
}

export default withStyles(styles, {name : ComponentName})(MenuPrivateModeRender);