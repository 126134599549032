const ComponentName = "UfeCategoryTitle";
const styles = theme => ({
    root : {
        display : "flex",
        alignItems : "center"
    },
    toggleIcon : {
        padding: "4px",
        marginRight : "4px"
    },
    collapsedCategory : {
        padding: "4px",
        marginRight : "4px",
        color: theme.palette.primary.main
    },
    countFont: {
        color: theme.palette.text.secondary
    }
});

export { styles };
export { ComponentName };