import React, { Component } from "react";
import MenuExpandAllRender from "./MenuExpandAllRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setAllExpanded } from "../../../actions/ui-actions";

class MenuExpandAll extends Component {


    expandAll = () => {
        this.props.setAllExpanded(true);
    }

    render() {
        return (
            <MenuExpandAllRender expandAll={this.expandAll} />
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setAllExpanded
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(MenuExpandAll);