import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./AvatarStyles";
import Avatar from '@ui-lib/core/Avatar';

function AvatarRender({classes, activeInteraction, interactionColor }) {
    return (
            <Avatar id='UfeContextAvatar' style={{backgroundColor: interactionColor.backgroundColor, color: interactionColor.textColor}} className={classes.avatar}>{activeInteraction.shortIdentifier}</Avatar>      
    );
}

export default withStyles(styles, {name : ComponentName})(AvatarRender);