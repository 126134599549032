import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./MenuLogoutStyles";
import MenuIconRoot from "../MenuIconRoot";

function MenuLogoutRender({onClick, i18nProvider}) {
    return (
        <MenuIconRoot
            id='UfeUserMenuIconLogout'
            icon={"icon-logout"}
            onClick={onClick}
            onClickClosePanel={true}
            title={i18nProvider.Texti18n("logout")}
        />
    );
}

export default withStyles(styles, {name : ComponentName})(MenuLogoutRender);