/**
 * Created by NB22722 on 14/11/2017.
 */
import { setLoggedUser, setAuthenticationTokens, setCurrentEntitlements } from "omni-shell-common/src/actions/shell-actions";
import { setDefaultUserEntitlements } from "../actions/interactions-actions";
import { UI_INITIAL_STATE } from "../reducers/ui-reducer";
import { Config } from "../config";
import { changeThemeDirection, changeThemeType } from "../actions/actions";
import { Map, List } from "immutable";
import { setDirtyJourneyModalOpen } from "../actions/ui-actions";
import { setAuthenticated } from "../actions/canvas-actions";

export default class AuthenticationHandlers {

    constructor(services) {
        this.services = services;
    }

    setLoggedUser = (username, user, resolve) => {
        user["username"] = username;
        this.services.Store.dispatch(setLoggedUser(user));
        this.services.LocalStorage.set(Config.LOCAL_STORAGE.CURRENT_LOGGED_USER, user, false);
        resolve(user);
    };

    authenticate = (authentication, username, resolve) => {
        let {accessToken, refreshToken, authenticatorName, email, entitlements, firstName, lastName, properties, id} = authentication.data;
        let user = {authenticatorName, firstName, lastName, email, properties, id};

        let {services} = this;

        services.Store.dispatch(setAuthenticationTokens(accessToken, refreshToken));
        services.LocalStorage.set(Config.LOCAL_STORAGE.ACCESS_TOKEN, accessToken, false).catch((error) => console.log(error));
        services.LocalStorage.set(Config.LOCAL_STORAGE.REFRESH_TOKEN, refreshToken, false).catch((error) => console.log(error));
        
        if(entitlements){                        
            services.Store.dispatch(setDefaultUserEntitlements(entitlements));
            services.LocalStorage.set(Config.LOCAL_STORAGE.LOGGED_USER_ENTITLEMENTS, entitlements, true);
            services.Store.dispatch(setCurrentEntitlements(entitlements));

            this.setLoggedUser(username, user, resolve);
                        
        } else{
            services.EntitlementsVerifier.getEntitlementsByURL()
                .then((entitlements) => {
                    services.Store.dispatch(setDefaultUserEntitlements(entitlements));
                    services.LocalStorage.set(Config.LOCAL_STORAGE.LOGGED_USER_ENTITLEMENTS, entitlements, true);
                    this.setLoggedUser(username, user, resolve);                                
                }
                ).catch(() =>{
                    console.log('user entitlements not found!!!');
                    this.setLoggedUser(username, user, resolve);                                
                });
        }
    }

    handleAuthentication = (username, password, authConfig) => {
        const authenticationURL = authConfig.authenticationURL ? authConfig.authenticationURL : Config.AUTHENTiCATION_URL;
        return new Promise(
            (resolve, reject) => {
                if (!username || !password)
                    reject(this.services.I18n.i18nProvider.Texti18n("missing_login"));
                this.services.HttpClient.post(authenticationURL, authConfig.headers, null, null, null, null, {
                    username : username,
                    password : password
                }).then((authentication) => {
                    this.authenticate(authentication,username,resolve);
                }).catch((authError) => {
                    console.log(authError.toString());
                    reject(this.services.I18n.i18nProvider.Texti18n("invalid_login"));
                });
            }
        );
    };

    handleRefreshAuthentication = (token) => {
        return new Promise(
            (resolve, reject) => {
                this.services.HttpClient.post(Config.REFRESH_AUTHENTICATION_URL, {"Accept" : "application/json"}, null, {token : token}).then((authentication) => {
                    let {accessToken, refreshToken} = authentication.data;
                    let {services} = this;
                    //Save the authentication in the store (using the provided storeCallback)
                    services.Store.dispatch(setAuthenticationTokens(accessToken, refreshToken));
                    services.LocalStorage.set(Config.LOCAL_STORAGE.ACCESS_TOKEN, accessToken, true).catch((error) => console.log(error));
                    services.LocalStorage.set(Config.LOCAL_STORAGE.REFRESH_TOKEN, refreshToken, true).catch((error) => console.log(error));
                    resolve();
                }).catch((error) => {
                    let {services} = this;
                    //Clear authentication tokens and user tokens from both localStorage and redux store
                    services.LocalStorage.remove(Config.LOCAL_STORAGE.ACCESS_TOKEN).catch((error) => console.log(error));
                    services.LocalStorage.remove(Config.LOCAL_STORAGE.REFRESH_TOKEN).catch((error) => console.log(error));
                    services.LocalStorage.remove(Config.LOCAL_STORAGE.CURRENT_LOGGED_USER).catch((error) => console.log(error));

                    services.Store.dispatch(setAuthenticationTokens());
                    //services.Store.dispatch(setLoggedUser());

                    console.log(error);
                    reject("Couldn't refresh the authentication tokens. Log in again.");
                });
            }
        );
    };

    handleLogout = () => {
        let {services} = this;
        let defaultLanguage = undefined;
        
        services.Store.dispatch(changeThemeType("light"));
        services.Store.dispatch(changeThemeDirection("ltr"));
        services.Store.getState().i18n.languages.map((language) => {
            if (language.default) {
                defaultLanguage = language.code;
            }
        });
        if (defaultLanguage === undefined && !services.Store.getState().disableOsLanguagePreference) {
            defaultLanguage = services.Store.getState().shell.services.I18n.i18nProvider.deviceInfoProvider.getDeviceLocale();
        } else if (defaultLanguage === undefined) {
            defaultLanguage = "en-US";
        }

        services.I18n.i18nProvider.changeLanguage(defaultLanguage, true);
        services.HttpClient.post(Config.LOG_OUT_URL).catch((error) => console.log(error)).finally(() => {
            services.I18n.i18nProvider.changeLanguage(defaultLanguage, true);
            services.LocalStorage.remove(Config.LOCAL_STORAGE.ACCESS_TOKEN).catch((error) => {
                console.log("Key: " + Config.LOCAL_STORAGE.ACCESS_TOKEN + " not found!");
            });
            let promises = [];
            promises.push(services.LocalStorage.remove(Config.LOCAL_STORAGE.REFRESH_TOKEN));
            promises.push(services.LocalStorage.remove(Config.LOCAL_STORAGE.CURRENT_LOGGED_USER));
            promises.push(services.LocalStorage.remove(Config.LOCAL_STORAGE.LOGGED_USER_ENTITLEMENTS));

            Promise.all(promises).then(() => {
                services.Store.dispatch(setAuthenticated(false));
                services.Store.dispatch(setAuthenticationTokens());
            }).catch(() => {
                services.Store.dispatch(setAuthenticated(false));
                services.Store.dispatch(setAuthenticationTokens());
            })
        });
    };

    handleExplicitLogout = () => {        
        let {services} = this;
        let {interactions : {interactions}, journeys : {journeyHistory}} = services.Store.getState();
        let dirtyJourneys = [];

        interactions.forEach(
            obj => {
                let currentJourneyHistory = journeyHistory.get(obj.instance_id);
                let numberOfOpenJourneys = currentJourneyHistory && currentJourneyHistory.filter(journey => journey.isDirty);
                if (numberOfOpenJourneys && numberOfOpenJourneys.size > 0)
                    dirtyJourneys.push(numberOfOpenJourneys);
            }
        );
        if (dirtyJourneys.length > 0) {
            services.Store.dispatch(setDirtyJourneyModalOpen(true, Config.CLOSE_JOURNEY_SCOPE.EXPLICIT_LOGOUT));
        } else {
            services.Store.dispatch(setDirtyJourneyModalOpen(false, Config.CLOSE_JOURNEY_SCOPE.EXPLICIT_LOGOUT));
        }
    };

    clearStoreState = () => {
        let {services} = this;
        let { journeys: { startingJourney } } = services.Store.getState();   

        let default_journey = Object.assign({}, startingJourney);
        let new_journey_history = startingJourney ? new Map().set("default",List([default_journey])) :
            new Map();

        let new_open_journeys; 
        if(startingJourney){
            new_open_journeys = new Map().set(startingJourney.type === 'unique' ? 'unique' : 'default', List([startingJourney]));
        } else new_open_journeys = new Map();
            
            
        var default_interaction = {
            context : null,
            iconLabel : "DF",
            instance_id : "default",
            interactionType : "manual",
            label : "Default",
            id : 1
        };

        let clear_object = {
            interactions : {
                interactionType : null,
                interactions : List([default_interaction]),
                currentInteraction : default_interaction
            },
            journeys : {
                startingJourney : default_journey,
                currentJourney : default_journey,
                journeyHistory : new_journey_history,
                openJourneys : new_open_journeys
            },
            canvas : {
                personalAvailability : "Available",
                widgets : []
            },
            ui : {
                ...UI_INITIAL_STATE
            }
        };
        
        services.Store.dispatch({type : "CLEAN_STORE", payload : clear_object});
    };
}
