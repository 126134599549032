const ComponentName = "OpenInteractionAction";
const styles = theme => ({
    root: {
        minHeight: '47px',
        height: '47px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        height: '100%'
    },
    icon: {
        marginRight: '10px',
        fontSize: '16px'
    },
    interactionOverflow: {
        boxShadow: '0 -4px 4px 0 rgba(51, 51, 51, 0.2)',
    },
    unfilledList: {
        borderTop: '1px solid ' + theme.palette.grey[400]
    }
});

export { styles };
export { ComponentName };