const ComponentName = "UfeClickAwayListenerIframeFix";
const styles = theme => ({
    root : { //Iframe Fix (If no overlay is present clicks on iframe are not detected )
        position : "absolute",
        top : 0,
        left : 0,
        zIndex : 'auto',
        width : "100%",
        height : "100vh"
    }
});

export { styles };
export { ComponentName };