import React, { Component } from "react";
import ClickAwayListenerRender from "./ClickAwayListenerRender";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class ClickAwayListener extends Component {

    static propTypes = {
        startOnHeader : PropTypes.bool
    };

    static defaultProps = {
        startOnHeader : false
    };

    escPressed = (event) => {
        if (event.keyCode === 27) {
            this.onClickAway();
        }
    };

    onClickAway = (event) => {
        const {onClickAway, disableClickAway} = this.props;
        !disableClickAway && onClickAway && onClickAway();
    };

    componentWillMount() {
        try {
            window.addEventListener("keyup", this.escPressed);
        } catch (e) {
        }
    }

    UNSAFE_componentWillMount() {
        try {
            window.removeEventListener("keyup", this.escPressed);
        } catch (e) {
        }
    }

    render() {
        const {onClickAway, children, ...others} = this.props;
        return (
            <ClickAwayListenerRender onClickAway={this.onClickAway} {...others}>
                {children}
            </ClickAwayListenerRender>
        );
    }
}

const mapStateToProps = ({ui : {disableClickAway}}) => ({disableClickAway});

export default connect(mapStateToProps, undefined)(ClickAwayListener);