import React, { Component } from "react";
import MenuIconRender from "./MenuIconRender";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setUserMenuOpen } from "../../../../../../actions/ui-actions";

class MenuIconRoot extends Component {

    static propTypes = {
        icon : PropTypes.string.isRequired,
        onClick : PropTypes.func.isRequired,
        onClickClosePanel : PropTypes.bool.isRequired,
        title : PropTypes.node
    };

    onClick = () => {
        const {setUserMenuOpen, onClick, onClickClosePanel} = this.props;
        onClickClosePanel && setUserMenuOpen(false);
        onClick && onClick();
    };

    render() {
        const {icon, title, id} = this.props;
        return (
            <MenuIconRender id={id} title={title} icon={icon} onClick={this.onClick}/>
        );
    }
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setUserMenuOpen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(MenuIconRoot);