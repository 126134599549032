import React, { Component } from "react";
import MenuItemRender from "./MenuItemRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class MenuItem extends Component {

    render() {
        const { isSelected, chooseGenericExtension, extension, id } = this.props;
        return (
            <MenuItemRender id={id} isSelected={isSelected} chooseGenericExtension={chooseGenericExtension}
                extension={extension} />
        );
    }
}

const mapStateToProps = ({ }) => ({});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);