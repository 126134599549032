const ComponentName = "UfeGlobalSearchOption";
const styles = theme => ({
    option : {
        height : "38px",
        backgroundColor : theme.palette.grey[50],
        border : "1px solid " + theme.palette.grey[400],
        cursor : "pointer",
        display : "flex",
        alignItems : "center"
    },
    optionSelected : {
        backgroundColor : theme.palette.primary.main,
        color : theme.palette.common.white
    },
    optionIconContainer : {
        marginLeft : "8px"
    },
    optionIcon : {
        width : "18px",
        height : "18px",
        display : "flex",
        alignItems : "center",
        fontSize : "18px",
    },
    optionSubText : {
        display : "flex",
        alignItems : "center",
        marginLeft : "8px",
        whiteSpace : "nowrap",
        textOverflow : "ellipsis",
        overflow : "hidden"
    },
    optionMainText : {
        flex : 1,
        display : "flex",
        alignItems : "center",
        marginLeft : "8px",
        whiteSpace : "nowrap",
        textOverflow : "ellipsis",
        overflow : "hidden"
    }
});

export { styles };
export { ComponentName };