import React, { Component } from "react";
import UserWidgetRender from "./UserWidgetRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setUserMenuOpen } from "../../../actions/ui-actions";

class UserWidget extends Component {

    openUserMenu = () => {
        const {setUserMenuOpen, isUserWidgetMenuOpen} = this.props;
        if (isUserWidgetMenuOpen) {
            setUserMenuOpen(false);
            return;
        }
        setUserMenuOpen(true);
    };

    setRef = (node) => {
        this.anchorEl = node;
    };

    render() {
        return (
            <UserWidgetRender toggleUserMenu={this.openUserMenu} setRef={this.setRef} buttonRef={this.anchorEl}/>
        );
    }
}

const mapStateToProps = ({ui : {isUserWidgetMenuOpen}}) => ({isUserWidgetMenuOpen});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setUserMenuOpen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(UserWidget);