import React, { Component } from "react";
import SearchListRender from "./SearchListRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setSearchContextOpen } from "../../../../../actions/ui-actions";
import { addInteractionWithJourneys, setCurrentInteraction, setInteractionTimer } from "../../../../../actions/interactions-actions";

class SearchList extends Component {

    state = {
        isSearchContextMenuOpen: false,
        displayedData: [],
        doSearchOnPressEnterKey: true,
        selected: undefined,
        searchValue: "",
        valueSearched: ""
    };


    componentDidMount() {
        try {
            document.addEventListener("keydown", this.handleKeyDown);
        } catch (err) {
        }
    };

    componentWillUnmount() {
        try {
            document.removeEventListener("keydown", this.handleKeyDown);
        } catch (err) {
        }
    };

    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.searchClick();
        }
    };

    onClearClick = () => {
        this.setState({
            searchValue: "",
            displayedData: [],
            doSearchOnPressEnterKey: true,
            valueSearched: ""
        });
    };

    searchClick = () => {
        const { searchValue } = this.state;
        const { sendEventHandler, currentInteraction, threesixtyConfigs } = this.props;

        this.setState({ valueSearched: searchValue });

        sendEventHandler.send360Search(currentInteraction.instance_id, searchValue).then(res => {
            if (res.length === 1 && threesixtyConfigs.singleSearchResultAutomaticContext) {
                this.onItemClick(res[0]);
            } else {
                this.setState({ displayedData: res, doSearchOnPressEnterKey: false, selected: res.length > 0 ? 0 : undefined });
            }
        }).catch(this.setState({ doSearchOnPressEnterKey: false }));
    };

    onChange = (event) => {
        const { doSearchOnPressEnterKey } = this.state;
        if (doSearchOnPressEnterKey) {
            this.setState({ searchValue: event.target.value });
        } else {
            this.setState({ doSearchOnPressEnterKey: true, searchValue: event.target.value });
        }
    };

    closeMenu = (event) => {
        const { setSearchContextOpen, buttonRef } = this.props;
        if (buttonRef && event && buttonRef.contains(event.target)) {
            return;
        }
        setSearchContextOpen(false);
    };

    onItemClick = (data) => {
        const { sendEventHandler, setCurrentInteraction, currentInteraction, addInteractionWithJourneys, toggleSearchOpen, threesixtyConfigs, interactions } = this.props;
        if (interactions.size <= threesixtyConfigs.interactionsLimit || !threesixtyConfigs.interactionsLimit) {
            sendEventHandler.set360Client(currentInteraction.instance_id, data).then(context => {
                let repeatedInteractionFound = false;
                if (!threesixtyConfigs.allowMultipleNewInteractionsWithSameCustomer) {
                    interactions.map(interaction => {
                        if ((interaction.id === context.id)) {
                            setCurrentInteraction(interaction.instance_id);
                            repeatedInteractionFound = true;
                            return;
                        }
                    })
                }
                toggleSearchOpen();
                if (repeatedInteractionFound) 
                    return
                addInteractionWithJourneys(context);
            })
        } else if (interactions.size > threesixtyConfigs.interactionsLimit) toggleSearchOpen();
    };

    handleKeyDown = (e) => {
        const { selected, displayedData, doSearchOnPressEnterKey } = this.state;
        if (e.keyCode === 13) {
            if (displayedData && displayedData[selected] && !doSearchOnPressEnterKey) {
                this.onItemClick(displayedData[selected]);
            }
        }
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
        if (e.keyCode === 38 && selected > 0) {
            if (selected < displayedData.length - 2) {
                let element = document.getElementById("UfeContextSearchOption_" + displayedData[selected].id);
                element && element.scrollIntoView(false);
            }
            this.setState(prevState => ({
                selected: prevState.selected - 1
            }));
        } else if (e.keyCode === 40 && selected < displayedData.length - 1) {
            if (selected > 1) {
                let element = document.getElementById("UfeContextSearchOption_" + displayedData[selected - 2].id);
                element && element.scrollIntoView(true);
            }
            this.setState(prevState => ({
                selected: prevState.selected + 1
            }));
        }
    };

    addInteractionWithoutContext = () => {
        const { interactions, threesixtyConfigs, toggleSearchOpen, addInteractionWithJourneys } = this.props;
        if (interactions.size <= threesixtyConfigs.interactionsLimit || !threesixtyConfigs.interactionsLimit) {

            let no_customer_object = {
                identifier: "No Customer",
                shortIdentifier: "NC"
            }

            addInteractionWithJourneys(no_customer_object);
            toggleSearchOpen();
        } else if (interactions.size > threesixtyConfigs.interactionsLimit) toggleSearchOpen();
    }

    render() {
        const { displayedData, selected, searchValue, valueSearched } = this.state;
        const { i18nProvider, toggleSearchOpen } = this.props;
        return (
            <SearchListRender
                data={displayedData}
                selected={selected}
                onItemClick={this.onItemClick}
                onClearClick={this.onClearClick}
                onChange={this.onChange}
                closeMenu={this.closeMenu}
                searchClick={this.searchClick}
                searchValue={searchValue}
                onKeyPress={this.onKeyPress}
                toggleSearchOpen={toggleSearchOpen}
                i18nProvider={i18nProvider}
                addInteractionWithoutContext={this.addInteractionWithoutContext}
                valueSearched={valueSearched}
            />
        );
    };
};

const mapStateToProps = ({ ui: { isSearchListMenuOpen }, shell: { entitlementsMapper, i18nProvider }, canvas: { sendEventHandler, config: { threesixtyConfigs } }, interactions: { currentInteraction, interactions } }) =>
    ({ isSearchListMenuOpen, entitlementsMapper, sendEventHandler, currentInteraction, threesixtyConfigs, interactions, i18nProvider });

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setSearchContextOpen,
        addInteractionWithJourneys,
        setCurrentInteraction,
        setInteractionTimer
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(SearchList);