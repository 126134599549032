import React, { Component } from "react";
import SettingsPanelRender from "./SettingsPanelRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setThemeType, setThemeDirection } from "omni-shell-common/src/actions/theme";
import { setDisableClickAway } from "../../../actions/ui-actions";

class SettingsPanel extends Component {

    state = {
        optionsType : [
            {value : "light", label : "Light"},
            {value : "dark", label : "Dark"}
        ],
        optionsDirection : [
            {value : "ltr", label : "Left-to-Right"},
            {value : "rtl", label : "Right-to-Left"}
        ],
        selectOpen : false
    };

    onTypeClick = (element) => {
        const {setThemeType} = this.props;
        setThemeType(element.value);
    };

    onDirectionClick = (element) => {
        const {setThemeDirection} = this.props;
        setThemeDirection(element.value);
    };

    openSelect = () => {
        const {setDisableClickAway} = this.props;
        setDisableClickAway(true);
        this.setState({selectOpen : true});
    };

    closeSelect = () => {
        this.disableClickAway();
        this.setState({selectOpen : false});
    };

    onLanguageChange = (event) => {
        const {i18nProvider} = this.props;
        i18nProvider.changeLanguage(event.target.value);
    };

    disableClickAway = () => {
        const {disableClickAway, setDisableClickAway} = this.props;
        if (disableClickAway) {
            setDisableClickAway(false);
        }
    };

    componentWillUnmount() {
        this.disableClickAway();
    }

    render() {
        const {type, direction, currentLanguage, languages, i18nProvider, entitlementsMapper} = this.props;
        const {optionsDirection, optionsType, selectOpen} = this.state;
        return (
            <SettingsPanelRender optionsType={optionsType}
                                 optionsDirection={optionsDirection}
                                 selectedType={type}
                                 selectedDirection={direction}
                                 onTypeClick={this.onTypeClick}
                                 onDirectionClick={this.onDirectionClick}
                                 onLanguageChange={this.onLanguageChange}
                                 currentLanguage={currentLanguage}
                                 languages={languages}
                                 openSelect={this.openSelect}
                                 closeSelect={this.closeSelect}
                                 selectOpen={selectOpen}
                                 i18nProvider={i18nProvider}
                                 entitlements={entitlementsMapper}
            />
        );
    }
}

const mapStateToProps = ({theme : {type, direction}, i18n : {currentLanguage, languages}, shell : {i18nProvider, entitlementsMapper}, ui : {disableClickAway}}) => ({
    type,
    direction,
    currentLanguage,
    languages,
    disableClickAway,
    i18nProvider,
    entitlementsMapper
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setThemeDirection,
        setThemeType,
        setDisableClickAway
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPanel);