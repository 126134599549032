import {
    SET_OS_LANGUAGE_PREFERENCE,
    SET_SERVICES,
    SET_LOGGED_USER,
    SET_CANVAS_NAME,
    SET_ROOT_PROJECT,
    SET_AUTHENTICATION_TOKENS,
    SET_I18NPROVIDER,
    SET_CONTENT_URL,
    SET_HOST_PORT,
    SET_CURRENT_ENTITLEMENTS,
    ADD_CONTEXT_ENTITLEMENTS,
    REMOVE_CONTEXT_ENTITLEMENTS,
    SET_SHELL_DEPENDENCY,
    SET_CANVAS_DEPENDENCY_RESOLVED,
    SET_ENTITLEMENTS_MAPPER,
    SET_GEO_LOCATION,
    SET_RUN_MODE,
    SET_LOGGED_USER_PREFS,
    SET_I18N_READY,
    SET_THEME_READY,
    SET_UFE_RENDER_STATE,
    SET_PRIVATE_MODE,
    SET_AVAILABILITY,
    TOGGLE_FULL_SCREEN,
    SET_PREVIOUS_LOGGED_USER,
    SET_ENTITLEMENTS_URL,
    SET_MENU_FAVOURITE_PREFS,
    REMOVE_MENU_FAVOURITE_PREFS,
    ADD_MENU_FAVOURITE_PREFS,
    SET_AUTHENTICATION_TYPE,
    SET_SAML_AUTH_LOGOUT_URL
} from "../actions/shell-actions";
import { List, Map } from "immutable";

const INITIAL_STATE = {

    //ufe state
    isUfeRendered: false,
    services : undefined,
    canvasName : "",
    rootProject : "",    
    personalAvailability : "Available",

   
    //authentication
    accessToken : "",
    refreshToken : "",
    authenticationType: "",
    samlAuthUrl: "",
    currentLoggedUser : undefined,
    previousLoggedUser : undefined,
    loggedUserPrefs : undefined,
    menuFavouritePrefs : undefined,

    //i18nCanvasProvider
    i18nProvider : undefined,
    disableOsLanguagePreference: undefined,

    //server settings
    contentUrl : "",
    hostPort : "",
    runMode : "",

    //EntitlementsVerifier
    entitlementsURL : undefined,
    currentEntitlements : undefined,
    contextEntitlements : List(),
    entitlementsMapper : {},

    //dependencies
    shellDependencies : Map({i18n : false, theme : false, auth : true, geoLocation : false}),
    canvasDependencies : false,
    themeReady : false,
    i18nReady : false,

    //geolocation
    geoLocation : undefined,

    //canvas options
    isPrivate : false,
    fullScreen : false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SERVICES:
            return {...state, services : action.services};
        case SET_LOGGED_USER:
            return {...state, currentLoggedUser : action.userData};
        case SET_PREVIOUS_LOGGED_USER:
            return{...state, previousLoggedUser : action.userData};
        case SET_CANVAS_NAME:
            return {...state, canvasName : action.canvasName};
        case SET_ROOT_PROJECT:
            return {...state, rootProject : action.rootProject};
        case SET_AUTHENTICATION_TOKENS:
            return {...state, accessToken : action.token, refreshToken : action.refreshToken};
        case SET_AUTHENTICATION_TYPE:
            return {...state, authenticationType : action.value}
        case SET_SAML_AUTH_LOGOUT_URL:
            return {...state, samlAuthUrl : action.value}
        case SET_I18NPROVIDER:
            return {...state, i18nProvider : action.i18nProvider};
        case SET_CONTENT_URL:
            return {...state, contentUrl : action.contentUrl};
        case SET_HOST_PORT:
            return {...state, hostPort : action.hostPort};
        case SET_RUN_MODE:
            return {...state, runMode : action.runMode};
        case SET_SHELL_DEPENDENCY:
            return {...state, shellDependencies : state.shellDependencies.set(action.dependency, true)};
        case SET_CANVAS_DEPENDENCY_RESOLVED:
            return {...state, canvasDependencies : true};
        case SET_CURRENT_ENTITLEMENTS:
            if (state.contextEntitlements.contains(action.entitlements))
                return {...state, currentEntitlements : action.entitlements};
            return {...state, currentEntitlements : action.entitlements, contextEntitlements : state.contextEntitlements.push(action.entitlements)};
        case ADD_CONTEXT_ENTITLEMENTS:
            if (state.contextEntitlements.contains(action.entitlements))
                return state;
            return {...state, contextEntitlements : state.contextEntitlements.push(action.entitlements)};
        case REMOVE_CONTEXT_ENTITLEMENTS:
            return {...state, contextEntitlements : state.contextEntitlements.delete(action.entitlements)};
        case SET_ENTITLEMENTS_MAPPER:
            return {...state, entitlementsMapper : action.entitlements};
        case SET_GEO_LOCATION:
            return {...state, geoLocation : action.geolocation, shellDependencies : state.shellDependencies.set("geoLocation", true)};
        case SET_LOGGED_USER_PREFS:
            return {...state, loggedUserPrefs : action.userPrefs};
        case SET_MENU_FAVOURITE_PREFS:
            return {...state, menuFavouritePrefs : action.menuPrefs};
        case ADD_MENU_FAVOURITE_PREFS:
            var newFavouritePrefs = Object.assign({},{}, state.menuFavouritePrefs);
            newFavouritePrefs["properties"] = state.menuFavouritePrefs.properties.concat([action.value]);
            return {...state, menuFavouritePrefs: newFavouritePrefs};
        case REMOVE_MENU_FAVOURITE_PREFS:
            var newFavouritePrefs = Object.assign({},{}, state.menuFavouritePrefs);
            newFavouritePrefs["properties"] = state.menuFavouritePrefs.properties.filter(e => e.value != action.value);
            return {...state, menuFavouritePrefs: newFavouritePrefs};
        case SET_THEME_READY:
            return {...state, themeReady : action.payload};
        case SET_I18N_READY:
            return {...state, i18nReady : action.value};
        case SET_UFE_RENDER_STATE:
            return {...state, isUfeRendered: action.value};
        case SET_OS_LANGUAGE_PREFERENCE:
            return {...state, disableOsLanguagePreference: action.payload};
        case SET_PRIVATE_MODE:
            return ({...state, isPrivate : action.isPrivate});
        case SET_AVAILABILITY:
            return ({...state, personalAvailability : action.newAvailability});
        case TOGGLE_FULL_SCREEN:
            return {...state, fullScreen : !state.fullScreen};
        case SET_ENTITLEMENTS_URL:
            return {...state, entitlementsURL : action.entitlementsURL};
        default:
            return state;
    }
};
