import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./UserInfoStyles";
import Typography from "@ui-lib/core/Typography";

function UserInfoRender({classes, userInfo}) {
    return (
        <div id='UfeUserInfo' className={classes.root}>
            <Typography variant="caption" className={classes.mainLabel}>
                {userInfo.user_detail_main_label}
            </Typography>
            <Typography variant="caption" className={classes.positionLabel}>
                {userInfo.user_detail_main_position_label}
            </Typography>
            <Typography variant="caption" className={classes.secondaryLabel}>
                {userInfo.user_detail_secondary_label}
            </Typography>
        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(UserInfoRender);