import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./UserWidgetStyles";
import ButtonBase from "@ui-lib/core/ButtonBase";
import UserAvatar from "./UserAvatar";
import UserInfo from "./UserInfo";
import UserMenu from "./UserMenu";

function UserWidgetRender({classes, toggleUserMenu, setRef, buttonRef}) {
    return (
        <React.Fragment>
            <ButtonBase id='UferUserWidget' className={classes.root} onClick={toggleUserMenu} component={"div"} buttonRef={setRef}>
                <UserInfo/>
                <UserAvatar/>
            </ButtonBase>
            <UserMenu buttonRef={buttonRef}/>
        </React.Fragment>
    );
}

export default withStyles(styles, {name : ComponentName})(UserWidgetRender);