import React, { Component } from "react";
import AvatarRender from "./AvatarRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Avatar extends Component {


    render() {
        const { theme, activeInteraction, currentInteraction } = this.props;
        return (
            <AvatarRender currentInteraction={currentInteraction} interactionColor={theme.interactionColorPalette[activeInteraction.colorIndex]} activeInteraction={this.props.activeInteraction} />
        );
    }
}

const mapStateToProps = ({ theme: { theme }, interactions: { activeInteraction, currentInteraction } }) => ({ activeInteraction, theme , currentInteraction});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);