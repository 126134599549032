import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./JourneyMenuStyles";
import Grid from "@ui-lib/core/Grid";
import JourneysCategorySection from "./JourneysCategorySection";
import JourneysFavouriteCategorySection from "./JourneysFavouriteCategorySection";

function JourneyMenu({ classes, itemsToRender }) {
    return (
        <Grid id='UfeJourneyMenu' container className={classes.root}>
            {<JourneysFavouriteCategorySection />}
            {itemsToRender && itemsToRender.map((category, i) => {
                return (<JourneysCategorySection key={i} category={category} id={i} />
                );
            })}
        </Grid>
    );
}
export default withStyles(styles, { name: ComponentName })(JourneyMenu);