const ComponentName = "UfeContextUserName";
const styles = theme => ({
    title: {
        textTransform: "none",
        marginLeft: 10,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: "calc(100vw / 6 - 94px)"
    }
});

export { styles };
export { ComponentName };