import React, { Component } from "react";
import SearchItemRender from "./SearchItemRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

class SearchItem extends Component {

    static propTypes = {
        icon : PropTypes.string,
        primaryName : PropTypes.string,
        secondaryName : PropTypes.string,
        number : PropTypes.string,
        optionId : PropTypes.any,
        selected : PropTypes.bool,
        onMouseOver : PropTypes.func

    };

    onClick = () => {
        const {onClick, optionId} = this.props;
        onClick && onClick(optionId);
    };
    
    render() {

        const {selected, primaryName, secondaryName, number, icon, id} = this.props;
        
        return (
            <SearchItemRender
                onClick={this.onClick}
                selected={selected}
                primaryName={primaryName}
                secondaryName={secondaryName}
                number={number}
                icon={icon}
                id={id}
            />
        );
    }
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => bindActionCreators(
    {},
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(SearchItem);