import React, { Component } from "react";
import WidgetCardRender from "./WidgetCardRender";
import PropTypes from "prop-types";

class WidgetCard extends Component {

    static propTypes = {
        imgThumbnail : PropTypes.string,
        title : PropTypes.node.isRequired,
        subtitle : PropTypes.node,
        onClick : PropTypes.func,
        callArg : PropTypes.any,
    };

    static defaultProps = {
        imgThumbnail : undefined,
        title : "Journey title",
        subtitle : undefined,
    };

    state = {
        isSelected : false
    };

    onClick = () => {
        const {onClick, callArg} = this.props;
        onClick && onClick(callArg);
        this.setState(prevstate => ({isSelected : !prevstate.isSelected}));
    };

    render() {
        const {imgThumbnail, title, subtitle, id} = this.props;
        const {isSelected} = this.state;
        return (
            <WidgetCardRender id={id} imgThumbnail={imgThumbnail} title={title} subtitle={subtitle} onClick={this.onClick} isSelected={isSelected}/>
        );
    }
}

export default WidgetCard;