const ComponentName = "UfeSessionExpired";
const styles = theme => ({
    root : {
        color : theme.palette.text.primary
    },
    titleContainer : {
        display : "inline-flex"
    },
    iconButtonClose : {
        float : "right",
        marginLeft : "50px"
    },
    iconClose : {
        height : "22px",
        width : "22px",
        fontSize : "22px"
    },
    contentPaddingFix : {
        padding : "0px 40px 20px"
    }
});

export { styles };
export { ComponentName };