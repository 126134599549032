import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./MenuItemStyles";
import Icon from "@ui-lib/core/Icon";
import Typography from '@ui-lib/core/Typography';
import IconButton from '@ui-lib/core/IconButton';
import Avatar from '@ui-lib/core/Avatar';
import classNames from '@ui-lib/core/classNames';

function MenuItemRender({ classes, onItemClick, identifier, shortIdentifier, closeInteraction, startedTime, interactionColor, id }) {
    return (
        <div id={'UfeOpenInteractionsMenuItem_' + id} className={classes.root} onClick={onItemClick}>
            <div className={classes.container}>
                <Avatar style={{backgroundColor: interactionColor.backgroundColor, color: interactionColor.textColor}} className={classes.avatar}>{shortIdentifier}</Avatar>
                <div className={classes.textContainer}>
                    <Typography className={classes.title} variant="body2">
                        {identifier}<br />
                    </Typography>
                    <Typography className={classes.time} variant="overline">
                        {startedTime}
                    </Typography>
                </div>
            </div>
            <IconButton id={'UfeOpenInteractionsMenuItemButton_' + id} className={classes.iconButton} aria-label="close" onClick={closeInteraction}>
                <Icon className={classNames(classes.icon, "icon-close")} />
            </IconButton>
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(MenuItemRender);