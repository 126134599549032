const ComponentName = "UfeJourneyInfo";
const styles = theme => ({
    root : {
        flex : 1
    },
    journeyTitle : {
        marginLeft : 14
    },
    subtitleDivider : {
        color : theme.palette.primary.main,
        marginLeft : 10,
        marginRight : 10
    },
    iconClose : {
        width : 12,
        height : 12,
        fontSize : 12
    },
    iconButton: {
        marginLeft: 10
    },
    breadcrumbClickable: {
        cursor: 'pointer'
    }
});

export { styles };
export { ComponentName };