import locale2 from 'locale2';

class DeviceInfoProvider {
    constructor() {}

    getDeviceLocale() {
        return locale2;
    }
}

const deviceInfoProvider = new DeviceInfoProvider();
export default deviceInfoProvider;
