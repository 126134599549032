import { fade } from "@ui-lib/core/styles/colorManipulator";

const ComponentName = "MenuItem";
const styles = theme => ({
    root: {
        color: theme.palette.text.primary,
        display: '-webkit-box',
        display: '-ms-flexbox',
        display: "flex",
        '-webkit-box-align': 'center',
        '-ms-flex-align': 'center',
        alignItems: "center",
        width: "100%",
        height: '50px',
        borderBottom: "1px solid" + theme.palette.grey[400],
        cursor: "pointer",
        "&:hover": {
            backgroundColor: fade(theme.palette.common.black, theme.palette.action.hoverOpacity),
        },
        '&:last-child': {
            borderBottom: '0px !important'
        }
    },
    container: {
        width: '100%',
        height: '100%',
        display: "flex",
        alignItems: "center"
    },
    title: {
        marginLeft: 16
    },
    textContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center'
    },
    isSelected: {
        color: theme.palette.primary.main,
        fontWeight: 300
    },
    dot: {
        height: 8,
        width: 8,
        marginLeft: "auto",
        marginRight: 16,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        display: "inline-block"
    }
});

export { styles };
export { ComponentName };