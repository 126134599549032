/**
 * Created by NB22722 on 30/10/2017.
 */
import {
    ADD_INTERACTION,
    REMOVE_INTERACTION,
    REMOVE_ALL_INTERACTIONS,
    SET_CURRENT_INTERACTION,
    UPDATE_CONTEXT_SELECTED,
    SET_WIDGETS,
    SET_INTERACTION_ENTITLEMENTS,
    SET_DEFAULT_USER_ENTITLEMENTS,
    SET_JOURNEY_DIRTY_ID,
    TOGGLE_360_VISIBILITY,
    SET_INTERACTION_TIMER,
    REMOVE_ALL_OPENED_INTERACTIONS

} from "../actions/interactions-actions";
import { List } from "immutable";
import _ from "lodash";

var default_interaction = generateInteraction(true);
var colorIndex = -1;
export const INTERACTIONS_INITIAL_STATE = {
    /**
     * NEW VERSION
     */
    search360results: [],
    currentInteraction: default_interaction,
    interactions: List([default_interaction]),
    threesixtyOpen: true,
    activeInteraction: undefined,
    /**
     * OLD VERSION
     */
    // Type of interaction (manual, cti, inline)
    interactionType: null,
    search_payload: undefined,

    // Default user entitlements
    defaultUserEntitlements: undefined,

    userInteraction: {
        id: 0,
        searchHistory: []
    },
    journeyDirtyId: undefined,

    //360 content control

    tool_bar_config: undefined,
    menuActive: false,
    widgetList: List(),
    interactionsHistory: List(),
};

export default (state = INTERACTIONS_INITIAL_STATE, action) => {
    var interaction;

    switch (action.type) {
        /**
         * ADD INTERACTION;
         * REMOVE INTERACTION;
         * REMOVE ALL INTERACTIONS
         * ADD INTERACTION WITH CONTEXT
         * SET CURRENT INTERACTION
         */
        case ADD_INTERACTION:
            //The value here is -1 due to the fact that we are incrementing further below
            if(colorIndex === 40) colorIndex = -1;

            colorIndex++;
            
            let new_interaction = generateInteraction(false, action.context, action.instance_id, colorIndex);
            //logic for interaction history
            if (state.interactions.size > 1 && action.instance_id !== 'default') {
                //we want only show the history when we have more than two interactions
                //inserts the current interaction into the head of the interaction history list.
                return {
                    ...state, currentInteraction: new_interaction, interactions: state.interactions.push(new_interaction),
                    interactionsHistory: state.interactionsHistory.insert(state.interactionsHistory.size + 1, state.activeInteraction), activeInteraction: new_interaction
                };
            }
            //is the second interaction so we don't need to add it into the interaction history.
            return { ...state, currentInteraction: new_interaction, interactions: state.interactions.push(new_interaction), activeInteraction: new_interaction };


        case REMOVE_INTERACTION:
            var interactionIndex = state.interactions.findIndex(elem => (elem.instance_id === action.instance_id)
                && (elem.instance_id !== "default"));

            if (!interactionIndex || interactionIndex === -1)
                return state;
            
            //logic for interaction history
            // if the interaction doesn't exist in the interaction list will not exist in the interaction history.
            // the default interaction will never be in the interaction history.
            var itemHistoryIndex = state.interactionsHistory.findIndex(elem => (elem.instance_id === action.instance_id));
            if (itemHistoryIndex !== -1) {
                return {
                    ...state, currentInteraction: state.activeInteraction,
                    interactions: state.interactions.delete(interactionIndex),
                    interactionsHistory: state.interactionsHistory.delete(itemHistoryIndex)
                };
            }
            // if the interaction you are trying to remove isn't in the interaction history but there are still interactions in the history.
            // this will remove the active interaction and replace it by the first interaction in the history while deleting it from that said history.
            if (itemHistoryIndex === -1 && state.interactionsHistory.size > 0) {
                return {
                    ...state, currentInteraction: state.interactions.get(0),
                    interactions: state.interactions.delete(interactionIndex), activeInteraction: state.interactionsHistory.get(0),
                    interactionsHistory: state.interactionsHistory.delete(0)
                };
            }

            return {
                ...state, currentInteraction: state.interactions.get(0),
                interactions: state.interactions.delete(interactionIndex), activeInteraction: undefined
            };
        case REMOVE_ALL_INTERACTIONS:
            var default_interaction = generateInteraction(true);
            return {
                ...state, interactions: List([default_interaction]), currentInteraction: default_interaction,
                interactionsHistory: List(), activeInteraction: undefined
            };
        case SET_CURRENT_INTERACTION:
            if (action.instance_id !== 'default' && state.interactionsHistory.size > 0) {
                const itemHistoryIndexToRemove = state.interactionsHistory.findIndex(elem => (elem.instance_id === action.instance_id));
                if (itemHistoryIndexToRemove !== -1) {
                    var newInteractionsHistory = state.interactionsHistory.delete(itemHistoryIndexToRemove);
                    newInteractionsHistory = newInteractionsHistory.insert(newInteractionsHistory.size + 1, state.activeInteraction);
                    return {
                        ...state, currentInteraction: state.interactions.find(elem => elem.instance_id === action.instance_id),
                        interactionsHistory: newInteractionsHistory, activeInteraction: state.interactions.find(elem => elem.instance_id === action.instance_id)
                    }
                } else return {
                    ...state, currentInteraction: state.interactions.find(elem => elem.instance_id === action.instance_id),
                    interactionsHistory: state.interactionsHistory, activeInteraction: state.activeInteraction
                }
            }
            return { ...state, currentInteraction: state.interactions.find(elem => elem.instance_id === action.instance_id) };
        case UPDATE_CONTEXT_SELECTED:
            return updateCurrentInteraction(state, action.context);
        case TOGGLE_360_VISIBILITY:
            return { ...state, threesixtyOpen: action.value };

        /**
     * Old Version Implementation
     */
        case SET_JOURNEY_DIRTY_ID:
            return { ...state, journeyDirtyId: action.id };
        case SET_DEFAULT_USER_ENTITLEMENTS:
            if (state.interactions && state.interactions.size > 0) {
                var default_interaction = state.interactions.get(0);
                default_interaction.currentEntitlements = action.defaultUserEntitlements;

                return {
                    ...state, interactions: state.interactions.set(0, default_interaction),
                    defaultUserEntitlements: action.defaultUserEntitlements
                };
            } else {
                return { ...state, defaultUserEntitlements: action.defaultUserEntitlements };
            }
        case SET_INTERACTION_ENTITLEMENTS:
            var index = !action.instance_id ? state.interactions.findIndex(obj => obj.instance_id === state.currentInteraction.instance_id)
                : state.interactions.findIndex(obj => obj.instance_id === action.instance_id);

            if (index === -1) {
                console.error("No interaction was found.");
                return state;
            }

            interaction = state.interactions.get(index);
            interaction.currentEntitlements = action.entitlements;

            return {
                ...state, currentInteraction: interaction,
                interactions: state.interactions.set(index, interaction)
            };

        /**
          * End of New Version Implementation
          */

        /**
         * CONTEXT LEVEL WILL NEED A REFACTOR SINCE IT NEEDS TO BE STORES ON THE JOURNEY
         */
        /**
       * 
       */
        case SET_WIDGETS:
            return Object.assign({}, state, { widgetList: List(action.widgets) });
        case SET_INTERACTION_TIMER:
            return changeInteractionTime(state, action.instance_id, action.time);

        case REMOVE_ALL_OPENED_INTERACTIONS:
            //keep the state of the default interaction, clear the interaction history and change the current interaction to the default.
            var defaultInteractionIndex = state.interactions.findIndex(elem => (elem.instance_id === "default"));
            var defaultInteraction = state.interactions.get(defaultInteractionIndex);
            return {
                ...state, interactions: List([defaultInteraction]), currentInteraction: defaultInteraction, interactionsHistory: List()
            };
        default:
            return state;
    }
}

function generateInteraction(default_name, context, default_instance_id, color_index) {
    const startedTime = Date.now();
    let instance_id;
    
    if (default_instance_id)
        instance_id = default_instance_id;
    else if (default_name)
        instance_id = "default";
    else
        instance_id = "_" + Math.random().toString(36).substr(2, 9);

    if (context) {
        context.startedTime = startedTime;
        context.instance_id = instance_id;
        context.colorIndex = color_index;
        context.idByInteraction = Math.random();
        return context;
    }
    const interaction = {};
    //TODO: rever forma como estao a ser dados os entitlements
    //interaction.entitlements = state.defaultUserEntitlements;
    interaction.instance_id = instance_id;
    interaction.startedTime = startedTime;
    interaction.context = null;
    interaction.iconLabel = "DF";
    interaction.interactionType = "manual";
    interaction.label = "Default";
    interaction.id = 0;
    interaction.idByInteraction = Math.random();
    interaction.colorIndex = color_index;
    return interaction;
}

function updateCurrentInteraction(state, context) {
    var interaction_position = state.interactions.findIndex(elem => elem.instance_id === state.currentInteraction.instance_id);
    var new_interaction = state.interactions.get(interaction_position);
    new_interaction = Object.assign({}, new_interaction, context);
    if(new_interaction.instance_id === 'default'){
        return { ...state, currentInteraction: new_interaction, interactions: state.interactions.set(interaction_position, new_interaction) };
    } else return { ...state, currentInteraction: new_interaction, activeInteraction: new_interaction, interactions: state.interactions.set(interaction_position, new_interaction) };
}

function changeInteractionTime(state, instance_id, time) {
    const index = state.interactions.findIndex(obj => obj.instance_id === instance_id);
    if (index === -1) {
        console.error("No interaction was found.");
        return state;
    }
    const newInteraction = state.interactions.get(index);
    newInteraction.startedTime = time;
    if (newInteraction.instance_id === state.currentInteraction.instance_id) {
        return { ...state, currentInteraction: newInteraction, interactions: state.interactions.set(index, newInteraction) }
    } else {
        return { ...state, interactions: state.interactions.set(index, newInteraction) }
    }
}
