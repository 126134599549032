const ComponentName = "UfeJourneyMenu";
const styles = theme => ({
    root : {},
    favouriteSection : {
        marginBottom: 48
    },
    subtitleContainer : {
        marginBottom : 16
    },
    journeyContainerGrid : {
        display : "flex",
        marginBottom : 48
    },
    journeyContainerList : {
        display : "block",
        marginBottom : 48
    }
});

export { styles };
export { ComponentName };