import React, { Component } from "react";
import SaveDirtyJourneyRender from "./SaveDirtyJourneyRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setDirtyJourneyModalOpen } from "../../../actions/ui-actions";
import { setLoaderState } from "omni-shell-common/src/actions/utils-actions";
import { Config } from "../../../config";
import { closeJourney } from "omni-shell-common/src/actions/journeys-actions";
import { logOut, setAuthenticated } from "../../../actions/canvas-actions";
import { removeInteraction, removeAllOpenedInteractions } from "../../../actions/interactions-actions";

class SaveDirtyJourney extends Component {

    getDirtyJourneys = (closeJourneyScope) => {
        const { currentJourney, interactions, currentInteraction, journeyHistory, openJourneys, interactionToRemove } = this.props;
        if (closeJourneyScope === Config.CLOSE_JOURNEY_SCOPE.CURRENT_JOURNEY) {
            if (currentJourney.isDirty) {
                return [[currentJourney]];
            }
        } else if (closeJourneyScope === Config.CLOSE_JOURNEY_SCOPE.CURRENT_INTERACTION) {
            const currentInteractionJourneys = openJourneys.get(currentInteraction.instance_id);
            const dirtyJourneys = [];
            dirtyJourneys.push(currentInteractionJourneys.filter(obj => obj.isDirty));
            return dirtyJourneys;
        } else if (closeJourneyScope === Config.CLOSE_JOURNEY_SCOPE.MENU_INTERACTION) {
            const currentInteractionJourneys = openJourneys.get(interactionToRemove.instance_id);
            const dirtyJourneys = [];
            dirtyJourneys.push(currentInteractionJourneys.filter(obj => obj.isDirty));
            return dirtyJourneys;
        } else if (closeJourneyScope === Config.CLOSE_JOURNEY_SCOPE.ALL_INTERACTIONS) {
            const dirtyJourneys = [];
            interactions.forEach(
                obj => {
                    let tmp = journeyHistory.get(obj.instance_id).filter(journey => journey.isDirty);
                    if (tmp.size > 0)
                        dirtyJourneys.push(tmp);
                }
            );
            return dirtyJourneys;
        } else if (closeJourneyScope === Config.CLOSE_JOURNEY_SCOPE.EXPLICIT_LOGOUT) {
            const dirtyJourneys = [];
            interactions.forEach(
                obj => {
                    let tmp = journeyHistory.get(obj.instance_id).filter(journey => journey.isDirty);
                    if (tmp.size > 0)
                        dirtyJourneys.push(tmp);
                }
            );
            return dirtyJourneys;
        }
    };

    getJourneysToBeNotified = (closeJourneyScope) => {
        const { currentJourney, openJourneys, currentInteraction, interactionToRemove } = this.props;
        if (closeJourneyScope === Config.CLOSE_JOURNEY_SCOPE.CURRENT_JOURNEY) {
            if (currentJourney.isDirty) {
                return [currentJourney];
            }
        } else if (closeJourneyScope === Config.CLOSE_JOURNEY_SCOPE.CURRENT_INTERACTION) {
            const currentInteractionJourneys = openJourneys.get(currentInteraction.instance_id);
            return currentInteractionJourneys.filter(obj => obj.shouldBeNotifiedBeforeUnload);
        } else if (closeJourneyScope === Config.CLOSE_JOURNEY_SCOPE.MENU_INTERACTION) {
            const currentInteractionJourneys = openJourneys.get(interactionToRemove.instance_id)
            return currentInteractionJourneys.filter(obj => obj.shouldBeNotifiedBeforeUnload);
        } else if (closeJourneyScope === Config.CLOSE_JOURNEY_SCOPE.ALL_INTERACTIONS) {
            const journeysToBeNotified = [];
            interactions.forEach(
                obj => {
                    let tmp = journeyHistory.get(obj.instance_id).filter(journey => journey.journeysToBeNotified);
                    if (tmp.size > 0)
                        journeysToBeNotified = [...journeysToBeNotified, ...tmp];
                }
            );
            return journeysToBeNotified;
        }
    };

    afterResolvePromises = (closeJourneyScope) => {
        const { closeJourney, currentJourney, currentInteraction, interactionToRemove, authenticationHandlers, logOut, setAuthenticated, removeInteraction, samlAuthUrl, authenticationType } = this.props;
        if (closeJourneyScope === Config.CLOSE_JOURNEY_SCOPE.CURRENT_JOURNEY) {
            closeJourney(currentJourney.instance_id, currentInteraction.instance_id);
        } else if (closeJourneyScope === Config.CLOSE_JOURNEY_SCOPE.CURRENT_INTERACTION) {
            this.notifyInteraction360View(currentInteraction).then(() => { removeInteraction(currentInteraction.instance_id) });
        } else if (closeJourneyScope === Config.CLOSE_JOURNEY_SCOPE.MENU_INTERACTION) {
            this.notifyInteraction360View(interactionToRemove).then(() => { removeInteraction(interactionToRemove.instance_id) });
        } else if (closeJourneyScope === Config.CLOSE_JOURNEY_SCOPE.ALL_INTERACTIONS) {
            this.notifyAllInteractions360Views().then(() => { removeAllOpenedInteractions() })
        } else if (closeJourneyScope === Config.CLOSE_JOURNEY_SCOPE.EXPLICIT_LOGOUT) {
            this.notifyAllInteractions360Views().then(() => {
                logOut();
                authenticationHandlers.handleLogout();
                authenticationHandlers.clearStoreState();
                //setAuthenticated(false);
                if(authenticationType === "saml_auth" && this.validURL(samlAuthUrl)) {
                    window.location.href = samlAuthUrl;  
                }
            }).catch(err => {
                console.log("360 didn't respond in time logging out.", err)
                logOut();
                authenticationHandlers.handleLogout();
                authenticationHandlers.clearStoreState();
                //setAuthenticated(false);
                if(authenticationType === "saml_auth" && this.validURL(samlAuthUrl)) {
                    window.location.href = samlAuthUrl;  
                } 
            });
        }
        this.props.setLoaderState(false, null);
    };

    validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      }

    /**
     * notify all the opened 360 views that his interaction will close.
     * returns a promise.
     */
    notifyAllInteractions360Views = () => {
        const { interactions } = this.props;
        const promises = [];
        interactions.forEach(
            interaction => {
                promises.push(this.notifyInteraction360View(interaction));
            }
        );
        return Promise.all(promises);
    };
    /**
     * sends a message to his 360 indicating the interaction will close.
     * returns a promise.
     */
    notifyInteraction360View = (interaction) => {
        const { sendEventHandler: { close360View } } = this.props
        const payload = { attendeeDuration: new Date() - interaction.startedTime };
        return close360View(interaction.instance_id, payload);
    };

    handleCloseNotifiableJourneys = (journeys, closeJourneyScope) => {
        const { sendEventHandler: { handleClosingJourneys } } = this.props;
        Promise.all(handleClosingJourneys(journeys)).then(timeouts => {
            this.afterResolvePromises(closeJourneyScope);
            timeouts.map((timeout) => {
                clearTimeout(timeout);
            });
        })
            .catch(() => {
                this.afterResolvePromises(closeJourneyScope);
            })
    };

    closeJourneys = (save, closeJourneyScope) => {
        const { sendEventHandler: { saveDirtyJourneys }, setLoaderState } = this.props;
        const journeysToBeNotified = this.getJourneysToBeNotified(closeJourneyScope);
        if (save) {
            const dirtyJourneys = this.getDirtyJourneys(closeJourneyScope);
            const savingJourneyPromises = saveDirtyJourneys(dirtyJourneys);
            this.props.setLoaderState(true, null);
            Promise.all(savingJourneyPromises).then(() => {
                if (!journeysToBeNotified) {
                    this.afterResolvePromises(closeJourneyScope);
                } else this.handleCloseNotifiableJourneys(journeysToBeNotified, closeJourneyScope);
            }).catch((res) => {
                setLoaderState(false, null);
            });
        } else if (journeysToBeNotified) {
            setLoaderState(true, null);
            this.handleCloseNotifiableJourneys(journeysToBeNotified, closeJourneyScope);
        } else {
            this.afterResolvePromises(closeJourneyScope);
        }
    };

    closeModal = () => {
        const { setDirtyJourneyModalOpen } = this.props;
        setDirtyJourneyModalOpen(false);
    };

    closeModalSaveJourney = (closeJourneyScope) => {
        //exists a property on the reducer closeJourneyScope that it's used to instance the modal and we need to keep it after we close the modal, because of async calls.
        this.closeJourneys(true, closeJourneyScope);
        this.closeModal();
    };

    closeModalDiscardJourney = (closeJourneyScope) => {
        //exists a property on the reducer closeJourneyScope that it's used to instance the modal and we need to keep it after we close the modal, because of async calls.
        this.closeJourneys(false, closeJourneyScope);
        this.closeModal();
    };

    componentDidUpdate() {
        const { isDirtyJourneyOpen, closeJourneyScope } = this.props;
        if (!isDirtyJourneyOpen && closeJourneyScope) {
            this.closeModalDiscardJourney(closeJourneyScope);
        }
    }

    render() {
        //exists a property on the reducer closeJourneyScope that it's used to instance the modal and we need to keep it after we close the modal, because of async calls.
        const { i18nProvider, isDirtyJourneyOpen, closeJourneyScope } = this.props;
        return (
            < SaveDirtyJourneyRender
                onClose={this.closeModal}
                closeJourneyScope={closeJourneyScope}
                onCloseSaveJourney={this.closeModalSaveJourney}
                onCloseDiscardJourney={this.closeModalDiscardJourney}
                isDirtyJourneyOpen={isDirtyJourneyOpen}
                maxWidth={"md"}
                fullWidth={true}
                i18nProvider={i18nProvider}
            />
        );
    }
}

const mapStateToProps = ({
    journeys: { currentJourney, journeyHistory, openJourneys },
    interactions: { currentInteraction, interactions },
    shell: { i18nProvider, authenticationType, samlAuthUrl },
    ui: { isDirtyJourneyOpen, closeJourneyScope, interactionToRemove },
    canvas: { sendEventHandler, authenticationHandlers }
}) => ({
    currentJourney,
    journeyHistory,
    openJourneys,
    currentInteraction,
    interactions,
    isDirtyJourneyOpen,
    closeJourneyScope,
    i18nProvider,
    sendEventHandler,
    authenticationHandlers,
    interactionToRemove,
    authenticationType,
    samlAuthUrl
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setDirtyJourneyModalOpen,
        setLoaderState,
        closeJourney,
        logOut,
        setAuthenticated,
        removeInteraction,
        removeAllOpenedInteractions
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(SaveDirtyJourney);