import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./MenuDisplayButtonStyles";
import IconButton from "@ui-lib/core/IconButton";
import Icon from "@ui-lib/core/Icon";
import { MENU_LAYOUT_GRID } from "../../../config";
import { IEntitlementsVerifier } from "omni-shell-common/src/entitlements-verifier";

function MenuDisplayButtonRender({ classes, journeyMenuViewMode, toggleViewMode, i18nProvider, entitlements }) {
    let EntitlementsVerifier = IEntitlementsVerifier(React);
    return (
        <EntitlementsVerifier entitlement={entitlements.subHeader.journeyMenu.journeyList}>
            <div className={classes.root}>
                <IconButton id='UfeMenuDisplayButton' onClick={toggleViewMode} title={journeyMenuViewMode === MENU_LAYOUT_GRID ? i18nProvider.Texti18n("menu_lines") : i18nProvider.Texti18n("menu_tiles")}>
                    <Icon className={journeyMenuViewMode === MENU_LAYOUT_GRID ? "icon-menu-lines" : "icon-menu-tiles"} />
                </IconButton>
            </div>
        </EntitlementsVerifier>
    );
}

export default withStyles(styles, { name: ComponentName })(MenuDisplayButtonRender);