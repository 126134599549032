
import {UserPreferencesURL} from "./../config";
/**
 * @description A provider that know how to invoke the services in the Foundation UserPreferences.
 * @author nb21459
 */
export default class UserPreferenceProvider {
    /**
     * @constructor
     * @param {string} endpoint http://localhost:4500/bin/mvc.do/foundation/v3/preferences/
     */
    constructor(endpoint, httpClient){
        this.endpoint = endpoint;
        this.httpClient = httpClient;
    };

    /**
     * @description GET ../users{userId}/preferences get all preferences of a certain user.
     * @description GET ../users{userId}/preferences/{preferenceId}
     * @param {number} userId
     * @param {number} preferenceId
     * @param {number} propertyId
     * @returns Promise that results of a request to the service.
     */
    getPreferences(userId, preferenceId, propertyId){
        return new Promise ((Resolve, Reject) => {
            this.httpClient.get(UserPreferencesURL.USER_PREFERENCES(this.endpoint, userId, preferenceId, propertyId))
                .then((response)=>{
                    Resolve(response);
                })
                .catch((error) => {
                    Reject(error);
                });
        });
    };

    /**
     * @description GET ../users{userId}/preferences/query?expression={String Object}
     * @param {number} userId
     * @param {string} expression
     * @param {number} preferenceId
     * @returns Promise that results of a request to the service.
     */
    queryPreferences(userId, expression, preferenceId){
        return new Promise ((Resolve, Reject) => {
            this.httpClient.get(UserPreferencesURL.QUERY_USER_PREFERENCES(this.endpoint, userId, expression, preferenceId))
                .then((response)=>{
                    Resolve(response);
                })
                .catch((error) => {
                    Reject(error);
                });
        });
    };
    /**
     * @description POST ../users/{userId}/preferences
     * @param {*} userid
     * @param {object} preference {"path":"String Object"}
     * @returns Promise that results of a request to the service.
     */
    createPreference(userId, preference){
        return new Promise ((Resolve, Reject) => {
            this.httpClient.post(UserPreferencesURL.CREATE_PREFERENCE(this.endpoint, userId), null, null, preference)
                .then((response)=>{
                    Resolve(response);
                })
                .catch((error) => {
                    Reject(error);
                });
        });
    };

     /**
     * @description POST ../users/{userId}/preferences/{preferenceId}/properties
     * @param {*} userId
     * @param {*} property
     * @param {*} preferenceId
     * @returns Promise that results of a request to the service.
     */
    createProperty(userId, preferenceId, property){
        return new Promise ((Resolve, Reject) => {
            this.httpClient.post(UserPreferencesURL.CREATE_PROPERTY(this.endpoint, userId, preferenceId), null, null, property)
                .then((response)=>{
                    Resolve(response);
                })
                .catch((error) => {
                    Reject(error);
                });
        });
    };

    /**
     * @description PUT ../users/{userId}/preferences/{preferenceId}                            udpate a preference
     * @description PUT ../users/{userId}/preferences/{preferenceId}/properties/{propertyId}    update a property
     * @param {number} userId
     * @param {number} preferenceId
     * @param {object} preference
     * @param {number} propertyId
     * @returns Promise that results of a request to the service.
     */
    updatePreference(userId, preference, preferenceId, propertyId ){
        return new Promise ((Resolve, Reject) => {
            this.httpClient.put(UserPreferencesURL.UPDATE_PREFERENCE(this.endpoint, userId, preferenceId, propertyId), null, null, preference)
                .then((response)=>{
                    Resolve(response);
                })
                .catch((error) => {
                    Reject(error);
                });
        });
    };

    /**
     * @description DELETE ../users/{userId}/preferences                                        delete all preferences of an user
     * @description DELETE ../users/{userId}/preferences/{preferenceId}                         delete a preference
     * @description DELETE ../users/{userId}/preferences/{preferenceId}/properties              delete all properties in the preference
     * @description DELETE ../users/{userId}/preferences/{preferenceId}/properties/{propertyId} delete a property
     *
     * @param {number} userId
     * @param {number} preferenceId
     * @param {number} propertyId
     *
     * @returns Promise that results of a request to the service.
     */
     deletePreference(userId, preferenceId){
       return new Promise ((Resolve, Reject) => {
           this.httpClient.delete(UserPreferencesURL.DELETE_PREFERENCE(this.endpoint, userId, preferenceId), null, null, null)
               .then((response)=>{
                   Resolve(response);
               })
               .catch((error) => {
                   Reject(error);
               });
       });
     };

     deleteProperty(userId, preferenceId, propertyId){
       return new Promise ((Resolve, Reject) => {
           this.httpClient.delete(UserPreferencesURL.DELETE_PROPERTY(this.endpoint, userId, preferenceId, propertyId), null, null, null)
               .then((response)=>{
                   Resolve(response);
               })
               .catch((error) => {
                   Reject(error);
               });
       });
     };
};
