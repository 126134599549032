import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./AddWidgetStyles";
import Dialog from "@ui-lib/core/Dialog";
import DialogContent from "@ui-lib/core/DialogContent";
import DialogTitle from "@ui-lib/core/DialogTitle";
import DialogActions from "@ui-lib/core/DialogActions";
import IconButton from "@ui-lib/core/IconButton";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";
import Grid from "@ui-lib/core/Grid";
import Typography from "@ui-lib/core/Typography";
import TextField from "@ui-lib/core/TextField";
import MenuItem from "@ui-lib/core/MenuItem";
import InputLabel from "@ui-lib/core/InputLabel";
import FormControl from "@ui-lib/core/FormControl";
import Select from "@ui-lib/core/Select";
import Button from "@ui-lib/core/Button";
import WidgetCard from "./../../ShareComponents/WidgetCard";
import CategoryTitle from "./../../Menu/JourneyMenu/JourneysCategorySection/CategoryTitle";

function AddWidgetRender({
    classes, isAddWidgetOpen, onClose, maxWidth, fullWidth, i18nProvider, filteredWidgets, addWidgetsToDashboard, handleWidgetTileClick,
    handleSearchClick, handleClear, handleCategorySelect, handleNameFilterChange, filterName, filterCategory, availableWidgets
}) {
    return (
        <Dialog
            id='UfeAddWidgetModal'
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={isAddWidgetOpen}
            onClose={onClose}
            className={classes.container}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">
                <div className={classes.titleContainer}>
                    {i18nProvider.Labeli18n("select_widgets")}
                </div>
                <IconButton id="UfeAddWidgetModalCloseIconBtn" onClick={onClose} className={classNames(classes.iconButtonClose, classes.titleContainer)} title={i18nProvider.Texti18n("close")}>
                    <Icon className={classNames("icon-close", classes.iconClose)} />
                </IconButton>
            </DialogTitle>

            <div className={classes.panelGrey}>
                <Grid spacing={24} container>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{i18nProvider.Labeli18n("add_widget_screen_filter")}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="UfeAddWidgetModalWidgetNameTf" value={filterName} onChange={(event) => handleNameFilterChange(event.target.value)}
                            label={i18nProvider.Labeli18n("add_widget_screen_name_label")}
                            placeholder={i18nProvider.Texti18n("add_widget_screen_name_label")} fullWidth={true} />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth={true}>
                            <InputLabel>{i18nProvider.Labeli18n("add_widget_screen_type_label")}</InputLabel>
                            <Select id="UfeAddWidgetModalWidgetTypeSelect" value={filterCategory} onChange={(event) => handleCategorySelect(event.target.value)}>
                                {Object.keys(availableWidgets).map((e, i) => (
                                    <MenuItem id={"UfeAddWidgetModalWidgetTypeOption_" + e} key={i} style={{ zIndex: 1500 }} value={e}>{i18nProvider.Labeli18n(e)}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} className={classes.alignButtons}>
                        <Button id="UfeAddWidgetModalClearOptionsBtn" onClick={handleClear} className={classes.ButtonClear} title={i18nProvider.Texti18n("add_widget_screen_clear_button")}>
                            {i18nProvider.Labeli18n("add_widget_screen_clear_button")}
                        </Button>
                        <Button id="UfeAddWidgetModalSearchOptionBtn" onClick={handleSearchClick} variant="outlined" title={i18nProvider.Texti18n("add_widget_screen_search_button")}>
                            {i18nProvider.Labeli18n("add_widget_screen_search_button")}
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <DialogContent>
                {Object.keys(filteredWidgets).map(category => (
                    <React.Fragment key={category}>
                        <div className={classes.categoriesContainer}>
                            <CategoryTitle title={i18nProvider.Texti18n(category)} count={filteredWidgets[category].length} />
                            <Grid spacing={16} container>
                                {filteredWidgets[category].map(widget => (
                                    <Grid item xs={4} key={widget.id}>
                                        <WidgetCard
                                            id={widget.id}
                                            imgThumbnail={widget.thumbnailUrl}
                                            title={i18nProvider.Texti18n(widget.info.i18NTitle)}
                                            subtitle={i18nProvider.Texti18n(widget.info.i18NDescription)}
                                            onClick={() => handleWidgetTileClick(widget)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </React.Fragment>
                ))}
            </DialogContent>
            <DialogActions>
                <Button id="UfeAddWidgetModalAddWidgetBtn" onClick={addWidgetsToDashboard} variant="contained" color="primary"
                    title={i18nProvider.Labeli18n("add_additional_widget_button")}>{i18nProvider.Texti18n("add_additional_widget_button")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default withStyles(styles, { name: ComponentName })(AddWidgetRender);