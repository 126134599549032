import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { setLoggedUser, setAuthenticationTokens, setAuthenticationType } from "omni-shell-common/src/actions/shell-actions";
import WebSpinner from "omni-shell-web/src/components/Spinner/SpinnerRender";

class SamlAuthentication extends React.Component {

    constructor(props) {
        super(props);
        let accessToken = this.getParameterByName("accessToken");
        let refreshToken = this.getParameterByName("refreshToken");
        let currentLoggedUser = this.getParameterByName("currentLoggedUser");
        
        props.setAuthenticationTokens(accessToken, refreshToken);
        props.setLoggedUser(currentLoggedUser);
        props.setAuthenticationType("saml_auth");
        props.services.LocalStorage.set("accessToken", accessToken);
        props.services.LocalStorage.set("refreshToken", refreshToken);
        props.services.LocalStorage.set("currentLoggedUser", JSON.parse(currentLoggedUser));
    }

    componentDidMount(){
        if(this.props.accessToken && this.props.refreshToken && this.props.currentLoggedUser) {
            this.props.services.EntitlementsVerifier.getEntitlementsByURL().then(() => {
                this.props.history.push("/ufe");
            }).catch((e) => {
                console.error("There was a problem loading the entitlements.")
                console.error(e);
            })
        }
    }

    getParameterByName(name, url) {
        if (!url) url = window.location.hash;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    render() {
        return (<WebSpinner spinnerId={"ufeSpinner"}/>)
    }
}

const mapStateToProps = ({shell : {services, accessToken, refreshToken, currentLoggedUser}}) => ({services, accessToken, refreshToken, currentLoggedUser});
const mapDispatchToProps = dispatch => bindActionCreators({
    setLoggedUser, setAuthenticationTokens, setAuthenticationType
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SamlAuthentication));