const ComponentName = "ItemListContainer";
const styles = theme => ({
    container: {
		width: 0
	},
    root: {
		position: 'relative',
		display: '-webkit - box',
		display: '-ms - flexbox',
		display: 'flex',
		maxHeight: '471px',
		backgroundColor: theme.palette.background.default,
		borderRadius: '6px',
		marginTop: '10px',
		"-webkit-box-orient": "vertical",
		"-webkit-box-direction": "normal",
		"-ms-flex-direction": "column",
		"flex-direction": "column",
		boxShadow: '0 0 6px 0 rgba(51, 51, 51, 0.2)'
	},
    listStyle: {
        height: '100%',
        overflowY: 'auto',
        maxHeight: '424‬px'
    }
});

export { styles };
export { ComponentName };