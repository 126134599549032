import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./CloseInteractionButtonStyles";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";
import IconButton from "@ui-lib/core/IconButton";

function CloseInteractionButtonRender({ classes, closeInteraction, closeIcon }) {
    return (
            <IconButton id='UfeCloseActiveInteractionButton' className={classes.button} aria-label="close" onClick={closeInteraction}>
                <Icon className={classNames(closeIcon ? closeIcon : "icon-close", classes.iconClose)} />
            </IconButton>
    );
}

export default withStyles(styles, { name: ComponentName })(CloseInteractionButtonRender);