import React, { Component } from "react";
import MenuSettingsRender from "./MenuSettingsRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setPanel } from "../../../../../../actions/ui-actions";
import SettingsPanel from "../../../../../Panels/SettingsPanel";

class MenuSettings extends Component {

    onClick = () => {
        const {setPanel} = this.props;
        setPanel(<SettingsPanel/>);
    };

    render() {
        const {i18nProvider} = this.props
        return (
            <MenuSettingsRender i18nProvider={i18nProvider} onClick={this.onClick}/>
        );
    }
}

const mapStateToProps = ({
    shell : {i18nProvider},
    ui : {isPanelOpen}}) => ({isPanelOpen, i18nProvider});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setPanel
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(MenuSettings);