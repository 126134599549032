import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./MenuStyles";
import MenuTitle from "./MenuTitle";
import MenuSearch from "./MenuSearch";
import MenuDisplayButton from "./MenuDisplayButton";
import MenuCollapseAll from "./MenuCollapseAll";
import MenuExpandAll from "./MenuExpandAll";
import JourneyMenu from "./JourneyMenu";
import Grid from "@ui-lib/core/Grid";

function MenuRender({ classes, searchValue, isSearchOpen, toggleSearchOpen, changeSearchValue }) {
    return (
        <div id='UfeMenu' className={classes.root}>
            <Grid container alignItems="center" className={classes.journeyTitleContainer}>
                <Grid item xs={8}>
                    <MenuTitle />
                </Grid>
                <Grid item className={classes.menuOptions} xs={4}>
                    <MenuSearch searchValue={searchValue}
                        isSearchOpen={isSearchOpen}
                        toggleSearchOpen={toggleSearchOpen}
                        changeSearchValue={changeSearchValue} />
                    <MenuExpandAll />
                    <MenuCollapseAll />
                    <MenuDisplayButton />
                </Grid>
            </Grid>
            <Grid container className={classes.journeyMenu}>
                <Grid item xs={12}>
                    <JourneyMenu searchValue={searchValue} />
                </Grid>
            </Grid>
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(MenuRender);