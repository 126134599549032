import { fade } from "@ui-lib/core/styles/colorManipulator";

const ComponentName = "UfeActiveContextContainer";
const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: "100%",
        padding: "0px 0px 0px 10px",
        cursor: "pointer",
        backgroundColor: fade(theme.palette.common.grey, 0.2),
        maxWidth: 'calc(100vw / 6)'
    }
});
export { styles };
export { ComponentName };