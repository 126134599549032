import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./MenuItemStyles";
import Typography from '@ui-lib/core/Typography';
import classNames from '@ui-lib/core/classNames';


function MenuItemRender({ classes, extension, chooseGenericExtension, isSelected, id }) {
    return (
        <div id={'UfeGenericExtentionListContainerMenuItem_' + id} className={classes.root}>
            <div className={classes.container} onClick={() => chooseGenericExtension(extension)}>
                <div className={classes.textContainer}>
                    <Typography className={classNames(classes.title, { [classes.isSelected]: isSelected })} variant="body2">
                        {extension}
                    </Typography>
                    {isSelected && <div className={classes.dot} />}
                </div>
            </div>
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(MenuItemRender);