

import React, { Component } from "react";
import GenericExtensionListContainerRender from "./GenericExtensionListContainerRender";
import { connect } from "react-redux";

class GenericExtensionListContainer extends Component {
    
    render() {
        const {chooseGenericExtension,availableExtensions,closeListExtensions} = this.props;
        return (<GenericExtensionListContainerRender chooseGenericExtension={chooseGenericExtension} 
            availableExtensions={availableExtensions} closeListExtensions={closeListExtensions}/>);
    }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GenericExtensionListContainer);