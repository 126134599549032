import React, { Component } from "react";
import RootQuickActionIconRender from "./RootQuickActionIconRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setPanel, setPanelOpen } from "../../../../actions/ui-actions";
import PropTypes from "prop-types";

class RootQuickActionIcon extends Component {

    static propTypes = {
        icon : PropTypes.string.isRequired,
        component : PropTypes.node.isRequired,
        badgeContent : PropTypes.number,
        onClick : PropTypes.func,
        title : PropTypes.node,
        id: PropTypes.string
    };

    state = {
        panelOpen : false
    };

    componentDidUpdate(prevProps, prevState) {
        const {panelOpen} = this.state;
        const {isPanelOpen} = this.props;
        if (panelOpen && !isPanelOpen && prevProps.isPanelOpen) {
            this.setState({panelOpen : false});
        }
    }

    setRef = (node) => {
        this.anchorEl = node;
    };

    onClick = () => {
        const {setPanel, component, setPanelOpen, isPanelOpen, onClick} = this.props;
        if (isPanelOpen) {
            setPanelOpen(false);
            return;
        }
        setPanel(component, true, this.anchorEl);
        this.setState({panelOpen : true});
        onClick && onClick();
    };

    render() {
        const {panelOpen} = this.state;
        const {icon, badgeContent, title, id} = this.props;
        return (
            <RootQuickActionIconRender
                id={id}
                isPanelOpen={panelOpen}
                icon={icon}
                setRef={this.setRef}
                badgeContent={badgeContent}
                onClick={this.onClick}
                title={title}
            />
        );
    }
}

const mapStateToProps = ({i18n, ui : {isPanelOpen}}) => ({isPanelOpen, i18n});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setPanel,
        setPanelOpen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(RootQuickActionIcon);