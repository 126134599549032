const ComponentName = 'UfeRootQuickActionIcon';
const styles = theme => ({
    root : {
        borderBottom : '2px solid transparent',
        zIndex : 1100
    },
    rootSelected : {
        borderBottom : '2px solid ' + theme.palette.common.white
    },
    button : {
        color : theme.palette.common.white,
        '&:hover' : {
            backgroundColor : theme.palette.type === 'light' ? `rgba(255, 255, 255, 0.2)` : `rgba(255, 255, 255, ${theme.palette.action.hoverOpacity})`
        }
    },
    icon : {
        width : '20px',
        height : '20px',
        fontSize : '20px'
    },
    badge : {
        backgroundColor : theme.palette.error.main,
        color : theme.palette.common.white,
        minWidth : 'unset',
        height : '16px',
        width : '16px',
        fontSize : '11px'
    }
});

export { styles };
export { ComponentName };