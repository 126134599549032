import React, { Component } from "react";
import FooterRender from "./FooterRender";
import { connect } from "react-redux";
import { IEntitlementsVerifier } from "omni-shell-common/src/entitlements-verifier";

let EntitlementsVerifier = IEntitlementsVerifier(React);

class Footer extends Component {

    render() {
        const { i18nProvider, entitlementsMapper } = this.props;
        return (
            <EntitlementsVerifier entitlement={entitlementsMapper.footer}>
                <FooterRender i18nProvider={i18nProvider} />
            </EntitlementsVerifier>
        );
    }
}

const mapStateToProps = ({ shell: { i18nProvider, entitlementsMapper } }) => ({ i18nProvider, entitlementsMapper });

export default connect(mapStateToProps, undefined)(Footer);