import React, { Component } from "react";
import MenuTitleRender from "./MenuTitleRender";

class MenuTitle extends Component {

    render() {
        return (
            <MenuTitleRender />
        );
    }
}

export default MenuTitle;