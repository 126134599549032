const ComponentName = "UfeCloseActiveInteractionButton";
const styles = theme => ({
    root: {
    },
    iconClose: {
        fontSize: 12
    },
    button: {
        marginLeft: '5px',
        marginRight: '5px',
        float: 'left',
        width: "36px",
        height: "36px",
        fontSize: "36px"
    }
});

export { styles };
export { ComponentName };