import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./AddButtonStyles";
import IconButton from "@ui-lib/core/IconButton";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";

function AddButtonRender({ classes, toggleSearchOpen, title, threesixtyConfigs, interactions, isSearchContextMenuOpen }) {
    if (!isSearchContextMenuOpen && interactions.size <= threesixtyConfigs.interactionsLimit) {
        return (
            <IconButton id='UfeAddContextButton' className={classes.button} title={title} onClick={toggleSearchOpen} component={"div"}>
                <Icon className={classNames("icon-add-full", classes.icon)}
                    classes={{ path1: classes.iconBackground, path2: classes.iconPlus }} />
            </IconButton>
        );
    } else if (interactions.size > threesixtyConfigs.interactionsLimit && threesixtyConfigs.interactionsLimit === 1) {
        return <div></div>;
    } else if (interactions.size > threesixtyConfigs.interactionsLimit && threesixtyConfigs.interactionsLimit > 1) {
        return (
            <IconButton id='UfeAddContextButtonDisabled' disabled className={classes.button} title={title} onClick={toggleSearchOpen} component={"div"}>
                <Icon className={classNames("icon-add-full", classes.icon)}
                    classes={{ path1: classes.iconBackgroundDisabled, path2: classes.iconPlus }} />
            </IconButton>
        );
    } else
        return (
            <IconButton id='UfeAddContextButtonFailed' className={classes.button} title={title} onClick={toggleSearchOpen} component={"div"}>
                <Icon className={classNames("icon-failed", classes.icon)} />
            </IconButton>
        );
}
export default withStyles(styles, { name: ComponentName })(AddButtonRender);
