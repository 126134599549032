import { fade } from "@ui-lib/core/styles/colorManipulator";

const ComponentName = "UfeLogo";
const styles = theme => ({
    logoRoot: {
        height: 30,
        display: 'flex',
        cursor: "pointer",
        alignItems: 'center'
    },
    span: {
        width:'2px',
        height: '24px',
        marginLeft: "20px",
        marginRight: "10px",
        backgroundColor: fade(theme.palette.grey[50], 0.2)
    }
});

export { styles };
export { ComponentName };