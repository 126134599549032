const ComponentName = "UfeMenu";
const styles = theme => ({
    root: {
        position: "absolute",
        height: "calc(100% - 129px)",
        backgroundColor: theme.palette.grey[100],
        width: "100%",
        padding: 24,
        top: "105px",
        zIndex: 1120,
        display: "block",
        borderTop: "1px solid " + theme.palette.common.grey,
    },
    journeyTitleContainer: {
        marginBottom: 24
    },
    journeyMenu: {
        overflowY: "auto",
        height: "calc(100% - 50px)" //Removes the top bar in heights
    },
    menuOptions: {
        alignItems: "flex-end",
        display: "flex",
        justifyContent: "flex-end"
    }
});

export { styles };
export { ComponentName };