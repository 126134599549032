import React from 'react';
import { withStyles } from '@ui-lib/core/styles';
import { styles, ComponentName } from './JourneyCardStyles';
import classNames from '@ui-lib/core/classNames';
import Typography from '@ui-lib/core/Typography';
import Icon from '@ui-lib/core/Icon';
import Badge from '@ui-lib/core/Badge';
import { IEntitlementsVerifier } from 'omni-shell-common/src/entitlements-verifier';

let EntitlementsVerifier = IEntitlementsVerifier(React);

/**
 *
 * This method is used to get a react node of the journey card title
 * it splits the title in 2 lines and adds "..." if it overflows
 *
 * @param title - Node or String
 * @param hover - String
 * @param classes - css styles
 * @returns {node}
 */

function renderGridTitle(title, hover, classes) {
    let textTitle;
    let textArray;
    if (typeof title === 'string') {
        textTitle = title;
        textArray = title.split(' ');
    } else {
        textTitle = hover;
        textArray = hover.split(' ');
    }
    if (textArray[0].length > 14) {
        let cardTitle = textArray[0].substring(0, 10) + '...';
        return (<Typography id='UfeJourneyCardGridTitle' className={classNames(classes.title, classes.titleGrid)} title={hover} variant="subtitle2">{cardTitle}</Typography>);
    }
    if (textTitle.length > 28 && textArray.length > 1) {
        let firstLine = textArray[0];
        let firstLineFull = false;

        while ( firstLineFull === false ) {
            if ((firstLine + ' ' + textArray[1]).length > 14) {
                firstLineFull = true;
                textArray.shift();
                break;
            }
            textArray.shift();
            firstLine = firstLine + ' ' + textArray[0];
        }
        let secondLineFull = false;
        let lastLine = textArray[0];
        while ( secondLineFull === false ) {
            if (lastLine.length > 14) {
                lastLine = lastLine.substring(0, 10) + '...';
                secondLineFull = true;
                break;
            }
            if ((lastLine + ' ' + textArray[1]).length + 3 > 14) {
                lastLine = lastLine + '...';
                secondLineFull = true;
                break;
            }
            if ((firstLine + lastLine).length === title.length) {
                break;
            }
            textArray.shift();
            lastLine = lastLine + ' ' + textArray[0];
        }
        return (
            <div id='UfeJourneyCardGridTitle' className={classes.titleGridOverflowContainer}>
                <Typography className={classNames(classes.title, classes.overflowTitleContainer, classes.titleGridOverflow)} title={hover}
                            variant="subtitle2" component={'div'}>{firstLine}</Typography>
                <Typography className={classNames(classes.title, classes.overflowTitleContainer, classes.titleGridOverflow)} title={hover}
                            variant="subtitle2" component={'div'}>{lastLine}</Typography>
            </div>
        );
    }
    return (<Typography id='UfeJourneyCardGridTitle' className={classNames(classes.title, classes.titleGrid)} title={hover} variant="subtitle2">{title}</Typography>);
}

function renderJourneyCardGrid(classes, badgeContent, icon, title, subtitle, onClick, hover, onFavoriteClick, favouritesEntitlements, favourite, configMenuFavourites, taskSwitcher, id) {
    return (
        <div id={'UfeJourneyCard_' + id} className={classes.rootGrid} onClick={onClick} title={hover}>
            <div className={classNames({ [classes.contentGridContainer] : !taskSwitcher, [classes.contentGridContainerTaskSwitcher] : taskSwitcher })}>
                <Badge invisible={!badgeContent} badgeContent={badgeContent} max={99} classes={{ badge : classes.badge }}>
                    <Badge invisible={!badgeContent} classes={{ badge : classes.badgeOverlay }}>
                        {onFavoriteClick && <EntitlementsVerifier entitlement={favouritesEntitlements}>
                            {favourite ?
                                <Icon onClick={onFavoriteClick} classes={{ path2 : classes.favouriteSelected }} className={classNames(configMenuFavourites && configMenuFavourites.selectedIcon ?
                                    configMenuFavourites.selectedIcon :
                                    'icon-wishlist-full', classes.favouriteIcon)}/>
                                :
                                <Icon onClick={onFavoriteClick} classes={{ path2 : classes.favouriteUnselected }} className={classNames(configMenuFavourites && configMenuFavourites.unselectedIcon ?
                                    configMenuFavourites.unselectedIcon :
                                    'icon-wishlist-full', classes.favouriteIcon)}/>}
                        </EntitlementsVerifier>}
                        {icon && icon.startsWith('icon-') ?
                            <Icon className={classNames(icon, classes.cardIcon, classes.iconGrid)}/> :
                            <Icon className={classNames(classes.cardIcon, classes.iconGrid)}>{icon}</Icon>}
                    </Badge>
                </Badge>
                {renderGridTitle(title, hover, classes)}
                {subtitle && <Typography title={subtitle} className={classes.subTitle} variant="caption" noWrap>{subtitle}</Typography>}
            </div>
        </div>
    );
}

function renderJourneyCardList(classes, badgeContent, icon, listIcon, title, onClick, hover, onFavoriteClick, favouritesEntitlements, favourite, configMenuFavourites, id) {
    return (
        <div id={'UfeJourneyCard_' + id} className={classes.rootList} onClick={onClick} title={hover}>
            <Badge invisible={!badgeContent} badgeContent={badgeContent} max={99}
                   classes={{ root : classes.badgeRootList, badge : classNames(classes.badge, classes.badgeList) }}>
                <div className={classes.contentListContainer}>
                    {listIcon ?
                        listIcon && listIcon.startsWith('icon-') ? <Icon className={classNames(listIcon, classes.iconList)}/> : <Icon className={classNames(classes.iconList)}>{listIcon}</Icon>
                        :
                        icon && icon.startsWith('icon-') ? <Icon className={classNames(icon, classes.iconList)}/> : <Icon className={classNames(classes.iconList)}>{icon}</Icon>
                    }
                    <div className={classes.favouriteIconListConteiner}>
                        <Typography id='UfeJourneyCardListTitle' className={classNames(classes.title, classes.titleList)} variant="h3" title={hover}
                                    noWrap>{title}</Typography>
                        {onFavoriteClick && <EntitlementsVerifier entitlement={favouritesEntitlements}>
                            {favourite ?
                                <Icon
                                    onClick={onFavoriteClick}
                                    classes={{ path2 : classes.favouriteSelected }}
                                    className={classNames(
                                        classes.favouriteIconList, classes.favouriteIconList,
                                        configMenuFavourites.selectedIcon && configMenuFavourites.selectedIcon ? configMenuFavourites.selectedIcon : 'icon-wishlist-full'
                                    )}/>
                                :
                                <Icon
                                    onClick={onFavoriteClick}
                                    classes={{ path2 : classes.favouriteUnselected }}
                                    className={classNames(
                                        classes.favouriteIconList, classes.favouriteIconList,
                                        configMenuFavourites.unselectedIcon && configMenuFavourites.unselectedIcon ? configMenuFavourites.unselectedIcon : 'icon-wishlist-full'
                                    )}/>
                            }
                        </EntitlementsVerifier>}
                    </div>
                </div>
            </Badge>
        </div>
    );

}

function JourneyCard({ classes, mode, badgeContent, icon, listIcon, title, subtitle, onClick, hover, onFavoriteClick, favouritesEntitlements, favourite, configMenuFavourites, taskSwitcher, id }) {
    return (
        <React.Fragment>
            {mode === 'GRID' ?
                renderJourneyCardGrid(classes, badgeContent, icon, title, subtitle, onClick, hover, onFavoriteClick, favouritesEntitlements, favourite, configMenuFavourites, taskSwitcher, id)
                :
                renderJourneyCardList(classes, badgeContent, icon, listIcon, title, onClick, hover, onFavoriteClick, favouritesEntitlements, favourite, configMenuFavourites, id)}
        </React.Fragment>
    );
}

export default withStyles(styles, { name : ComponentName })(JourneyCard);