const ComponentName = "UfeSearchOption";
const styles = theme => ({
    option : {
        cursor : "pointer",
        display : "flex",
        alignItems: "flex-end",
        padding: "10px 5px 10px 5px",
        color: theme.palette.text.secondary,
        '&:hover': {
            backgroundColor: theme.palette.grey[200]
        }
    },
    optionSelected : {
        cursor : "pointer",
        display : "flex",
        alignItems: "flex-end",
        padding: "10px 5px 10px 5px",
        color: theme.palette.text.secondary,
        backgroundColor : theme.palette.primary[300]
    },
    optionIconContainer : {
        margin: "5px 6px 0 7px",
        color: theme.palette.text.primary
    },
    optionIcon : {
        fontSize : 25,
    },
    optionMainText: {
        display: "flex"
    },
    optionSplitter : {
        margin: "auto 16px auto 16px",
        width: 4,
        height: 5,
        backgroundColor: theme.palette.text.secondary,
        borderRadius: 25
    }
});

export { styles };
export { ComponentName };