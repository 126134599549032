import React, { Component } from "react";
import UserInContextRender from "./UserInContextRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCurrentInteraction, removeInteraction } from "../../../../../../actions/interactions-actions";

class UserInContext extends Component {

    onClick = () => {
        this.props.setCurrentInteraction(this.props.activeInteraction.instance_id);
    }
    render() {
        const { entitlementsMapper, currentInteraction } = this.props;
        return (
            <UserInContextRender entitlementsMapper={entitlementsMapper} onClick={this.onClick} currentInteraction={currentInteraction} />
        );
    }
}

const mapStateToProps = ({ interactions: { currentInteraction, activeInteraction }, canvas: { sendEventHandler, config: { threesixtyConfigs } }, shell: { entitlementsMapper } }) => ({ sendEventHandler, threesixtyConfigs, entitlementsMapper, currentInteraction, activeInteraction });
const mapDispatchToProps = dispatch => bindActionCreators({
    setCurrentInteraction,
    removeInteraction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserInContext);