import { fade } from "@ui-lib/core/styles/colorManipulator";

const ComponentName = "MenuItem";
// noinspection JSDuplicatedDeclaration
const styles = theme => ({
    root: {
        color: theme.palette.text.primary,
        display: '-webkit-box',
        display: '-ms-flexbox',
        display: "flex",
        '-webkit-box-align': 'center',
        '-ms-flex-align': 'center',
        alignItems: "center",
        width: "100%",
        height: '75px',
        //borderBottom: "1px solid" + theme.palette.grey[400],
        padding: "0px 10px 0px 10px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: fade(theme.palette.common.black, theme.palette.action.hoverOpacity),
        },
        '&:last-child': {
            borderBottom: '0px !important'
        }
    },
    container: {
        width: '100%',
        display: "flex",
        alignItems: "center"
    },
    avatar: {
        width: 22,
        height: 22,
        fontSize: 13
    },
    title: {
        textTransform: "none",
        marginLeft: 10
    },
    button: {
    },
    icon: {
        fontSize: '12px',
        width: '12px',
        height: '12px'
    },
    iconButton:{
        marginTop : "-12px"
    },
    time: {
        marginLeft: '10px',
        fontSize: '12px',
        color: theme.palette.text.secondary
    },
    textContainer: {
        display: 'block'
    }
});

export { styles };
export { ComponentName };