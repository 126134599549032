/**
 * UX COMPONENTS
 */
import Header from 'omni-ufe-common/src/components/Header';
import SubHeader from 'omni-ufe-common/src/components/SubHeader';
import Menu from 'omni-ufe-common/src/components/Menu';
import JourneyPlayer from './journey-player';
import Panel from 'omni-ufe-common/src/components/Panels';
import Footer from 'omni-ufe-common/src/components/Footer';
import TaskSwitcher from 'omni-ufe-common/src/components/Modals/TaskSwitcher';
import AddWidget from 'omni-ufe-common/src/components/Modals/AddWidget';
import SaveDirtyJourney from 'omni-ufe-common/src/components/Modals/SaveDirtyJourney';
import SessionExpired from 'omni-ufe-common/src/components/Modals/SessionExpired';
import JourneyActionDirtyModal from 'omni-ufe-common/src/components/Modals/JourneyActionDirtyModal';
import ThreesixtyPlayer from './threesixty-player';
import { ComponentName, styles } from './ufeStyles';
import { withStyles } from '@ui-lib/core/styles';
import Notifications from 'omni-ufe-common/src/components/Notifications';
import WebSpinner from 'omni-shell-web/src/components/Spinner/SpinnerRender';
import EditModePlaceHolder from 'omni-shell-web/src/components/EditModePlaceHolder/EditModePlaceHolder';
/**
 * REDUX
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCurrentEntitlements, setUfeRenderState } from 'omni-shell-common/src/actions/shell-actions';
import { setLoaderState } from 'omni-shell-common/src/actions/utils-actions';
import { setMenuOpen, setSessionExpiredModalOpen } from 'omni-ufe-common/src/actions/ui-actions';
/**
 * React & Bridge & Theme
 */
import React from 'react';

/**
 * IE Compability handler
 */
import { checkArrayFrom } from 'omni-ufe-common/src/utils/ie-compability';

import { addInteractionWithJourneys } from "omni-ufe-common/src/actions/interactions-actions";
import _ from "lodash";
import classNames from "@ui-lib/core/classNames";
import { IEntitlementsVerifier } from "omni-shell-common/src/entitlements-verifier";

// Global Services available at common shell
let Services;
let EntitlementsVerifier = IEntitlementsVerifier(React);

checkArrayFrom();

class UFE extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fontsLoaded : false,
            isWidgetsEditableLabel : this.props.i18nProvider.Texti18n('widget_not_editable_label'),
            quickActionSelectedIndex : null,
            isLoading : false,
            loaderType : null
        };

        Services = props.Services;
        props.SendEventHandler.setLoaderFunc(this.setLoader);
    };

    showAuthorizationExpiredDisclaimer = () => {
        const { setSessionExpiredModalOpen } = this.props;
        setSessionExpiredModalOpen(true);
    };

    changeWidgetsEditableLabel = (isEditable) => {
        if (isEditable) {
            this.setState({ isWidgetsEditableLabel : this.props.i18nProvider.Texti18n('widget_editable_label') });
        } else {
            this.setState({ isWidgetsEditableLabel : this.props.i18nProvider.Texti18n('widget_not_editable_label') });
        }
    };

    setLoader = (loading, loaderType) => {
        this.props.setLoaderState(loading, loaderType);
        if (loading) {
            let loaderTimeOut = setInterval(() => {
                if (this.props.isLoading) {
                    this.props.setLoaderState(false, null);
                }
                clearInterval(loaderTimeOut);
            }, 60000);
        }
    };

    handleBeforeUnload = (event) => {
        let dirtyInteractions = this.props.openJourneys.filter(
            journeyList => (journeyList.filter(journey => journey.isDirty).size > 0)
        );
        if (dirtyInteractions.size === 0) return undefined;
        var message = 'If you leave before saving, your changes will be lost.';
        (event || window.event).returnValue = message;//Gecko + IE
        return message;//Gecko + Webkit, Safari, Chrome etc.
    };

    UNSAFE_componentWillMount() {
        this.props.setUfeRenderState(true);
        window.addEventListener('authorizationFailedEvents', this.showAuthorizationExpiredDisclaimer, false);
        window.addEventListener('sessionExpired', this.showAuthorizationExpiredDisclaimer, false);
        window.addEventListener('beforeunload', this.handleBeforeUnload, false);
    };

    componentWillUnmount() {
        window.removeEventListener('authorizationFailedEvents', this.showAuthorizationExpiredDisclaimer, false);
        window.removeEventListener('sessionExpired', this.showAuthorizationExpiredDisclaimer, false);
        window.removeEventListener('beforeunload', this.handleBeforeUnload, false);
        this.props.setUfeRenderState(false);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { currentJourney, SendEventHandler, currentInteraction, availableWidgets, config, interactions, i18n } = this.props;

        if (SendEventHandler.isReady()) {
            if (currentJourney && nextProps.currentJourney) {
                if (!_.isEqual(currentJourney.widgets, nextProps.currentJourney.widgets)) {
                    SendEventHandler.setJourneyJourneyWidgets(currentJourney.widgets);
                }
                if (currentJourney.instance_id === nextProps.currentJourney.instance_id && currentJourney.isDirty !== nextProps.currentJourney.isDirty) {
                    SendEventHandler.setJourneyDirty(nextProps.currentJourney.isDirty);
                }
            }
            if (!_.isEqual(availableWidgets, nextProps.availableWidgets)) {
                SendEventHandler.setJourneyAvailableWidgets(nextProps.availableWidgets);
            }
            if (currentInteraction.instance_id && nextProps.currentInteraction && nextProps.currentInteraction.instance_id) {
                if (!_.isEqual(currentInteraction, nextProps.currentInteraction)) {
                    SendEventHandler.setJourneyContext(nextProps.currentInteraction, nextProps.currentInteraction.instance_id);
                    SendEventHandler.setThreesixtyContext(nextProps.currentInteraction, nextProps.currentInteraction.instance_id);
                }
            }
        }

        if (nextProps.currentInteraction && currentInteraction &&
            nextProps.currentInteraction.instance_id !== currentInteraction.instance_id
            && nextProps.interactions.size > interactions.size
            && config && config.showUserMenuByDefault) {
            this.props.setMenuOpen(true);
        }

        if (i18n.currentLanguage !== nextProps.i18n.currentLanguage) {
            this.changeWidgetsEditableLabel(this.props.isWidgetsEditable);
        }
    }

    componentDidMount() {
        this.props.services.Notifications.initNotifications();
    }

    alreadyExistsMoreThanOneInteractionWithoutContext = interactions => {
        let interactionsWithoutContext = interactions.filter(interaction => !interaction.context);
        return interactionsWithoutContext.count() > 1;
    };

    render() {
        const {
            AuthenticationHandlers, SendEventHandler, ReceivedEventsHandler, currentLoggedUser, currentInteraction, accessToken, classes, isPrivate, entitlementsMapper,
            threesixtyOpen,
            Login,
            fullScreen,
            config
        } = this.props;
        let displayUfe = !!(currentLoggedUser && accessToken && currentInteraction);

        let containerStyle = threesixtyOpen ? classes.visible360 : config.threesixtyConfigs.collapsePanel === 'horizontal' ? classes.visibleHeader360Horizontal : classes.visibleHeader360Vertical;
        let horizontalCollapse = config.threesixtyConfigs.collapsePanel === 'horizontal';
        return (
            <React.Fragment>
                <div className={classes.root} style={{ display : displayUfe ? 'flex' : 'none' }}>
                    <Header/>
                    <Panel/>
                    <Notifications/>
                    <div
                        className={classNames(!config.threesixtyConfigs.openOnStart && this.props.currentInteraction.instance_id === 'default' ?
                            classes.invisible360 :
                            containerStyle, classes.firefoxFix, {
                            [classes.fullScreenJourney] : fullScreen
                        })}>
                        <div className={classes.subheader}><SubHeader/></div>
                        <div
                            className={this.props.isWidgetsEditable ?
                                classNames(classes.journeyplayer, classes.editMode, classes.journeysContainer) :
                                classNames(this.props.currentInteraction.instance_id === 'default' || threesixtyOpen || horizontalCollapse ?
                                    classes.journeyplayer :
                                    classes.journeyplayerFull, classes.journeysContainer)}
                        >
                            <Menu/>
                            <JourneyPlayer
                                Services={Services} ReceivedEventsHandler={ReceivedEventsHandler}
                                SendEventHandler={SendEventHandler}
                                AuthenticationHandlers={AuthenticationHandlers}
                                setLoader={(isLoading, loaderType) => {
                                    this.setLoader(isLoading, loaderType);
                                }}/>
                        </div>
                        {<EntitlementsVerifier entitlement={entitlementsMapper.header.threesixty}>
                            <div
                                className={classNames(!config.threesixtyConfigs.openOnStart && this.props.currentInteraction.instance_id === 'default' ?
                                    classes.threesixtyHiden :
                                    threesixtyOpen || horizontalCollapse ? classes.threesixty : classes.threesixtyHeader)}>
                                <ThreesixtyPlayer ReceivedEventsHandler={ReceivedEventsHandler}
                                                  SendEventHandler={SendEventHandler}
                                                  AuthenticationHandlers={AuthenticationHandlers}
                                                  setLoader={(isLoading, loaderType) => {
                                                      this.setLoader(isLoading, loaderType);
                                                  }}/>
                            </div>
                        </EntitlementsVerifier>}
                    </div>
                    <Footer/>
                    <TaskSwitcher/>
                    <JourneyActionDirtyModal/>
                    <AddWidget/>
                    <SaveDirtyJourney/>
                    <SessionExpired/>
                    {this.props.isLoading && this.props.loaderType !== 'JOURNEY' && <WebSpinner spinnerId={'ufeSpinner'} loaderType={this.state.loaderType}/>}
                    {this.props.isWidgetsEditable && <EditModePlaceHolder/>}
                </div>
                <div style={{ display : displayUfe ? 'none' : 'flex' }}>
                    <Login services={Services} handleDependencies={this.props.handleDependencies}
                           authHandlers={AuthenticationHandlers}
                           clearState={true}
                    />
                </div>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (
    {
        shell : { currentLoggedUser, services, accessToken, i18nProvider, currentEntitlements, isPrivate, entitlementsMapper, fullScreen },
        canvas : { widgets, isWidgetsEditable, config },
        utils : { isLoading, loaderType },
        interactions : { currentInteraction, interactions, widgetList : { availableWidgets }, threesixtyOpen },
        journeys : { openJourneys, currentJourney },
        i18n
    }
) => ({
    currentLoggedUser, services, loaderType, isLoading, currentEntitlements, i18nProvider, widgets, isWidgetsEditable, config,
    fullScreen,
    currentInteraction, openJourneys, currentJourney, interactions, i18n, accessToken, availableWidgets, isPrivate, threesixtyOpen, entitlementsMapper
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setUfeRenderState,
    setMenuOpen,
    setCurrentEntitlements,
    addInteractionWithJourneys,
    setLoaderState,
    setSessionExpiredModalOpen
}, dispatch);

export default withStyles(styles, { name : ComponentName })(connect(mapStateToProps, mapDispatchToProps)(UFE));
