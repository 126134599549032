/**
 * Created by NB22722 on 03/08/2017.
 */
//import "@babel/polyfill/noConflict";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

ReactDOM.render(<App />,
    document.getElementById('root')
);
