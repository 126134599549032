import React, { Component } from "react";
import TimerRender from "./TimerRender";

class Timer extends Component {

    state = {
        elapsed: 0,
        timer: undefined
    }

    componentDidMount() {
        const timer = setInterval(this.tick.bind(this), 50);
        this.setState({ timer: timer });
    }

    componentWillUnmount() {
        clearInterval(this.state.timer);
    }

    tick() {
        this.setState({ elapsed: new Date() - this.props.currentInteraction.startedTime });
    }

    convertDuration(sec) {
        var hours = Math.floor(sec / 3600);
        var minutes = Math.floor((sec - (hours * 3600)) / 60);
        var seconds = sec - (hours * 3600) - (minutes * 60);

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        return hours + ':' + minutes + ':' + seconds;
    }

    render() {
        let elapsed = Math.round(this.state.elapsed / 100);
        let secondsTmp = (elapsed / 10).toFixed(0);
        this.seconds = secondsTmp;

        return (
            <div>
                <TimerRender startTime={this.convertDuration(this.seconds)} />
            </div>
        );
    }
}

export default Timer;