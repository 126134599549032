import React, { Component } from "react";
import SessionExpiredRender from "./SessionExpiredRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setSessionExpiredModalOpen } from "../../../actions/ui-actions";

class SessionExpired extends Component {

    closeModal = () => {
        const {setSessionExpiredModalOpen} = this.props;
        const event = new CustomEvent("loginEvents");
        event.message = "destroyTokens";
        window.dispatchEvent(event);
        setSessionExpiredModalOpen(false);
    };

    render() {
        let {isSessionExpireOpen, i18nProvider} = this.props;
        return (
            <SessionExpiredRender
                isSessionExpireOpen={isSessionExpireOpen}
                onClose={this.closeModal}
                maxWidth={"sm"}
                fullWidth={true}
                i18nProvider={i18nProvider}
            />
        );
    }
}

const mapStateToProps = ({
                             shell : {i18nProvider},
                             ui : {isSessionExpireOpen},
                             journeys : {}
                         }) => ({
    isSessionExpireOpen,
    i18nProvider,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setSessionExpiredModalOpen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(SessionExpired);