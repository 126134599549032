import { setAuthenticationTokens, setLoggedUser, toggleFullScreen } from "../src/actions/shell-actions";
import { setThemeType, setThemeDirection } from "../src/actions/theme";
import { EventRegister } from "react-native-event-listeners";
import NotificationHandler from "./notification-handler";
import { setLoaderState } from "./actions/utils-actions";


/**
 * All data receive by the functions in this class have the same structure - handle functions
 * @field type - String stating the type of the message
 * @field method - String with the method 
 * @field payload - Object, payload to be sent
 * @field id - Number, identifier of the message
 * @field response - Boolean, states if the message is a response
 * @field result - String, with 2 expected values "success" or "error"
 */
/**
const data = {
    type : "",
    method : "",
    payload : {},
    id : -1,
    response : false,
    result : "success"
};
 */

let NotificationsHandler;

export const BaseBridge = React => class extends React.Component {

    /**
     * Constructor
     * It will set the notifications handler directly, to allow dispatch of notifications.
     * It will generate the iframe_id of this bridge
     * It will add the onReceiveEvent Listeners to the "this" context
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        NotificationsHandler = new NotificationHandler(props.services.Store);
        this.iframe_id = Math.floor((Math.random() * 9999999999999) + 1111111111111);
        
        this.onReceiveMessage = this.onReceiveMessage.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.attributes != null && this.props.src !== nextProps.src) {
            this.setLoading();
        }
        if (this.props.type !== nextProps.type) {
            this.setJourneyThemeType(nextProps.type);
        }
        if (this.props.direction !== nextProps.direction) {
            this.setJourneyThemeDirection(nextProps.direction);
        }
        if (this.props.language !== nextProps.language) {
            this.setJourneyLanguage(nextProps.language);
        }
        if (this.props.currentEntitlements !== nextProps.currentEntitlements) {
            this.setJourneyCurrentEntitlements(nextProps.currentEntitlements);
        }
        if (this.props.widgets !== nextProps.widgets) {
            this.addWidgets(nextProps.widgets);
        }
        if (this.props.style.display !== "none" && nextProps.style.display === "none") {
            this.setJourneyActive(false);
        }
        if (this.props.initialArgs !== nextProps.initialArgs && nextProps.initialArgs) {
            this.setInitialArgs(nextProps.initialArgs);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.style.display !== "none" && prevProps.style.display === "none") {
            this.setJourneyActive(true);
        }
    }

    /**
     * Triggers when a message is received from the iframe.
     * In this case it means a journey sent a message.
     * There is a validation to check if it is a known request (example: LocalStorage or HttpClient).
     * If it isn't a known request, it will let it go through the generic handleReceiveMessage.
     * @param event - Content of the event received.
     */
    onReceiveMessage(event) {
        if (this.IsJsonString(event.data)) {
            let data = (event.data ? JSON.parse(event.data) : event.nativeEvent.data && JSON.parse(event.nativeEvent.data));

            if (data && (data.iframe_id === this.iframe_id || data.iframe_id === undefined)) {
                if (data && data.type) {
                    switch (data.type) {
                        case "globalDispatcher":
                            return this.handleGlobalDispatcher(data);
                        case "fileSystem":
                            return this.handleFileSystem(data);
                        case "localStorage":
                            return this.handleLocalStorage(data);
                        case "utils":
                            return this.handleUtils(data);
                        case "httpStream":
                            return this.handleHttpStream(data);
                        case "httpClient":
                            return this.handleHttpClient(data);
                        case "loading":
                            return this.handleLoading(data);
                        case "theme":
                            return this.handleTheme(data);
                        case "i18n":
                            return this.handleI18n(data);
                        case "user":
                            return this.handleUser(data);
                        case "entitlements":
                            return this.handleEntitlements(data);
                        case "journeyAction":
                            if (data.method === "toggleFullScreen")
                                return this.props.toggleFullScreen();
                            return this.props.handleReceiveMessage(data);
                        case "dirty_journeys_save":
                            return this.handleDirtyJourneySave(data);
                        case "userPreferences":
                            return this.handleUserPreferences(data);
                        case "notifications":
                            return this.handleNotification(data);
                        case "geoLocation":
                            return this.handleGeoLocationRequest(data);
                        case "router":
                            return this.handleRouterRequest(data);
                        default:
                            if (this.props.handleReceiveMessage)
                                return this.props.handleReceiveMessage(data, this.iframe_id);
                    }
                }
                if (this.props.handleReceiveMessage) {
                    return this.props.handleReceiveMessage(data, this.iframe_id);
                }
            }
        }
    };

    handleRouterRequest(data) {
        var event;
        switch(data.method) {
            case "push":
                event = new CustomEvent('ufe-events-router-push');
                break;
            case "pop":
                event = new CustomEvent('ufe-events-router-pop');
                break;
            case "addArguments":
                event = new CustomEvent('ufe-events-router-add-arguments');
                break;
            case "removeArguments":
                event = new CustomEvent('ufe-events-router-remove-arguments');
                break;
            case "UNSAFE_set":
                event = new CustomEvent('ufe-events-router-UNSAFE-set');
                break;
            default:
                console.warn("------WARNING------- An attempt to alter the router of the current journey ocurred, with the incorrect arguments");
                break;
        }
        event.data = data;
        window.dispatchEvent(event);
    }

    /**
     * Method that sends information to this Iframe the it is active (with focus and current journey).
     * @param {*} value - Boolean value, true || false
     */
    setJourneyActive(value) {
        let data = {
            type : "journeyActive",
            response : false,
            payload : value
        };
        this.sendMessage(data);
    };

    /**
     * Method that will send the theme information
     */
    setTheme() {
        let {theme, type, direction, overrides, themeId} = this.props;
        let data = {
            type : "theme",
            response : false,
            method : "fullTheme",
            payload : { theme, type, direction, overrides, themeId }
        };
        this.sendMessage(data);
    };

    /**
     * Method to send the message to change the theme type
     */
    setThemeType(type) {
        let data = {
            type : "theme",
            response : false,
            method : "changeType",
            payload : {
                type
            }
        };
        this.sendMessage(data);
    };

    /**
     * Method to send the message to change the theme direction
     */
    setThemeDirection(direction) {
        let data = {
            type : "theme",
            response : false,
            method : "changeDirection",
            payload : {
                direction
            }
        };
        this.sendMessage(data);
    };

    /**
     * Method to send information that the view is ready
     */
    webviewReady(contentVersion) {
        const {contentUrl, hostPort} = this.props;
        let data = {
            type : "webview_ready",
            response : false,
            payload : { contentUrl, hostPort, contentVersion }
        };
        this.sendMessage(data);
    };

    /**
     * Method that will send the message to UFE to set
     */
    setInitialArgs(initialArgs) {
        let data = {
            type : "initialArgs",
            response : false,
            payload : { initialArgs }
        };
        this.sendMessage(data);
    };

    /**
     * Method that will handle httpStream requests from the journey
     */
    handleHttpStream(data) {
        let promise = this.handleHttpRequest(data);
        data.response = true;

        promise.then(res => {
            data.result = "success";
            data.slicesAmmount = Math.ceil(res.data.length / 1500000);

            let toSend = [];
            let start;
            let end;
            for (let i = 0; i < data.slicesAmmount; i++) {
                if (i + 1 === data.slicesAmmount) {
                    start = 1500000 * (i);
                    end = res.data.length;
                    toSend.push(res.data.substring(start, end));
                } else if (i === 0) {
                    toSend.push(res.data.substring(0, 1500000));
                } else {
                    start = 1500000 * (i);
                    end = 1500000 * (i + 1);
                    toSend.push(res.data.substring(start, end));
                }
            }
            toSend.forEach((e, i) => {
                e = e.replace(/%(?=[0-9])/gm, "&percent");
                data.payload = e;
                data.sliceNumber = i;
                this.sendMessage(data);
            });

        }).catch((error) => {
            data.result = "error";
            data.payload = error + "";
            this.sendMessage(data);
        });
    };

    /**
     * Method that will handle the httpRequests from the journeys.
     * It will verify the payload to validate the desired method, and it will resolve the promise of the httpClient
     * of the shell.
     * After it will send a message to the Journey with the response containing:
     * response.type = 'httpClient'
     * response.result = 'success' || 'error'
     * response.payload = content of the response of the httpClient request
     * response.id = id, that identifies the request
     * @param payload - contains multiple fields:
     *  url,method,headers,params,timeout,responseType,data
     * @param id - unique identifier for the message (enables bi-direccional communicaton without losing context).
     */
    handleHttpClient(data) {
        let response = {};
        response.method = data.method;
        response.id = data.id;
        response.response = true;
        response.type = data.type;
        let promise = this.handleHttpRequest(data);
        promise.then(function (res) {
            if (res.config && res.config.responseType === "arraybuffer") {
                try {
                    let blob = new Blob([res.data], {type : "application/pdf"});
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = res.config.headers["filename"];
                    link.click();
                } catch (e) {

                }
                response.result = "success";
                response.payload = "File was saved successfully";
                this.sendMessage(response);
            } else {
                response.result = "success";
                response.payload = res.data;
                this.sendMessage(response);
            }
        }.bind(this)).catch(function (error) {
            response.result = "error";
            response.payload = error;
            this.sendMessage(response);
        }.bind(this));
    };

    handleHttpRequest(data) {
        let promise;
        let payload = data.payload;

        if (this.props.accessToken) {
            if (payload.headers) {
                if (this.props.accessToken[0] === "\"") {
                    payload.headers = Object.assign(payload.headers, {Authorization : "Bearer " + JSON.parse(this.props.accessToken)});
                } else {
                    payload.headers = Object.assign(payload.headers, {Authorization : "Bearer " + this.props.accessToken});
                }
            } else {
                if (this.props.accessToken[0] === "\"") {
                    payload.headers = {Authorization : "Bearer " + JSON.parse(this.props.accessToken)};
                } else {
                    payload.headers = {Authorization : "Bearer " + this.props.accessToken};
                }
            }
        }

        /**
         * The request is a multipart request with a form data (since the JSON.stringify and JSON.parse will eliminate the content of the data,
         * it creates the necessity to threat the request in our side, so we have to pick up the base64 and strings and convert them to a blob in  a
         * form data)
         * @param  {[type]} payload [description]
         * @return {[type]}         [description]
         */
        if (payload.headers["Content-Type"] === "multipart/form-data") {
            let formData = new FormData();
            for (var key in payload.data) {
                if (payload.data.hasOwnProperty(key)) {
                    if (typeof payload.data[key] === "object") {
                        var blob = new Blob([JSON.stringify(payload.data[key])], {type : "application/json"});
                        blob.lastModifiedDate = new Date();
                        blob.name = key;
                        formData.append(key, blob);
                    } else {
                        var file = new File([payload.data[key]], "svgFile.svg", {type : "image/svg+xml"});
                        formData.append(key, file);
                    }
                }
            }
            payload.data = formData;
        }

        switch (data.method) {
            case "get":
                promise = this.props.services.HttpClient.get(payload.url, payload.headers, payload.params, payload.timeout, payload.responseType);
                break;
            case "post":
                promise = this.props.services.HttpClient.post(payload.url, payload.headers, payload.params, payload.data, payload.timeout, payload.responseType);
                break;
            case "put":
                promise = this.props.services.HttpClient.put(payload.url, payload.headers, payload.params, payload.data, payload.timeout, payload.responseType);
                break;
            case "delete":
                promise = this.props.services.HttpClient.delete(payload.url, payload.headers, payload.params, payload.timeout, payload.responseType);
                break;
            case "patch":
                promise = this.props.services.HttpClient.patch(payload.url, payload.headers, payload.params, payload.data, payload.timeout, payload.responseType);
                break;
            case "options":
                promise = this.props.services.HttpClient.options(payload.url, payload.headers, payload.params, payload.timeout, payload.responseType);
                break;
            default:
                break;
        }
        this.currentPromise = promise;
        this.setAuthenticationTokens = setAuthenticationTokens;
        this.setLoggedUser = setLoggedUser;
        return promise;
    };

    /**
     * Receives the request of handling FileSystem requests
     * @param - Object - object conatining an id (number), type (String) and payload (object) with the base64 and filename (String)
     */
    handleFileSystem(data) {
        data.response = true;
        data.result = "success";
        this.props.services.FileSystem.download(data.payload.base64, data.payload.filename);
        this.sendMessage(response);
    };

    /**
     * HandleGlobalDispatcher method. It will verfy if a function is available on the store and if it is, call that function.
     */
    handleGlobalDispatcher(data) {
        const { handleGlobalDispatcher } = this.props; 
        if (handleGlobalDispatcher) {
            return handleGlobalDispatcher(data);
        } else {
            throw ("No global dispatcher implementation was found on the canvas.");
        }
    };

    handleDirtyJourneySave(data) {
        data.response = true;
        data.result = "success";
        this.sendMessage(data);

        if (window.Event) {
            let event = new CustomEvent("dirty_journeys_save");
            event.message = data;
            window.dispatchEvent(event);
        } else {
            let event = {};
            event.message = data;
            EventRegister.emit("dirty_journeys_save", event);
        }
    };

    /**
     * Method to handle the entilements request
     * @param {*} data 
     */
    handleEntitlements(data) {
        const {services, currentEntitlements} = this.props;
        
        data.result = "success";        
        data.type = "entitlements";
        data.response = true;

        switch(data.method){
            case "verify":
                data.payload = services.EntitlementsVerifier.validateEntitlements(data.payload.entitlements);
                return this.sendMessage(data);
            case "getCurrentEntitlements":
                data.payload = currentEntitlements;
                return this.sendMessage(data);
            case "getEntitlementsByURL":
                services.EntitlementsVerifier.getEntitlementsByURL().then(success => {               
                    data.payload = success; 
                    this.sendMessage(data);
                }).catch((error) => {                
                    data.payload = error;
                    data.result = "error";
                    this.sendMessage(data);
                });
                break;
            default:
                data.payload = "Unsupported method " + data.method + "in " + data.type + ' type';
                data.result = "error";
                return this.sendMessage(data);
        }
    }

    handleUser(data) {
        const { currentLoggedUser } = this.props;
        data.payload = currentLoggedUser;
        data.response = true;
        data.result = "success";
        this.sendMessage(data);
    }

    handleTheme(data) {
        switch (data.method) {
            case "getTheme" :
                let {theme, type, direction, overrides, themeId} = this.props;
                data.payload = { theme, type, direction, overrides, themeId };
                break;
            case "changeType" :
                this.props.setThemeType(data.payload.type);
                this.setThemeType(data.payload.type);
                break;
            case "changeDirection":
                this.props.setThemeDirection(data.payload.direction);
                this.setThemeDirection(data.payload.direction);
                break;
        }
        data.response = true;
        data.result = "success";
        this.sendMessage(data);
    }

    handleI18n(data) {
        data.response = true;
        data.result = "success";
        data.response = true;
        switch (data.method) {
            case "language":
                data.payload = this.props.language;
                break;
            case "changeLanguage":
                this.props.i18nProvider.changeLanguage(data.payload.languageCode);
                return;
            default:
                console.warn("----WARNING (Development)","\n",
                    "I18n method called not supported: "+data.method);
                data.payload = "I18n method called not supported: "+data.method;
                data.result = "error";
                break;
        }
        this.sendMessage(data);
    }

    handleLoading(data) {
        data.response = true;
        switch (data.method) {
            case "setLoading":
                this.setLoading(data.loaderType);
                data.result = "success";
                break;
            case "stopLoading":
                this.stopLoading();
                data.result = "success";
                break;
            default:
                data.result = "error";
        }
        this.sendMessage(data);
    }

    handleUtils(data) {
        const { contentUrl, hostPort, services } = this.props;
        data.result = "success";
        data.response = true;

        switch(data.method){
            case "getDefaultUrl":
                data.payload = { contentUrl, hostPort };
                this.sendMessage(data);
                break;
            case "getHttpHeaders":
                data.payload = services.HttpClient.getDefaultHeaders();
                this.sendMessage(data);
                break;
            case "notifySessionExpired":
                services.HttpClient.refreshToken().then(res => {
                    data.payload = res;
                    this.sendMessage(data);
                }).catch(err => {
                    data.result = "error";
                    data.payload = err;
                    this.sendMessage(data);
                })
                break;
            default:
                data.payload = "No method found: "+data.method;
                data.result = "error";
                this.sendMessage(data);
                return
        }
    }

    /**
     * @param id
     */
    handleLocalStorage(data) {
        let promise, payload = data.payload;
        const { LocalStorage } = this.props.services;

        switch (data.method) {
            case "set":
                promise = LocalStorage.set(payload.key, payload.value, payload.toCipher);
                break;
            case "setAll":
                promise = LocalStorage.setAll(payload.map, payload.toCipher);
                break;
            case "get":
                promise = LocalStorage.get(payload.key);
                break;
            case "remove":
                promise = LocalStorage.remove(payload.key);
                break;
            default:
                return;
        }

        data.response = true;
        promise.then((res) => {
            data.result = "success";
            data.payload = res;
            this.sendMessage(data);
        }).catch((error) => {
            data.result = "error";
            data.payload = error + "";
            this.sendMessage(data);
        });
    };

    handleUserPreferences(data) {
        const { UserPreferences } = this.props.services;
        let payload = data.payload, promise;
        data.response = true;

        switch (data.method) {
            case "read":
                promise = UserPreferences.readPreferences(payload.userId, payload.preferenceId, payload.propertyId);
                break;
            case "query":
                promise = UserPreferences.queryPreferences(payload.userId, payload.expression, payload.preferenceId);
                break;
            case "create-preference":
                promise = UserPreferences.createPreference(payload.userId, payload.preference);
                break;
            case "create-property":
                promise = UserPreferences.createProperty(payload.userId, payload.preferenceId, payload.property);
                break;
            case "update":
                promise = UserPreferences.updatePreference(payload.userId, payload.preference, payload.preferenceId, payload.propertyId);
                break;
            case "delete-preference":
                promise = UserPreferences.deletePreference(payload.userId, payload.preferenceId);
                break;
            case "delete-property":
                promise = UserPreferences.deleteProperty(payload.userId, payload.preferenceId, payload.propertyId);
                break;
            default:
                throw new Error("UserPreference Service not implemented");
        }

        promise.then(res => {
            data.result = "success";
            data.payload = res;
            this.sendMessage(data);
        }).catch(error => {
            data.result = "error";
            data.payload = error + "";
            this.sendMessage(data);
        });

    };

    handleNotification(data) {
        NotificationsHandler.addNotification(data);
        data.result = "success";
        this.sendMessage(data);
    };

    handleGeoLocationRequest(data) {
        data.result = "success";
        data.response = this.props.geoLocation;
        this.sendMessage(data);
    }

    setJourneyThemeType(type) {
        let data = {
            type : "theme",
            response : false,
            method : "changeType",
            payload : {
                type,
                //Retrocompability
                theme : this.props.theme,
                themeId : this.props.themeId
            }
        };
        this.sendMessage(data);
    };

    setJourneyThemeDirection(direction) {
        let data = {
            type : "theme",
            response : false,
            method : "changeDirection",
            payload : {
                direction,
                //Retrocompability
                theme : this.props.theme,
                themeId : this.props.themeId
            }
        };
        this.sendMessage(data);
    };

    setJourneyCurrentEntitlements(entitlements) {
        let data = {
            type : "currentEntitlements",
            response : false,
            payload : { entitlements }
        };
        this.sendMessage(data);
    }

    setJourneyLanguage(language) {
        let data = {
            type : "i18n",
            response : false,
            payload : language
        };
        this.sendMessage(data);
    };

    /**
     * Method that will send information to the journey regarding new widgets to add (Dashboard).
     * @param {*} widgets - List of objects (widgets) to add to dashboard.
     */
    addWidgets(widgets) {
        let data = {
            type : "widgets",
            response : false,
            payload : widgets
        };
        this.sendMessage(data);
    };

    IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
};

export const mapStateToProps = ({utils : {isLoading, loaderType}, shell : {currentLoggedUser, contentUrl, services, hostPort, i18nProvider, accessToken, refreshToken, geoLocation, currentEntitlements}, theme : {type, theme, direction, overrides, themeId}}) => ({
    currentLoggedUser,
    contentUrl,
    services,
    hostPort,
    i18nProvider,
    accessToken,
    refreshToken,
    geoLocation,
    currentEntitlements,
    theme,
    type,
    direction,
    overrides,
    isLoading,
    loaderType,
    themeId
});

export const mapDispatchToProps = {
    setAuthenticationTokens,
    setLoggedUser,
    setThemeType,
    setThemeDirection,
    toggleFullScreen,
    setLoaderState
};