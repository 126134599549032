import {
    SET_LOADER_STATE,
} from "../actions/utils-actions";

const INITIAL_STATE = {
    // shows/hide the loading animation.
    isLoading : false,
    /**
     * Refers to the area occupied by the loading for example: loaderType:'JOURNEY' the loading area occupies only the journey area.
     * When the loaderType is undefined the loading animation occupies all the page.
     */
    loaderType : undefined 
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOADER_STATE:
            const {isLoading, loaderType} = action.payload;
            return {...state, isLoading, loaderType};
        default:
            return state;
    }
};
