import React, { Component } from "react";
import MenuRender from "./MenuRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Menu extends Component {

    state = {
        isSearchOpen : false,
        searchValue : ""
    };

    toggleSearchOpen = () => {
        this.setState((prevState) => ({isSearchOpen : !prevState.isSearchOpen, searchValue : ""}));
    };

    changeSearchValue = (event) => {
        this.setState({searchValue : event.target.value});
    };

    render() {
        const {isMenuOpen} = this.props;
        const {isSearchOpen, searchValue} = this.state;
        if (!isMenuOpen) {
            return (null);
        }
        return (
            <MenuRender isSearchOpen={isSearchOpen}
                        searchValue={searchValue}
                        toggleSearchOpen={this.toggleSearchOpen}
                        changeSearchValue={this.changeSearchValue}/>
        );
    }
}

const mapStateToProps = ({ui : {isMenuOpen}}) => ({isMenuOpen});

const mapDispatchToProps = dispatch => bindActionCreators(
    {},
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(Menu);