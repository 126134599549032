import React, { Component } from "react";
import GenericExtensionRender from "./GenericExtensionRender";
import { connect } from "react-redux";

class GenericExtension extends Component {

    constructor(props){
        super(props);
        this.state = {
            availableExtensions: undefined,
            GenericExtensionPoint: undefined,
            isOpen: true,
            listOpen: false
        }
        if(props.config && props.config.headerGenericExtensions){
            this.getHeaderExtensions();
            this.closeExtension = this.closeExtension.bind(this);
            this.chooseGenericExtension = this.chooseGenericExtension.bind(this);
            this.openListExtensions = this.openListExtensions.bind(this);
            this.closeListExtensions = this.closeListExtensions.bind(this);
        }
    }

    /**
     * Function that will fetch all declared extensions on the configurations
     */
    getHeaderExtensions() {
        const { headerGenericExtensions } = this.props.config;
        let keys = Object.keys(headerGenericExtensions);
        
        keys.forEach(extension => {
            this.loadScript(headerGenericExtensions[extension].url, extension, headerGenericExtensions[extension].libraryname, 
                headerGenericExtensions[extension].friendlyName);
        });
    }

    /**
     * Method that set's a script tag with. On sucess it will set the extension on the store
     * @param {*} src url source of the extension
     * @param {*} extension identifier of the extension
     * @param {*} libraryname library name defined of the extension
     * @param {*} friendlyName friendly name of the extension
     */
    loadScript(src,extension,libraryname,friendlyName) {
        var s;
        s = document.createElement('script');
        s.src = src;
            
        s.onload = this.successLoadedScript.bind(this,extension,libraryname,friendlyName);
        s.onerror = this.errorLoadingScript.bind(this,extension,libraryname);
        document.getElementsByTagName( "head" )[0].appendChild( s );
    }

    /**
     * Method called upon success of the load script
     * @param {*} extension identifier of the extension
     * @param {*} libraryname library name defined of the extension
     * @param {*} friendlyName friendly name of the extension
     */
    successLoadedScript(extension,libraryname,friendlyName) {
        this.setState({availableExtensions: {...this.state.availableExtensions,  [friendlyName]: {
            component: window[libraryname][extension],
            extension: extension,
            isSelected: false
        }}})
    }

    /**
     * Method to update the selected state
     * @param {*} friendlyName friendly name of the extension
     * @param {*} value value boolean, true or false of the selected value
     */
    updateSelected(friendlyName,value){
        let object = Object.assign({},{},this.state.availableExtensions[friendlyName]);
        object.isSelected = value;

        let newAvailableExtensions = this.state.availableExtensions;
        newAvailableExtensions[friendlyName] = object;
        return newAvailableExtensions;
    }

    /**
     * Method called upon an error loading the script
     * @param {*} extension identifier of the extension
     * @param {*} libraryname library name defined of the extension 
     */
    errorLoadingScript(extension,libraryname) {
        console.warn("------------------WARNING--------------------\n"+
            "There was an error loading a generic extension:\n"+
            +"Extension: "+extension+"\n"+
            +"Library Name: "+libraryname);
    }

    /**
     * Method to choose the desired generic extension
     * @param {*} friendlyName friendly name fo the extension
     */
    chooseGenericExtension(friendlyName){
        const {availableExtensions} = this.state;
        let genericExtension = availableExtensions ? availableExtensions[friendlyName] : undefined;

        if(genericExtension){
            if(!genericExtension.isSelected){
                this.setState({GenericExtensionPoint: {component: genericExtension.component, friendlyName: friendlyName}, isOpen: true, listOpen: false,
                    availableExtensions: this.updateSelected(friendlyName, true)});
            } else {
                this.setState({GenericExtensionPoint: undefined, isOpen: false, listOpen: false,
                    availableExtensions: this.updateSelected(friendlyName,false)});
            }
        } else {
            console.warn("----------------- WARNING ---------------\n"+
                "No Generic Extension with name "+extension+ " was found.");
        }
    }

    /**
     * Method to open the extensions list
     */
    openListExtensions(){
        this.setState({listOpen: true});
    }

    /**
     * Method to close the extensions list
     */
    closeListExtensions(){
        this.setState({listOpen: false});
    }

    /**
     * Close the generic extension
     */
    closeExtension(friendlyName) {
        this.setState({GenericExtensionPoint: undefined, isOpen: false, listOpen: false,
            availableExtensions: this.updateSelected(friendlyName,false)});
    }
    
    render() {
        if(this.state.availableExtensions && Object.keys(this.state.availableExtensions).length > 0){
            return (
                <GenericExtensionRender isOpen={this.state.isOpen} GenericExtensionPoint={this.state.GenericExtensionPoint} entitlementsMapper={this.props.entitlementsMapper}
                    listActive={true} closeExtension={this.closeExtension} chooseGenericExtension={this.chooseGenericExtension} availableExtensions={this.state.availableExtensions} 
                    listOpen={this.state.listOpen} openListExtensions={this.openListExtensions} closeListExtensions={this.closeListExtensions}/>
            )
        } else {
            return (
                null
            )
        }
    }
}

const mapStateToProps = ({canvas: {config}}) => ({config});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GenericExtension);