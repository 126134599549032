const ComponentName = "UfeSubHeader";
const styles = theme => ({
    root : {
        flex : "0 1 auto",
        display : "flex",
        minHeight : "60px", //Opera Fix
    },
    subHeaderAppBar : {
        boxShadow : "none",
        zIndex : 'auto',
        color : theme.palette.text.primary,
        backgroundColor : theme.palette.grey[100]
    },
    subHeaderAppBarPrimary : {
        backgroundColor : theme.palette.primary.main,
        color : theme.palette.common.white
    },
    subHeaderToolbar : {
        minHeight : "60px",
        height : "60px",
        paddingRight : "20px",
        paddingLeft : "8px",
        maxHeight : "60px"
    },
    contentContainer : {
        display : "flex",
        width : "100%",
        height : "100%",
        justifyContent : "center",
        alignItems : "center"
    }
});

export { styles };
export { ComponentName };