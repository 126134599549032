import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./TimerStyles";
import Typography from '@ui-lib/core/Typography';

function TimerRender({ classes, startTime }) {

    return (
        <React.Fragment>
                <Typography id='UfeContextTimer' className={classes.root} variant='overline'>
                    {startTime}
                </Typography>
        </React.Fragment>
    );
}

export default withStyles(styles, { name: ComponentName })(TimerRender);