const ComponentName = "UfeUserInfo";
const styles = theme => ({
    root : {
        marginRight : "5px",
        textAlign : "end",
        height : "100%"
    },
    mainLabel : {
        color : theme.palette.common.white,
        display : "inline-flex",
        marginRight : "5px"
    },
    positionLabel : {
        color : theme.palette.common.grey,
        display : "inline-flex"
    },
    secondaryLabel : {
        color : theme.palette.common.grey
    }
});

export { styles };
export { ComponentName };