import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./GlobalSearchPanelStyles";
import Input from "@ui-lib/core/Input";
import InputLabel from "@ui-lib/core/InputLabel";
import FormControl from "@ui-lib/core/FormControl";
import InputAdornment from "@ui-lib/core/InputAdornment";
import IconButton from "@ui-lib/core/IconButton";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";
import GlobalSearchOption from "./GlobalSearchOption";

function GlobalSearchPanelRender({classes, searchValue, onChange, onClearClick, results, i18nProvider, onMouseOver, selected, onItemClick}) {
    return (
        <div className={classes.root}>
            {/*Fix for unwanted auto complete in chrome*/}
            <input style={{display : "none", width : 0, height : 0}} type="text" name="username" id={"username"}/>
            <input style={{display : "none", width : 0, height : 0}} type="password" name="password" id="password"/>
            {/* End Fix*/}
            <FormControl className={classes.input}>
                <InputLabel>{i18nProvider.Labeli18n("search_journeys")}</InputLabel>
                <Input
                    autoComplete={"off"}
                    name={"search_journeys"}
                    id={"search_journeys"}
                    autoFocus={true}
                    value={searchValue}
                    onChange={onChange}
                    endAdornment={searchValue ?
                        <InputAdornment position="start">
                            <IconButton onClick={onClearClick} title={i18nProvider.Texti18n("close")}>
                                <Icon className={classNames(`icon-close`, classes.iconSearch)}/>
                            </IconButton>
                        </InputAdornment>
                        : undefined
                    }
                />
                <div className={classes.optionsContainer}>
                    {results && results.map((element, index) => {
                        return (
                            <GlobalSearchOption optionId={index}
                                                key={index}
                                                selected={selected === index}
                                                subText={element.action === "open" ? i18nProvider.Labeli18n("open") : element.value}
                                                mainText={element.action === "open" ? element.name : element.description}
                                                onMouseOver={onMouseOver}
                                                icon={element.icon}
                                                onClick={onItemClick}
                                                title={element.name}
                            />);
                    })}
                </div>
            </FormControl>
        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(GlobalSearchPanelRender);