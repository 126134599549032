import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./MenuCollapseAllStyles";
import IconButton from '@ui-lib/core/IconButton';
import Icon from '@ui-lib/core/Icon';

function MenuCollapseAllRender({ classes, collapseAll }) {
    return (
        <div className={classes.root}>
            <IconButton id='UfeCollapseAllButton' onClick={() => collapseAll()}><Icon className={'icon-collapse-all'}/></IconButton>
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(MenuCollapseAllRender);