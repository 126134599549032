import React, { Component } from "react";
import TaskSwitcherIconRender from "./TaskSwitcherIconRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setTaskSwitcherModalOpen } from "../../../actions/ui-actions";

class TaskSwitcherIcon extends Component {

    openTaskSwitcher = () => {
        let {setTaskSwitcherModalOpen} = this.props;
        setTaskSwitcherModalOpen(true);
    };

    render() {
        const {i18nProvider} = this.props
        return (
            <TaskSwitcherIconRender i18nProvider={i18nProvider} openTaskSwitcher={this.openTaskSwitcher}/>
        );
    }
}

const mapStateToProps = ({
    i18n,
    shell : {i18nProvider},
    journeys : {openJourneys}, interactions : {currentInteraction}} )=> ({
        openJourneys, currentInteraction, i18nProvider, i18n
});


const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setTaskSwitcherModalOpen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(TaskSwitcherIcon);