import { fade } from "@ui-lib/core/styles/colorManipulator";

const ComponentName = "UfeUserWidget";
const styles = theme => ({
    root : {
        display : "flex",
        alignItems : "center",
        height : "100%",
        padding : "0px 10px",
        "&:hover" : {
            backgroundColor : fade(theme.palette.common.white, theme.palette.action.hoverOpacity),
        }
    }
});

export { styles };
export { ComponentName };