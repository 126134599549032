import LocalStorageService from "./service-providers/local-storage-service";
import FileSystemService from "./service-providers/file-system-service";
import I18nService from "./service-providers/i18n-service";
import DeviceInfoService from "./service-providers/device-info-service";
import EntitlementsVerifierService from "./service-providers/entitlements-verifier-service";
import EntitlementsVerifierProviderCommon from "./providers-commons/entitlements-verifier-provider-common";
import GeoLocationProvider from "./providers-commons/geo-location-provider-common";
import GeoLocationService from "./service-providers/geo-location-service";
import GeoLocationWatcherProvider from "./providers-commons/geo-location-provider-watcher-common";
import GeoLocationWatcherService from "./service-providers/geo-location-watcher-service";
import { EventRegister } from "react-native-event-listeners";
import UserPreferencesProvider from "./providers-commons/user-preference-provider-common";
import UserPreferencesService from "./service-providers/user-preference-service";

try {
    var RealTimeService = require("real-time-service").RealTimeService;
} catch (err) {
    console.log("A Real time service module was not found.");
}

try {
    var HttpClientService = require("http-client-service").HttpClientService;
} catch (err) {
    console.error("A valid http-client-service was not found. The app will not work.");
}

try {
    var NotificationsService = require("notifications-service").NotificationsService;
} catch (err) {
    console.log("A client notifications module was not found.");
}

import { setAuthenticationTokens, setShellDependency, setRunMode } from "./actions/shell-actions";
import { UserPreferencesURL } from "./config";

/**
 * Class used to add common functionalities to the all the shells.
 * @param {*} React
 */
export const Shell = React => class extends React.Component {

    constructor(props, PocStore, loader) {
        super(props);
        this.state = {
            loader
        };

        this.services = {
            Store: PocStore,
            LocalStorage: LocalStorageService,
            FileSystem: FileSystemService,
            HttpClient: new HttpClientService(),
            I18n: I18nService,
            DeviceInfo: DeviceInfoService,
            EntitlementsVerifier: EntitlementsVerifierService,
            GeoLocation: GeoLocationService,
            GeoLocationWatcher: GeoLocationWatcherService,
            UserPreferences: UserPreferencesService,
            RealTime: new RealTimeService(),
            Notifications: NotificationsService
        };

        let disableGeoLocation;
        let nativeGeoEnabled = true;
        try {
            disableGeoLocation = (document.getElementById("disableGeoLocation").innerText === "true");
        } catch (err) {
            disableGeoLocation = false;
            nativeGeoEnabled = false;
        }

        try {
            PocStore.dispatch(setRunMode(document.getElementById("runMode").innerText));
        } catch (err) {
            PocStore.dispatch(setRunMode("loc"));
        }

        /**
         * Setting GeoLocation Watcher
         * It is possible at any time to disable and stop the watcher and start it again.
         */
        if (!disableGeoLocation && nativeGeoEnabled) {
            this.services.GeoLocationWatcher.setGeoLocationWatcherProvider(GeoLocationWatcherProvider);
            this.services.GeoLocationWatcher.setStore(PocStore);
            this.services.GeoLocationWatcher.setWatcher();
        } else {
            PocStore.dispatch(setShellDependency("geoLocation"));
        }

        this.services.GeoLocation.setGeoLocationProvider(GeoLocationProvider);
        this.services.GeoLocation.setStore(PocStore);

        this.services.HttpClient.setStore(PocStore);
        this.services.HttpClient.setGeoLocationService(this.services.GeoLocation);

        const entitlementsVerifierProviderCommon = new EntitlementsVerifierProviderCommon();
        entitlementsVerifierProviderCommon.setHttpClient(this.services.HttpClient);
        this.services.EntitlementsVerifier.setEntitlementVerifierProvider(entitlementsVerifierProviderCommon);
        this.services.EntitlementsVerifier.setStore(PocStore);

        this.services.UserPreferences.setUserPreferenceProvider(new UserPreferencesProvider(UserPreferencesURL.ENDPOINT, this.services.HttpClient));

        this.loginEvents = this.loginEvents.bind(this);

        if (window && window.addEventListener) {
            window.addEventListener("loginEvents", this.loginEvents, false);
        } else {
            EventRegister.addEventListener("loginEvents", this.loginEvents);
        }
        props.setServices(this.services);
    };

    /**
     *   after create the shell component, a call to get the theme is made.
     */
    componentDidMount() {
        const {getTheme, currentThemeId} = this.props;
        const {services: {HttpClient}} = this;
        getTheme(currentThemeId, HttpClient);
    };

    /**
     *  Only will render if all the dependencies are completed.
     *  @param {*} nextProps
     */
    shouldComponentUpdate(nextProps) {
        if (!nextProps.shellDependencies.every(v => v)) {
            const {dictionaries, setShellDependency, theme} = this.props;
            if (JSON.stringify(dictionaries) !== JSON.stringify(nextProps.dictionaries)) {
                setShellDependency("i18n");
            }
            if (this.loadTheme && (JSON.stringify(theme) !== JSON.stringify(nextProps.theme))) {
                setShellDependency("theme");
                this.loadTheme = false;
            }
        }
        return nextProps.shellDependencies.every(v => v);
    };

    /**
     * Function that is invoked when the tokens are updated or deleted.
     * The function is registered as window event.
     * @param {*} event
     */
    loginEvents(event) {

        const {message, access_token, refresh_token} = event;
        const {services: {Store, LocalStorage, I18n}, props: {setThemeType, setThemeDirection}} = this;
        switch (message) {
            case 'changeTokens':
                Store.dispatch(setAuthenticationTokens(access_token, refresh_token));
                LocalStorage.set("accessToken", access_token, true).then();
                LocalStorage.set("refreshToken", refresh_token, true).then();
                break;

            case 'destroyTokens':
                Store.dispatch(setAuthenticationTokens("", ""));
                LocalStorage.remove("accessToken").then();
                LocalStorage.remove("refreshToken").then();

                let defaultLanguage = undefined;

                setThemeType(Store.getState().canvas.config.defaultType, true);
                setThemeDirection(Store.getState().canvas.config.defaultDirection, true);

                Store.getState().i18n.languages.map(language => {
                    defaultLanguage = language.default ? language.code : defaultLanguage;
                });

                if (defaultLanguage === undefined && !Store.getState().disableOsLanguagePreference) {
                    defaultLanguage = Store.getState().shell.services.I18n.i18nProvider.deviceInfoProvider.getDeviceLocale();
                } else if (defaultLanguage === undefined) {
                    defaultLanguage = "en-US";
                }
                I18n.i18nProvider.changeLanguage(defaultLanguage, true);
                break;
        }
    };

    /**
     * Set the component that extends the implementation of this class.
     * @param {*} InnerComponent
     */
    setInnerComponent(InnerComponent) {
        this.innerComponent = InnerComponent;
    };

    /**
     * To set the spinner that will be used in the moments that requires the loading animation.
     * For example the render of loading spinner render in native is different to the web.
     * @param {*} LoadingSpinner
     */
    setLoadingSpinner(LoadingSpinner) {
        this.loadingSpinner = LoadingSpinner;
    };

    render() {
        const InnerComponent = this.innerComponent;
        return (this.props.shellDependencies.every(v => v) ? <InnerComponent {...this.state} {...this.props} /> : <this.state.loader/>);
    };
};
