import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./UserMenuStyles";
import ClickAwayListener from "../../../ShareComponents/ClickAwayListener";
import MenuLogout from "./Icons/MenuLogout";
import MenuSettings from "./Icons/MenuSettings";
import MenuPrivateMode from "./Icons/MenuPrivateMode";
import { IEntitlementsVerifier } from "omni-shell-common/src/entitlements-verifier";

function UserMenuRender({ classes, closeMenu, entitlements }) {
    let EntitlementsVerifier = IEntitlementsVerifier(React);
    return (
        <ClickAwayListener onClickAway={closeMenu}>
            <div id='UfeUserMenu' className={classes.root}>
                <EntitlementsVerifier entitlement={entitlements.header.userMenu.privateMode}>
                    <MenuPrivateMode />
                </EntitlementsVerifier>
                <EntitlementsVerifier entitlement={entitlements.header.userMenu.userSettings}>
                    <MenuSettings />
                </EntitlementsVerifier>
                <MenuLogout />
            </div>
        </ClickAwayListener>
    );
}

export default withStyles(styles, { name: ComponentName })(UserMenuRender);