/**
 * Created by NB22722 on 14/11/2017.
 */
import {
    closeJourney,
    openJourney,
    setDirtyJourney,
    setJourneySubtitle
} from "omni-shell-common/src/actions/journeys-actions";
import { addInteractionWithJourneys, updateContextSelected, toggle360Visibility } from "../actions/interactions-actions";
import {
    setJourneyActionDirtyModalOpen
} from "../actions/ui-actions";
import {
    setJourneyDirtyId,
    setInteractionTimer
} from "../actions/interactions-actions";

export default class ReceivedEventsHandler {

    constructor(store) {
        this.store = store;
    }

    setIFrame(_iframe) {
        this._iframe = _iframe;
    }

    setThreesixtyIframe(_iframe) {
        this._threesixtyIframe = _iframe;
    }

    handleInteractionAction = (data) => {
        let response = {};
        response.type = "interactions";
        response.id = data.id;

        switch (data.method) {
            case "openInteraction":
                if (this.store.getState().canvas.config.threesixtyConfigs.interactionsLimit > this.store.getState().interactions.interactions.size) {
                    this.store.dispatch(addInteractionWithJourneys(data.payload));
                    response.result = "success";
                } else response.result = "error";
                this._iframe.sendMessage(response);
                return;
            case "addContextToCurrentInteraction":
                this.store.dispatch(updateContextSelected(data.payload));

                response.result = "success";
                this._iframe.sendMessage(response);
                return;
            case "toggle360Visibility":
                this.store.dispatch(toggle360Visibility(data.payload));

                response.result = "success";
                this._iframe.sendMessage(response);
                return;
            case "resetInteractionCounter":
                this.handleResetInteractionCounter(data);
        }
    }

    handleResetInteractionCounter = (data) => {
        const response = {};
        response.type = "interactions";
        response.result = "success";
        response.id = data.id;
        let instanceId = data.payload.instance_id
        
        if (!instanceId) {
            instanceId = this.store.getState().interactions.currentInteraction.instance_id;
        }
        
        this.store.dispatch(setInteractionTimer(instanceId, Date.now()));       
        this._iframe.sendMessage(response);
    }

    handleJourneyAction = (data, interaction_instance_id, instance_id, threesixty) => {
        let response = {};

        response.type = "journeyAction";
        response.id = data.id;
        response.response = true;

        let response_channel = !threesixty ? this._iframe : this._threesixtyIframe;

        switch (data.method) {
            case "closeJourney":
                var { journey_instance_id } = data.payload;
                var currentInstanceId = this.store.getState().interactions.currentInteraction.instance_id;
                this.store.dispatch(closeJourney(journey_instance_id ? journey_instance_id : instance_id, currentInstanceId));

                response.result = "success";
                response_channel.sendMessage(response);
                return;
            case "openJourney":
                var currentInstanceId = this.store.getState().interactions.currentInteraction.instance_id;
                this.store.dispatch(openJourney(data.payload.journeyId, currentInstanceId));

                response.result = "success";
                response_channel.sendMessage(response);
                return;
            case "setDirty":
                var currentInstanceId = this.store.getState().interactions.currentInteraction.instance_id;
                this.store.dispatch(setDirtyJourney(instance_id, currentInstanceId, data.payload.value));

                response.result = "success";
                response_channel.sendMessage(response);
                return;
            case "availableWidgets":
                response.payload = this.store.getState().interactions.widgetList;

                response.result = "success";
                response_channel.sendMessage(response);
                return;
            case "journeyWidgets":
                response.payload = this.store.getState().journeys.currentJourney.widgets;

                response.result = "success";
                response_channel.sendMessage(response);
                return;
            case "editableState":
                response.payload = this.store.getState().canvas.isWidgetsEditable;

                response.result = "success";
                response_channel.sendMessage(response);
                return;
            case "openJourneyWithArgs":
                var { journeyId, args } = data.payload;
                var currentInstanceId = this.store.getState().interactions.currentInteraction.instance_id;
                this.store.dispatch(openJourney(journeyId, currentInstanceId, args));

                response.result = "success";
                response_channel.sendMessage(response);
                return;
            case "setJourneySubtitle":
                var { subtitle, journey_instance_id } = data.payload;
                var currentInstanceId = this.store.getState().interactions.currentInteraction.instance_id;

                this.store.dispatch(setJourneySubtitle(journey_instance_id ? journey_instance_id : instance_id, currentInstanceId, subtitle));

                response.result = "success";
                response_channel.sendMessage(response);
                return;
            case "openDirtyJourneyModal":
                this.store.dispatch(setJourneyDirtyId(data.id))
                this.store.dispatch(setJourneyActionDirtyModalOpen(true));
                break;
            case "resetInteractionCounter":
                this.handleResetInteractionCounter(data);
            default:
                break;
        }
    };
}
