class GeoLocationService {

  constructor(){}

  setGeoLocationProvider(geoLocationProvider) {
      this.geoLocationProvider = geoLocationProvider;
  }

  getPosition(){
    return this.geoLocationProvider.getPosition();
  }

  setStore(store){
    return this.geoLocationProvider.setStore(store);
  }
}
const geoLocationService = new GeoLocationService();
export default geoLocationService;
