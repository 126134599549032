const ComponentName = "UfeUserAvatar";
const styles = theme => ({
    root : {
        height : "100%",
        justifyContent : "center",
        alignItems : "center",
        display : "flex"
    },
    avatar : {
        width : 31,
        height : 31,
        marginBottom : "5px"
    }
});

export { styles };
export { ComponentName };