import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./SaveChangesButtonStyles";
import Button from "@ui-lib/core/Button";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";

function SaveChangesButtonRender({classes, saveChanges, i18nProvider, dashboardEditMode}) {
    return (
        <div className={dashboardEditMode ? classNames(classes.root, classes.editMode) : classes.root}>
            <Button id='UfeSaveChangesButton' title={i18nProvider.Texti18n("save_changes_button")} onClick={saveChanges} variant="outlined" className={classes.button}>
                <Icon className={classNames("icon-save", classes.icon)}/>
                {i18nProvider.Labeli18n("save_changes_button")}
            </Button>
        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(SaveChangesButtonRender);