import React, { Component } from "react";
import SearchFieldRender from "./SearchFieldRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class SearchField extends Component {

    render() {
        const { onChange, onKeyPress, onClearClick, i18nProvider, searchValue, searchClick } = this.props;
        return (
            <SearchFieldRender
                i18nProvider={i18nProvider}
                searchValue={searchValue}
                onChange={onChange}
                onKeyPress={onKeyPress}
                onClearClick={onClearClick}
                searchClick={searchClick}
            />
        );
    };
};

const mapStateToProps = ({ ui: { isSearchListMenuOpen }, shell: { entitlementsMapper, i18nProvider }, canvas: { sendEventHandler, config: { threesixtyConfigs } }, interactions: { currentInteraction, interactions } }) =>
    ({ isSearchListMenuOpen, entitlementsMapper, sendEventHandler, currentInteraction, threesixtyConfigs, interactions, i18nProvider });

const mapDispatchToProps = dispatch => bindActionCreators(
    {
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);