import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeInteraction } from "../../../../../../actions/interactions-actions";
import { setDirtyJourneyModalOpen } from "../../../../../../actions/ui-actions";
import CloseInteractionButtonRender from "./CloseInteractionButtonRender";
import { Config } from '../../../../../../config';

class CloseInteractionButton extends Component {

    setTimer = (timer) => {
        this.timer = timer;
    }

    closeInteraction = () => {
        const { sendEventHandler: { close360View }, currentInteraction, removeInteraction, journeyHistory, setDirtyJourneyModalOpen, startedTime } = this.props;
        let payload = { attendeeDuration: new Date() - currentInteraction.startedTime };
        const dirtyJourneys = journeyHistory.get(currentInteraction.instance_id).filter(journey => journey.isDirty);
        
        if (dirtyJourneys.size > 0) {
            setDirtyJourneyModalOpen(true, Config.CLOSE_JOURNEY_SCOPE.CURRENT_INTERACTION);
        }
        else {
            const journeysToBeNotified = journeyHistory.get(currentInteraction.instance_id).filter(journey => journey.shouldBeNotifiedBeforeUnload);
            if (journeysToBeNotified.size > 0) {
                setDirtyJourneyModalOpen(false, Config.CLOSE_JOURNEY_SCOPE.CURRENT_INTERACTION);
            }
            else {
                close360View(currentInteraction.instance_id, payload).then(res => {
                    removeInteraction(currentInteraction.instance_id);
                }).catch(err => {
                    console.log("Close Interaction error: " + err);
                    removeInteraction(currentInteraction.instance_id);
                });
            }
        }
    }

    render() {
        const { threesixtyConfigs, currentInteraction } = this.props;
        return (
            <CloseInteractionButtonRender
                closeInteraction={this.closeInteraction}
                setTimer={this.setTimer}
                currentInteraction={currentInteraction}
                closeIcon={threesixtyConfigs.closeIcon}
            />
        );
    }
}

const mapStateToProps = ({ journeys: { journeyHistory }, interactions: { currentInteraction }, canvas: { sendEventHandler, config: { threesixtyConfigs } }, shell: { entitlementsMapper } }) => ({ sendEventHandler, threesixtyConfigs, entitlementsMapper, currentInteraction, journeyHistory });
const mapDispatchToProps = dispatch => bindActionCreators({
    removeInteraction,
    setDirtyJourneyModalOpen
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CloseInteractionButton);