import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./PanelStyles";
import ClickAwayListener from "../ShareComponents/ClickAwayListener";
import IconButton from "@ui-lib/core/IconButton";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";

function PanelRender({classes, closePanel, panel, i18nProvider}) {
    return (
        <ClickAwayListener onClickAway={closePanel}>
            <div className={classes.root}>
                {panel && panel}
                <IconButton onClick={closePanel} className={classes.buttonClose} title={i18nProvider.Texti18n("close")}>
                    <Icon className={classNames("icon-close", classes.iconClose)}/>
                </IconButton>
            </div>
        </ClickAwayListener>
    );
}

export default withStyles(styles, {name : ComponentName})(PanelRender);