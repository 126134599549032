
class GeoLocationProvider {
    
    constructor() {}

    setStore(Store) {
        this.store = Store;
    }

    getPosition() {
        return this.store.getState().shell.geoLocation;
    }
}
const geoLocationProvider = new GeoLocationProvider();
export default geoLocationProvider;
