import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./SearchFieldStyles";
import TextField from "@ui-lib/core/TextField";
import FormControl from "@ui-lib/core/FormControl";
import InputAdornment from "@ui-lib/core/InputAdornment";
import IconButton from "@ui-lib/core/IconButton";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";

function SearchFieldRender({ classes, i18nProvider, searchValue, onChange, onKeyPress, onClearClick, searchClick }) {
    return (
        <div>
            {/*Fix for unwanted auto complete in chrome*/}
            <input style={{ display: "none", width: 0, height: 0 }} type="text" name="username" id={"username"} />
            <input style={{ display: "none", width: 0, height: 0 }} type="password" name="password" id="password" />
            {/* End Fix*/}
            <FormControl className={classes.input}>
                <TextField
                    className={classes.textField}
                    fullWidth={true}
                    placeholder={i18nProvider.Texti18n("search_360_placeholder")}
                    autoComplete={"off"}
                    name={"search_contenxt"}
                    id='UfeContextSearchField'
                    autoFocus={true}
                    value={searchValue}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchValue.length > 0 && <IconButton id='UfeContextSearchFieldClearButton' className={classes.iconsAlignRight} onClick={onClearClick} title={"Close"}>
                                    <Icon className={classNames(`icon-close`, classes.iconClear)} />
                                </IconButton>}
                                <IconButton id='UfeContextSearchFieldSearchButton' className={classes.iconsAlignRight} color={"primary"} title={"Search"} onClick={searchClick}>
                                    <Icon className={classNames(`icon-search`, classes.iconSearch)} />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </FormControl>
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(SearchFieldRender);