import { findThemeKeyValue } from '../../utils/theme';

const ComponentName = 'UfeHeader';
const styles = theme => ({
    root : {
        flex : '0 0 auto',
        display : 'flex',
    },
    headerAppBar : {
        boxShadow : 'none',
        color : theme.palette.common.white,
        zIndex : 'auto',
        backgroundColor : findThemeKeyValue('headerBackgroundColor', theme)
    },
    headerToolbar : {
        minHeight : '46px',
        height : '46px',
        maxHeight : '46px',
        paddingRight : '20px',
        paddingLeft : '20px',
    }
});

export { styles };
export { ComponentName };