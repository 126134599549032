import { i18nActions } from 'redux-react-i18n';
import I18nProviderCommon from 'omni-shell-common/src/providers-commons/i18n-provider-common';


export class I18nProvider extends I18nProviderCommon{

    constructor() {
        super(i18nActions);
    }


}

const i18nProvider = new I18nProvider();
export default i18nProvider;
