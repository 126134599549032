import { fade } from "@ui-lib/core/styles/colorManipulator";

const ComponentName = "UfeContextContainer";
const styles = theme => ({
    root : {
        zIndex: 1,
        height: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    openedMenuContainer: {
        height: '100%',
        display: 'flex'
    },
    span: {
        height:'100%',
        width: '1px',
        backgroundColor: fade(theme.palette.grey[50], 0.2)
    }
});

export { styles };
export { ComponentName };