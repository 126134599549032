import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./GlobalSearchOptionStyles";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";

function GlobalSearchOptionsRender({classes, onClick, onMouseOver, icon, mainText, subText, selected, title}) {
    let rootClasses = selected ? classNames(classes.option, classes.optionSelected) : classes.option;
    return (
        <div onClick={onClick} onMouseOver={onMouseOver} className={rootClasses} title={title}>
            <div className={classes.optionIconContainer}>
                 {icon && icon.startsWith("icon-") ? <Icon className={classNames(icon, classes.optionIcon)}/> : <Icon className={classNames(classes.optionIcon)}>{icon}</Icon>}
            </div>
            <div className={classes.optionSubText}>
                {subText}
            </div>
            <div className={classes.optionMainText}>
                {mainText}
            </div>
        </div>

    );
}

export default withStyles(styles, {name : ComponentName})(GlobalSearchOptionsRender);