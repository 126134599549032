import React, { Component } from "react";
import MenuCollapseAllRender from "./MenuCollapseAllRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setAllExpanded } from "../../../actions/ui-actions";

class MenuCollapseAll extends Component {


    collapseAll = () => {
        this.props.setAllExpanded(false);
    }

    render() {
        return (
            <MenuCollapseAllRender collapseAll={this.collapseAll} />
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setAllExpanded
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(MenuCollapseAll);