const ComponentName = 'JourneyCategorySection';
const styles = theme => ({
    root: {
    },
    subtitleContainer: {
        marginBottom: 16
    },
    journeyContainerGrid: {
        display: "flex",
        marginBottom: 48
    },
    journeyContainerList: {
        display: "block",
        marginBottom: 48
    },
    toggleIcon: {
        padding: "4px",
        marginRight: "4px"
    },
    categoryRoot: {
        display: "flex",
        alignItems: "center"
    },
    collapsed: {
        marginBottom: 48
    },
    collapsedCategory: {
        padding: "4px",
        marginRight: "4px",
        color: theme.palette.primary.main
    },
    emptyFavouritesHeaderText: {
        cursor: 'default',
        marginTop: 16,
        color: theme.palette.text.main
    },
    emptyFavouritesSubHeaderText: {
        cursor: 'default',
        color: theme.palette.text.secondary,
        marginTop: 8
    },
    emptyFavouritesText: {
        marginLeft: 20
    },
    gridEmptyFavourites: {
        display: '-webkit-box',
        display: '-ms-flexbox',
        display: "flex",
        '-webkit-box-align': 'center',
        '-ms-flex-align': 'center',
        alignItems: 'center',
        marginLeft: 28
    },
    countFont: {
        color: theme.palette.text.secondary
    },
    clearFavouritesButton: {
        marginLeft: 33
    },
    imageDiv: {
        width: '40px',
        height: '32px',
        marginTop: 16,
    }

})

export { ComponentName, styles }