import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./JourneyActionDirtyModalStyles";
import Dialog from "@ui-lib/core/Dialog";
import DialogContent from "@ui-lib/core/DialogContent";
import DialogTitle from "@ui-lib/core/DialogTitle";
import DialogActions from "@ui-lib/core/DialogActions";
import classNames from "@ui-lib/core/classNames";
import IconButton from "@ui-lib/core/IconButton";
import Icon from "@ui-lib/core/Icon";
import Button from "@ui-lib/core/Button";

function JourneyActionDirtyModalRender({classes, isJourneyActionDirtyModalOpen, onButtonClicked, onClose, maxWidth, fullWidth, i18nProvider}) {

    return (
        <Dialog
            id='UfeActionJourneyDirtyModal'
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={isJourneyActionDirtyModalOpen}
            onClose={onClose}
            className={classes.root}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">
                <div className={classes.titleContainer}>
                    {i18nProvider.Labeli18n("work_in_progress")}
                </div>
                <IconButton id="UfeJourneyActionDirtyModalCloseIconBtn" onClick={onClose} className={classNames(classes.iconButtonClose, classes.titleContainer)} title={i18nProvider.Texti18n("close")}>
                    <Icon className={classNames("icon-close", classes.iconClose)}/>
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.contentPaddingFix}>
                {i18nProvider.Labeli18n("work_in_progress_message")}
            </DialogContent>
            <DialogActions>
                <Button id="UfeJourneyActionDirtyModalSaveBtn" variant="contained" color="primary" title={"Button"} onClick={() => {
                    onButtonClicked('save');
                }}>{i18nProvider.Labeli18n("save")}</Button>
                <Button id="UfeJourneyActionDirtyModalDiscardBtn" variant="contained" color="primary" title={"Button"} onClick={() => {
                    onButtonClicked('discard');
                }}>{i18nProvider.Labeli18n("discard")}</Button>
                <Button id="UfeJourneyActionDirtyModalCloseBtn" variant="contained" color="primary" title={"Button"} onClick={onClose}>{i18nProvider.Labeli18n("close")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default withStyles(styles, {name : ComponentName})(JourneyActionDirtyModalRender);