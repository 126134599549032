import React, { Component } from "react";
import GlobalSearchPanelRender from "./GlobalSearchPanelRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    openJourney
} from "omni-shell-common/src/actions/journeys-actions";
import { setGlobalSearchSpec } from "../../../actions/canvas-actions";
import { setPanelOpen } from "../../../actions/ui-actions";

class GlobalSearchPanel extends Component {

    state = {
        selected : 0,
        results : [],
    };

    onClearClick = () => {
        this.props.setGlobalSearchSpec("");
        this.setState({
            selected : 0,
            results : []
        });
    };

    componentDidMount() {
        this.props.globalSearchSpec && this.getData(this.props.globalSearchSpec);
        try {
            document.addEventListener("keydown", this.handleKeyDown);
        } catch (err) {
        }
    }

    componentWillUnmount() {
        try {
            document.removeEventListener("keydown", this.handleKeyDown);
        } catch (err) {
        }
    }

    handleKeyDown = (e) => {
        const {selected, results} = this.state;

        if (e.keyCode === 13) {
            if (results && results[selected]) {
                this.onItemClick(selected);
            }
        }
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
        if (e.keyCode === 38 && selected > 0) {
            this.setState(prevState => ({
                selected : prevState.selected - 1
            }));
        } else if (e.keyCode === 40 && selected < results.length - 1) {
            this.setState(prevState => ({
                selected : prevState.selected + 1
            }));
        }
    };

    onItemClick = (selected) => {
        const {results} = this.state;
        const {setPanelOpen, openJourney, currentInteraction} = this.props;
        let result = results[selected];
        if (result.action === "open") {
            // Open a new journey
            setPanelOpen(false);
            openJourney(result.id, currentInteraction.instance_id);
        } else if (result.action === "open_execute") {
            // Open a new journey and execute command
            setPanelOpen(false);
            openJourney(result.id, currentInteraction.instance_id, {action_journey : result.action_journey, value : result.value});
        }
    };

    onMouseOver = (index) => {
        const {selected} = this.state;
        if (selected === index) {
            return;
        }
        this.setState({selected : index});
    };

    onChange = (event) => {
        this.getData(event.target.value);
        this.props.setGlobalSearchSpec(event.target.value);
    };

    getData = (value) => {
        //TODO improve method otherwise in some pcs this method blocks the browser
        const {config} = this.props;
        let processesNameMinLength = config.globalSearchPatterns.ProcessesNameMatchMinLength;
        let processesActionMinLength = config.globalSearchPatterns.ProcessesActionMatchMinLength;
        if (value.length < processesNameMinLength && value.length < processesActionMinLength) {
            this.setState({results : []});
            return;
        }
        let data = [];
        if (value.length >= processesNameMinLength) {
            data = data.concat(this.searchForProcessesToOpen(value));
        }
        if (value.length >= processesActionMinLength) {
            data = data.concat(this.searchForProcessesToExecuteSearchSpec(value));
        }
        this.setState({results : data});
    };

    searchForProcessesToOpen = (value)=> {
        const {journeysManifest, i18nProvider, EntitlementsVerifier} = this.props;
        let data = [];
        journeysManifest.forEach(function (journey) {
            if (EntitlementsVerifier.validateEntitlements(journey.entitlement)) {
                if (journey.searchableByName !== "false") {
                    let journeyName = i18nProvider.Texti18n(journey.i18NLabel);
                    let match = journeyName.toUpperCase().includes(value.toUpperCase());
                    if (match) {
                        data.push(
                            {
                                action : "open",
                                id : journey.id,
                                icon : journey.icon,
                                name : journeyName,
                                key : data.length
                            }
                        );
                    }
                }
            }
        }.bind(this));
        return data;
    }

    searchForProcessesToExecuteSearchSpec = (value) => {
        const {journeysManifest, i18nProvider, EntitlementsVerifier} = this.props;
        let data = [];
        journeysManifest.forEach((journey) => {
            if (EntitlementsVerifier.validateEntitlements(journey.entitlement)) {
                if (journey.globalSearchSpecs) {
                    journey.globalSearchSpecs.forEach((spec) => {
                        if (new RegExp(spec.filterPattern).test(value)) {
                            data.push({
                                action : "open_execute",
                                action_journey : spec.actionJourney,
                                id : journey.id,
                                icon : spec.icon,
                                description : i18nProvider.Texti18n(spec.i18NsearchDescription),
                                value : value,
                                key : data.length
                            });
                        }
                    });
                }
            }
        });
        return data;
    };

    render() {
        const {results, selected} = this.state;
        const {i18nProvider, globalSearchSpec} = this.props;
        return (
            <GlobalSearchPanelRender
                searchValue={globalSearchSpec}
                onChange={this.onChange}
                onClearClick={this.onClearClick}
                results={results}
                selected={selected}
                i18nProvider={i18nProvider}
                onMouseOver={this.onMouseOver}
                onItemClick={this.onItemClick}
            />
        );
    }
}

const mapStateToProps = ({canvas : {config, globalSearchSpec}, journeys : {journeysManifest}, shell : {i18nProvider, services : {EntitlementsVerifier}}, interactions : {currentInteraction}}) => ({
    config,
    globalSearchSpec,
    journeysManifest,
    i18nProvider,
    EntitlementsVerifier,
    currentInteraction
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        openJourney,
        setPanelOpen,
        setGlobalSearchSpec
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearchPanel);