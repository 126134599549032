import * as constants from "../constants";

const initialState = {
    themeId : "default",
    theme : {},
    canvasName : "",
    overrides : undefined,
    type : "light",
    direction : "ltr"
};

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case constants.SET_THEME:
            let {themeId, theme, canvasName} = action.theme;
            return {...state, themeId : themeId, theme : theme, canvasName : canvasName};
        case constants.SET_THEME_OVERRIDES:
            return {...state, overrides : action.overrides};
        case constants.SET_THEME_TYPE:
            return {...state, type : action.themeType};
        case constants.SET_THEME_DIRECTION:
            return {...state, direction : action.direction};
        default:
            return state;
    }
}
