import { setCurrentEntitlements } from "../actions/shell-actions";
export default class EntitlementsVerifierProviderCommon {

	constructor() { };

	/**
	 * [setStore Sets the store that will be used to store the i18n]
	 * @param {ReduxStore} store [Store to be used]
	 */
	setStore(store) {
		this.store = store;
		this.store.subscribe(() => this.currentEntitlements = this.store.getState().shell.currentEntitlements);
	};

	/**
     * the service http client
     * @param {*} httpClient
     */
    setHttpClient(httpClient) {
        this.httpClient = httpClient;
    }

	/**
	 * To check if a character is reserved.
	 * @param {String} character
	 * @returns	{boolean} 
	 */
	isCharacaterReserved(character) {
		switch (character) {
			case '!':
			case '&':
			case '|':
			case '(':
			case ')':
				return true;
			default:
				return false;
		}
	};

	/**
	 * [Verifies if an entitlement exist on the current user entitlements list.]
	 * @param {String} entitlement 
	 */
	entitlementExistsOnCurrentUser(entitlement) {
		let match = this.currentEntitlements.filter((ce) => ce === entitlement);
		if (match.length > 0) {
			return 'true';
		}
		return 'false';
	};
	/**
	 * [Returns an expression that turns an entitlement to true or false depending on it being currently on the entitlements list or not.
	 * Example: (CAN_DRAG_WIDGETS&&ENTITLEMENT)||CAN_DRAG_WIDGETS => (true&&false||true).]
	 * @param {String} entitlements 
	 */
	generateExpressionToBeEvaluated(entitlements) {
		let expression = [];
		let currentEntitlement = [];
		for (var i = 0; i < entitlements.length; i++) {
			let element = entitlements[i];
			if (this.isCharacaterReserved(element)) {
				if (currentEntitlement.length > 0) {
					expression.push(this.entitlementExistsOnCurrentUser(currentEntitlement.join('').trim()) + element);
					currentEntitlement = [];
				} else {
					expression.push(element);
				}
			} else {
				currentEntitlement.push(element)
			}
		}
		if (currentEntitlement.length > 0) {
			expression.push(this.entitlementExistsOnCurrentUser(currentEntitlement.join('').trim()));
		}
		return expression.join('');
	};

	/**
	 * [validateEntitlements Validates the entitlements, it will only return a boolean checking if it is allowed or not ...to be extended]
	 * @param  {object} entitlements [entitlements to validate if allowed]
	 * @return {boolean}     [value that will inform if the user has the right entitlements]
	 */
	validateEntitlements(entitlements) {
		if (!entitlements) return true;

		if (!this.currentEntitlements) return false;

		let evalResult = eval(this.generateExpressionToBeEvaluated(entitlements));

		return evalResult ? true : false;
	};

	/**
	 * [try to get the currentEntitlements for the logged user of a external service]
	 * @return {promise}
	 */
	getEntitlementsByURL = () => {
		const url = this.store.getState().shell.entitlementsURL;		
		if (url){			
			return new Promise ((resolve, reject) => {
				this.httpClient.post(url).then((response) => {
					this.store.dispatch(setCurrentEntitlements(response.data));	
					resolve(response.data);
				}
				).catch( (error) => {
					reject(error);
				}
				);
			});
		} else {
			return new Promise (({reject}) => {
				reject('Missing the Entitlements URL');			
			});			
		}		
	};
}
