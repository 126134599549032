export const ADD_WIDGETS = "add_widgets";
export const addWidgets = (widgets) => ({type : ADD_WIDGETS, widgets});

export const SET_CONTEXTS = "set_contexts";
export const setContexts = (contexts) => ({type : SET_CONTEXTS, contexts});

export const SET_CONFIGURATION = "set_configuration";
export const setConfiguration = (configuration) => ({type : SET_CONFIGURATION, payload : configuration});

export const SET_EDITABLE_WIDGETS_STATE = "set_editable_widgets_state";
export const setEditableWidgetsState = (isWidgetsEditable) => ({type : SET_EDITABLE_WIDGETS_STATE, isWidgetsEditable});

export const SET_SEND_EVENT_HANDLER = "SET_SEND_EVENT_HANDLER";
export const setSendEventHandler = (instance) => ({type : SET_SEND_EVENT_HANDLER, payload : instance});

export const SET_AUTHENTICATION_HANDLERS = "SET_AUTHENTICATION_HANDLERS";
export const setAuthenticationHandlers = (instance) => ({type : SET_AUTHENTICATION_HANDLERS, payload : instance});

export const SET_USER_INFO_FIELDS = "SET_USER_INFO_FIELDS";
export const setUserInfoFields = (userInfoFields) => ({type : SET_USER_INFO_FIELDS, userInfoFields});

export const LOG_IN = "LOG_IN";
export const logIn = () => ({type : LOG_IN});

export const LOG_OUT = "LOG_OUT";
export const logOut = () => ({type : LOG_OUT});

export const SET_TIMEOUT = "SET_TIMEOUT";
export const setTimeout = (timeout) => ({type : SET_TIMEOUT, timeout});

export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const setAuthenticated = (value) => ({type : SET_AUTHENTICATED, value});

export const SET_USER_PREFS_LOADED = "SET_USER_PREFS_LOADED";
export const setUserPrefsLoaded = (value) => ({type : SET_USER_PREFS_LOADED, payload : value});

export const SET_GLOBAL_SEARCH_SPEC = "SET_GLOBAL_SEARCH_SPEC";
export const setGlobalSearchSpec = (value) => ({type : SET_GLOBAL_SEARCH_SPEC, value});

export const SET_MENU_FAVOURITE_JOURNEYS = "SET_MENU_FAVOURITE_JOURNEYS";
export const setFavouriteMenuJourneys = (value) => ({type : SET_MENU_FAVOURITE_JOURNEYS, value});

import { addMenuFavouritePrefs, removeMenuFavouritePrefs } from 'omni-shell-common/src/actions/shell-actions';

export const ADD_FAVOURITE_JOURNEY = "ADD_FAVOURITE_JOURNEY";
export const addFavouriteJourney = value => ({type: ADD_FAVOURITE_JOURNEY, value});

export const addFavourite = (value) => {
    return dispatch => {
        dispatch(addFavouriteJourney(value));
        dispatch(addMenuFavouritePrefs(value));
    }
}

export const REMOVE_FAVOURITE_JOURNEY = "REMOVE_FAVOURITE_JOURNEY";
export const removeFavouriteJourney = id => ({type: REMOVE_FAVOURITE_JOURNEY, id});

export const removeFavourite = (id) => {
    return dispatch => {
        dispatch(removeFavouriteJourney(id));
        dispatch(removeMenuFavouritePrefs(id));
    }
}

export const SET_MENU_PREFS_LOADED = "SET_MENU_PREFS_LOADED";
export const setMenuPrefsLoaded = value => ({type: SET_MENU_PREFS_LOADED,value});

