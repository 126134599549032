const ComponentName = "UfeUserInContext";
const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    collapsed: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px'
    }
});

export { styles };
export { ComponentName };