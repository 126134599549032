import React, { Component } from "react";
import CategoryTitleRender from "./CategoryTitleRender";
import PropTypes from "prop-types";

class CategoryTitle extends Component {

    static propTypes = {
        title : PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node
        ]).isRequired,
        count : PropTypes.number
    };

    static defaultProps = {
        title : "Category Title",
        count : 0
    };

    render() {
        const {title, count, toggleVisibility, collapsed, category, id} = this.props;
        return (
            <CategoryTitleRender category={category} title={title} id={id} count={count} toggleVisibility={toggleVisibility} collapsed={collapsed}/>
        );
    }
}

export default CategoryTitle;