const ComponentName = 'JourneyCategorySection';
const styles = theme =>({
    root : {},
    subtitleContainer : {
        marginBottom : 16
    },
    journeyContainerGrid : {
        display : "flex",
        marginBottom : 48
    },
    journeyContainerList : {
        display : "block",
        marginBottom : 48
    }
})

export {ComponentName, styles}