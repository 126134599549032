import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./GenericExtensionListContainerStyles";
import ItemListContainer from "../../../ShareComponents/ItemListContainer";
import MenuItem from "./MenuItem";

function GenericExtensionListContainerRender({ classes, closeListExtensions, availableExtensions, chooseGenericExtension }) {
    return (
        <ItemListContainer id='UfeGenericExtentionListContainer' clickAwayCallback={closeListExtensions} innerDivClass={classes.innerDivClass} outerDivClass={classes.interactionZero}>
            {Object.keys(availableExtensions).map((extension, index) => {
                return <MenuItem isSelected={availableExtensions[extension].isSelected} chooseGenericExtension={chooseGenericExtension} id={index} extension={extension} />
            })}
        </ItemListContainer>
    );
}

export default withStyles(styles, { name: ComponentName, withTheme: true })(GenericExtensionListContainerRender);