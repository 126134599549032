import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./SearchItemStyles";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";
import Typography from '@ui-lib/core/Typography';

function SearchItemRender({ classes, onClick, selected, icon, primaryName, number, secondaryName, id}) {
    let rootClasses = selected ? classes.optionSelected : classes.option;
    return (
        <div id={'UfeContextSearchOption_' + id} key={id} onClick={onClick} className={rootClasses}>
            <div className={classes.optionIconContainer}>
                {icon && icon.startsWith("icon-") ? <Icon className={classNames(icon, classes.optionIcon)}/> : <Icon className={classNames(classes.optionIcon)}>{icon}</Icon>}
            </div>
            <div>
                <div className={classes.optionSubText}>
                    <Typography color={"textPrimary"} gutterBottom variant="body2">{primaryName}</Typography>
                </div>
                <div className={classes.optionMainText}>
                    <Typography color={"textSecondary"} variant="overline">{number}</Typography>
                    <div className={classes.optionSplitter}/>
                    <Typography color={"textSecondary"} variant="overline">{secondaryName}</Typography>
                </div>
            </div>
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(SearchItemRender);