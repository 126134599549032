import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./SearchActionsStyles";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";
import Button from '@ui-lib/core/Button';

function SearchFieldRender({ classes, data, i18nProvider, addInteractionWithoutContext, valueSearched}) {
    return (
        <div id='UfeContextSearchActions' className={classes.root}>
            {(data.length > 0 || valueSearched) && (
                <Button id='UfeContextAddInteractionWithoutContextButton' fullWidth className={classes.noContextButton} onClick={addInteractionWithoutContext}>
                    <Icon className={classNames(`icon-sidemenu`, classes.iconSearch)} />
                    {i18nProvider.Labeli18n("open_360_without_context")}
                </Button>)
            }
        </div>

    );
}

export default withStyles(styles, { name: ComponentName })(SearchFieldRender);