const ComponentName = "UfeTaskSwitcher";
const styles = theme => ({
    cardContainer: {
        display: "flex",
        flex: "1 1 auto",
        overflowY: "hidden",
        overflowX: "auto",
        padding: "2px 40px 40px",
        minWidth: "100%"
    },
    cardContainerShadowFix: {
        display: "flex"
    },
    containerRoot: {
        color: theme.palette.text.primary
    },
    titleContainer: {
        display: "inline-flex",
        alignItems: "center"
    },
    titleCounter: {
        color: theme.palette.text.disabled
    },
    iconButtonClose: {
        float: "right",
        marginLeft: "50px"
    },
    iconClose: {
        height: "22px",
        width: "22px",
        fontSize: "22px"
    }
});

export { styles };
export { ComponentName };