import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./TaskSwitcherIconStyles";
import IconButton from "@ui-lib/core/IconButton";
import JourneyInformation from "./JourneyInformation";

function TaskSwitcherIconRender({classes, openTaskSwitcher, journeysCount, i18nProvider}) {
    return (
        <div className={classes.root}>
            <IconButton id='UfeTaskSwitcherIcon' title={i18nProvider.Texti18n("task_switcher_button")} onClick={openTaskSwitcher}>
                <JourneyInformation journeysCount={journeysCount}/>
            </IconButton>
            
        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(TaskSwitcherIconRender);