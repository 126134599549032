import * as globalAction from "omni-shell-common/src/actions";
import { i18nActions } from "omni-shell-common/src/actions";

export const changeTheme = (theme, httpClient) => {
    return dispatch => {
        dispatch(globalAction.getTheme(theme, httpClient));
    };
};

export const changeThemeType = (type) => {
    return dispatch => {
        dispatch(globalAction.setThemeType(type));
    };
};

export const changeThemeDirection = (direction) => {
    return dispatch => {
        dispatch(globalAction.setThemeDirection(direction));
    };
};


export const changeLanguage = (language) => {
    return dispatch => {
        dispatch(i18nActions.setCurrentLanguage(language));
    };
};
