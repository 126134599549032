import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./TaskSwitcherStyles";
import Dialog from "@ui-lib/core/Dialog";
import DialogContent from "@ui-lib/core/DialogContent";
import DialogTitle from "@ui-lib/core/DialogTitle";
import IconButton from "@ui-lib/core/IconButton";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";
import JourneyCard from "../../ShareComponents/JourneyCard";
import Typography from "@ui-lib/core/Typography";

/**
 * @return {null}
 */
function TaskSwitcherRender({ classes, isTaskSwitcherOpen, onClose, maxWidth, fullWidth, i18nProvider, journeyHistory, currentInteraction, onSwitchJourney }) {

    let journeys_list = journeyHistory.get(currentInteraction.instance_id);
    journeys_list = journeys_list ? journeys_list.reverse() : journeys_list;
    if (!journeys_list) {
        return null;
    }
    return (
        <Dialog
            id='UfeTaskSwitcherModal'
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={isTaskSwitcherOpen}
            onClose={onClose}
            className={classes.containerRoot}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">
                <div className={classes.titleContainer}>
                    <Typography variant={"h1"}>
                        {i18nProvider.Labeli18n("task_switcher")}
                    </Typography>
                    <span>&nbsp;</span>
                    <Typography variant={"h2"} className={classes.titleCounter}>
                        ({journeys_list ? journeys_list.size : "0"})
                    </Typography>
                </div>
                <IconButton onClick={onClose} className={classNames(classes.iconButtonClose, classes.titleContainer)} title={i18nProvider.Texti18n("close")}>
                    <Icon id="UfeSessionExpireModalCloseTaskSwitcherBtn" className={classNames("icon-close", classes.iconClose)} />
                </IconButton>
            </DialogTitle>
            <div className={classes.cardContainer}>
                {
                    !journeys_list || journeys_list.length === 0 ?
                        (
                            <Typography variant="subtitle2" noWrap>
                                {i18nProvider.Labeli18n("empty_journey_list_task_switcher")}
                            </Typography>
                        )
                        :
                        <div className={classes.cardContainerShadowFix}>
                            {
                                journeys_list.map((journey, index) => {
                                    let journeySubtitle = journey.subtitle ? journey.subtitle : "";
                                    let title = i18nProvider.Texti18n(journey.i18NLabel) + (journey.isDirty ? "(*)" : "");
                                    return (
                                        <JourneyCard
                                            taskSwitcher={true}
                                            callArg={journey.instance_id}
                                            key={index}
                                            onClick={onSwitchJourney}
                                            icon={journey.icon}
                                            listIcon={journey.listIcon}
                                            title={title}
                                            subtitle={journeySubtitle}
                                            hover={i18nProvider.Texti18n(journey.i18NLabel)}
                                        />
                                    );
                                })
                            }
                        </div>
                }
            </div>
        </Dialog>
    );
}

export default withStyles(styles, { name: ComponentName })(TaskSwitcherRender);