import React, { Component } from "react";
import SubHeaderRender from "./SubHeaderRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class SubHeader extends Component {

    state = {};

    static propTypes = {};

    static defaultProps = {};

    render() {
        const {currentJourney, entitlementsMapper, currentInteraction} = this.props;
        let type = currentJourney && currentJourney.subHeaderContent && currentJourney.subHeaderContent.style;
        type = type ? type : "secondary";
        return (
            <SubHeaderRender type={type} entitlements={entitlementsMapper} currentJourney={currentJourney}
                currentInteraction={currentInteraction}/>
        );
    }
}

const mapStateToProps = ({journeys : {currentJourney}, shell:{entitlementsMapper}, interactions: {currentInteraction}}) => ({currentJourney, entitlementsMapper, currentInteraction});

const mapDispatchToProps = dispatch => bindActionCreators(
    {},
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);