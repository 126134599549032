import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import { IFrameBridge } from "omni-shell-web";
import { setSendEventHandler } from "omni-ufe-common/src/actions/canvas-actions";

let MyFrameBridge = IFrameBridge(React, "threesixtyIframeSpinner");
let staticIdCount = 0;

class ThreesixtyPlayer extends React.PureComponent {

    /**
     * Method to handle Threesixty Messages that are not known by the shell
     * It is validated if it is a Threesixty Specific Action
     * It is validated if it is a Canvas Journey Action
     * @param data 
     * @param interaction_instance_id
     * @param instance_id
     */
    handleBridgeRequest = (data, interaction_instance_id) => {
        if (!data)
            return;
        if (data.type === "journeyAction") {
            this.props.ReceivedEventsHandler.handleJourneyAction(data, interaction_instance_id, true);
        } else if (data && data.type === "interactions") {
            this.props.ReceivedEventsHandler.handleInteractionAction(data);
        } else if (data.type === "closeJourneyEvent") {
            let event = new CustomEvent("closeJourneyEvent" + data.id);
            window.dispatchEvent(event);
        } else if (data.response) {
            let event = new CustomEvent(data.type + data.id);
            event.message = data;
            window.dispatchEvent(event);
        }
    };

    /**
     * Generic Global Dispatcher
     * @param data 
     */
    handleGlobalDispatcher = (data) => {
        this.props.SendEventHandler.handleGlobalDispatcher(data, this.props.currentInteraction.instance_id);
    };

    /**
     * 
     */
    getThreesixtyPlayerContent = () => {
        let result = [];
        let iFrames = [];
        const { interactions, currentInteraction } = this.props;

        var refCallback = (iframe) => {
            this._iframe = iframe;
            if (!iframe)
                return;

            iFrames = iFrames.concat([iframe]);

            this.props.ReceivedEventsHandler.setThreesixtyIframe(iframe);
            this.props.SendEventHandler.setThreesixtyIframe(iframe);
            this.props.setSendEventHandler(this.props.SendEventHandler);

            this.props.SendEventHandler.setThreesixtyIframes(iFrames);
        };

        var onLoadCallback = () => {
            const { SendEventHandler, isPrivate } = this.props;
            SendEventHandler.setPrivateMode(isPrivate);
            SendEventHandler.setThreesixtyContext(this.props.currentInteraction, currentInteraction.instance_id);
        };

        interactions.toArray().map(interaction =>
            // noinspection JSDuplicatedDeclaration
            (currentInteraction.instance_id === interaction.instance_id) ?
                result.push(this.getThreeSixtyObject(interaction.instance_id, { display: "flex", width: "100%", height: "100%" }, refCallback, onLoadCallback)) :
                result.push(this.getThreeSixtyObject(interaction.instance_id, { display: "none", width: "100%", height: "100%" }, refCallback, onLoadCallback))
        );

        return result;
    }

    /**
     * Method to retrieve a Frame Bridge responsible for rendering the Threesixty
     * 
     * @param interaction_instance_id
     * @param style
     * @param refCallback
     * @param onLoadCallback
     */
    getThreeSixtyObject = (interaction_instance_id, style, refCallback, onLoadCallback) => {

        return (<MyFrameBridge
            key={interaction_instance_id}
            title={"ThreeSixtyPlayer"}
            id={interaction_instance_id}
            staticId={staticIdCount++}
            services={this.props.services}
            ref={iframe => refCallback(iframe)}
            onLoad={onLoadCallback}
            contentVersion={this.props.threesixtyConfigs.contentVersion}
            attributes={{
                width: "100%",
                height: "100%",
                scrolling: "YES"
            }}
            src={this.props.threesixtyConfigs.url}
            handleReceiveMessage={(data) => this.handleBridgeRequest(data, interaction_instance_id)}
            handleGlobalDispatcher={this.handleGlobalDispatcher}
            theme={this.props.currentTheme}
            language={this.props.i18n}
            style={style}
            authHandlers={this.props.AuthenticationHandlers}
            setLoader={(isLoading, loaderType) => {
                this.props.setLoader(isLoading, loaderType);
            }} />)
    }

    render() {
        let threesixty = this.getThreesixtyPlayerContent();
        return (<React.Fragment>{threesixty}</React.Fragment>);
    }
}

ThreesixtyPlayer.defaultProps = {};

const mapStateToProps = ({
    interactions: { interactions, currentInteraction, threesixtyOpen },
    shell: { isPrivate, services },
    i18n,
    canvas: { config: { threesixtyConfigs } },
    theme: { theme }
}) =>
    ({
        interactions, currentInteraction, threesixtyOpen,
        i18n, isPrivate, services, threesixtyConfigs,
        currentTheme: theme
    });

const mapDispatchToProps = dispatch => bindActionCreators({
    setSendEventHandler
}, dispatch);

ThreesixtyPlayer.propTypes = {
    interactions: PropTypes.object,
    isPrivate: PropTypes.bool,
    currentInteraction: PropTypes.object,
    i18n: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ThreesixtyPlayer);
