import React, { Component } from "react";
import ItemListContainerRender from "./ItemListContainerRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class ItemListContainer extends Component {

    render() {
        const { Footer, innerDivClass, children, outerDivClass, clickAwayCallback, ...others } = this.props;
        return (
            <ItemListContainerRender Footer={Footer} innerDivClass={innerDivClass}
                clickAwayCallback={clickAwayCallback} outerDivClass={outerDivClass}
                {...others}
            >
                {children}
            </ItemListContainerRender>
        );
    };
};

const mapStateToProps = ({ }) => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItemListContainer);