import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./RootQuickActionIconStyles";
import IconButton from "@ui-lib/core/IconButton";
import Icon from "@ui-lib/core/Icon";
import Badge from "@ui-lib/core/Badge";
import classNames from "@ui-lib/core/classNames";

function RootQuickActionIconRender({classes, isPanelOpen, onClick, icon, badgeContent, setRef, title, id}) {
    let rootClasses = isPanelOpen ? classNames(classes.root, classes.rootSelected) : classes.root;
    return (
        <div id={id} className={rootClasses}>
            <IconButton className={classes.button} onClick={onClick} buttonRef={setRef} title={title}>
                <Badge invisible={!badgeContent} badgeContent={badgeContent} max={99} classes={{badge : classes.badge}}>
                    <Icon className={classNames(icon, classes.icon)}/>
                </Badge>
            </IconButton>
        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(RootQuickActionIconRender);