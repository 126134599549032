const ComponentName = "UfeAddContextButton";
const styles = theme => ({
    root: {
    },
    button: {
        "&:hover": {
            backgroundColor: theme.palette.type === "light" ? `rgba(255, 255, 255, 0.2)` : `rgba(255, 255, 255, ${theme.palette.action.hoverOpacity})`
        }
    },
    icon: {
        width: "22px",
        height: "22px",
        fontSize: "22px",
    },
    iconBackground: {
        "&:before": {
            color: theme.palette.primary.main + "!important",
        }
    },
    iconBackgroundDisabled: {
        "&:before": {
            color: theme.palette.text.secondary + "!important",
        }
    },
    iconPlus: {
        "&:before": {
            color: theme.palette.common.white + "!important",
        }
    }
});

export {styles};
export {ComponentName};