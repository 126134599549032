import { closeJourney, openJourney } from 'omni-shell-common/src/actions/journeys-actions';
import { addInteractionWithJourneys } from 'omni-ufe-common/src/actions/interactions-actions';

const registerRouterEvents = (store) => {
    window.addEventListener('ufe-events-open-journey', (event) => openJourneyEvent(store, event), false);
    window.addEventListener('ufe-events-open-journey-with-context', (event) => openJourneyWithContextEvent(store, event), false);
    window.addEventListener('ufe-events-close-journey', (event) => closeJourneyEvent(store, event), false);
};

const openJourneyEvent = (store, event) => {
    const { journeyId, args } = event.data.payload;
    const { currentInteraction } = store.getState().interactions;

    store.dispatch(openJourney(journeyId, currentInteraction.instance_id, args));
};

const openJourneyWithContextEvent = (store, event) => {
    const { journeysToOpen, context } = event.data.payload;

    store.dispatch(addInteractionWithJourneys(context, journeysToOpen));
};

const closeJourneyEvent = (store, event) => {
    const { currentInteraction } = store.getState().interactions;
    const { instanceId } = event.data.payload;

    if (instanceId) {
        store.dispatch(closeJourney(instanceId, currentInteraction.instance_id));
    } else {
        console.warn('-----WARNING----- An attempt to close a journey without stating the target  journey was made');
    }
};

export default registerRouterEvents;