const ComponentName = "UfeUserMenu";
const styles = theme => ({
    root: {
        position: "absolute",
        right: "20px",
        top: "63px",
        zIndex: 1400
    }
});

export { styles };
export { ComponentName };