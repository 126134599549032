import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import UFE from "./ufe";
import { Switch, HashRouter as Router, Route, Redirect, withRouter  } from "react-router-dom";
import { Config } from "omni-ufe-common/src/config";

import {
    setCanvasDependencyResolved,
    setEntitlementsMapper,
    setCurrentEntitlements,
    setEntitlementsURL,
    setSamlAuthLogoutUrl
} from "omni-shell-common/src/actions/shell-actions";
import {
    setContexts,
    setConfiguration,
    setTimeout,
    setUserInfoFields,
    setSendEventHandler,
    setAuthenticationHandlers
} from "omni-ufe-common/src/actions/canvas-actions";
import {
    setWidgets,
    setDefaultUserEntitlements
} from "omni-ufe-common/src/actions/interactions-actions";
import {
    setJourneyMenuViewMode
} from "omni-ufe-common/src/actions/ui-actions";
import {
    setConfigJourneys,
    setJourneys
} from "omni-shell-common/src/actions/journeys-actions";

import withRoot from "./withRoot";
import WebSpinner from "omni-shell-web/src/components/Spinner/SpinnerRender";
import {
    setThemeType,
    setThemeDirection
} from "omni-shell-common/src/actions/theme";
/**
 * Event Handlers
 */
import NotificationHandler from "omni-shell-common/src/notification-handler";
import REHandlers from "omni-ufe-common/src/utils/received-event-handlers";
import SEHandlers from "omni-ufe-common/src/utils/send-event-handlers";
import AuthHandlers from "omni-ufe-common/src/utils/authentication-handlers";
import SamlAuthentication from "./login/saml-authentication";
import registerEvents from "./events-bridge/bridge";

let Services;
let NotifiyHandler;
let AuthenticationHandlers;
let ReceivedEventsHandler;
let SendEventHandler;

class Home extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentThemeId : undefined,
            isLoading : true,
            loginType : undefined,
            isLoginTypeSelected : false
        };

        Services = props.services;
        NotifiyHandler = new NotificationHandler(Services.Store);
        AuthenticationHandlers = new AuthHandlers(Services, NotifiyHandler);
        ReceivedEventsHandler = new REHandlers(Services.Store, NotifiyHandler);
        SendEventHandler = new SEHandlers(Services.Store, NotifiyHandler);
        props.setSendEventHandler(SendEventHandler);
        props.setAuthenticationHandlers(AuthenticationHandlers);
        this.setCanvasDependencies = this.setCanvasDependencies.bind(this);
        this.handleDependencies = this.handleDependencies.bind(this);
        this.onRetryClick = this.onRetryClick.bind(this);

        registerEvents(Services.Store);
    }

    componentDidMount() {
        this.handleDependencies();
        const { history } = this.props;

        history.listen((newLocation) => {
            if(newLocation.hash === "#/login"){
                //this.handleDependencies();
                window.location.reload();
            }
        })
    }

    setCanvasDependencies = () => {
        const {canvasDependencies, journeysCategoriesManifest, config, journeysManifest} = this.props;

        if (!canvasDependencies) {
            if (journeysCategoriesManifest && config && journeysManifest && journeysManifest.length > 0) {
                this.props.setCanvasDependencyResolved();
            }
        }
    };

    shouldComponentUpdate({accessToken : newToken}) {
        const {accessToken} = this.props;
        return !(accessToken && accessToken !== "" && newToken && newToken !== "" && accessToken !== newToken);
    }

    /**
     * to call the services with the canvas dependencies.
     */
    handleDependencies() {
        const {setCurrentEntitlements, setDefaultUserEntitlements, setJourneys, setConfigJourneys, setEntitlementsMapper, setContexts,
             setWidgets, setTimeout, setUserInfoFields, setThemeType, setThemeDirection, setConfiguration, setEntitlementsURL, setJourneyMenuViewMode, setSamlAuthLogoutUrl} = this.props
        this.setState({...this.state, isLoading : true});
        Services.LocalStorage.get(Config.LOCAL_STORAGE.LOGGED_USER_ENTITLEMENTS).then((response) => {
            setCurrentEntitlements(response);
            setDefaultUserEntitlements(response);
            this.setCanvasDependencies();
        }).catch((error) => {
            console.log(error);
            this.setState({...this.state, isLoading : false});
        });

        Services.HttpClient.get(this.props.contentUrl + Config.SETTINGS_URL(this.props.runMode)).then((response) => {
            const {journeys, categories, entitlements, contexts, widgets, saveTimeout, userInfoFields, customAuthentication} = response.data;

            setJourneys(journeys);
            setConfigJourneys(categories);
            setEntitlementsMapper(entitlements);
            setContexts(contexts);
            setWidgets(widgets);
            setTimeout(saveTimeout);
            setUserInfoFields(userInfoFields);

            const { headerGenericExtensions, entitlementsURL, authenticationURL, coreUrl, defaultType, defaultDirection, threesixtyConfigs, allowTimerOnInteraction, allowClear360SearchCriteria, showUserMenuByDefault, loginSpec, loginCustomFields, globalSearchPatterns, allowMultipleNewInteractionsWithNoCustomer, isUserInfoPrivate, menuLayout, menuFavourites, logoutSamlConfig } = response.data;
            
            let config = {
                headerGenericExtensions,
                authenticationURL,
                coreUrl,
                threesixtyConfigs,
                allowTimerOnInteraction,
                allowClear360SearchCriteria,
                showUserMenuByDefault,
                loginSpec,
                loginCustomFields,
                globalSearchPatterns,
                allowMultipleNewInteractionsWithNoCustomer,
                isUserInfoPrivate,
                defaultType,
                defaultDirection,
                customAuthentication,
                menuFavourites,
                logoutSamlConfig
            };
            setEntitlementsURL(entitlementsURL);
            setThemeType(defaultType);
            setThemeDirection(defaultDirection);
            setConfiguration(config);
            setSamlAuthLogoutUrl(logoutSamlConfig);
            menuLayout && setJourneyMenuViewMode(menuLayout);

            if(!threesixtyConfigs){
                window.THREESIXTY_PANELSIZE = "320px";
            } else if(threesixtyConfigs.collapsePanel === "horizontal") {
                if(!threesixtyConfigs.collapsedPanelSize || parseInt(threesixtyConfigs.collapsedPanelSize,10) < 60){
                    console.warn("-----WARNING (Configuration)","\n",
                        "A configuration about the 360 was provided where the collapse is configured as horizontal and the size was omitted or less than 60px.","\n",
                        "The minimum value for the collapsed 360 is 60px and it is the default value.");
                    window.THREESIXTY_PANELSIZE = "60px";
                } else {
                    window.THREESIXTY_PANELSIZE = threesixtyConfigs.collapsedPanelSize;
                }
            } else {
                window.THREESIXTY_PANELSIZE = threesixtyConfigs.collapsedPanelSize && parseInt(threesixtyConfigs.collapsedPanelSize, 10) > 320 ? threesixtyConfigs.collapsedPanelSize : '320px';
            }

            this.setCanvasDependencies();
        }).catch((error) => {
            console.log(error);
            this.setState({...this.state, isLoading : false});
        });
    };

    onRetryClick() {
        this.handleDependencies();
    }

    render() {
        if (!this.props.canvasDependencies) {
            return (<WebSpinner spinnerId={"homeSpinner"} retryOn={!this.state.isLoading} onRetryClick={this.onRetryClick}/>);
        }

        if (!this.state.isLoginTypeSelected) {
            let promise = undefined;
            if (this.props.config.loginCustomFields && this.props.config.loginCustomFields.length > 0){
                promise = import("./login/login-custom-fields");
            } else if (this.props.config.customAuthentication) {
                promise = import("./login/external-login");
            } else {
                promise = import("./login/basic-login");
            }
            promise.then(Login => this.setState({loginType : Login.default, isLoginTypeSelected : true}));

            return (<WebSpinner spinnerId={"homeSpinner"} retryOn={!this.state.isLoading} onRetryClick={this.onRetryClick}/>);
        }
        
        const Login = this.state.loginType;
        return (
            <Router>
                <Switch>
                    <Route exact path="/" onEnter={this.isAuthenticated}
                           component={() => <Login services={Services} handleDependencies={this.handleDependencies}
                                                   authHandlers={AuthenticationHandlers}/>}/>
                    <Route exact path="/login" onEnter={this.isAuthenticated}
                           component={() => <Login services={Services} handleDependencies={this.handleDependencies}
                                                   authHandlers={AuthenticationHandlers}/>}/>
                    <Route exact path="/saml_authentication"
                            component={() => <SamlAuthentication/>}/>
                    <Route exact path="/ufe"
                           render={() => (
                               this.props.isAuthenticated === true
                                   ? this.props.userPrefsLoaded && this.props.themeReady && this.props.menuPrefsLoaded ?
                                   <UFE AuthenticationHandlers={AuthenticationHandlers}
                                        NotificationHandler={NotificationHandler}
                                        ReceivedEventsHandler={ReceivedEventsHandler}
                                        SendEventHandler={SendEventHandler} Services={Services}
                                        handleDependencies={this.handleDependencies}
                                        Login={Login}
                                   />
                                   : <WebSpinner spinnerId={"homeSpinner"} retryOn={false}/>
                                   : <Redirect to='/login'/>
                           )}/>
                </Switch>
            </Router>
        );
    };
}

const mapStateToProps = ({
                             shell : {canvasDependencies, accessToken, themeReady, contentUrl, hostPort, runMode, services},
                             canvas : {config, isAuthenticated, userPrefsLoaded, menuPrefsLoaded}, theme : {theme},
                             journeys : {journeysManifest, journeysCategoriesManifest}
                         }) => ({
    canvasDependencies, contentUrl, hostPort, userPrefsLoaded, menuPrefsLoaded, themeReady, accessToken, config, isAuthenticated, runMode,
    currentTheme : theme, services,
    journeysManifest, journeysCategoriesManifest
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setCurrentEntitlements,
        setDefaultUserEntitlements,
        setJourneys,
        setConfigJourneys,
        setEntitlementsMapper,
        setContexts,
        setWidgets,
        setTimeout,
        setConfiguration,
        setCanvasDependencyResolved,
        setUserInfoFields,
        setSendEventHandler,
        setAuthenticationHandlers,
        setThemeType,
        setThemeDirection,
        setEntitlementsURL,
        setJourneyMenuViewMode,
        setSamlAuthLogoutUrl
    },
    dispatch
);

Home.propTypes = {
    canvasDependencies : PropTypes.bool,
    config : PropTypes.object,
    authToken : PropTypes.string,
    contentUrl : PropTypes.string,
    hostPort : PropTypes.string,
    userPrefsLoaded : PropTypes.bool,
    menuPrefsLoaded : PropTypes.bool,
    themeReady : PropTypes.bool,
    isAuthenticated : PropTypes.bool,
    journeysCategoriesManifest : PropTypes.array,
    journeysManifest : PropTypes.array,
};

export default withRoot(connect(mapStateToProps, mapDispatchToProps)(withRouter(Home)));
