import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { i18nReducer } from "redux-react-i18n";
import theme from "../reducers/theme";
import shell from "../reducers/shell-reducer";
import utils from "../reducers/utils";
import journeys from "../reducers/journeys-reducer";
import { combinedReducers as notificationService } from "notifications-service";

/**
 * Method to clean the store state
 * In this case multiple reducers may be altered so their values are reset with new values
 * @param {Object} state - Current state of the application reducers
 * @param {Object} to_merge - Values to merge into the new tree of the application reducers
 */
const cleanStoreState = (state, to_merge) => {
    return custom_override(state, to_merge);
};

/**
 * Recursive method to alter values of a target object, given values from a source object.
 * @param {Object} obj1 - Target object to be modified
 * @param {Object} obj2  - Source object that will be used
 * @param {Boolean} isChild - States if obj1 is a child of another node in the object
 */
const custom_override = (obj1, obj2, isChild) => {

    for (var attrname in obj2) {
        if (typeof obj2[attrname] === "object") {
            if (Array.isArray(obj2[attrname]) || isChild) {
                obj1[attrname] = obj2[attrname];
            } else {
                obj1[attrname] = custom_override(obj1[attrname], obj2[attrname], true);
            }
        } else {
            obj1[attrname] = obj2[attrname];
        }
    }

    return obj1;
};

/**
 * Const to map the static initial reducers in an object
 */
const staticReducers = {i18n: i18nReducer, theme, shell, journeys, utils, notificationService};

/**
 * Method to create the reducer Manager.
 * It will return an object capable of mutating the state of the reducers merge.
 * The possible actions on this object ->
 *  -getReducerMap: Retrieves the list of currently active reducers in the reducer manager;
 *  -reduce: Method that serves as an entrypoint to the app that will be described as the store;
 *  -add: Method to add a reducer to the reducers list (store). The input values are the key of the reducer and the specific reducer;
 *  -remove: Method to remove a reducer from the reducers list (store). The input value if the key of the specific reducer.
 * @param {Object} initialReducers - List of the initial reducers in the reducers list (store)
 */
export function createReducerManager(initialReducers) {

    const reducers = {...initialReducers};
    let combinedReducer = combineReducers(reducers);

    let keysToRemove = [];

    return {
        getReducerMap: () => reducers,
        reduce: (state, action) => {
            if (keysToRemove.length > 0) {
                state = {...state};
                for (let key of keysToRemove) {
                    delete state[key]
                }
                keysToRemove = []
            }

            const rootReducer = (state, action) => {
                if (action.type === "CLEAN_STORE") {
                    state = cleanStoreState(state, action.payload);
                }

                return combinedReducer(state, action);
            };

            return rootReducer(state, action);
        },
        add: (key, reducer) => {
            if (!key || reducers[key]) {
                return
            }
            reducers[key] = reducer;
            combinedReducer = combineReducers(reducers);
        },
        remove: key => {
            if (!key || !reducers[key]) {
                return
            }
            // Remove it from the reducer mapping
            delete reducers[key];
            // Add the key to the list of keys to clean up
            keysToRemove.push(key);
            // Generate a new combined reducer
            combinedReducer = combineReducers(reducers)
        }
    }
}

/**
 * Method to retrieve the constructed store.
 * This method receives the input of the reducers of other applications (above shell on the architecture, respectively UI applications)
 * The reducers will be merged and a reducer manager will be created resulting in a store with both the reducers of the UI application and default shell reducers.
 * @param {Object} reducersToMerge - List of reducers from other application (UI application)
 */
export function createStoreSingleton(reducersToMerge) {
    const mergedObject = Object.assign(reducersToMerge, staticReducers);

    const reducerManager = createReducerManager(mergedObject);
    const store = createStore(reducerManager.reduce, compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f));
    store.reducerManager = reducerManager;
    return store;
}