const ComponentName = "GenericExtensionListContainer";
const styles = theme => ({
    root: {},
    innerDivClass: {
		top: '52px',
		width: '162px',
		height: '-webkit - fit - content',
		height: '-moz - fit - content',
		height: 'fit - content',
		height: 'fit-content'
	},
	interactionZero: {
		left: '-106px'
	}
});

export { styles };
export { ComponentName };