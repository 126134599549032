import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./ActiveContextContainerStyles";
import UserInContext from "./UserInContext";
import CloseInteractionButton from "./CloseInteractionButton";
import classNames from '@ui-lib/core/classNames';

function ActiveContextContainerRender({ classes, currentInteraction }) {
    return (
        <div id='UfeActiveContextContainer' className={classNames(classes.root)}>
            <UserInContext />
            {currentInteraction.instance_id !== 'default' && <CloseInteractionButton/>}
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(ActiveContextContainerRender);