import React, { Component } from "react";
import EditingDashboardAreaRender from "./EditingDashboardAreaRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IEntitlementsVerifier } from "omni-shell-common/src/entitlements-verifier";

let EntitlementsVerifier = IEntitlementsVerifier(React);

class EditingDashboardArea extends Component {
    render() {
        return (
            <EditingDashboardAreaRender/>
        );
    }
}
const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditingDashboardArea);