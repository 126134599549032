const ComponentName = "UfeFooter";
const styles = theme => ({
    root : {
        display : "flex",
        alignItems : "center",
        justifyContent : "flex-end",
        height : 24,
        padding : "0px 20px",
        backgroundColor : theme.palette.grey[100],
        flex : "0 0 auto"
    },
});

export { styles };
export { ComponentName };