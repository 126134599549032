/*
 * Class that abstracts storage implementation.
 */
class FileSystemService {

    /*
     * Constructor.
     */
    constructor() {}

    /*
     * Defines the file system provider to be used in the application.
     * @param fileSystemProvider - instance of the file system provider.
     */
    setFileSystemProvider(fileSystemProvider) {
        this.fileSystemProvider = fileSystemProvider;
    }

    /*
     * Adds / replaces an entry in the storage.
     * @param key - key of the storage entry.
     * @param value - value to be stored.
     * @return a promise
     */
    download(base64,filename) {
        return this.fileSystemProvider.download(base64,filename);
    }
}
const fileSystemService = new FileSystemService();
export default fileSystemService;
