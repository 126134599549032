/**
 * Returns the customSettings key given of the right theme type
 */
export function findThemeKeyValue(key, theme) {
    let lightKey = key + "Light";
    let darkKey = key + "Dark";
    return theme.palette.type === "light" ? theme.customSettings[lightKey] : theme.customSettings[darkKey];
}

/**
 * Converts a color from CSS hex format to CSS rgb format or rbga if second a second argument is given.
 *
 * @param {string} color - Hex color, i.e. #nnn or #nnnnnn
 * @param transparency number to use if rba wanted
 * @returns {string} A CSS rgb color string
 */
export function convertHexToRGB(color, transparency) {
    color = color.substr(1);

    const re = new RegExp(`.{1,${color.length / 3}}`, "g");
    let colors = color.match(re);

    if (colors && colors[0].length === 1) {
        colors = colors.map(n => n + n);
    }
    if (!transparency) {
        return colors ? `rgb(${colors.map(n => parseInt(n, 16)).join(", ")})` : "";
    } else {
        return colors ? `rgb(${colors.map(n => parseInt(n, 16)).join(", ")} ,${transparency})` : "";
    }

}