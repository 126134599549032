import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./MenuIconStyles";
import IconButton from "@ui-lib/core/IconButton";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";

function MenuIconRender({ classes, icon, onClick, title, id }) {
    return (
        <div id={id} className={classes.root}>
            <IconButton className={classes.button} onClick={onClick} title={title}>
                <Icon className={classNames(icon, classes.icon)} />
            </IconButton>
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(MenuIconRender);