const ComponentName = "UfeJourneyCard";
const styles = theme => ({
    rootGrid: {
        display: "inline-flex",
        marginRight: "20px"
    },
    contentGridContainer: {
        width: "148px",
        height: "148px",
        backgroundColor: theme.palette.grey[50],
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.29)",
        transition: "0.3s",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            cursor: "pointer"
        },
        "&:hover $badge": {
            backgroundColor: theme.palette.grey[50],
            color: theme.palette.primary.main,
            cursor: "pointer"
        },
        "&:hover $badgeOverlay": {
            backgroundColor: theme.palette.primary.main,
            cursor: "pointer"
        },
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    contentGridContainerTaskSwitcher: {
        width: "160px",
        height: "180px",
        backgroundColor: theme.palette.grey[50],
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.29)",
        display: "flex",
        transition: "0.3s",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            cursor: "pointer"
        },
        "&:hover $badge": {
            backgroundColor: theme.palette.grey[50],
            color: theme.palette.primary.main,
            cursor: "pointer"
        },
        "&:hover $subTitle": {
            width: 160,
            height: 32,
            padding: 8,
            textAlign: "center",
            backgroundColor: theme.palette.grey[50],
            color: theme.palette.primary.main
        },
        textAlign: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    rootList: {
        marginLeft: "1px",
        width: "757px"
    },
    contentListContainer: {
        width: "100%",
        height: 56,
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.29)",
        backgroundColor: theme.palette.grey[50],
        padding: 10,
        display: "flex",
        transition: "0.3s",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            cursor: "pointer"
        },
        textAlign: "center",
        alignItems: "center"
    },
    iconGrid: {
        fontSize: 48,
        marginTop: 24
    },
    iconList: {
        fontSize: 24
    },
    title: {
        textOverflow: "ellipsis",
    },
    titleGrid: {
        width: 116,
        height: 44,
        fontSize: 16,
        textAlign: "center",
        alignItems: "center",
        whiteSpace: "normal",
        justifyContent: "center",
        display: "flex",
        textOverflow: "ellipsis",
        overflow: "hidden",
        margin: "16px",
    },
    overflowTitleContainer: {
        width: 116,
        fontSize: 16,
        textAlign: "center",
        alignItems: "center",
        whiteSpace: "normal",
        justifyContent: "center",
        display: "flex",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    titleGridOverflow: {
        whiteSpace: "nowrap",
        marginBottom: 0,
    },
    titleGridOverflowContainer: {
        marginBottom: 8,
        display: 'inline-block',
        marginTop: '16px'
},
    titleList : {
        width: "100%",
        marginLeft: "20px",
        fontSize: 16,
        textAlign: "left"
    },
    subTitle: {
        width: 160,
        height: 32,
        padding: 8,
        textAlign: "center",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.grey[50]
    },
    badgeRootList: {
        width: "100%"
    },
    badge: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        height: "20px",
        width: "20px",
        fontSize: "16px",
        borderRadius: "50%",
        position: "absolute",
        top: "72px"
    },
    badgeOverlay: {
        backgroundColor: theme.palette.grey[50],
        height: 24,
        width: 24,
        borderRadius: "50%",
        position: "absolute",
        top: 72
    },
    badgeList: {
        top: "50%",
        right: "75px"
    },
    favouriteIcon: {
        fontSize: 20,
        position: "absolute",
        left: 70,
        top: 8
    },
    favouriteIconListConteiner: {
        width: "100%",
        display: "-ms-flexbox",
        display: "flex"
    },
    favouriteIconList: {
        fontSize: 20,
        /* position: "absolute",
        right: 24 */
    },
    favouriteSelected: {
        color: "#ff877b" + "!important",
            "&:before": {
    color: "#ff877b" + "!important"
},
"&:hover": {
    backgroundColor: theme.palette.error[700] + "!important",
        "&:before": {
        color: theme.palette.error[700] + "!important"
    }
}
    },
favouriteUnselected: {
    color: theme.palette.grey[300] + "!important",
        "&:before": {
        color: theme.palette.grey[300] + "!important"
    },
    "&:hover": {
        color: theme.palette.primary[700] + "!important",
            "&:before": {
            color: theme.palette.primary[700] + "!important"
        }
    }
},
});

export { styles };
export { ComponentName };