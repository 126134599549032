import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./EditDashboardButtonStyles";
import Button from "@ui-lib/core/Button";

function AddWidgetButtonRender({classes, editDashboard, i18nProvider}) {
    return (
        <div className={classes.root}>
            <Button id='UfeEditDashboardButton' title={i18nProvider.Texti18n("edit_dashboard_button")} onClick={editDashboard} variant="outlined" className={classes.button}>
                {i18nProvider.Labeli18n("edit_dashboard_button")}
            </Button>
        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(AddWidgetButtonRender);