import React, { Component } from "react";
import JourneyInfoRender from "./JourneyInfoRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setLoaderState } from "omni-shell-common/src/actions/utils-actions";
import { closeJourney } from "omni-shell-common/src/actions/journeys-actions";
import { setDirtyJourneyModalOpen } from "./../../../actions/ui-actions";
import {Config} from "./../../../config";

class JourneyInfo extends Component {

    onClose = () => {
        const { currentJourney, closeJourney, currentInteraction, setDirtyJourneyModalOpen, sendEventHandler: { handleClosingJourneys } } = this.props;

        if(currentJourney.breadcrumb) {
            let event = new CustomEvent('ufe-events-router-pop');
            window.dispatchEvent(event);
        } else {
            if (currentJourney.isDirty) {
                setDirtyJourneyModalOpen(currentJourney.isDirty, Config.CLOSE_JOURNEY_SCOPE.CURRENT_JOURNEY);
            } else {
                if(currentJourney.shouldBeNotifiedBeforeUnload){
                    Promise.all(handleClosingJourneys ([currentJourney])).then(() =>{
                        closeJourney(currentJourney.instance_id, currentInteraction.instance_id);
                    });      
                } else {
                    closeJourney(currentJourney.instance_id, currentInteraction.instance_id);
                }                 
            }
        }
    };

    changeBreadcrumb = (crumbIndex) => {
        const { currentJourney } = this.props;
        let event = new CustomEvent('ufe-events-router-UNSAFE-set');
        let path;
        
        if(crumbIndex === "root"){
            path = "";
        } else {
            let crumbLevels = currentJourney.breadcrumb.split("/");
            
            path = crumbLevels[0];
            for(var i = 1; i <= crumbIndex; i++) {  
                path += "/"
                path += crumbLevels[i];
            }
        }

        event.data = {payload: {path}};
        window.dispatchEvent(event);
    }

    render() {
        const {i18nProvider, currentJourney} = this.props;
        let breadcrumbLevels = currentJourney && currentJourney.breadcrumb ? currentJourney.breadcrumb.split('/') : undefined

        return (
            <JourneyInfoRender i18nProvider={i18nProvider} currentJourney={currentJourney} onClose={this.onClose} breadcrumbLevels={breadcrumbLevels} changeBreadcrumb={this.changeBreadcrumb}/>
        );
    }
}

const mapStateToProps = (
    {
        journeys : {currentJourney}, 
        shell : {i18nProvider}, 
        canvas : {sendEventHandler}, 
        interactions : {currentInteraction}
    }) => ({
        i18nProvider,        
        currentJourney, 
        sendEventHandler, 
        currentInteraction
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setLoaderState,
        closeJourney,
        setDirtyJourneyModalOpen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(JourneyInfo);