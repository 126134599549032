import React from "react";
import { NotificationsAlerts, NotificationCenter,NotificationsSnackBars } from "notifications-ui";
import { IEntitlementsVerifier } from "omni-shell-common/src/entitlements-verifier";
import { connect } from "react-redux";

function NotificationsRender({entitlementsMapper}) {
    let EntitlementsVerifier = IEntitlementsVerifier(React);
    return (
        <React.Fragment>
            <EntitlementsVerifier entitlement={entitlementsMapper.notifications.contextual}>
                <NotificationsAlerts/>
            </EntitlementsVerifier>
            <EntitlementsVerifier entitlement={entitlementsMapper.notifications.notificationCenter}>
                <NotificationCenter/>
            </EntitlementsVerifier>
            <EntitlementsVerifier entitlement={entitlementsMapper.notifications.nonContextual}>
                <NotificationsSnackBars/>
            </EntitlementsVerifier>
        </React.Fragment>
    );
}

const mapStateToProps = ({shell: {entitlementsMapper}}) => ({entitlementsMapper});

export default connect(mapStateToProps)(NotificationsRender);