import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./ContextStyles";
import ContextContainer from "./ContextContainer";
import ContextSearch from "./ContextSearch";

function ContextRender({ classes, interactions }) {
    return (
        <div id='UfeContext' className={classes.root}>
                <ContextSearch />
            {interactions.size > 1 && <ContextContainer />}
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(ContextRender);