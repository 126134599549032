import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./GenericExtensionStyles";
import IconButton from "@ui-lib/core/IconButton";
import classNames from "@ui-lib/core/classNames";
import GenericExtensionListContainer from "./GenericExtensionListContainer";

function GenericExtensionRender({classes, theme, closeListExtensions, listOpen, GenericExtensionPoint, isOpen, closeExtension, chooseGenericExtension, openListExtensions, availableExtensions}) {
    return (
        <React.Fragment>
            <div className={classNames(classes.rootIcon, {[classes.arrow]: listOpen})}>
                <IconButton id='UfeGenericExtentionButton' className={classes.iconButton} onClick={openListExtensions}>
                    <img className={classes.image} src={theme.customSettings.hostPort + theme.customSettings.contentUrl+"/foundation/v3/extensions/inlinemanager/unselected.svg"}></img>
                </IconButton>
            </div>
            {listOpen && <GenericExtensionListContainer chooseGenericExtension={chooseGenericExtension} availableExtensions={availableExtensions} closeListExtensions={closeListExtensions}/>}
            {GenericExtensionPoint && isOpen && <GenericExtensionPoint.component closeExtension={() => closeExtension(GenericExtensionPoint.friendlyName)}/>}
        </React.Fragment>
    );
}

export default withStyles(styles, {name : ComponentName, withTheme: true})(GenericExtensionRender);