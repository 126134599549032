import { setEditableWidgetsState } from "../actions/canvas-actions";
import { addNotification } from "omni-shell-common/src/actions/notifications-actions";
import { send360Search, set360Client, close360View } from "./threexisty-connector";

export default class SendEventsHandler {

    constructor(store, NotificationHandler) {
        this.NotificationHandler = NotificationHandler;
        this.store = store;
    }

    setIFrame = (_iframe) => {
        this._iframe = _iframe;
    };

    setThreesixtyIframe = (_iframe) => {
        this._threesixty_iframe = _iframe;
    };

    setThreesixtyIframes = (_iFrames) => {
        this._threesixty_iframes = _iFrames;
    };

    sendMessageToThreesixty = (data, instance_id) => {
        let iframe = this._threesixty_iframes.find(elem => elem.props.id === instance_id);

        if (iframe)
            iframe.sendMessage(data);
    };

    sendMessageToAllThreesixtys = (data) => {
        if (this._threesixty_iframes)
            this._threesixty_iframes.forEach(elem => {
                elem.sendMessage(data);
            });
    };

    isReady = () => {
        return !!this.iFrames;
    };

    setIframes = (iFrames) => {
        this.iFrames = iFrames;
    };

    setLoaderFunc = (setLoader) => {
        this.setLoader = setLoader;
    };

    sendMessageToJourney = (data, instance_id) => {
        let currentInteractionId = this.store.getState().interactions.currentInteraction.instance_id;
        var iframe = this.iFrames[currentInteractionId].find(elem => elem.props.id === instance_id);

        if (iframe)
            iframe.sendMessage(data);
    };

    sendAll = (data, journeys_instances_id, interaction_instance_id) => {
        if (!interaction_instance_id) {
            for (var interaction in this.iFrames) {
                this.iFrames[interaction].forEach((iframe) => {
                    if (!journeys_instances_id) {
                        if (iframe !== null && iframe.sendMessage !== null) {
                            iframe.sendMessage(data);
                        }
                    } else {
                        if (iframe !== null && iframe.sendMessage !== null && journeys_instances_id.includes(iframe.props.journey_instance_id)) {
                            iframe.sendMessage(data);
                        }
                    }
                });
            }
        } else {
            /**
             * Instance and uniques
             */
            var to_send = [];
            if (this.iFrames[interaction_instance_id]) {
                to_send = to_send.concat(this.iFrames[interaction_instance_id]);
            }
            if (this.iFrames["unique"]) {
                to_send = to_send.concat(this.iFrames["unique"]);
            }

            to_send.forEach((iframe) => {
                if (!journeys_instances_id) {
                    if (iframe !== null && iframe.sendMessage !== null) {
                        iframe.sendMessage(data);
                    }
                } else {
                    if (iframe !== null && iframe.sendMessage !== null && journeys_instances_id.includes(iframe.props.journey_instance_id)) {
                        iframe.sendMessage(data);
                    }
                }
            });
        }
    };

    saveDirtyJourneys = (interactions) => {
        let promises = [];
        if (interactions && interactions.length && interactions.length > 0) {
            this.setLoader(true);
        }
        interactions.forEach((interaction) => {
            interaction.forEach((journey) => {
                promises.push(
                    new Promise(
                        (resolve, reject) => {
                            let data = {
                                type: "dirty_journeys_save",
                                response: false,
                                payload: {},
                                id: Math.random() * 1000
                            };

                            let saveDirtyJourneyWeb = (event) => {
                                let response = event.message;
                                if (response.id === data.id) {
                                    switch (response.result) {
                                        case "error":
                                            window.removeEventListener(response.type, saveDirtyJourneyWeb);
                                            reject(response.payload);
                                            break;
                                        case "not_supported":
                                            window.removeEventListener(response.type, saveDirtyJourneyWeb);
                                            let content = this.store.getState().shell.i18nProvider.Texti18n("dirty_save_journey_not_supported");
                                            this.handleNotification("process", "warning", content);
                                            reject(response.payload);
                                            break;
                                        case "success":
                                            window.removeEventListener(response.type, saveDirtyJourneyWeb);
                                            resolve(response.payload);
                                            break;
                                    }
                                }
                            };

                            let saveDirtyJourneyNative = (event) => {
                                let response = event.message;
                                if (response.id === data.id) {
                                    switch (response.result) {
                                        case "error":
                                            reject(response.payload);
                                            break;
                                        case "not_supported":
                                            let notification = {};
                                            let notificationId = Math.random() * 1000;
                                            notification.notificationId = notificationId;
                                            let date = new Date().toString().split(" ");
                                            notification.time = date[1] + " " + date[2] + " " + date[3] + " " + date[4];
                                            notification.content = this.store.getState().shell.i18nProvider.Texti18n("dirty_save_journey_not_supported");
                                            notification.icon = "warning";
                                            notification.level = "warning";
                                            notification.type = "process";
                                            notification.seen = false;
                                            this.store.dispatch(addNotification(notification));
                                            reject(response.payload);
                                            break;
                                        case "success":
                                            resolve(response.payload);
                                            break;
                                    }
                                }
                            };

                            if (window && window.addEventListener) {
                                window.addEventListener("dirty_journeys_save", saveDirtyJourneyWeb, false);
                            }
                            this.sendMessageToJourney(data, journey.instance_id);
                        }
                    )
                );
            });
        });

        return promises;
    };

    handleEditableWidgets = (bool) => {
        let data = {
            type: "editableState",
            response: false,
            payload: bool
        };
        this.store.dispatch(setEditableWidgetsState(bool));
        this.sendAll(data);
    };

    handleDiscardLayout = () => {
        let data = {
            type: "discardLayout",
            response: false
        };
        this.sendAll(data);
    };

    handleSaveLayout = () => {
        let data = {
            type: "saveLayout",
            response: false
        };
        this.sendAll(data);
    };

    handleClosingJourneys = (journeysToBeNotified) => {
        let promises = [];
        journeysToBeNotified.map((journey) => {
            promises.push(
                new Promise((resolve, reject) => {
                    let data = {
                        type: "closeJourneyEvent",
                        id: Math.random() * 1000
                    };

                    let timeout = setTimeout(() => {
                        if (window && window.removeEventListener) {
                            window.removeEventListener("closeJourneyEvent" + journey.id, closeJourneyEventWeb);
                        } else {
                            EventRegister.removeEventListener("closeJourneyEvent" + journey.id, closeJourneyEventNative);
                        }
                        reject("timeout");
                    }, 2000);
                    
                    let closeJourneyEventWeb = (event) => {
                        window.removeEventListener("closeJourneyEvent" + journey.id, closeJourneyEventWeb);
                        resolve(timeout);
                    };

                    let closeJourneyEventNative = (event) => {
                        EventRegister.removeEventListener("closeJourneyEvent" + journey.id, closeJourneyEventNative);
                        resolve(timeout);
                    };

                    if (window && window.addEventListener) {
                        window.addEventListener("closeJourneyEvent" + journey.id, closeJourneyEventWeb, false);
                    } else {
                        EventRegister.addEventListener("closeJourneyEvent" + journey.id, closeJourneyEventNative);
                    }

                    this.sendMessageToJourney(data, journey.instance_id);
                })
            );
        }
        );
        return promises;
    };

    setJourneyJourneyWidgets = (journeyWidgets) => {
        let data = {
            type: "journeyWidgets",
            response: false,
            payload: journeyWidgets
        };
        this.sendAll(data);
    };

    setJourneySettings = (info) => {
        let data = {
            type: "journeySettings",
            response: false,
            payload: info
        };
        this._iframe.sendMessage(data);
    };

    setJourneyAvailableWidgets = (availableWidgets) => {
        let data = {
            type: "availableWidgets",
            response: false,
            payload: availableWidgets
        };
        this._iframe.sendMessage(data);
    };

    setJourneyDirty = (value) => {
        let data = {
            type: "dirtyJourney",
            response: false,
            payload: value
        };
        this._iframe.sendMessage(data);
    };

    setPrivateMode = (value) => {
        let data = {
            type: "PrivateMode",
            response: false,
            payload: value
        };
        this.sendAll(data);
        this.sendMessageToAllThreesixtys(data);
    };

    setJourneyContext = (context, interactionId) => {
        let data = {
            type: "context",
            response: false,
            payload: context
        };
        this.sendAll(data, undefined, interactionId);
    };

    setThreesixtyContext = (context, instance_id) => {
        let data = {
            type: "context",
            response: false,
            payload: context
        };
        this.sendMessageToThreesixty(data, instance_id);
    };

    /**
     *
     */
    handleGlobalDispatcher = (message, currentInteractionId) => {
        let data = {
            type: message.filters.subtype,
            response: false,
            payload: message.payload,
            id: message.id
        };
        //filter by currentInteraction
        if (message.filters.currentInteraction) {
            this.sendAll(data, message.filters.journeysId, currentInteractionId);
        } else {
            this.sendAll(data, message.filters.journeysId);
        }
        this.sendMessageToAllThreesixtys(data);
    };

    handleNotification = (type, level, content, timeout) => {
        var data = {
            payload: {}
        };

        data.payload.type = type;
        data.payload.level = level;
        data.payload.content = content;
        data.payload.timeout = timeout;

        this.NotificationHandler.addNotification(data);
    };

    handleDirtyModalResponse = (buttonClicked) => {
        let response = {};
        response.type = "journeyAction";
        response.response = true;
        response.result = "success";
        response.id = this.store.getState().interactions.journeyDirtyId;
        response.payload = buttonClicked;

        this._iframe.sendMessage(response);
    };

    set360Client = (instance_id, payload) => {
        return set360Client(this.sendMessageToThreesixty, instance_id, payload);
    };

    send360Search = (instance_id, search) => {
        return send360Search(this.sendMessageToThreesixty, instance_id, search);
    };

    close360View = (instance_id, payload) => {
        return close360View(this.sendMessageToThreesixty, instance_id, payload);
    };
}
