import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Spinner = (props) => {
    return (props.children);
};

Spinner.contextTypes = {
    theme : PropTypes.object
};

Spinner.propTypes = {
    loaderType : PropTypes.string
};

Spinner.defaultProps = {};

const mapStateToProps = ({shell : {hostPort, contentUrl}}) => ({contentUrl, hostPort});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Spinner);