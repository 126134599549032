import React, { Component } from "react";
import ButtonsNavegationsWidgetRender from "./AddWidgetButtonRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setAddWidgetModalOpen } from "../../../../../actions/ui-actions";

class ButtonsNavegationsWidget extends Component {

    openAddWidget = () => {
        let {setAddWidgetModalOpen} = this.props;
        setAddWidgetModalOpen(true);
    };

    render() {
        const {i18nProvider, dashboardEditMode} = this.props
        return (
            <ButtonsNavegationsWidgetRender dashboardEditMode={dashboardEditMode} i18nProvider={i18nProvider} openAddWidget={this.openAddWidget} />
        );
    }
}

const mapStateToProps = ({ui: {dashboardEditMode}, i18n, shell : {i18nProvider},journeys : {currentJourney}}) => ({currentJourney, i18nProvider, i18n, dashboardEditMode});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setAddWidgetModalOpen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsNavegationsWidget);