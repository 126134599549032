import CryptoJS from 'crypto-js';

const master_key = 'kqagLwBKP9SVvER8172B';
const secondary_key = 'W9nN5QzUxhszgD8WtSlz'

/*
 * Local Storage provider that uses HTML5 localStorage for persistence.
 */
class LocalStorageProvider {

    constructor() {
      this.cipherkey = master_key+navigator.userAgent;
    }

    set(key, value, toCipher) {
        let storage = this.getStorage();
        return new Promise(function(resolve, reject) {
            if (!key)
                reject(new Error("Invalid storage key!"));

            if(toCipher){
              var cipherValue = CryptoJS.AES.encrypt(JSON.stringify(value), this.cipherkey+key);
              storage.setItem(key, secondary_key+cipherValue.toString());
            } else {
              storage.setItem(key,JSON.stringify(value));
            }
            resolve("Object with Key: "+key + " saved successfully.");
        }.bind(this));
    }

    setAll(map, toCipher) {
      let keys = Object.keys(map);

      let storage = this.getStorage();
      return new Promise(function(resolve,reject) {
        if(!keys)
          reject(new Error("Invalid keys!"));

        if(toCipher){
          keys.forEach(function(key){
            var cipherValue = CryptoJS.AES.encrypt(JSON.stringify(map[key]), this.cipherkey+key);

            storage.setItem(key, secondary_key+cipherValue.toString());
            resolve("Object with Key: "+key + " saved successfully.");
          }.bind(this))
        }
        else {
          keys.forEach(function(key){
            storage.setItem(key, JSON.stringify(map[key]));
            resolve("Object with Key: "+key + " saved successfully.");
          }.bind(this))
        }
      }.bind(this))
    }

    get(key) {
        let storage = this.getStorage();
        return new Promise(function(resolve, reject) {
            if (!key)
                reject(new Error("Invalid storage key!"));

            let rawValue = storage.getItem(key);

            if(!rawValue)
              reject(new Error("No such key!"));

            if(rawValue.startsWith(secondary_key)){
              rawValue = rawValue.substr(secondary_key.length);
              var bytes = CryptoJS.AES.decrypt(rawValue, this.cipherkey+key);
              rawValue = bytes.toString(CryptoJS.enc.Utf8);
            }

            try {
                //json value
                resolve(JSON.parse(rawValue));
            } catch (e) {
                //not a JSON
                resolve(rawValue);
            }
        }.bind(this));
    }

    remove(key) {
        let storage = this.getStorage();
        return new Promise(function(resolve, reject) {
            if (!key)
                reject(new Error("Invalid storage key!"));
            if(!storage.getItem(key))
                reject(new Error("Key doesn't exists!"));

            storage.removeItem(key);
            resolve();
        });
    }

    getStorage() {
        return localStorage;
    }

    clear() {
        return new Promise(function (resolve) {
            localStorage.clear();
            resolve("All keys Removed");
        });
    }
}

const localStorageProvider = new LocalStorageProvider();
export default localStorageProvider;
