import React, { Component } from "react";
import SearchItemsContainerRender from "./SearchItemsContainerRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class SearchItemsContainer extends Component {

    render() {
        const { data, selected, onItemClick } = this.props;
        return (
            <SearchItemsContainerRender
                data={data}
                selected={selected}
                onItemClick={onItemClick}
            />
        );
    };
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchItemsContainer);