import {
    SET_ADD_WIDGET_MODAL_OPEN,
    SET_TASK_SWITCHER_MODAL_OPEN,
    SET_SESSION_EXPIRED_MODAL_OPEN,
    SET_DIRTY_JOURNEY_MODAL_OPEN,
    SET_LOGIN_LOADING,
    SET_MENU_OPEN,
    SET_JOURNEY_MENU_VIEW_MODE,
    SET_USER_WIDGET_MENU_OPEN,
    SET_SEARCH_CONTEXT_MENU_OPEN,
    SET_PANEL,
    SET_PANEL_OPEN,
    SET_DISABLE_CLICK_AWAY,
    SET_PROCESS_COUNT,
    SET_SEMAPHORE_COUNT,
    SET_DASHBOARD_EDIT_MODE,
    SET_JOURNEY_ACTION_DIRTY_MODAL_OPEN,
    SET_COLLAPSED_CATEGORIES,
    SET_ALL_EXPANDED,
    SET_DIRTY_INTERACTION_MODAL_OPEN
} from "../actions/ui-actions";
import { MENU_LAYOUT_GRID } from "../config";
import { Map } from 'immutable';

export const UI_INITIAL_STATE = {
    //Modals
    isTaskSwitcherOpen: false,
    isAddWidgetOpen: false,
    isSessionExpireOpen: false,
    isDirtyJourneyOpen: false,
    closeJourneyScope: undefined,
    isJourneyActionDirtyModalOpen: false,
    //Spinners
    isLoginLoading: false,
    //UserMenu
    isUserWidgetMenuOpen: false,
    //SearchList
    isSearchListMenuOpen: false,
    //JourneyMenu
    journeyMenuViewMode: MENU_LAYOUT_GRID,
    isMenuOpen: false,
    collapsedCategories: Map(),
    //Panels
    panel: undefined,
    isPanelOpen: false,
    refToIgnore: undefined,
    //Needed as a way to prevent panels from closing if a select is present (select menu is rendering outside of the component, so click away is triggered )
    disableClickAway: false,
    //Number of unseen notifications
    processCount: 0,
    semaphoreCount: 0,

    dashboardEditMode: false,

    interactionToRemove: undefined
};

export default (state = UI_INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOGIN_LOADING:
            return { ...state, isLoginLoading: action.payload };
        case SET_USER_WIDGET_MENU_OPEN:
            return { ...state, isUserWidgetMenuOpen: action.open };
        case SET_SEARCH_CONTEXT_MENU_OPEN:
            return { ...state, isSearchListMenuOpen: action.open };
        case SET_JOURNEY_MENU_VIEW_MODE:
            return { ...state, journeyMenuViewMode: action.mode };
        case SET_MENU_OPEN:
            return { ...state, isMenuOpen: action.open };
        case SET_ADD_WIDGET_MODAL_OPEN:
            return { ...state, isAddWidgetOpen: action.isAddWidgetOpen };
        case SET_TASK_SWITCHER_MODAL_OPEN:
            return { ...state, isTaskSwitcherOpen: action.isTaskSwitcherOpen };
        case SET_SESSION_EXPIRED_MODAL_OPEN:
            return { ...state, isSessionExpireOpen: action.isSessionExpireOpen };
        case SET_DIRTY_JOURNEY_MODAL_OPEN:
            return { ...state, isDirtyJourneyOpen: action.isDirtyJourneyOpen, closeJourneyScope: action.closeJourneyScope };
        case SET_JOURNEY_ACTION_DIRTY_MODAL_OPEN:
            return { ...state, isJourneyActionDirtyModalOpen: action.isJourneyActionDirtyModalOpen }
        case SET_DIRTY_INTERACTION_MODAL_OPEN:
            return { ...state, isDirtyJourneyOpen: action.isDirtyJourneyOpen, closeJourneyScope: action.closeJourneyScope, interactionToRemove: action.interactionToRemove }
        case SET_PANEL_OPEN:
            return { ...state, isPanelOpen: action.isPanelOpen, refToIgnore: action.ref };
        case SET_PANEL:
            return { ...state, panel: action.component, isPanelOpen: action.isPanelOpen, refToIgnore: action.ref };
        case SET_DISABLE_CLICK_AWAY:
            return { ...state, disableClickAway: action.disableClickAway };
        case SET_PROCESS_COUNT:
            return { ...state, processCount: action.count };
        case SET_SEMAPHORE_COUNT:
            return { ...state, semaphoreCount: action.count };
        case SET_DASHBOARD_EDIT_MODE:
            return { ...state, dashboardEditMode: action.value };
        case SET_COLLAPSED_CATEGORIES:
            return { ...state, collapsedCategories: action.value };
        case SET_ALL_EXPANDED:
            var newCollapsedCategories = state.collapsedCategories;
            if (!action.value) {
                newCollapsedCategories.map((isCollapsed, category) => {
                    newCollapsedCategories = newCollapsedCategories.set(category, true)
                })
            } else {
                newCollapsedCategories.map((isCollapsed, category) => {
                    newCollapsedCategories = newCollapsedCategories.set(category, false)
                })
            }
            return { ...state, collapsedCategories: newCollapsedCategories };
        default:
            return state;
    }
};
