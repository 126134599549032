/*
 * Class that abstracts i18n implementation.
 */
class I18nService {

    /*
     * Constructor.
     */
    constructor() {}

    /*
     * Defines the i18n provider to be used in the application.
     * @param i18nProvider - instance of the i18n provider.
     */
    setI18nProvider(i18nProvider, services, deviceInfoProvider) {
        this.i18nProvider = i18nProvider;
        this.i18nProvider.setStore(services.Store);
        this.i18nProvider.setDeviceInfoProvider(deviceInfoProvider);
        this.i18nProvider.setHttpClient(services.HttpClient);
    }


    //Init i18n
    initI18n(canvasName,rootProject) {
        return this.i18nProvider.initI18n(canvasName,rootProject);
    }

    /*
     * Adds / set new dictionary.
     * @param dictionary - Dictionary to set.
     * @return a promise
     */
    setDictionaries(dictionaries, store) {
        return this.i18nProvider.setDictionaries(dictionaries, this.store);
    }

    /*
     * Adds / add an new specific dictionary.
     * @param languageCode - Code language of dictionary to add.
     * @param dictionary - Dictionary to add.
     * @return a promise
     */
    addDictionary( languageCode, dictionary, store ) {
        return this.i18nProvider.addDictionary(languageCode, dictionary, store);
    }


    /*
     * Adds / set languages available.
     * @param languages - Languages list available across application.
     * @return a promise
     */
    setLanguages(languages, store) {
        return this.i18nProvider.setLanguages(languages, store);
    }

    /*
     * Adds / change language across application.
     * @param language - Code of language to change.
     * @return a promise
     */
    changeLanguage(language, store) {
        return this.i18nProvider.changeLanguage(language, store);
    }

    // TODO component LOC
    /*export default props => {
        return (
            <Loc locKey={props.lockey}/>
        )
    }*/
}
const i18nService = new I18nService();
export default i18nService;
