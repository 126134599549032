/**
 * INITAL STATE OF JOURNEYS
 */
export const SET_CONFIG_MANIFEST = 'set_config_journeys';
export const setConfigJourneys = configJourneys => ({ type: SET_CONFIG_MANIFEST, configJourneys });

export const SET_JOURNEYS = 'set_journeys';
export const setJourneys = journeys => ({ type: SET_JOURNEYS, journeys });

 /**
  * JOURNEYS LOGIC
  */
export const INITIALIZE_MAP_ENTRY = "initialize_map_entry";
export const initializeMapEntry = (key) => ({type: INITIALIZE_MAP_ENTRY, key});

export const OPEN_JOURNEY = "open_journey";
export const openJourney = (id, key, initialArgs) => ({type : OPEN_JOURNEY, id, key, initialArgs});

export const CHANGE_CURRENT_JOURNEY = "change_current_journey";
export const changeCurrentJourney = (instance_id, key) => ({type : CHANGE_CURRENT_JOURNEY, instance_id, key });

export const CLOSE_JOURNEY = "close_journey";
export const closeJourney = (instance_id, key) => ({type: CLOSE_JOURNEY, instance_id, key});

export const CLOSE_KEY_ENTRY = "close_key_entry";
export const closeKeyEntry = key => ({type: CLOSE_KEY_ENTRY, key});

export const CHANGE_JOURNEY_PROPERTY = "change_journey_property";
export const changeJourneyProperty = (instance_id, key, key_value, value) => ({type:CHANGE_JOURNEY_PROPERTY, instance_id, key, key_value, value});

/**
 * ADDITIONAL JOURNEYS LOGIC
 */
export const SET_DIRTY_JOURNEY = 'set_dirty_journey';
export const setDirtyJourney = (instance_id, key, value) => ({type: SET_DIRTY_JOURNEY, instance_id, key, value});

export const SET_JOURNEY_SUBTITLE = "set_journey_subtitle";
export const setJourneySubtitle = (instance_id, key, value) => ({type: SET_JOURNEY_SUBTITLE, instance_id, key, value});