import React, { Component } from "react";
import JourneyMenuRender from "./JourneyMenuRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCollapsedCategories } from "../../../actions/ui-actions";
import { Map } from 'immutable';
class JourneyMenu extends Component {

    state = {
        itemsToRender: undefined,
        searchValue: this.props.searchValue
    };

    getI18NLabel = (i18NKey) => {
        const { i18nProvider } = this.props;
        let label = i18nProvider.Texti18n(i18NKey);
        if (label) {
            return label;
        }
        return i18NKey;
    };

    loadJourneysToBeShown = (journeys = this.props.journeysManifest, filter = this.state.searchValue) => {
        const { journeysCategoriesManifest, collapsedCategories } = this.props;
        let items = journeysCategoriesManifest.map((category) => {
            let journeysToBeShown = journeys.filter((journey) => {
                return category.processesOrder.filter(processOrder => processOrder.processId === journey.id).length !== 0;
            });
            journeysToBeShown = journeysToBeShown.sort((firstJourney, secondJourney) => {
                let firstJourneyOrder = category.processesOrder.find(e => e.processId === firstJourney.id).order;
                let secondJourneyOrder = category.processesOrder.find(e => e.processId === secondJourney.id).order;
                if (firstJourneyOrder === secondJourneyOrder) return 0;
                if (firstJourneyOrder < secondJourneyOrder) return -1;
                if (firstJourneyOrder > secondJourneyOrder) return 1;
            });
            if (filter) {
                journeysToBeShown = journeysToBeShown.filter(journey => this.getI18NLabel(journey.i18NLabel).toLowerCase().includes(filter.toLowerCase()));
            }
            journeysToBeShown = journeysToBeShown.map(journey => ({
                id: journey.id,
                titleKey: journey.i18NLabel,
                icon: journey.icon,
                entitlement: journey.entitlement,
                type: journey.type
            }));
            return {
                //Journeys
                journeys: journeysToBeShown,
                //Name To be shown
                titleKey: this.getI18NLabel(category.i18NLabel),
                //Entitlement of the category
                entitlement: category.entitlement
            };
        });
        collapsedCategories.size < 1 && this.setCollapsedCategories(items)
        this.setState({ itemsToRender: items });
    };

    componentDidUpdate(prevProps, prevState) {
        const { searchValue } = this.state;
        if (searchValue !== prevState.searchValue) {
            this.loadJourneysToBeShown();
        }
    }

    setCollapsedCategories = (itemsToRender) => {
        var newCollapsedCategories = Map();
        itemsToRender.map((category, index) => {
            newCollapsedCategories = newCollapsedCategories.set(category.titleKey, false)
        })
        newCollapsedCategories = newCollapsedCategories.set("menu_favourites", false)
        this.props.setCollapsedCategories(newCollapsedCategories);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.searchValue !== prevState.searchValue) {
            return { searchValue: nextProps.searchValue };
        }
        return null;
    }

    componentWillUnmount() {
        try {
            window.removeEventListener("keyup", this.closeMenu);
        } catch (e) {
        }
    }

    componentDidMount() {
        try {
            window.addEventListener("keyup", this.closeMenu);
        } catch (e) {
        }
        this.loadJourneysToBeShown();
    }

    render() {

        const { itemsToRender } = this.state;
        const { favouriteMenuJourneys } = this.props;
        return (
            <JourneyMenuRender favouriteMenuJourneys={favouriteMenuJourneys} itemsToRender={itemsToRender} />
        );
    }
}

const mapStateToProps = ({
    i18n,
    shell: { i18nProvider },
    ui: { collapsedCategories },
    canvas: { favouriteMenuJourneys },
    journeys: { journeysCategoriesManifest, journeysManifest }
}) => ({
    journeysCategoriesManifest,
    journeysManifest,
    i18nProvider,
    i18n,
    collapsedCategories,
    favouriteMenuJourneys
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setCollapsedCategories
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(JourneyMenu);