/**
 * @author nb21459
 */
class UserPreferenceService {

    constructor(){}

    /**
     * To use a custom UserPrerference Provider
     * @param {*} userPreferenceProvider
     */
    setUserPreferenceProvider(userPreferenceProvider) {
        this.userPreferenceProvider = userPreferenceProvider;
    };

    /**
     *
     * @param {*} userId
     * @param {*} preferenceId
     * @param {*} propertyId
     */
    readPreferences (userId, preferenceId, propertyId){
        return this.userPreferenceProvider.getPreferences(userId, preferenceId, propertyId);
    };
    /**
     *
     * @param {*} userId
     * @param {*} expression
     * @param {*} preferenceId
     */
    queryPreferences(userId, expression, preferenceId){
        return this.userPreferenceProvider.queryPreferences(userId, expression, preferenceId);
    };
    /**
     *
     * @param {*} userId
     * @param {*} preference
     */
    createPreference(userId, preference) {
        return this.userPreferenceProvider.createPreference(userId, preference);
    };

   /**
    *
    * @param {*} userId
    * @param {*} preferenceId
    * @param {*} property
    */
    createProperty(userId, preferenceId, property) {
        return this.userPreferenceProvider.createProperty(userId, preferenceId, property);
    };
    /**
     *
     * @param {*} userId
     * @param {*} preference
     * @param {*} preferenceId
     * @param {*} propertyId
     */
    updatePreference (userId, preference, preferenceId, propertyId){
        return this.userPreferenceProvider.updatePreference(userId, preference, preferenceId, propertyId);
    };

    /**
     *
     * @param {*} userId
     * @param {*} preferenceId
     * @param {*} propertyId
     */
     deletePreference (userId, preferenceId){
         return this.userPreferenceProvider.deletePreference(userId, preferenceId);
     };
     
     deleteProperty (userId, preferenceId, propertyId){
         return this.userPreferenceProvider.deleteProperty(userId, preferenceId, propertyId);
     };

};

const userPreferenceService = new UserPreferenceService();
export default userPreferenceService;
