import React, { Component } from "react";
import MenuItemRender from "./MenuItemRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeInteraction, setCurrentInteraction } from '../../../../../../actions/interactions-actions';
import { setDirtyInteractionModalOpen } from '../../../../../../actions/ui-actions';
import { Config } from '../../../../../../config';

class MenuItem extends Component {

    onMouseOver = () => {
        const { onMouseOver, interaction } = this.props;
        onMouseOver && onMouseOver(interaction.instance_id);
    };

    onItemClick = () => {
        const { setCurrentInteraction, interaction, setMenuState } = this.props;
        setCurrentInteraction(interaction.instance_id);
        setMenuState();
    }

    closeInteraction = () => {
        const { sendEventHandler: { close360View }, interaction, removeInteraction, journeyHistory, setDirtyInteractionModalOpen } = this.props;
        let payload = { attendeeDuration: new Date() - interaction.startedTime };
        const dirtyJourneys = journeyHistory.get(interaction.instance_id).filter(journey => journey.isDirty);

        if (dirtyJourneys.size > 0) {
            setDirtyInteractionModalOpen(true, Config.CLOSE_JOURNEY_SCOPE.MENU_INTERACTION, interaction);
        }
        else {
            const journeysToBeNotified = journeyHistory.get(interaction.instance_id).filter(journey => journey.shouldBeNotifiedBeforeUnload);
            if (journeysToBeNotified > 0) {
                setDirtyInteractionModalOpen(false, Config.CLOSE_JOURNEY_SCOPE.MENU_INTERACTION, interaction);
            }
            else {
                close360View(interaction.instance_id, payload).then(res => {
                    removeInteraction(interaction.instance_id);
                }).catch(err => {
                    console.log("Close Interaction error: " + err);
                    removeInteraction(interaction.instance_id);
                });
            }
        }
    }

    timeConvert = (ms) => {
        var time = new Date(ms);
        var hours = time.getHours() >= 10 ? time.getHours() : '0' + time.getHours();
        var minutes = time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes();

        return hours + ':' + minutes;
    }


    render() {

        const { interaction, theme, id } = this.props;
        var startedTime = this.timeConvert(interaction.startedTime);
        return (
            <MenuItemRender
                onItemClick={this.onItemClick}
                onMouseOver={this.onMouseOver}
                identifier={interaction.identifier}
                shortIdentifier={interaction.shortIdentifier}
                closeInteraction={this.closeInteraction}
                startedTime={startedTime}
                interactionColor={theme.interactionColorPalette[interaction.colorIndex]}
                id={id}
            />
        );
    }
}

const mapStateToProps = ({ theme: { theme }, journeys: { journeyHistory }, interactions: { currentInteraction }, canvas: { sendEventHandler, config: { threesixtyConfigs } }, shell: { entitlementsMapper } }) => ({ sendEventHandler, threesixtyConfigs, entitlementsMapper, currentInteraction, journeyHistory, theme });
const mapDispatchToProps = dispatch => bindActionCreators({
    removeInteraction,
    setDirtyInteractionModalOpen,
    setCurrentInteraction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);