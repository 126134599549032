const ComponentName = "UfeJourneyInformation";
const styles = theme => ({
    root : {
    },
    numberBadge: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    numberBadgePrimary: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main
    }
});

export { styles };
export { ComponentName };