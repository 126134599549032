import React from 'react';
import { ComponentName, styles } from './JourneysCategorySectionStyles';
import { withStyles } from '@ui-lib/core/styles';
import CategoryTitle from './CategoryTitle';
import JourneyCard from '../../../ShareComponents/JourneyCard';
import { MENU_LAYOUT_GRID } from '../../../../config';
import { IEntitlementsVerifier } from 'omni-shell-common/src/entitlements-verifier';
import Grid from '@ui-lib/core/Grid';
import Collapse from '@ui-lib/core/Collapse';

function JourneysCategorySectionRender({ category, index, classes, journeyMenuViewMode, i18nProvider, changeActiveJourney, currentInteraction, openJourneys, toggleFavourite, toggleVisibility, collapsed, favouriteMenuJourneys, entitlementsMapper, configMenuFavourites, id, journeysManifest }) {
    let EntitlementsVerifier = IEntitlementsVerifier(React);

    return (
        <Grid id={'UfeJourneyMenuCategory_' + id} container className={classes.root}>
            <EntitlementsVerifier entitlement={category.entitlement}>
                <Grid item xs={12} className={classes.subtitleContainer}>
                    <CategoryTitle id={id} category={category} collapsed={collapsed} toggleVisibility={toggleVisibility} title={i18nProvider.Texti18n(category.titleKey)}
                                   count={category.journeys.length}/>
                </Grid>
                <Grid item className={journeyMenuViewMode === MENU_LAYOUT_GRID ? classes.journeyContainerGrid : classes.journeyContainerList}
                      xs={12}>
                    <Collapse in={!collapsed}>
                        {
                            category.journeys.map((journey, j) => {

                                let countJourneyInstances = ((journeyId) => {
                                    if (!openJourneys)
                                        return 0;
                                    if (journey.type === 'unique') {
                                        let uniqueJourneys = openJourneys.get('unique');
                                        return (uniqueJourneys && (uniqueJourneys.findIndex(j => j.id === journeyId) != -1)) ? 1 : 0;
                                    }
                                    if (journey.type === 'context_unique') {
                                        let currentJourneys = openJourneys.get(currentInteraction.instance_id);
                                        return (currentJourneys && (currentJourneys.findIndex(j => j.id === journeyId) != -1)) ? 1 : 0;
                                    }

                                    let currentJourneys = openJourneys.get(currentInteraction.instance_id);
                                    return (currentJourneys && currentJourneys.size > 0) ? currentJourneys.count(journey => journey.id === journeyId) : 0;

                                })(journey.id);
                                let fullJourney = journeysManifest.find(e => e.id === journey.id);
                                return (
                                    <EntitlementsVerifier key={`tile-${j}`} entitlement={journey.entitlement}>
                                        <JourneyCard
                                            key={j}
                                            callArg={journey.id}
                                            configMenuFavourites={configMenuFavourites}
                                            favourite={favouriteMenuJourneys && favouriteMenuJourneys.some(elem => elem.value == journey.id)}
                                            onClick={changeActiveJourney}
                                            icon={journey.icon}
                                            listIcon={fullJourney.listIcon}
                                            favouritesEntitlements={entitlementsMapper.menuFavourites}
                                            badgeContent={countJourneyInstances}
                                            onFavoriteClick={(event) => toggleFavourite(journey.id, journey.titleKey, event)}
                                            title={i18nProvider.Texti18n(journey.titleKey)}
                                            hover={i18nProvider.Texti18n(journey.titleKey)}
                                            mode={journeyMenuViewMode === MENU_LAYOUT_GRID ? 'GRID' : 'LIST'}
                                        />
                                    </EntitlementsVerifier>
                                );
                            })
                        }
                    </Collapse>
                </Grid>
            </EntitlementsVerifier>
        </Grid>
    );
}

export default withStyles(styles, { withName : ComponentName })(JourneysCategorySectionRender);
