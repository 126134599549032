import React, { Component } from "react";
import TaskSwitcherRender from "./TaskSwitcherRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setTaskSwitcherModalOpen, setMenuOpen } from "../../../actions/ui-actions";
import { changeCurrentJourney} from "omni-shell-common/src/actions/journeys-actions";

class TaskSwitcher extends Component {

    closeModal = () => {
        let {setTaskSwitcherModalOpen} = this.props;
        setTaskSwitcherModalOpen(false);
    };

    onSwitchJourney = (journeyInstanceId) => {
        const {setMenuOpen, changeCurrentJourney, currentInteraction, setTaskSwitcherModalOpen} = this.props;
        changeCurrentJourney(journeyInstanceId, currentInteraction.instance_id);
        setTaskSwitcherModalOpen(false);
        setMenuOpen(false);
    };

    render() {
        let {isTaskSwitcherOpen, i18nProvider, journeyHistory, currentInteraction} = this.props;
        return (
            <TaskSwitcherRender
                isTaskSwitcherOpen={isTaskSwitcherOpen}
                onSwitchJourney={this.onSwitchJourney}
                onClose={this.closeModal}
                maxWidth={"md"}
                fullWidth={false}
                i18nProvider={i18nProvider}
                journeyHistory={journeyHistory}
                currentInteraction={currentInteraction}
            />
        );
    }
}

const mapStateToProps = ({
    shell : {i18nProvider},
    interactions : {currentInteraction},
    ui : {isTaskSwitcherOpen},
    journeys : {openJourneys, journeyHistory}}) => ({
    isTaskSwitcherOpen,
    journeyHistory,
    currentInteraction,
    i18nProvider,
    openJourneys
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setTaskSwitcherModalOpen,
        changeCurrentJourney,
        setMenuOpen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(TaskSwitcher);