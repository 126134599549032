import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./SessionExpiredStyles";
import Dialog from "@ui-lib/core/Dialog";
import DialogContent from "@ui-lib/core/DialogContent";
import DialogTitle from "@ui-lib/core/DialogTitle";
import DialogActions from "@ui-lib/core/DialogActions";
import classNames from "@ui-lib/core/classNames";
import Typography from "@ui-lib/core/Typography";
import IconButton from "@ui-lib/core/IconButton";
import Icon from "@ui-lib/core/Icon";
import Button from "@ui-lib/core/Button";

function SessionExpiredRender({classes, isSessionExpireOpen, onClose, maxWidth, fullWidth, i18nProvider}) {

    return (
        <Dialog
            id='UfeSessionExpiredModal'
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={isSessionExpireOpen}
            onClose={onClose}
            className={classes.root}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">
                <div className={classes.titleContainer}>
                    {i18nProvider.Labeli18n("session_expired")}
                </div>
                <IconButton id="UfeSessionExpireModalCloseIconBtn" onClick={onClose} className={classNames(classes.iconButtonClose, classes.titleContainer)} title={i18nProvider.Texti18n("close")}>
                    <Icon className={classNames("icon-close", classes.iconClose)}/>
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.contentPaddingFix}>
                <Typography variant="subtitle2" noWrap>
                    {i18nProvider.Labeli18n("token_has_been_expired")}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button id="UfeSessionExpireModalOkBtn" variant="contained" color="primary" title={"Button"} onClick={onClose}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}

export default withStyles(styles, {name : ComponentName})(SessionExpiredRender);