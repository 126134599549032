import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./ContextContainerStyles";
import ActiveContextContainer from "./ActiveContextContainer";
import OpenInteractionsMenuButton from './OpenInteractionsMenuButton';
import OpenInteractionsMenu from './OpenInteractionsMenu';

function ContextContainerRender({ classes, setMenuState, isInteractionsMenuOpen, interactionsHistory }) {
    return (
        <div className={classes.root}>
            <ActiveContextContainer />
            <span className={classes.span} />
            <div className={classes.openedMenuContainer}>
                {interactionsHistory.size > 0 && <OpenInteractionsMenuButton isInteractionsMenuOpen={isInteractionsMenuOpen} setMenuState={setMenuState} />}
                {isInteractionsMenuOpen && <OpenInteractionsMenu setMenuState={setMenuState}/>}
            </div>
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(ContextContainerRender);