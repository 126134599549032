import React, { Component } from "react";
import ActiveContextContainerRender from "./ActiveContextContainerRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class ActiveContextContainer extends Component {

    render() {
        const { currentInteraction } = this.props;
        return (
            <ActiveContextContainerRender currentInteraction={currentInteraction} />
        );
    }
}

const mapStateToProps = ({ interactions: { currentInteraction } }) => ({ currentInteraction });
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ActiveContextContainer);