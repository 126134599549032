import React, { Component } from "react";
import QuickActionsRender from "./QuickActionsRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class QuickActions extends Component {

    render() {
        return (
            <QuickActionsRender entitlements={this.props.entitlementsMapper} />
        );
    }
}

const mapStateToProps = ({shell:{entitlementsMapper}}) => ({entitlementsMapper});

const mapDispatchToProps = dispatch => bindActionCreators({},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuickActions);