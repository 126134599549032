export const SET_LOGIN_LOADING = "SET_LOGIN_LOADING";
export const setLoginLoading = (value) => ({type : SET_LOGIN_LOADING, payload : value});

export const SET_USER_WIDGET_MENU_OPEN = "SET_USER_WIDGET_MENU_OPEN";
export const setUserMenuOpen = (open) => ({type : SET_USER_WIDGET_MENU_OPEN, open});

export const SET_JOURNEY_MENU_VIEW_MODE = "SET_JOURNEY_MENU_VIEW_MODE";
export const setJourneyMenuViewMode = (mode) => ({type : SET_JOURNEY_MENU_VIEW_MODE, mode});

export const SET_MENU_OPEN = "SET_MENU_OPEN";
export const setMenuOpen = (open) => ({type : SET_MENU_OPEN, open});

export const SET_TASK_SWITCHER_MODAL_OPEN = "SET_TASK_SWITCHER_OPEN";
export const setTaskSwitcherModalOpen = (isTaskSwitcherOpen) => ({type : SET_TASK_SWITCHER_MODAL_OPEN, isTaskSwitcherOpen});

export const SET_ADD_WIDGET_MODAL_OPEN = "SET_ADD_WIDGET_OPEN";
export const setAddWidgetModalOpen = (isAddWidgetOpen) => ({type : SET_ADD_WIDGET_MODAL_OPEN, isAddWidgetOpen});

export const SET_SESSION_EXPIRED_MODAL_OPEN = "SET_SESSION_EXPIRED_OPEN";
export const setSessionExpiredModalOpen = (isSessionExpireOpen) => ({type : SET_SESSION_EXPIRED_MODAL_OPEN, isSessionExpireOpen});

export const SET_DIRTY_JOURNEY_MODAL_OPEN = "SET_DIRTY_JOURNEY_OPEN";
export const setDirtyJourneyModalOpen = (isDirtyJourneyOpen, closeJourneyScope) => ({type : SET_DIRTY_JOURNEY_MODAL_OPEN, isDirtyJourneyOpen, closeJourneyScope});

export const SET_JOURNEY_ACTION_DIRTY_MODAL_OPEN = "SET_JOURNEY_ACTION_DIRTY_MODAL_OPEN";
export const setJourneyActionDirtyModalOpen = (isJourneyActionDirtyModalOpen) => ({type : SET_JOURNEY_ACTION_DIRTY_MODAL_OPEN, isJourneyActionDirtyModalOpen});

export const SET_PANEL = "SET_PANEL";
export const setPanel = (component, isPanelOpen = true, ref = undefined) => ({type : SET_PANEL, component, isPanelOpen, ref});

export const SET_PANEL_OPEN = "SET_PANEL_OPEN";
export const setPanelOpen = (isPanelOpen, ref = undefined) => ({type : SET_PANEL_OPEN, isPanelOpen, ref});

export const SET_SEARCH_CONTEXT_MENU_OPEN = "SET_SEARCH_CONTEXT_MENU_OPEN";
export const setSearchContextOpen = (open) => ({type : SET_SEARCH_CONTEXT_MENU_OPEN, open});

//Needed as a way to prevent panels from closing if a select is present (select menu is rendering outside of the component, so click away is triggered )
export const SET_DISABLE_CLICK_AWAY = "SET_DISABLE_CLICK_AWAY";
export const setDisableClickAway = (disableClickAway = false) => ({type : SET_DISABLE_CLICK_AWAY, disableClickAway});

export const SET_PROCESS_COUNT = "SET_PROCESS_COUNT"
export const setProcessCount = (count) => ({type : SET_PROCESS_COUNT, count});

export const SET_SEMAPHORE_COUNT = "SET_SEMAPHORE_COUNT"
export const setSemaphoreCount = (count) => ({type : SET_SEMAPHORE_COUNT, count});

export const SET_DASHBOARD_EDIT_MODE = "SET_DASHBOARD_EDIT_MODE"
export const setDashboardEditMode = (value) => ({type: SET_DASHBOARD_EDIT_MODE, value});

export const SET_COLLAPSED_CATEGORIES = "SET_COLLAPSED_CATEGORIES"
export const setCollapsedCategories = (value) => ({type: SET_COLLAPSED_CATEGORIES, value});

export const SET_ALL_EXPANDED = "SET_ALL_EXPANDED"
export const setAllExpanded = (value) => ({type: SET_ALL_EXPANDED, value});

export const SET_DIRTY_INTERACTION_MODAL_OPEN = "SET_DIRTY_INTERACTION_MODAL_OPEN"
export const setDirtyInteractionModalOpen = (isDirtyJourneyOpen, closeJourneyScope, interactionToRemove) => ({type : SET_DIRTY_INTERACTION_MODAL_OPEN, isDirtyJourneyOpen, closeJourneyScope, interactionToRemove});