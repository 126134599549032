import { changeJourneyProperty } from "omni-shell-common";

const registerRouterEvents = (store) => {
    window.addEventListener("ufe-events-router-push", (event) => push(store,event), false);
    window.addEventListener("ufe-events-router-pop", () => pop(store), false);
    window.addEventListener("ufe-events-router-add-arguments", (event) => addArguments(store,event), false);
    window.addEventListener("ufe-events-router-remove-arguments", (event) => removeArguments(store,event), false);
    window.addEventListener("ufe-events-router-UNSAFE-set", (event) => UNSAFE_set(store,event), false);
}

const push = (store,event) => {
    const { currentJourney } = store.getState().journeys;
    const { currentInteraction } = store.getState().interactions;

    let next_breadcrumb_state = currentJourney && currentJourney.breadcrumb ? currentJourney.breadcrumb + "/" + event.data.payload.next : event.data.payload.next;
    store.dispatch(changeJourneyProperty(currentJourney.instance_id, currentInteraction.instance_id, "breadcrumb", next_breadcrumb_state));
    sendInformationToJourney(store, next_breadcrumb_state);
}

const pop = (store) => {
    const { currentJourney } = store.getState().journeys;
    const { currentInteraction } = store.getState().interactions;
    
    let split_last = currentJourney && currentJourney.breadcrumb ? currentJourney.breadcrumb.lastIndexOf("/") : -1;
    let next_breadcrumb_state = currentJourney.breadcrumb ? currentJourney.breadcrumb.substring(0, split_last) : "";

    store.dispatch(changeJourneyProperty(currentJourney.instance_id, currentInteraction.instance_id, "breadcrumb", next_breadcrumb_state));
    sendInformationToJourney(store, next_breadcrumb_state);
}

const addArguments = (store,event) => {
    const { currentJourney } = store.getState().journeys;
    const { currentInteraction } = store.getState().interactions;
    const { key, value } = event.data.payload;

    let next_breadcrumb_state = currentJourney && currentJourney.breadcrumb ? (currentJourney.breadcrumb.includes("?") ? 
        currentJourney.breadcrumb + "&" + key + "=" + value : 
        currentJourney.breadcrumb + "?" + key + "=" + value) : "";
    store.dispatch(changeJourneyProperty(currentJourney.instance_id, currentInteraction.instance_id, "breadcrumb", next_breadcrumb_state));
    sendInformationToJourney(store, next_breadcrumb_state);
}

const removeArguments = (store,event) => {
    const { currentJourney } = store.getState().journeys;
    const { currentInteraction } = store.getState().interactions;
    const { key } = event.data.payload;
    
    let next_breadcrumb_state = currentJourney && currentJourney.breadcrumb && currentJourney.breadcrumb.includes("?") ? 
        currentJourney.breadcrumb.substring(0, currentJourney.breadcrumb.lastIndexOf(key) - 1) : 
        currentJourney.breadcrumb;

    store.dispatch(changeJourneyProperty(currentJourney.instance_id, currentInteraction.instance_id, "breadcrumb", next_breadcrumb_state));
    sendInformationToJourney(store, next_breadcrumb_state);
}

const UNSAFE_set = (store, event) => {
    const { currentJourney } = store.getState().journeys;
    const { currentInteraction } = store.getState().interactions;
    const { path } = event.data.payload;

    store.dispatch(changeJourneyProperty(currentJourney.instance_id, currentInteraction.instance_id, "breadcrumb", path));
    sendInformationToJourney(store, path);
}

const sendInformationToJourney = (store, next_breadcrumb_state) => {
    const { journeys: { currentJourney }, canvas: { sendEventHandler } } = store.getState();

    let data = {
        type: "breadcrumbChangeEvent",
        payload: next_breadcrumb_state
    };

    sendEventHandler.sendMessageToJourney(data, currentJourney.instance_id);
}

export default registerRouterEvents;