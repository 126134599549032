import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./DiscardChangesButtonStyles";
import Button from "@ui-lib/core/Button";
import classNames from "@ui-lib/core/classNames";

function DiscardChangesButtonRender({classes, discardChanges, i18nProvider, dashboardEditMode}) {
    return (
        <div className={dashboardEditMode ? classNames(classes.root, classes.editMode) : classes.root}>
            <Button id='UfeDiscardChangesButton' title={i18nProvider.Texti18n("discard_dashboard_button")} onClick={discardChanges} className={classes.button}>
                {i18nProvider.Labeli18n("discard_dashboard_button")}
            </Button>
        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(DiscardChangesButtonRender);