import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./ItemListContainerStyles";
import ClickAwayListener from "../ClickAwayListener";
import classNames from "@ui-lib/core/classNames";

function ItemListContainerRender({ classes, children, Footer, clickAwayCallback, outerDivClass, innerDivClass, ...other }) {
    return (
        <ClickAwayListener onClickAway={clickAwayCallback}>
            <div {...other} className={classes.container}>
                <div className={classNames(classes.root, outerDivClass, innerDivClass)}>
                    <div className={classes.listStyle}>
                        {children}
                    </div>
                    {Footer && <Footer />}
                </div>
            </div>
        </ClickAwayListener>
    );
}

export default withStyles(styles, { name: ComponentName })(ItemListContainerRender);
