const ComponentName = "UfeDiscardChangesButton";
const styles = theme => ({
    root : {
        marginRight: 24
    },
    button : {
        color : "inherit"
    },
    input: {
        display: 'none',
    },
    editMode: {
        zIndex: 1300
    }
});

export { styles };
export { ComponentName };