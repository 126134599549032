const ComponentName = "UfeSettingsPanel";
const styles = theme => ({
    root : {
        minHeight : "inherit",
        paddingTop : "20px"
    },
    languageContainer : {
        // width : "270px"
    }
});

export { styles };
export { ComponentName };