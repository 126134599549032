import React, { Component } from "react";
import { i18nActions, Loc } from "redux-react-i18n";
import { UserPreferencesNames } from "../config";
import { SET_LOGGED_USER_PREFS, SET_OS_LANGUAGE_PREFERENCE } from "../actions/shell-actions";
import _ from "lodash";

export default class I18nProvider {
    /**
     * [constructor]
     * @param {i18nActions} i18nActions
     * @param {Loc} Loc
     */
    constructor(i18nActions, Loc) {
        this.i18nActions = i18nActions;
        window.setLanguages = this.setLanguages.bind(this);

        this.dictionaries = undefined;
        this.languages = undefined;
        this.currentLanguage = undefined;
    }

    /**
     * [Labeli18n Function that retrieves a component that contain the i18nLabel. The result is a React componenet]
     * @param {string} locKey [String that identifies the key of the label to be used]
     * @return {ReactComponent} React component that renders a label that contains the value of the key.
     */
    Labeli18n(locKey) {
        if (this.verifyKey(locKey))
            return (<Loc locKey={locKey} />);
        return <span>{locKey}</span>;
    }

    verifyKey = (key) => {
        if (this.dictionaries && this.dictionaries[this.currentLanguage] && this.dictionaries[this.currentLanguage][key])
            return true;
        return false;
    };

    /**
     * [Texti18n Function that retrieves a String that contains the content of the i18n]
     * @param {string} locKey [String that identifies the key of the label to be used]
     * @param {array} args [Array with args to replace on i18n string with key %s]
     * @return {string} String that is returned as the value of the i18nkey
     */
    Texti18n(locKey, args) {
        if (this.verifyKey(locKey)) {
            let texti18n = this.dictionaries[this.currentLanguage][locKey];
            if (args && args.length > 0) {
                let finalString = this.parse(texti18n, args);
                return finalString;
            } else {
                return texti18n;
            }          
        } else {
            return locKey;
        }
        
    }

    /**
     * [setStore Sets the store that will be used to store the i18n]
     * @param {ReduxStore} store [Store to be used]
     */
    setStore(store) {
        this.store = store;
    }

    /**
     *
     * @param {*} httpClient
     */
    setHttpClient(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * [setDeviceInfoProvider sets the deviceinfo]
     * @param {string} deviceInfoProvider [deviceInfoProivder]
     */
    setDeviceInfoProvider(deviceInfoProvider) {
        this.deviceInfoProvider = deviceInfoProvider;
    }

    /**
     * [setDictionaries function that receives the dictionaries and set them]
     * @param {object} dictionaries [dictionaries to be added]
     */
    setDictionaries(dictionaries) {
        this.dictionaries = dictionaries;
        return this.store.dispatch(this.i18nActions.setDictionaries(dictionaries));
    }

    /**
     * [addDictionary functions that will set the dictionary, given the language code]
     * @param {string} languageCode [languageCode, example: en-US, pt-PT]
     * @param {object} dictionary   [object dictionary that contains the keys and values of the dictionary]
     */
    addDictionary(languageCode, dictionary) {
        this.languages = languages;
        this.store.dispatch(this.i18nActions.addDictionary(languageCode, dictionary));
    }

    /**
     * [setLanguages function that will set the languages of the i18n]
     * @param {object} languages [languages to be stored]
     */
    setLanguages(languages) {
        this.store.dispatch(this.i18nActions.setLanguages(languages));
    }

    setOsLanguagePreference(osLanguagePreference) {
        this.store.dispatch({
            type: SET_OS_LANGUAGE_PREFERENCE,
            payload: osLanguagePreference
        });
    }

    /**
     * [changeLanguage function that will change the current language]
     * @param  {string} language [identifier of the language that will be choosen as the new one]
     */
    changeLanguage(language, disableUserPrefs) {
        if (this.store.getState().i18n.languages.find((e) => e.code === language)) {
            this.currentLanguage = language;
            this.store.dispatch(this.i18nActions.setCurrentLanguage(language));
        } else if (this.store.getState().i18n.languages.find((e) => e.default)) {
            this.store.getState().i18n.languages.map((language, index) => {
                if (language.default) {
                    this.currentLanguage = language.code;
                    this.store.dispatch(this.i18nActions.setCurrentLanguage(language.code));
                }
            })
        } else if (!services.Store.getState().shell.disableOsLanguagePreference) {
            let defaultLanguage = services.Store.getState().shell.services.I18n.i18nProvider.deviceInfoProvider.getDeviceLocale()
            this.currentLanguage = defaultLanguage;
            this.store.dispatch(this.i18nActions.setCurrentLanguage(defaultLanguage));
        } else {
            this.currentLanguage = "en-US";
            this.store.dispatch(this.i18nActions.setCurrentLanguage("en-US"));
        }
        let loggedUserPrefs = this.store.getState().shell.loggedUserPrefs;
        let currentLoggedUser = this.store.getState().shell.currentLoggedUser;
        if (disableUserPrefs !== true) {
            if (this.store.getState().shell.services && !_.isEmpty(loggedUserPrefs) && currentLoggedUser) {
                let i18nThemePref = loggedUserPrefs.properties.find((e) => e.name === UserPreferencesNames.I18N_NAME);
                if (i18nThemePref && i18nThemePref.value !== language || i18nThemePref.value !== this.currentLanguage) {
                    let userPrefService = this.store.getState().shell.services.UserPreferences;
                    if (userPrefService && currentLoggedUser.id && loggedUserPrefs.id && i18nThemePref.id && language) {
                        let i18nProperty = {
                            name: UserPreferencesNames.I18N_NAME,
                            description: UserPreferencesNames.I18N_DESCRIPTION,
                            value: this.currentLanguage
                        };
                        userPrefService.updatePreference(currentLoggedUser.id, i18nProperty, loggedUserPrefs.id, i18nThemePref.id).then((res) => {
                            let newLoggedUserPrefs = Object.assign({}, loggedUserPrefs);
                            loggedUserPrefs.properties.forEach((e) => {
                                if (e.id === res.data.id) {
                                    e.value = res.data.value;
                                }
                            });
                            this.store.dispatch({
                                type: SET_LOGGED_USER_PREFS,
                                userPrefs: newLoggedUserPrefs
                            });
                        }).catch((err) => {
                            console.log(err);
                        });
                    }
                }
            }
        }
    }

    /**
     * [initI18n inits the i18n. It will take care of all the coordenation of the i18n, including calling the setlanguages and dictionaries]
     * @param  {string} canvasName [canvas for which the i18n is being prepared]
     */
    initI18n(canvasName, rootProject) {

        const osLanguage = this.deviceInfoProvider.getDeviceLocale();
        let finalLanguageUri = this.store.getState().shell.contentUrl + `/${rootProject}/v3/canvas/${canvasName}/config/languages.json`;
        this.httpClient.get(finalLanguageUri).then((response) => {
            let availableLanguages = [];
            let promise = [];

            this.setLanguages(response.data.availableLanguages);
            this.setOsLanguagePreference(response.data.disableOsLanguagePreference);

            let languageFound = undefined;
            if (!response.data.disableOsLanguagePreference) {
                response.data.availableLanguages.forEach((languageItem) => {
                    if (languageItem.code === osLanguage) languageFound = languageItem;
                });
            }

            if (languageFound) {
                this.changeLanguage(osLanguage);
            } else {
                let defaultLang = response.data.availableLanguages.find(e => !!e.default);
                defaultLang = defaultLang ? defaultLang.code : "en-US";
                this.changeLanguage(defaultLang);
            }

            response.data.availableLanguages.map((language, index) => {
                let languageCode = language.code;
                availableLanguages.push(languageCode);
                let dictionaryUri = this.store.getState().shell.contentUrl + `/${rootProject}/v3/canvas/${canvasName}/i18n/dictionary/${languageCode}.json`;
                promise.push(this.httpClient.get(dictionaryUri, {responseEncoding : "utf8"}));
            });
            Promise.all(promise).then(function (values) {
                let finalDictionaries = {};
                values.map((dictionary, index) => {
                    let availableDictionaries = availableLanguages[index];
                    finalDictionaries[availableDictionaries] = dictionary.data;
                });
                this.setDictionaries(finalDictionaries);

                if (window && window.location) {
                    let title = this.dictionaries[this.currentLanguage].document_title;
                    try {
                        if (document && title) {
                            document.title = title;
                        }
                    }catch (e) {

                    }
                }

            }.bind(this)).catch();

        });
    }

    parse(str, args) {
        // Args example: [{'KEY_EXAMPLE_1': 'Example 1'}, {'KEY_EXAMPLE_2': 'Example 2'}]
        args.forEach(function (item) {
            let array = _.keys(item);
            let keyTmp = "${" + array[0] + "}";
            str = str.replace(keyTmp, item[Object.keys(item)[0]]);
        });
        return str;
    }
}
