import React, { Component } from 'react';
import JourneysCategorySectionRender from './JourneysCategorySectionRender';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setMenuOpen, setCollapsedCategories } from '../../../../actions/ui-actions';
import {
    openJourney
} from 'omni-shell-common/src/actions/journeys-actions';
import { addFavourite, removeFavourite } from '../../../../actions/canvas-actions';

class JourneysCategorySection extends Component {

    state = {
        collapsed : false
    };

    changeActiveJourney = (journeyId) => {
        const { setMenuOpen, openJourney, currentInteraction } = this.props;
        openJourney(journeyId, currentInteraction.instance_id);
        setMenuOpen(false);
    };

    closeMenu = (event) => {
        if (event.keyCode === 27) {
            const { setMenuOpen } = this.props;
            setMenuOpen(false);
        }
    };

    toggleVisibility = (category) => {
        const { setCollapsedCategories, collapsedCategories } = this.props;

        let newCollapsedCategories;
        var isCollapsed = collapsedCategories.get(category.titleKey);

        newCollapsedCategories = collapsedCategories.set(category.titleKey, !isCollapsed);
        setCollapsedCategories(newCollapsedCategories);
    };

    toggleFavourite = (journeyId, journeyI18nLabel, event) => {
        event.stopPropagation();
        const { i18nProvider, services, menuFavouritePrefs, addFavourite, removeFavourite } = this.props;
        let property;

        if (menuFavouritePrefs && menuFavouritePrefs.properties) {
            property = menuFavouritePrefs.properties.find(e => e.value == journeyId);
        }

        if (property) {
            services.UserPreferences.deleteProperty(menuFavouritePrefs.userId, property.preferenceId, property.id)
                .then(() => removeFavourite(journeyId))
                .catch(err => console.log('---- ERROR -----', '\n', 'There was a problem deleting a favourite.', '\n', err));
        } else {
            let newProperty = {
                name : journeyI18nLabel,
                description : 'The journey: ' + i18nProvider.Texti18n(journeyI18nLabel) + ' is currently on your favourites.',
                value : journeyId
            };
            services.UserPreferences.createProperty(menuFavouritePrefs.userId, menuFavouritePrefs.id, newProperty)
                .then(res => addFavourite(res.data))
                .catch(err => console.log('---- ERROR -----', '\n', 'There was a problem creating a new favourite.', '\n', err));
        }
    };

    render() {
        const { itemsToRender, journeyMenuViewMode, i18nProvider, currentInteraction, openJourneys, favouriteMenuJourneys, journeysManifest, entitlementsMapper, collapsedCategories, category, config, id } = this.props;
        return (
            <JourneysCategorySectionRender journeyMenuViewMode={journeyMenuViewMode}
                                           changeActiveJourney={this.changeActiveJourney}
                                           i18nProvider={i18nProvider}
                                           collapsed={collapsedCategories.get(category.titleKey)}
                                           toggleVisibility={this.toggleVisibility}
                                           currentInteraction={currentInteraction}
                                           openJourneys={openJourneys}
                                           category={category}
                                           journeysManifest={journeysManifest}
                                           favouriteMenuJourneys={favouriteMenuJourneys}
                                           toggleFavourite={this.toggleFavourite}
                                           entitlementsMapper={entitlementsMapper}
                                           configMenuFavourites={config.menuFavourites}
                                           itemsToRender={itemsToRender}
                                           id={id}
            />
        );
    }
}

const mapStateToProps = ({
                             i18n,
                             shell : { i18nProvider, services, menuFavouritePrefs, entitlementsMapper },
                             interactions : { currentInteraction },
                             canvas : { favouriteMenuJourneys, config },
                             ui : { journeyMenuViewMode, collapsedCategories },
                             journeys : { journeysCategoriesManifest, journeysManifest, openJourneys }
                         }) => ({
    journeyMenuViewMode,
    journeysCategoriesManifest,
    journeysManifest,
    currentInteraction,
    i18nProvider,
    openJourneys,
    i18n,
    services,
    favouriteMenuJourneys,
    collapsedCategories,
    config,
    menuFavouritePrefs,
    entitlementsMapper
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setMenuOpen,
        openJourney,
        addFavourite,
        removeFavourite,
        setCollapsedCategories
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(JourneysCategorySection);