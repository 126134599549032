import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./SubHeaderStyles";
import AppBar from "@ui-lib/core/AppBar";
import Toolbar from "@ui-lib/core/Toolbar";
import classNames from "@ui-lib/core/classNames";
import MenuIcon from "./MenuIcon";
import TaskSwitcherIcon from "./TaskSwitcherIcon";
//import ThreeSixtyHeader from "./ThreeSixtyHeader";
import JourneyActions from "./JourneyActions";
import { IEntitlementsVerifier } from "omni-shell-common/src/entitlements-verifier";
import JourneyInfo from "./JourneyInfo";

function SubHeaderRender({classes, type, entitlements, currentJourney, currentInteraction}) {
    let EntitlementsVerifier = IEntitlementsVerifier(React);
    let appBarClasses = type === "primary" ? classNames(classes.subHeaderAppBar, classes.subHeaderAppBarPrimary) : classes.subHeaderAppBar;
    let journeyComponents = currentJourney && currentJourney.subHeaderContent ? currentJourney.subHeaderContent.components : undefined;
    return (
        <div className={classes.root}>
            <AppBar position="relative" component={"div"} className={appBarClasses}>
                <Toolbar id='UfeSubHeader' className={classes.subHeaderToolbar}>
                    <EntitlementsVerifier entitlement={entitlements.subHeader.menu}>
                        <MenuIcon/>
                    </EntitlementsVerifier>

                    <EntitlementsVerifier entitlement={entitlements.subHeader.taskSwitcher}>
                        <TaskSwitcherIcon/>
                    </EntitlementsVerifier>
                    <JourneyInfo/>
                    {journeyComponents && journeyComponents.indexOf("dashboardWidgets") > -1 && <JourneyActions/>}
                    {/**currentInteraction.instance_id !== "default" && <ThreeSixtyHeader/> */}
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(SubHeaderRender);