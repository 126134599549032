import React, { Component } from "react";
import MenuPrivateModeRender from "./MenuPrivateModeRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setPrivateMode } from "omni-shell-common/src/actions/shell-actions";

class MenuPrivateMode extends Component {

    onClick = () => {
        const {sendEventHandler, setPrivateMode, isPrivate} = this.props;
        setPrivateMode(!isPrivate);
        sendEventHandler.setPrivateMode(!isPrivate);
    };

    render() {
        const {isPrivate, i18nProvider} = this.props;
        return (
            <MenuPrivateModeRender i18nProvider={i18nProvider} onClick={this.onClick} isPrivate={isPrivate}/>
        );
    }
}

const mapStateToProps = ({shell : {isPrivate, i18nProvider}, canvas : {sendEventHandler}}) => ({isPrivate, sendEventHandler, i18nProvider});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setPrivateMode
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(MenuPrivateMode);