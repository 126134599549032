const ComponentName = "UfeMenuSearch";
const styles = theme => ({
    root : {
        display : "inline-flex"
    },
    rootSearchOpen : {
        paddingBottom : "12px",
        width : "100%",
    },
    iconClear : {
        width : "16px",
        height : "16px",
        fontSize : "16px",
    },
    iconSearch : {
        width : "20px",
        height : "20px",
        fontSize : "20px"
    }
});

export { styles };
export { ComponentName };