const ComponentName = "UfeWidgetCard";
const styles = theme => ({
    card: {
        maxWidth: 240,
        backgroundColor : theme.palette.common.white,
        boxShadow : "0 2px 4px 0 rgba(0, 0, 0, 0.29)",
        borderRadius: 0,
        marginTop: 20,
        color: theme.palette.primary.main,
        transition: '0.3s',
        marginRight: 40,
        "&:hover" : {
            opacity: 0.8,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        }
      },
      divider:{
        backgroundColor : theme.palette.primary[100],
      },
      media: {
        height: 110,
        backgroundColor: theme.palette.grey[100]
      },
      subTitle:{
          marginTop: 10
      },
      selected:{
        backgroundColor : theme.palette.primary.main,
        color: theme.palette.common.white
      }
});

export { styles };
export { ComponentName };