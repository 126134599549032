import React, { Component } from "react";
import MenuIconRender from "./MenuIconRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setMenuOpen } from "../../../actions/ui-actions";

class MenuIcon extends Component {

    toggleMenu = () => {
        let {setMenuOpen, isMenuOpen} = this.props;
        setMenuOpen(!isMenuOpen);
    };

    render() {
        const {isMenuOpen, i18nProvider} = this.props
        return (
            <MenuIconRender i18nProvider={i18nProvider} toggleMenu={this.toggleMenu} isMenuOpen={isMenuOpen}/>
        );
    }
}

const mapStateToProps = ({
    shell : {i18nProvider}, i18n, ui : {isMenuOpen}}) => ({i18n, isMenuOpen, i18nProvider});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setMenuOpen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(MenuIcon);