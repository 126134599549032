import React, { Component } from "react";
import UserMenuRender from "./UserMenuRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setUserMenuOpen } from "../../../../actions/ui-actions";

class UserMenu extends Component {

    closeMenu = (event) => {
        const {setUserMenuOpen, buttonRef} = this.props;
        if (buttonRef && event && buttonRef.contains(event.target)) {
            return;
        }
        setUserMenuOpen(false);
    };

    render() {
        const {isUserWidgetMenuOpen, entitlementsMapper} = this.props;
        if (!isUserWidgetMenuOpen) {
            return (null);
        }
        return (
            <UserMenuRender closeMenu={this.closeMenu} entitlements={entitlementsMapper}/>
        );
    }
}

const mapStateToProps = ({ui : {isUserWidgetMenuOpen}, shell:{entitlementsMapper}}) => ({isUserWidgetMenuOpen, entitlementsMapper});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setUserMenuOpen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);