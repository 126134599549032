import React from 'react';
import { withStyles } from '@ui-lib/core/styles';
import { styles, ComponentName } from './QuickActionsStyles';
import GlobalSearchIcon from './GlobalSearchIcon';
import { IEntitlementsVerifier } from 'omni-shell-common/src/entitlements-verifier';
import { NotificationQuickAction } from 'notifications-ui';

function QuickActionsRender({ classes, entitlements }) {
    let EntitlementsVerifier = IEntitlementsVerifier(React);
    return (
        <div id='UfeQuickActions' className={classes.root}>
            <EntitlementsVerifier entitlement={entitlements.header.globalSearch}>
                <GlobalSearchIcon/>
            </EntitlementsVerifier>
            <EntitlementsVerifier entitlement={entitlements.notifications.notificationCenter}>
                <NotificationQuickAction/>
            </EntitlementsVerifier>
        </div>
    );
}

export default withStyles(styles, { name : ComponentName })(QuickActionsRender);