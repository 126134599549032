import {
    /**
     * Initial configurations
     */
    SET_CONFIG_MANIFEST,
    SET_JOURNEYS,
    /**
     * Basic journey actions
     */
    INITIALIZE_MAP_ENTRY,
    OPEN_JOURNEY,
    CHANGE_CURRENT_JOURNEY,
    CLOSE_JOURNEY,
    CLOSE_KEY_ENTRY,
    CHANGE_JOURNEY_PROPERTY,
    /**
     * Additional journey actions
     */
    SET_DIRTY_JOURNEY,
    SET_JOURNEY_SUBTITLE
} from "../actions/journeys-actions";
import { List, Map } from "immutable";

/**
 * WARNING ---- THIS IS THE REDUCER RESPONSIBLE FOR STORING ALL PROCESSES THAT WILL BE RENDERED
 * 
 * @constant {Map<String,List>} openJourneys Map with the a key String and a List (Immutable) of journeys as value:
 * Should always store the state of the openJourneys/processes in the application. 
 * In multiple strings can be used as keys (the developer may choose the values or use unique identifiers).
 * Two default keys entries will be used on the following cases: "default" value upon the usage of homePage and for string 
 * the first values of openJourneys. "unique" which will be used to store all the unique journeys in the application (
 * that maintain the same instance through all the application.) --- Example:
 * openJourney=> {"unique",List("Dashboard")},{"default",List("Journey1","Journey2")},{"y",List("Journey3")}
 * @constant {Map<String,List>} journeyHistory Similar to openJourneys it stores the history of which the journeys were open.
 * It uses the same system as the openJourneys and map and keys, with the exception of the "unique" default key value,
 * since the journeyHistory is attached to each entry individually. 
 * @constant {Object} currentJourney Object containing information about the currentJourney to be rendered
 * @constant {Object} startingJourney Object containing information about the homePage journey (if it exists)
 * @constant {Object} journeysManifest Object containing the full manifest of all the journeys that can be open and used in the application.
 * @constant {Object} journeysCategoriesManifest Object containing the categories of the Journeys
 * @constant {Object} iframeAttributes
 */

const INITIAL_STATE = {

    openJourneys: Map(),
    currentJourney: undefined,
    startingJourney: undefined,
    journeyHistory: Map(),

    iframeAttributes: {
        width: "100%",
        height: "100%",
        scrolling: "YES"
    },

    journeysManifest: undefined,
    journeysCategoriesManifest: undefined
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CONFIG_MANIFEST:
            return { ...state, journeysCategoriesManifest: action.configJourneys };

        case SET_JOURNEYS:
            var homePage = action.journeys.find(elem => elem.isHomePage && (elem.isHomePage === true || elem.isHomePage === "true"));
            if(!homePage || homePage.type !== "unique"){
                console.warn("--------------------------------WARNING-------------------","\n",
                "You have a configuration without an homepage defined or without type on that homepage.", "\n",
                "It is highly advised for at least one Customer Journey/Process to be homePage, containing the configuartion isHomePage true.", "\n",
                "Additionally that Customer Journey/Process should also have the configuration type unique, type: unique.","\n",
                "Please check the configuration files.");
            }

            if(!homePage){
                return { ...state, journeysManifest: action.journeys, journeyHistory: state.journeyHistory.set("default", List([])), 
                    openJourneys: state.openJourneys.set("default", List([])) };
            }

            /**
             * Copy a new homePage instance; Add it's intance_id; Set it as the currentJourney, the starting journey, and set the map on it's value
             */
            var defaultPage = Object.assign({}, homePage);
            defaultPage.instance_id = "_" + Math.random().toString(36).substr(2, 9);

            return {
                ...state, journeysManifest: action.journeys, startingJourney: defaultPage, currentJourney: defaultPage,
                openJourneys: state.openJourneys.set(defaultPage.type === "unique" ? "unique" : "default", List([defaultPage])), 
                journeyHistory: state.journeyHistory.set("default", List([defaultPage]))
            };

        case INITIALIZE_MAP_ENTRY:
            if (!action.key) {
                console.warn("-----WARNING-----","\n",
                    "There was an attempt to open a new interaction/context without a key value.", "\n",
                    "When developing a Canvas and using the action initializeMapEntry, a key should be provided");
                return state;
            }
            var uniqueJourneys = state.openJourneys.get("unique");
            
            /**
             * If there's no initial journey:
             * -Return the state without currentJourney; openJourneys with a new empty entry; new history entry with the uniques journeys already open;
             */
            if (!state.startingJourney) {
                return {
                    ...state, currentJourney: undefined, openJourneys: state.openJourneys.set(action.key, List([])),
                    journeyHistory: uniqueJourneys ? state.journeyHistory.set(action.key, List(uniqueJourneys)) : state.journeyHistory.set(action.key, List([]))
                };
            }

            /**
             * If there's an initial journey:
             * -If the type is unique: Set the currentJourney as the initial journey; Set the journey history of this new journey as the unique journeys (already contains the 
             * starting journey, since it's not closable).
             * -Otherwise set a new instance id to the staring journey (since they are 2 different instances) and set the currentJourney as this new starting journey, 
             * the openJourneys as a list with this new starting journey and the journey history as a concat of the unique journeys and this starting journey.
             */
            if (state.startingJourney.type === "unique") {
                return {...state, currentJourney : state.startingJourney, journeyHistory : state.journeyHistory.set(action.key, List(uniqueJourneys))};
            } else {
                var initialJourney = Object.assign({}, state.startingJourney);
                initialJourney.instance_id = "_" + Math.random().toString(36).substr(2, 9);
                let newHistory = uniqueJourneys ? List(uniqueJourneys.concat([initialJourney])) : List([initialJourney]);

                return {
                    ...state, currentJourney : initialJourney, journeyHistory : state.journeyHistory.set(action.key, newHistory),
                    openJourneys : state.openJourneys.set(action.key, List([initialJourney]))
                };
            }

        case OPEN_JOURNEY:
            /**
             * Check if the journey exists in the Manifest:
             * -If not return the state
             */
            var validateJourney = state.journeysManifest.find(elem => elem.id == action.id);
            if (!validateJourney){
                console.warn("----Warning (development)-------", "\n",
                    "There was an attempt to open a Journey with an Id that doesn't exist in the manifest!", "\n",
                    "Please check your configurations.");
                return state;
            }
                
            var journey = Object.assign({}, validateJourney);
            journey.instance_id = "_" + Math.random().toString(36).substr(2, 9);

            /**
             * Check if a key for the map was given (if not, set as no_key)
             * Check the type of the journey and insert it given it's unique (insertUniqueJourney) or otherwise (inserNonUniqueJourney)
             */
            action.key = !action.key ? "no_key" : action.key;
            if (action.initialArgs)
                journey.initialArgs = action.initialArgs;
            return journey.type === "unique" ? insertUniqueJourney(action.key, journey, state)
                : insertNonUniqueJourney(action.key, journey, state);

        case CHANGE_CURRENT_JOURNEY:
            /**
             * If no journey instance is provided it is assumed as if the journey is being closed and should move to the last one.
             * If the a key of the map is provided it's still a valid action, otherwise it will be an invalid operation.
             */
            if(!action.instance_id){
                if(action.key){
                    var history = state.journeyHistory.get(action.key);
                    return { ...state, currentJourney: history ? history.last() : undefined }
                } else {
                    console.warn("-----WARNING (Development)------","\n",
                        "When changing an active journey, an instance_id of the desired journey or key value of the entry map should be provided.","\n",
                        "No Journey will be changed and the state will remain the same.");
                    return state;
                }
            }
            
            /**
             * Retrieve the journey that should be changed to the current
             */
            var journey = action.key ? state.journeyHistory.get(action.key).find(elem => elem.instance_id === action.instance_id)
                : getJourneyWithoutKeyMap(state, action).journey;
            var key = action.key ? action.key : "no_key";

            if (journey) {
                var previousHistory = state.journeyHistory.get(key);
                var  i = previousHistory.findIndex(elem => elem.instance_id === action.instance_id);
                return { ...state, currentJourney: journey, journeyHistory: state.journeyHistory.set(key, (previousHistory.delete(i)).push(journey))};
            }

            return state;
        case CLOSE_JOURNEY:
            var journey,key;
            /**
             * Retrieve the Journey and key Values
             */
            ( { journey, key } = action.key ? getJourneyWithKeyMap(state, action.key, action) : 
                getJourneyWithoutKeyMap(state, action));

            /**
             * Added Special condition:
             * -When attempting to close a journey using the closeJourney API from the JourneyActions,
             * it should be possible to close the journey by it's journeyId instead of by the instanceId,
             * but if and only if, the type of the journey is context unique or unique. 
             */
            if(!journey){
                journey = state.journeyHistory.get(action.key).find(elem => elem.id === action.instance_id);
                if(!journey || journey.type !== "unique" || journey.type !== "context_unique"){
                    return state;
                }
                if(journey.type === "unique"){
                    key = "unique";
                }
            }

            /**
             * If the journey is not active or not closable, return the state.
             */
            if (!journey || journey.unclosable) {
                return state;
            }

            //Remove journey from the openJourneys List
            var previousOpenJourneys = state.openJourneys.get(key);
            var nextOpenJourneys = state.openJourneys.set(key,previousOpenJourneys.delete(previousOpenJourneys.findIndex(elem => elem.instance_id === journey.instance_id)));

            var nextHistory = Map();
            //If the journey type is unique, we need to remove it from all histories
            if (journey.type === "unique") {
                nextHistory = state.journeyHistory.map(value => value.delete(value.findIndex(elem => elem.instance_id === journey.instance_id)));
            } else {
                var previousHistory = state.journeyHistory.get(action.key);
                nextHistory = state.journeyHistory.set(action.key, previousHistory.delete(previousHistory.findIndex(elem => elem.instance_id === journey.instance_id)));
            }

            var nextJourney = nextHistory.get(action.key).last();

            return {
                ...state, currentJourney: nextJourney, journeyHistory: nextHistory,
                openJourneys: nextOpenJourneys
            };
        case CLOSE_KEY_ENTRY:
            if (!state.openJourneys.get(action.key) && !state.journeyHistory.get(action.key)) {
                console.error("You cannot close a key entry without a valid key.");
                return state;
            }
            return {
                ...state, currentJourney: state.journeyHistory.first().last(), openJourneys: state.openJourneys.delete(action.key),
                journeyHistory: state.journeyHistory.delete(action.key)
            };
        case SET_DIRTY_JOURNEY:
            return changePropertyOfJourney(state, action.instance_id, action.key, "isDirty", action.value);
        case SET_JOURNEY_SUBTITLE:
            return changePropertyOfJourney(state, action.instance_id, action.key, "subtitle", action.value);
        case CHANGE_JOURNEY_PROPERTY:
            return changePropertyOfJourney(state, action.instance_id, action.key, action.key_value, action.value);
        default:
            return state;
    }
};

/**
 * Auxiliar method to change the properties of a given journey.
 * @param {*} state State of the reducer at the time this function is called
 * @param {*} instance_id Identifier of the journey to be edited.
 * @param {*} keyValue Key value of the map where the journey is inserted.
 * @param {*} keyEntry Key value to input or edit on the journey
 * @param {*} value Value to input or edit on the journey.
 */
function changePropertyOfJourney(state, instance_id, keyValue, keyEntry, value) {
    let journey;
    let key;
    let returnObject = { ...state };

    /**
     * If a key was provided use the getJourneyWithKeyMap, otherwise use getJourneyWithoutKeyMap.
     * In both cases the correct journey and key should be returned.
     */
    ({ journey, key} = keyValue ? getJourneyWithKeyMap(state, keyValue, { instance_id })
        : getJourneyWithoutKeyMap(state, action));

    if (!journey) {
        return state;
    }

    /**
     * Update the value
     */
    journey[keyEntry] = value;

    /**
     * Update the currentJourney value (if it is the currentJourney)
     */
    if (state.currentJourney.instance_id === instance_id) {
        returnObject["currentJourney"] = journey;
    }

    let newHistory;
    /**
     * Change the values existing in the history
     * Validate if the value is unique, if it is, the entries need to be updated in all map histories
     */
    if(key === "unique"){
        newHistory = state.journeyHistory.map(journeysHistory => 
            journeysHistory.set(journeysHistory.findIndex(elem => elem.instance_id === journey.instance_id), journey));
    } else {
        let oldHistory = state.journeyHistory.get(key);
        newHistory = state.journeyHistory.set(key, oldHistory.set(
            oldHistory.findIndex(elem => elem.instance_id === journey.instance_id), journey));
    }

    returnObject["journeyHistory"] = newHistory;
    
    /**
     * Change the existing values in the openJourneys
     */
    let previousOpenJourneys = state.openJourneys.get(key);
    let i = previousOpenJourneys.findIndex(elem => elem.instance_id === journey.instance_id);
    let nextOpenJourneys = (previousOpenJourneys.delete(i)).insert(i, journey);

    returnObject["openJourneys"] = state.openJourneys.set(key, nextOpenJourneys);
    return returnObject;
}

/**
 * Auxiliar method to retrieve the pair journey, key  in which no key has been given.
 * @param {*} state State of the reducer at the time this function is called
 * @param {*} action Action conatining information about the instance_id of the journey.
 */
function getJourneyWithoutKeyMap(state, action) {
    let key = "";
    let journey;


    let auxJourney = state.openJourneys.get("unique").find(elem => elem.instance_id === action.instance_id);

    if (!auxJourney || auxJourney.size <= 0) {
        auxJourney = Object.assign({}, state.openJourneys.get("no_key").find(elem => elem.instance_id === action.instance_id));
        key = "no_key";
    } 

    if(!auxJourney)
        return {journey: null, key: null};

    journey = Object.assign({}, auxJourney);

    return { journey, key };
}

/**
 * Auxiliar method to retrieve the pair journey, key given an action with the instance_id of a journey.
 * @param {*} state State of the reducer at the time this function is called
 * @param {*} auxKey Key value of the map entry. 
 * @param {*} action Action conatining information about the instance_id of the journey.
 */
function getJourneyWithKeyMap(state, auxKey, action) {
    let key = auxKey;
    let journey;
    let auxJourney = undefined;

    let keyEntry = state.openJourneys.get(auxKey);

    if(!keyEntry || keyEntry.size <= 0) {
        keyEntry = state.openJourneys.get("unique");
        key = "unique";

        auxJourney = keyEntry.find(elem => elem.instance_id === action.instance_id);
    } else {
        auxJourney = keyEntry.find(elem => elem.instance_id === action.instance_id);
        if(!auxJourney){
            keyEntry = state.openJourneys.get("unique");
            if(keyEntry){
                key = "unique";
                auxJourney = keyEntry.find(elem => elem.instance_id === action.instance_id);
            }
        }
    }
    
    if(!auxJourney)
        return {journey: null, key: null};
    
    journey = Object.assign({}, auxJourney);
    return { journey, key };
}

/**
 * Auxiliar method to insert Unique Journeys in the key map.
 * Unique Journeys are different from others since they are the same instance through all instances and if open in one interaction, it will be available in all.
 * @param {*} key Key of the map of journeys
 * @param {*} journey Journey object of the unique journey to open
 * @param {*} state State of the reducer at the time this function is called
 */
function insertUniqueJourney(key, journey, state) {
    /**
     * Check if there's already an entry on the map with "unique"
     * If it doens't create a new List
     */
    let keyValue = state.openJourneys.get("unique");
    let alreadyOpen = undefined;
    if (!keyValue){
        keyValue = List();
    } else {
        alreadyOpen = keyValue.find(elem => elem.id === journey.id);
    }
        
    if (!alreadyOpen) {
        /**
         * A Unique journey needs to be added to all histories
         */
        let newHistory = state.journeyHistory.map(value => value.push(journey));
        return {
            ...state,
            currentJourney: journey,
            journeyHistory: newHistory,
            openJourneys: state.openJourneys.set("unique", keyValue.push(journey))
        };
    }

    /**
     * Even if the journey is already open, it should receive it's initial args
     */
    if (journey.initialArgs) {
        alreadyOpen.initialArgs = journey.initialArgs;
    }
    
    /**
     * This is needed to change the order of the of the journey history
     */
    let oldHistory = state.journeyHistory.get(key);
    let i = oldHistory.findIndex(e => e.instance_id === alreadyOpen.instance_id);
    return { ...state, currentJourney: alreadyOpen, journeyHistory: state.journeyHistory.set(key, (oldHistory.delete(i)).push(alreadyOpen)) };
}

/**
 * Auxiliar method  to inset Non Unique Journeys in the Key map.
 * Non unique journeys can have two types:
 * "context_unique": Only one can be open by interaction.
 * "default": Multiple instances can be open on the same interaction.
 * @param {*} key Key of the map of journeys
 * @param {*} journey Journey object to open
 * @param {*} state State of the reducer at the time this function is called
 */
function insertNonUniqueJourney(key, journey, state) {
    /**
     * Check if the key already exists in the openJourneys and journeyHistory map
     */
    var keyValue = state.openJourneys.get(key);
    var oldHistory = state.journeyHistory.get(key);
    if (!keyValue)
    keyValue = List();
    if (!oldHistory)
    oldHistory = List();

    /**
     * Check the amount of open instances
     */
    var countValues = keyValue.filter(elem => elem.id === journey.id).size;

    /**
     * If the value of openInstances is greater than 0 and the type is context_unique:
     * -Get the already existing instance
     * -Pass it the new initialArgs
     * -Change it's position on the journeyHistory
     * Otherwise if only the open instances is greater than 0 but without the type: context_unique:
     * -Set the subtitle with the count, allowing to know the difference between the multiple instances
     */
    if (countValues > 0 && journey.type === "context_unique") {
        let alreadyOpen = keyValue.find(elem => elem.id === journey.id);
        if (journey.initialArgs) {
            alreadyOpen.initialArgs = journey.initialArgs;
        }

        /**
        * This is needed to change the order of the of the journey history
        */
        let oldHistory = state.journeyHistory.get(key);
        let i = oldHistory.findIndex(e => e.instance_id === alreadyOpen.instance_id);
        return { ...state, currentJourney: alreadyOpen, journeyHistory: state.journeyHistory.set(key, (oldHistory.delete(i)).push(alreadyOpen)) };
    } else if (countValues > 0) {
        journey.subtitle = " (" + countValues + ")";
    }

    return {
        ...state,
        currentJourney: journey,
        journeyHistory: state.journeyHistory.set(key, oldHistory.push(journey)),
        openJourneys: state.openJourneys.set(key, keyValue.push(journey))
    };
}