import React, { Component } from "react";
import GlobalSearchOptionRender from "./GlobalSearchOptionRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

class GlobalSearchOption extends Component {

    static propTypes = {
        icon : PropTypes.string,
        mainText : PropTypes.node,
        subText : PropTypes.node,
        optionId : PropTypes.any,
        selected : PropTypes.bool,
        onMouseOver : PropTypes.func,
        title : PropTypes.string
    };

    onClick = () => {
        const {onClick, optionId} = this.props;
        onClick && onClick(optionId);
    };

    onMouseOver = () => {
        const {onMouseOver, optionId} = this.props;
        onMouseOver && onMouseOver(optionId);
    };

    render() {
        const {icon, mainText, subText, selected, title} = this.props;
        return (
            <GlobalSearchOptionRender
                onClick={this.onClick}
                onMouseOver={this.onMouseOver}
                icon={icon}
                mainText={mainText}
                subText={subText}
                selected={selected}
                title={title}
            />
        );
    }
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => bindActionCreators(
    {},
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearchOption);