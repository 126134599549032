const ComponentName = "UfeEditDashboardButton";
const styles = theme => ({
    root : {},
    icon : {
        fontSize : 16,
        marginRight : 10
    },
    button : {
        border : "1px solid",
        color : "inherit"
    }
});

export { styles };
export { ComponentName };