import React, { Component } from "react";
import OpenInteractionsMenuButtonRender from "./OpenInteractionsMenuButtonRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class OpenInteractionsMenuButton extends Component {

    render() {
        const { isInteractionsMenuOpen, setMenuState } = this.props;
        return (
            <OpenInteractionsMenuButtonRender isInteractionsMenuOpen={isInteractionsMenuOpen} setMenuState={setMenuState} />
        );
    }
}

const mapStateToProps = ({ }) => ({});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OpenInteractionsMenuButton);