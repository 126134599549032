import React, { Component } from "react";
import PanelRender from "./PanelRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setPanelOpen } from "../../actions/ui-actions";

class Panel extends Component {

    closePanel = (event) => {
        const {setPanelOpen, refToIgnore} = this.props;
        if (refToIgnore && event && refToIgnore.contains(event.target)) {
            return;
        }
        setPanelOpen(false);
    };

    render() {
        const {isPanelOpen, panel, i18nProvider} = this.props;
        if (!isPanelOpen) {
            return (null);
        }
        return (
            <PanelRender
                closePanel={this.closePanel}
                panel={panel}
                i18nProvider={i18nProvider}
            />
        );
    }
}

const mapStateToProps = ({
    i18n, ui : {isPanelOpen, panel, refToIgnore}, shell : {i18nProvider}}) => ({isPanelOpen, panel, refToIgnore, i18nProvider, i18n});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setPanelOpen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(Panel);