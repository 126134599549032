import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./UserInContextStyles";
import Avatar from './Avatar';
import UserName from './UserName';
import Timer from './Timer';
import { IEntitlementsVerifier } from "omni-shell-common/src/entitlements-verifier";

let EntitlementsVerifier = IEntitlementsVerifier(React);

function UserInContextRender({ classes, currentInteraction, onClick, entitlementsMapper }) {
    return (
        <div id='UfeUserInContext' onClick={onClick} className={currentInteraction.instance_id !== 'default' ? classes.root : classes.collapsed}>
            <Avatar />
            {currentInteraction.instance_id !== 'default' && <div>
                <UserName />
                <EntitlementsVerifier entitlement={entitlementsMapper.header.threesixtyTimer}>
                    <Timer currentInteraction={currentInteraction} />
                </EntitlementsVerifier>
            </div>}
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(UserInContextRender);