import * as consts from "../constants";
import { UserPreferencesNames } from "../config";
import { SET_THEME_READY, SET_LOGGED_USER_PREFS } from "./shell-actions";
import { SET_THEME_OVERRIDES, SET_THEME_TYPE, SET_THEME_DIRECTION } from "./../constants/theme";

export function getTheme(themeId, httpClient) {
    return function (dispatch, getState) {
        let canvasName = getState().shell.canvasName;
        return httpClient.get(getState().shell.contentUrl + `/foundation/v3/canvas/${canvasName}/themes/${themeId}/theme.json`).then(response => {
            if (response.status === 200) {
                dispatch({
                    type : consts.SET_THEME,
                    theme : {themeId : themeId, theme : response.data}
                });
                if (!getState().shell.themeReady) {
                    dispatch({
                        type : SET_THEME_READY,
                        payload : true
                    });
                }
                httpClient.get(getState().shell.contentUrl + `/foundation/v3/canvas/${canvasName}/themes/${themeId}/overrides.json`)
                    .then((res) => {
                        dispatch({
                            type : SET_THEME_OVERRIDES,
                            overrides : res.data
                        });
                    })
                    .catch((err) => {
                        //Setting overrides to empty object in case of an error to let canvas load(canvas should load even if overrides don't load )
                        dispatch({
                            type : SET_THEME_OVERRIDES,
                            overrides : {}
                        });
                    });
            }
        }, error => {
        });
    };
}

export function setThemeType(type, dontSetUserPrefs = false) {
    return function (dispatch, getState) {
        if (!dontSetUserPrefs) {
            let loggedUserPrefs = getState().shell.loggedUserPrefs;
            let currentLoggedUser = getState().shell.currentLoggedUser;
            if (getState().shell.services && loggedUserPrefs && loggedUserPrefs.properties && currentLoggedUser) {
                let userThemePref = loggedUserPrefs.properties.find((e) => e.name === UserPreferencesNames.THEME_NAME);
                if (userThemePref && userThemePref.value !== type) {
                    let themeProperty = {
                        name : UserPreferencesNames.THEME_NAME,
                        description : UserPreferencesNames.THEME_DESCRIPTION,
                        value : type
                    };
                    updateUserPrefs(getState().shell.services.UserPreferences, currentLoggedUser.id, loggedUserPrefs, userThemePref.id, themeProperty, dispatch);
                }
            }
        }
        dispatch({
            type : SET_THEME_TYPE,
            themeType : type
        });
    };
}

export function setThemeDirection(direction, dontSetUserPrefs = false) {
    return function (dispatch, getState) {
        if (!dontSetUserPrefs) {
            let loggedUserPrefs = getState().shell.loggedUserPrefs;
            let currentLoggedUser = getState().shell.currentLoggedUser;
            if (getState().shell.services && loggedUserPrefs && loggedUserPrefs.properties && currentLoggedUser) {
                let userThemePref = loggedUserPrefs.properties.find((e) => e.name === UserPreferencesNames.THEME_DIRECTION_NAME);
                if (userThemePref && userThemePref.value !== direction) {
                    let themeProperty = {
                        name : UserPreferencesNames.THEME_DIRECTION_NAME,
                        description : UserPreferencesNames.THEME_DIRECTION_DESCRIPTION,
                        value : direction
                    };
                    updateUserPrefs(getState().shell.services.UserPreferences, currentLoggedUser.id, loggedUserPrefs, userThemePref.id, themeProperty, dispatch);
                }
            }
        }
        dispatch({
            type : SET_THEME_DIRECTION,
            direction : direction
        });
    };
}

function updateUserPrefs(userPrefService, userId, userPreferences, propertyId, propertyContent, dispatch) {
    if (!userPrefService || !userId || !userPreferences || !propertyId || !propertyContent || !dispatch) {
        return;
    }
    userPrefService.updatePreference(userId, propertyContent, userPreferences.id, propertyId).then((res) => {
        let newLoggedUserPrefs = Object.assign({}, userPreferences);
        newLoggedUserPrefs.properties.forEach((e) => {
            if (e.id === res.data.id) {
                e.value = res.data.value;
            }
        });
        dispatch({
            type : SET_LOGGED_USER_PREFS,
            userPrefs : newLoggedUserPrefs
        });
    }).catch((err) => {

    });
};