import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./JourneyInformationStyles";
import Badge from "@ui-lib/core/Badge";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";

function JourneyInformationRender({ journeysCount, type, classes }) {

    let numberBadgeClasses = type === "primary" ? classNames(classes.numberBadge, classes.numberBadgePrimary) : classes.numberBadge;

    return (
        <Badge id='UfeJourneyInformationBadge' invisible={!journeysCount} max={99} badgeContent={journeysCount} classes={{ badge: numberBadgeClasses }}>
            <Icon className={`icon-openprocesses`} />
        </Badge>
    );
}

export default withStyles(styles, { name: ComponentName })(JourneyInformationRender);