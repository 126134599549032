/**
 * Created by NB22722 on 15/09/2017.
 */
import { BaseBridge, mapStateToProps, mapDispatchToProps } from "omni-shell-common/src/base-bridge";
import WebSpinner from "./components/Spinner/SpinnerRender";
import { connect } from "react-redux";

const IFrameBridgeFunc = (React,spinnerId) => class extends BaseBridge(React) {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            staticId: props.staticId
        }
    };

    /**
     * Method that stops the loading effect on top of the iframe
     */
    stopLoading = () => {
        const {setLoader} = this.props;
        setLoader(false);
        this.setState({isLoading: false});
    };

    /**
     * Method that starts the loading effect on top of the iframe
     */
    setLoading = (loaderType) => {
        const {setLoader} = this.props;
        !loaderType ? setLoader(true) : setLoader(true, "JOURNEY");
    };

    /**
     * When the component finishes mounting, it will add a listener message to the window triggered when a message is receieved.
     * It will call this.onReceiveMessage.
     */
    componentDidMount() {
        const {onLoad,initialArgs,contentVersion} = this.props;
        window.addEventListener("message", this.onReceiveMessage);

        this._iframe.onload = () => {
            this.sendMessage({action : "id_event", payload : this.iframe_id});
            onLoad && onLoad();
            this.webviewReady(contentVersion);
            initialArgs && this.setInitialArgs(initialArgs);
            this.setJourneyActive(true);
            this.setTheme()
        };
    };

    /**
     * When the component will unmount, the listener that was added for the bridge received events will be removed.
     */
    componentWillUnmount() {
        window.removeEventListener("message", this.onReceiveMessage);
    };

    sendMessage(data) {
        let { targetOrigin } = this.props;
        if (!targetOrigin)
            targetOrigin = "*";

        this && this._iframe && this._iframe.contentWindow.postMessage(JSON.stringify(data), targetOrigin);
    };

    render() {
        const {attributes,style,hostPort,src, title} = this.props;
        const {staticId} = this.state;
        const defaultAttributes = {
            allowFullScreen : false,
            frameBorder : 0
        };
        const mergedAttributes = {...defaultAttributes, ...attributes};
        const mergedStyles = {...{"WebkitOverflowScrolling": "touch", "overflowY": "auto", "overflow": "auto", "height": "100%"}, ...style};
        const url = (!src.startsWith("http") ? hostPort + src : src);

        return (
           <div style={mergedStyles}>
                {this.state.isLoading && <WebSpinner spinnerId={spinnerId} isIFrame={true} loaderType={"CANVAS"}/>}
                <iframe title={title} id={staticId} onLoad={this.stopLoading} ref={(f) => {
                    this._iframe = f;
                }} {...mergedAttributes} src={url} name="web_iframe"/>
            </div>
        );
    }
};

const hoc = (IFrameBridgeFunc, React,spinnerId) => connect(mapStateToProps, mapDispatchToProps, null, {forwardRef : true})(IFrameBridgeFunc(React,spinnerId));
export const IFrameBridge = (React, spinnerId) => hoc(IFrameBridgeFunc, React,spinnerId);
