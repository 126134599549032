import React, { Component } from "react";
import MenuLogoutRender from "./MenuLogoutRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

class MenuLogout extends Component {

    onClick = () => {
        const {authenticationHandlers} = this.props;
        authenticationHandlers.handleExplicitLogout();
    };

    render() {
        const {i18nProvider} = this.props;
        return (
            <MenuLogoutRender i18nProvider={i18nProvider} onClick={this.onClick}/>
        );
    }
}

const mapStateToProps = ({
    shell : {i18nProvider},
    canvas : {authenticationHandlers}}) => ({authenticationHandlers, i18nProvider});

const mapDispatchToProps = dispatch => bindActionCreators(
    {},
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuLogout));