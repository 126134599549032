class FileSystemProvider {

  constructor() {}

  download(base64,filename) {
    if(window.navigator && window.navigator.msSaveOrOpenBlob){
      let blob = b64toBlob(base64);
      window.navigator.msSaveOrOpenBlob(blob, filename);
    }else{
      let link = document.createElement('a');
      link.setAttribute("type","hidden");
      link.href = base64;
      link.download = filename;
      document.body.appendChild(link); //Firefox needs the link to be appended
      link.click();
      link.remove();
    }
  }
}

function b64toBlob(base64) {

  let base64String = "base64,";
  let b64Index = base64.indexOf(base64String) + base64String.length;
  let b64Data = base64.substring(b64Index, base64.length);

  let dataString = "data:";
  let contentTypeIndex = base64.indexOf(dataString) + dataString.length;
  let contentType = base64.substring(contentTypeIndex, base64.indexOf("base64") - 1);

  let sliceSize = 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

let fileSystemProvider = new FileSystemProvider();
module.exports = fileSystemProvider;
