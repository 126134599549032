import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./OpenInteractionsMenuStyles";
import MenuItem from "./MenuItem";
import ItemListContainer from "../../../../ShareComponents/ItemListContainer";
import OpenInteractionActions from "./OpenInteractionActions";

function OpenInteractionsMenuRender({ classes, interactionHistory, setMenuState, currentInteraction }) {
    var newInteractionHistory = interactionHistory.reverse();
    return (
        <ItemListContainer id='UfeOpenInteractionsMenu' Footer={OpenInteractionActions} clickAwayCallback={setMenuState} innerDivClass={classes.innerDivClass}
            outerDivClass={currentInteraction.instance_id !== 'default' ? classes.openInteraction : classes.interactionZero}>
            {newInteractionHistory.map((interaction, index) => {
                return <MenuItem setMenuState={setMenuState} id={index} interaction={interaction} />
            })}
        </ItemListContainer>
    );
}

export default withStyles(styles, { name: ComponentName })(OpenInteractionsMenuRender);