import React, { Component } from "react";
import ContextRender from "./ContextRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Context extends Component {

    render() {
        const { interactions, interactionsLimit } = this.props;
        return (
            <ContextRender interactions={interactions} interactionsLimit={interactionsLimit} />
        );
    }
}

const mapStateToProps = ({ canvas: { config: { threesixtyConfigs: { interactionsLimit } } }, interactions: { interactions } }) => ({ interactions, interactionsLimit });

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Context);