import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./MenuIconStyles";
import IconButton from "@ui-lib/core/IconButton";
import Icon from "@ui-lib/core/Icon";
import Fade from "@ui-lib/core/Fade";
import classNames from "@ui-lib/core/classNames";

function MenuIconRender({ classes, toggleMenu, isMenuOpen, i18nProvider }) {
    return (
        <div>
            {isMenuOpen ?
                <div id='UfeMenuIconOpened'>
                    <Fade in={isMenuOpen}>
                        <span onClick={toggleMenu} className={classes.menuOverlay} />
                    </Fade>
                    <div className={classes.rootSelect}>
                        <IconButton title={i18nProvider.Texti18n("menu_close")} onClick={toggleMenu}>
                            <Icon className={classNames("icon-close", classes.selectedIcon)} />
                        </IconButton>
                    </div>
                </div>
                :
                <IconButton id='UfeMenuIconClosed' title={i18nProvider.Texti18n("menu")} onClick={toggleMenu}>
                    <Icon className={classNames("icon-menu")} />
                </IconButton>
            }

        </div>
    );
}

export default withStyles(styles, {name: ComponentName,})(MenuIconRender);