import React, { Component } from "react";
import { connect } from "react-redux";
import { ThemeProvider } from "@ui-lib/core/styles";
import WebSpinner from "omni-shell-web/src/components/Spinner/SpinnerRender";

const customZIndex = {
    modal: 1600, // ClickAway iframe fix
    customNotifications: 2000
};

const withRoot = (InnerComponent) => class extends Component {

    render() {
        let {overrides, theme} = this.props;
        //dont load ufe if overrides request was not done
        if (!overrides || !theme) {
            return (<WebSpinner spinnerId={"withRootSpinner"} retryOn={false}/>);
        }
        let {contentUrl, hostPort, type, direction} = this.props;
        let settings = {contentUrl, hostPort, ...theme.canvasSettings};
        let themeOverrides = {...overrides, zIndex: {...customZIndex, ...overrides.zIndex}};
        return (
            <ThemeProvider type={type} direction={direction} theme={theme} themeOverrides={themeOverrides} settings={settings}>
                <InnerComponent {...this.props} />
            </ThemeProvider>
        );
    }
};

const mapStateToProps = ({theme: {theme, overrides, type, direction}, shell: {contentUrl, hostPort}}) => ({
    theme,
    overrides,
    contentUrl,
    hostPort,
    type,
    direction
});

export default (InnerComponent) => connect(mapStateToProps, undefined)(withRoot(InnerComponent));
