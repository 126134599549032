const ComponentName = "UfeContextSearch";
const styles = theme => ({
    root: {
    },
    rootSelected: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
    }
});

export {styles};
export {ComponentName};