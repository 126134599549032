const ComponentName = "UfeSearchActions";
const styles = theme => ({
    root : {
        width: '100%'
    },
    input : {
        display : "flex",
        padding : "10px 25px 10px 50px"
    },
    iconClear : {
        fontSize : 10,
        color : theme.palette.text.primary
    },
    iconSearch : {
        fontSize : 20,
        marginRight : 8
    },
    noContextButton : {
        padding : "13px 8px",
    }
});

export { styles };
export { ComponentName };