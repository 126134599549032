import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import { IFrameBridge } from "omni-shell-web";
import { setSendEventHandler } from "omni-ufe-common/src/actions/canvas-actions";

let MyFrameBridge = IFrameBridge(React, "journeyIframeSpinner");
let staticIdCount = 0;

class JourneyPlayer extends React.PureComponent {

    /**
     * Method to handle Journey Messages that are not known by the shell
     * @param data
     */
    handleBridgeRequest = (data, interaction_instance_id, instance_id) => {
        if (data && data.type === "journeyAction") {
            this.props.ReceivedEventsHandler.handleJourneyAction(data, interaction_instance_id, instance_id);
        } else if (data && data.type === "closeJourneyEvent") {
            let event = new CustomEvent("closeJourneyEvent" + data.id);
            window.dispatchEvent(event);
        } else if (data && data.type === "interactions") {
            this.props.ReceivedEventsHandler.handleInteractionAction(data);
        }
    };

    /**
     *
     */
    handleGlobalDispatcher = (data) => {
        this.props.SendEventHandler.handleGlobalDispatcher(data, this.props.currentInteraction.instance_id);
    };

    getJourneyPlayerContent = () => {
        var journeysToDisplay = [];
        let iFrames = {};
        var style = this.props.fullScreen ? {
            position: "absolute",
            height: "100%",
            width: "100%",
            left: "0",
            top: "0",
            zIndex: "99999999",
            display: "flex"
        } : { display: "flex", flexGrow: "1", height: "100%" };

        var refCallback = (iframe, interaction, current) => {
            this._iframe = iframe;
            if (!iframe)
                return;
            var interaction_key = !interaction ? "unique" : interaction.instance_id;

            if (!iFrames[interaction_key])
                iFrames[interaction_key] = [iframe];
            else
                iFrames[interaction_key] = iFrames[interaction_key].concat([iframe]);

            if (current) {
                this.props.ReceivedEventsHandler.setIFrame(iframe);
                this.props.SendEventHandler.setIFrame(iframe);
                this.props.setSendEventHandler(this.props.SendEventHandler);
            }
            this.props.SendEventHandler.setIframes(iFrames);
        };

        var onLoadCallback = (interaction, journey) => {
            this.props.SendEventHandler.setJourneySettings(this.props.currentJourney);
            this.props.SendEventHandler.setJourneyContext(this.props.currentInteraction, interaction.instance_id);
            this.props.SendEventHandler.setPrivateMode(this.props.isPrivate);
        };

        if (this.props.openJourneys.get("unique")) {
            this.props.openJourneys.get("unique").map(journey =>
                this.props.currentJourney && journey.instance_id === this.props.currentJourney.instance_id ?
                    journeysToDisplay.push(this.getIframeJourneyObject("unique", journey, style, (iframe) => refCallback(iframe, null, true), () => onLoadCallback("unique", journey), 0))
                    : journeysToDisplay.push(this.getIframeJourneyObject("unique", journey, { display: "none" }, (iframe) => refCallback(iframe), function () {
                    }, 0))
            );
        }

        this.props.interactions.map(interaction => {
            if (this.props.openJourneys.get(interaction.instance_id)) {
                staticIdCount++;
                this.props.openJourneys.get(interaction.instance_id) && this.props.openJourneys.get(interaction.instance_id).map(journey =>
                    this.props.currentJourney && journey.instance_id === this.props.currentJourney.instance_id ?
                        journeysToDisplay.push(this.getIframeJourneyObject(interaction.instance_id, journey, style, (iframe) => refCallback(iframe, interaction, true), () => onLoadCallback(interaction, journey), staticIdCount))
                        : journeysToDisplay.push(this.getIframeJourneyObject(interaction.instance_id, journey, { display: "none" }, (iframe) => refCallback(iframe, interaction), function () {
                        }, staticIdCount))
                );
            }
            return null;
        }
        );

        return journeysToDisplay;
    };

    getIframeJourneyObject(interaction_instance_id, journey, style, refCallback, onLoadCallback, staticId) {
        return (<MyFrameBridge
            key={journey.instance_id}
            title={"JoureyPlayer"}
            id={journey.instance_id}
            journeyId={journey.id}
            staticId={journey.i18NLabel + staticId.toString()}
            services={this.props.Services}
            attributes={this.props.iframeAttributes}
            ref={iframe => refCallback(iframe)}
            contentVersion={journey.contentVersion}
            onLoad={onLoadCallback}
            src={journey.journeyUrl}
            handleReceiveMessage={(data) => this.handleBridgeRequest(data, interaction_instance_id, journey.instance_id)}
            handleGlobalDispatcher={this.handleGlobalDispatcher}
            theme={this.props.currentTheme}
            language={this.props.i18n}
            widgets={this.props.widgets}
            style={style}
            authHandlers={this.props.AuthenticationHandlers}
            initialArgs={journey.initialArgs}
            setLoader={(isLoading, loaderType) => {
                this.props.setLoader(isLoading, loaderType);
            }} />);
    }

    render() {
        let journeysToDisplay = this.getJourneyPlayerContent();
        return (<React.Fragment>{journeysToDisplay}</React.Fragment>);
    }
}

JourneyPlayer.defaultProps = {};

const mapStateToProps = ({
    interactions: { interactions, currentInteraction },
    journeys: { currentJourney, openJourneys, iframeAttributes },
    canvas: { widgets },
    shell: { fullScreen, contentUrl, hostPort, isPrivate },
    i18n,
    theme: { theme }
}) =>
    ({
        interactions, currentInteraction,
        currentJourney, openJourneys, iframeAttributes,
        i18n, isPrivate, fullScreen, widgets, contentUrl, hostPort,
        currentTheme: theme
    });

const mapDispatchToProps = dispatch => bindActionCreators({
    setSendEventHandler
}, dispatch);

JourneyPlayer.propTypes = {
    interactions: PropTypes.object,
    widgets: PropTypes.array,
    isPrivate: PropTypes.bool,
    fullScreen: PropTypes.bool,
    contentUrl: PropTypes.string,
    hostPort: PropTypes.string,
    currentInteraction: PropTypes.object,
    iframeAttributes: PropTypes.object,
    i18n: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(JourneyPlayer);
