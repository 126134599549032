import React, { Component } from "react";
import ContextSearchRender from "./ContextSearchRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class ContextSearch extends Component {

    state = {
        isSearchContextMenuOpen: false
    };

    toggleSearchOpen = () => {
        this.setState((prevState) => ({ isSearchContextMenuOpen: !prevState.isSearchContextMenuOpen }));
    };

    render() {
        const { isSearchContextMenuOpen } = this.state;
        const { threesixtyConfigs } = this.props;

        return (
            <ContextSearchRender
                threesixtyConfigs={threesixtyConfigs}
                isSearchContextMenuOpen={isSearchContextMenuOpen}
                toggleSearchOpen={this.toggleSearchOpen}
            />
        );
    }
}

const mapStateToProps = ({ canvas: { config: { threesixtyConfigs } }, ui: { isSearchContextMenuOpen, refToIgnore }, interactions: { interactions } }) => ({ isSearchContextMenuOpen, refToIgnore, interactions, threesixtyConfigs });

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContextSearch);