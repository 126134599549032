import {
    ADD_WIDGETS,
    SET_CONTEXTS,
    SET_CONFIGURATION,
    SET_EDITABLE_WIDGETS_STATE,
    SET_SEND_EVENT_HANDLER,
    LOG_OUT,
    LOG_IN,
    SET_TIMEOUT,
    SET_USER_INFO_FIELDS,
    SET_AUTHENTICATED,
    SET_USER_PREFS_LOADED,
    SET_AUTHENTICATION_HANDLERS,
    SET_GLOBAL_SEARCH_SPEC,
    SET_MENU_FAVOURITE_JOURNEYS,
    ADD_FAVOURITE_JOURNEY,
    REMOVE_FAVOURITE_JOURNEY,
    SET_MENU_PREFS_LOADED
} from "../actions/canvas-actions";
import { List } from "immutable";

export const CANVAS_INITIAL_STATE = {
    
    // Widegts
    widgets : [],                              // to store the loaded widgets
    isWidgetsEditable : false,
    
    // Canvas configurations
    config : undefined,                      // canvas configuration properties. e.g. all the possible journeys that an user can use, if he is allowed to them.
    contexts : undefined,                    // configuration of the available contexts when an interaction with context is opened.
     
    // Logged user data and status
    isAuthenticated : false,
    userInfoFields : undefined,
    userPrefsLoaded : false,

    // the parameterization of what to get at typing on GlobalSearch
    globalSearchSpec : "",

    //Canvas custom events
    sendEventHandler : undefined,
    authenticationHandlers : undefined,
    favouriteMenuJourneys : List(),

    // to display the loading page
    renderLoginPage : true,
    blockUi : false, //Old
    saveTimeout : undefined,
    userPrefsLoaded : false,
    menuPrefsLoaded : false,

    // maximum amount of time that a journey have to save its data. 
    saveTimeout : undefined
};

export default (state = CANVAS_INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_INFO_FIELDS:
            return ({...state, userInfoFields : action.userInfoFields});
        case ADD_WIDGETS:
            return ({...state, widgets : action.widgets});
        case SET_CONTEXTS:
            return ({...state, contexts : action.contexts});
        case SET_CONFIGURATION:
            return {...state, config : action.payload};
        case SET_EDITABLE_WIDGETS_STATE:
            return {...state, isWidgetsEditable : action.isWidgetsEditable};
        case SET_SEND_EVENT_HANDLER:
            return {...state, sendEventHandler : action.payload};
        case SET_GLOBAL_SEARCH_SPEC:
            return {...state, globalSearchSpec : action.value};
        case LOG_OUT:
            return {...state, renderLoginPage : true};
        case LOG_IN:
            return {...state, renderLoginPage : false};
        case SET_TIMEOUT:
            return {...state, saveTimeout : action.timeout};
        case SET_AUTHENTICATED:
            return {...state, isAuthenticated : action.value};
        case SET_USER_PREFS_LOADED:
            return {...state, userPrefsLoaded : action.payload};
        case SET_AUTHENTICATION_HANDLERS:
            return {...state, authenticationHandlers : action.payload};
        case SET_MENU_FAVOURITE_JOURNEYS:
            return {...state, favouriteMenuJourneys : List(action.value)};
        case ADD_FAVOURITE_JOURNEY:
            return {...state, favouriteMenuJourneys: state.favouriteMenuJourneys.push(action.value)}
        case REMOVE_FAVOURITE_JOURNEY:
            let favourite = state.favouriteMenuJourneys.findIndex(elem => elem.value == action.id);
            return {...state, favouriteMenuJourneys: state.favouriteMenuJourneys.delete(favourite)}
        case SET_MENU_PREFS_LOADED:
            return {...state, menuPrefsLoaded: action.value}
        default:
            return state;
    }
};
