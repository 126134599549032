import React, { Component } from "react";
import JourneyActionDirtyModalRender from "./JourneyActionDirtyModalRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setJourneyActionDirtyModalOpen } from "../../../actions/ui-actions";

class JourneyActionDirtyModal extends Component {

    onButtonClicked = (buttonClicked) => {
        this.props.setJourneyActionDirtyModalOpen(false);
        this.props.sendEventHandler.handleDirtyModalResponse(buttonClicked)
    }

    onClose = () => {
        this.props.setJourneyActionDirtyModalOpen(false);
        this.props.sendEventHandler.handleDirtyModalResponse('close')
    }

    render() {
        let { i18nProvider, isJourneyActionDirtyModalOpen } = this.props;
        return (
            <JourneyActionDirtyModalRender
                isJourneyActionDirtyModalOpen={isJourneyActionDirtyModalOpen}
                onButtonClicked={this.onButtonClicked}
                onClose={this.onClose}
                maxWidth={"md"}
                fullWidth={true}
                i18nProvider={i18nProvider}
            />
        )
    }
}

const mapStateToProps = ({
    journeys: { currentJourney, journeyHistory, openJourneys },
    interactions: { currentInteraction, interactions },
    shell: { i18nProvider },
    ui: { isJourneyActionDirtyModalOpen },
    canvas: { sendEventHandler }
}) => ({
    currentJourney,
    journeyHistory,
    openJourneys,
    currentInteraction,
    interactions,
    isJourneyActionDirtyModalOpen,
    i18nProvider,
    sendEventHandler
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setJourneyActionDirtyModalOpen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(JourneyActionDirtyModal);