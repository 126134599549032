const ComponentName = "UfeSearchItemsContainer";
const styles = theme => ({
    root : {
        position : "absolute",
        top : 44,
        boxShadow : "0 4px 4px 0 rgba(0, 0, 0, 0.29)",
        borderRadius : 10,
        borderTopLeftRadius : 0,
        minHeight : 60,
        width : 424,
        backgroundColor : theme.palette.background.paper,
        zIndex : 2000
    },
    searchListResult : {
        maxHeight : 180,
        marginBottom : 5
    },
    footer : {
        textAlign : "center",
        borderTop : "1px solid",
        borderColor : theme.palette.grey[300],
        display : "flex",
        justifyContent : "space-around",
        flexFlow : "row wrap",
        alignItems : "stretch",
    },
    input : {
        display : "flex",
        padding : "10px 25px 10px 50px"
    },
    iconClear : {
        fontSize : 10,
        color : theme.palette.text.primary
    },
    iconSearch : {
        fontSize : 20
    },
    noContextButton : {
        color : theme.palette.text.primary,
        padding : "10px 20px 10px 20px",
        display : "flex",
        alignItems : "flex-start",
        borderRight : "1px solid",
        borderColor : theme.palette.grey[300],
        fontSize : 14,
        flex : 1
    },
    allResultsButton : {
        padding : "10px 20px 10px 20px",
        flex : 1
    },
    noContextButtonList : {
        margin : "10px 0px 10px 0px",
        justifyContent : "flex-start",
        paddingLeft:"43px"
    }
});

export { styles };
export { ComponentName };