import React from "react";
import PropTypes from "prop-types";
import Spinner from "omni-shell-common/src/components/Spinner";
import { connect } from "react-redux";

class WebSpinner extends React.Component {

    static propTypes = {
        spinnerId: PropTypes.string
    }

    static defaultProps = {
        spinnerId: "targetSpinner"
    }

    blockKeyboard = (event) => {
        const { shellDependencies } = this.props;
        event = event || window.event;
        if (shellDependencies.every((v) => v)) {
            // F keys
            const allowedKeys = /^(112|113|114|115|116|117|118|119|120|121|122|123)$/.test("" + event.keyCode);
            if (!allowedKeys) {
                event.preventDefault();
            }
        }
    };

    componentDidMount() {
        const { spinnerId } = this.props;
        window.addEventListener("keydown", this.blockKeyboard);
        const spinner = document.getElementById(spinnerId);
        if (spinner) {
            spinner.focus();
        }
    };

    componentWillUnmount() {
        window.removeEventListener("keydown", this.blockKeyboard);
    }

    render() {
        const { hostPort, contentUrl, retryOn, isIFrame, onRetryClick, spinnerId } = this.props;

        let styles = getStyles(isIFrame);
        return (
            <Spinner>
                <div style={styles.container}>
                    <input style={{ width: 0, height: 0, position: "absolute", top: 0, right: 0 }} id={spinnerId} />
                    {!retryOn ?
                        <div style={styles.viewContainer}>
                            <img src={hostPort + contentUrl + "/foundation/v3/canvas/ufe/assets/images/omni-spinner.gif"} alt="Spinner" height="42" width="42" />
                            <label style={styles.label}>
                                Loading ...
                            </label>
                        </div> :
                        <div style={styles.viewContainer}>
                            <button id={"button"} onClick={onRetryClick}>
                                Retry
                            </button>
                            <label style={styles.label}>
                                Fails loading dependencies
                            </label>
                        </div>}
                </div>
            </Spinner>
        );
    }
};

const mapStateToProps = ({ shell: { hostPort, contentUrl, shellDependencies }, theme: { theme }, utils: { isLoading } }) => ({ contentUrl, hostPort, shellDependencies, isLoading, theme });
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(WebSpinner);

const getStyles = (isIFrame) => {
    let spinnerStyle = {
        overlaySpinner: "rgba(35, 35, 35, 0.8)",
        overlayOpacity: 0.35,
        fundoSpinner: "transparent",
        textSpinner: "#ffffff"
    };

    return {
        container: {
            position: isIFrame ? "absolute" : "fixed",
            top: 0,
            left: 0,
            zIndex: isIFrame ? 10 : 99999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: spinnerStyle.overlaySpinner,
            opacity: spinnerStyle.overlayOpacity,
            width: "100%",
            height: "100%"
        },
        viewContainer: {
            position: "relative",
            backgroundColor: spinnerStyle.fundoSpinner,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            color: spinnerStyle.textSpinner,
            width: 300,
            height: 300,
            fontSize: 13
        },
        label: {
            marginTop: 20,
            fontFamily: "Arial, Verdana, sans-serif"
        }
    };
};
