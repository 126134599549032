import React, { Component } from "react";
import JourneyActionsRender from "./JourneyActionsRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IEntitlementsVerifier } from "omni-shell-common/src/entitlements-verifier";

let EntitlementsVerifier = IEntitlementsVerifier(React);

class JourneyActions extends Component {
    render() {
        const { entitlementsMapper, dashboardEditMode } = this.props;

        return (
            <EntitlementsVerifier entitlement={entitlementsMapper.subHeader.dashboardWidgets.addWidget}>
                <JourneyActionsRender dashboardEditMode={dashboardEditMode}/>
            </EntitlementsVerifier>
        );
    }
}
const mapStateToProps = ({ui: {dashboardEditMode}, shell: { entitlementsMapper } }) => ({ entitlementsMapper, dashboardEditMode });

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(JourneyActions);