import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./SearchItemsContainerStyles";
import SearchItem from './SearchItem'

function SearchItemsContainerRender({ classes, data, selected, onItemClick }) {

    return (
        <div id='UfeContextSearchItemsContainer' className={classes.searchListResult}>
            {data.map((el, index) => {
                return <SearchItem
                    optionId={index}
                    key={index}
                    id={index}
                    selected={selected === index}
                    icon={el.icon}
                    primaryName={el.primaryName}
                    secondaryName={el.secondaryName}
                    number={el.number}
                    onClick={() => onItemClick(el, index)} />
            })}
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(SearchItemsContainerRender);