import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./MenuSettingsStyles";
import MenuIconRoot from "../MenuIconRoot";

function MenuSettingsRender({onClick, i18nProvider}) {
    return (
        <MenuIconRoot
            id='UfeUserMenuIconMenuSettings'
            icon={"icon-services"}
            onClick={onClick}
            onClickClosePanel={true}
            title={i18nProvider.Texti18n("settings")}
        />
    );
}

export default withStyles(styles, {name : ComponentName})(MenuSettingsRender);