import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GlobalSearchIconRender from "./GlobalSearchIconRender";

class GlobalSearchIcon extends Component {
    render() {
        const {i18nProvider} = this.props;
        return (
            <GlobalSearchIconRender i18nProvider={i18nProvider}/>
        );
    }
}

const mapStateToProps = ({i18n, shell : {i18nProvider}}) => ({
    i18nProvider, i18n
});

const mapDispatchToProps = dispatch => bindActionCreators({},
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearchIcon);