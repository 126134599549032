import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./ClickAwayListenerStyles";
import ClickAwayListener from "@ui-lib/core/ClickAwayListener";
import classNames from "@ui-lib/core/classNames";

function ClickAwayListenerRender({classes, children, ...other}) {
    return (
        <React.Fragment>
            <div className={classNames(classes.root)}/>
            <ClickAwayListener {...other}>
                <div style={{zIndex : 1400}}>
                    {children}
                </div>
            </ClickAwayListener>
        </React.Fragment>
    );
}

export default withStyles(styles, {name : ComponentName})(ClickAwayListenerRender);