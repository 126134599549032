import React, { Component } from 'react';
import JourneyCardRender from './JourneyCardRender';
import PropTypes from 'prop-types';

class JourneyCard extends Component {

    static propTypes = {
        mode : PropTypes.string,
        badgeContent : PropTypes.number,
        icon : PropTypes.string,
        title : PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node
        ]).isRequired,
        subtitle : PropTypes.string,
        onClick : PropTypes.func,
        hover : PropTypes.string.isRequired,
        callArg : PropTypes.any,
    };

    static defaultProps = {
        mode : 'GRID',
        badgeContent : 0,
        icon : undefined,
        title : 'Journey title',
        hover : '',
        subtitle : undefined,
    };

    onClick = () => {
        const { onClick, callArg } = this.props;
        onClick && onClick(callArg);
    };

    render() {
        const { mode, badgeContent, icon, listIcon, title, subtitle, hover, onFavoriteClick, favouritesEntitlements, favourite, configMenuFavourites, taskSwitcher, callArg } = this.props;
        return (
            <JourneyCardRender id={callArg} configMenuFavourites={configMenuFavourites} favourite={favourite} favouritesEntitlements={favouritesEntitlements} taskSwitcher={taskSwitcher} mode={mode}
                               badgeContent={badgeContent} listIcon={listIcon} icon={icon} title={title} subtitle={subtitle} onClick={this.onClick} hover={hover} onFavoriteClick={onFavoriteClick}/>
        );
    }
}

export default JourneyCard;