import { Shell } from "omni-shell-common";
import LocalStorageProvider from "./providers/local-storage-provider";
import LoadingSpinner from "omni-shell-common/src/components/Spinner";
import DeviceInfoProvider from "./providers/device-info-provider";
import I18nProvider from "./providers/i18n-provider";
import FileSystemProvider from "./providers/file-system-provider";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    getTheme,
    setCanvasName,
    setRootProject,
    setShellDependency,
    setAuthenticationTokens,
    setLoggedUser,
    setThemeType,
    setThemeDirection
} from "omni-shell-common/src/actions";
import {
    setServices,
    setI18nProvider
} from "omni-shell-common/src/actions/shell-actions";
import WebSpinner from "./components/Spinner/SpinnerRender";

try {
    var RealTimeProvider = require("real-time-provider").RealTimeProvider;
} catch (err) {
    console.error("A valid real-time-provider was not found.");
}

try {
    var HttpClientProvider = require("http-client-provider").HttpClientProvider;
} catch (err) {
    console.error("A valid http-client-provider was not found. The app will not work.");
}

try {
    var NotificationsProvider = require("notifications-provider").NotificationsProvider;
} catch (err) {
    console.log("A client notifications module was not found.");
}

const ShellWeb = (React, InnerComponent, PocStore, CanvasName, RootProject) => class extends Shell(React) {

    constructor(props) {
        super(props, PocStore, WebSpinner);
        this.services.LocalStorage.setLocalStorageProvider(LocalStorageProvider);
        this.services.FileSystem.setFileSystemProvider(FileSystemProvider);

        this.HttpClientProvider = HttpClientProvider(PocStore, LocalStorageProvider);
        this.services.HttpClient.setHostAndContent("web-" + this.getOS());
        this.services.HttpClient.setHttpClientProvider(this.HttpClientProvider, "web-" + this.getOS());

        this.services.RealTime.setRealTimeProvider(new RealTimeProvider());
        this.services.RealTime.setStore(PocStore);

        this.services.DeviceInfo.setDeviceInfoProvider(DeviceInfoProvider);
        this.services.I18n.setI18nProvider(I18nProvider, this.services, this.services.DeviceInfo);
        this.services.I18n.initI18n(CanvasName, RootProject);
        this.HttpClientProvider.setI18nProvider(this.services.I18n);
        this.setInnerComponent(InnerComponent);
        this.setLoadingSpinner(LoadingSpinner);
        this.loadTheme = true;
        props.setCanvasName(CanvasName);
        props.setRootProject(RootProject);
        props.setI18nProvider(this.services.I18n.i18nProvider);

        try {
            this.services.Notifications.setNotificationsProvider(NotificationsProvider);
            this.services.Notifications.setHttpClient(this.services.HttpClient);
            this.services.Notifications.setRealTimeClient(this.services.RealTime);
            this.services.Notifications.setStore(PocStore);
        } catch (err) {
            console.log("A client notifications module was not found.", err);
        }
    }

    getOS() {
        let OSName = "Unknown OS";
        if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
        if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
        if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
        if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";

        return OSName;
    }
};

const mapStateToProps = ({i18n: {dictionaries}, shell: {shellDependencies, canvasDependencies}, theme: {theme, themeId}}) => ({
    dictionaries, shellDependencies, canvasDependencies,
    theme,
    currentThemeId: themeId
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getTheme,
        setCanvasName,
        setRootProject,
        setShellDependency,
        setAuthenticationTokens,
        setLoggedUser,
        setServices,
        setI18nProvider,
        setThemeType,
        setThemeDirection
    },
    dispatch
);

const hoc = (ShellWeb, React, InnerComponent, PocStore, CanvasName, RootProject) => connect(mapStateToProps, mapDispatchToProps)(ShellWeb(React, InnerComponent, PocStore, CanvasName, RootProject));
export const FinalShell = (React, UFE, PocStore, CanvasName, RootProject) => hoc(ShellWeb, React, UFE, PocStore, CanvasName, RootProject);
