const ComponentName = "UfeAddWidgetButton";
const styles = theme => ({
    root : {
        marginRight: 24
    },
    icon : {
        fontSize : 16,
        marginRight : 10
    },
    button : {
        border : "1px solid",
        color : "inherit"
    },
    editMode: {
        zIndex: 1300
    }
});

export { styles };
export { ComponentName };