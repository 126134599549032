import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./SearchListStyles";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";
import SearchItemsContainer from "./SearchItemsContainer";
import Button from '@ui-lib/core/Button';
import SearchField from "./SearchField";
import SearchActions from "./SearchActions";
import Typography from '@ui-lib/core/Typography';

function SearchListRender({ classes, searchValue, onChange, onKeyPress, onClearClick, searchClick, data, selected, onMouseOver, onItemClick, i18nProvider, addInteractionWithoutContext, toggleSearchOpen, valueSearched }) {
    return (
        <div id='UfeContextSearchList' className={classes.root}>
            <SearchField searchValue={searchValue} onChange={onChange} onKeyPress={onKeyPress} onClearClick={onClearClick} searchClick={searchClick} onItemClick={onItemClick} toggleSearchOpen={toggleSearchOpen} />
                <div className={classes.searchListResult}>
                    <SearchItemsContainer data={data} selected={selected} onMouseOver={onMouseOver} onItemClick={onItemClick} />
                </div>
            {data.length === 0 && valueSearched &&
                <div className={classes.noResultsList}>
                    <div className={classes.noResultsListRow}>
                        <Typography variant="body2">
                            {i18nProvider.Texti18n("open_360_no_results")} "{valueSearched}".
                        </Typography>
                    </div>
                    <div className={classes.noResultsListRow}>
                        <Typography variant="body2">
                            {i18nProvider.Texti18n("open_360_no_results_warning")}
                        </Typography>
                    </div>
                </div>
            }
            {data.length === 0 && !valueSearched ? (
                <Button id='UfeContextNoContextButton' fullWidth className={classes.noContextButtonList} onClick={addInteractionWithoutContext}>
                    <Icon className={classNames(`icon-sidemenu`, classes.iconSearch)} />
                    {i18nProvider.Labeli18n("open_360_without_context")}
                </Button>
            ) : <div className={classes.footer}>
                    <SearchActions valueSearched={valueSearched} data={data} addInteractionWithoutContext={addInteractionWithoutContext} />
                </div>}
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(SearchListRender);