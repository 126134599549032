import React, { Component } from "react";
import UserAvatarRender from "./UserAvatarRender";
import { connect } from "react-redux";

class UserAvatar extends Component {

    render() {
        const {hostPort, contentUrl} = this.props
        return (
            <UserAvatarRender
                hostPort={hostPort}
                contentUrl={contentUrl}
            />
        );
    }
}

const mapStateToProps = ({shell:{hostPort, contentUrl}}) => ({hostPort, contentUrl});

export default connect(mapStateToProps, undefined)(UserAvatar);