const ComponentName = "OpenInteractionsMenu";
// noinspection JSDuplicatedDeclaration
const styles = theme => ({
	innerDivClass: {
		top: '46px',
		width: '262px',
		height: '-webkit - fit - content',
		height: '-moz - fit - content',
		height: 'fit - content',
		height: 'fit-content'
	},
	interactionZero: {
		left: '-106px'
	},
	openInteraction: {
		left: '-242px',
	}
});

export { styles };
export { ComponentName };