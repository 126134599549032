const ComponentName = "UfeQuickActions";
const styles = theme => ({
    root : {
        display : "flex",
        marginLeft : "auto",
        marginRight : 18
    }
});

export { styles };
export { ComponentName };