const ComponentName = "UfeSearchField";
const styles = theme => ({
    root : {
    },
    input : {
        display : "flex",
        padding : "10px 25px 10px 50px"
    },
    iconClear : {
        fontSize : 10,
        color : theme.palette.text.primary
    },
    iconSearch : {
        fontSize : 20
    },
    iconsAlignRight : {
        left : 12
    }
});

export { styles };
export { ComponentName };