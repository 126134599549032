import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./MenuExpandAllStyles";
import IconButton from '@ui-lib/core/IconButton';
import Icon from '@ui-lib/core/Icon';

function MenuExpandAllRender({ classes, expandAll }) {
    return (
        <div className={classes.root}>
            <IconButton id='UfeMenuExpandAllButton' onClick={() => expandAll()}><Icon className={'icon-expand-all'}/></IconButton>
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(MenuExpandAllRender);