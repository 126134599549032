import React, { Component } from "react";
import AddButtonRender from "./AddButtonRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class AddButton extends Component {

    render() {
        const { threesixtyConfigs, toggleSearchOpen, interactions, isSearchContextMenuOpen } = this.props;

        return (
            <AddButtonRender
                interactions={interactions}
                threesixtyConfigs={threesixtyConfigs}
                toggleSearchOpen={toggleSearchOpen}
                isSearchContextMenuOpen={isSearchContextMenuOpen}
            />
        );
    }
}

const mapStateToProps = ({ canvas: { config: { threesixtyConfigs } }, interactions: { interactions } }) => ({ interactions, threesixtyConfigs });

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddButton);