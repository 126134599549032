
export const UserPreferencesURL = {
    ENDPOINT : "/bin/mvc.do/foundation/v3/preferences/",

    USER_PREFERENCES: (endpoint, userId, preferenceId, propertyId) => {
        if (propertyId && preferenceId) {
            return endpoint + 'users/' + userId + '/preferences/' + preferenceId + '/properties/'+ propertyId
        } else if (preferenceId){
            return endpoint + 'users/' + userId + '/preferences/' + preferenceId
        }
        return endpoint + 'users/' + userId + '/preferences';
    },

    QUERY_USER_PREFERENCES: (endpoint, userId, expression, preferenceId) => {
        if(preferenceId){
            return endpoint + 'users/' + userId +'/preferences/' + preferenceId + '/properties/query?' + expression
        }
        return endpoint + 'users/' + userId +'/preferences/query?'+ expression
    },

    CREATE_PREFERENCE: (endpoint, userId) => {
        return endpoint + 'users/' + userId + '/preferences'
    },

    CREATE_PROPERTY: (endpoint, userId, preferenceId) => {
        return endpoint + 'users/' + userId + '/preferences/' + preferenceId + '/properties'
    },

    UPDATE_PREFERENCE: (endpoint, userId, preferenceId, propertyId) => {
        if (propertyId && preferenceId) {
            return endpoint + 'users/' + userId + '/preferences/'+ preferenceId + '/properties/' + propertyId;
        }
        return endpoint + 'users/' + userId + '/preferences/' + preferenceId;
    },

    DELETE_PREFERENCE: (endpoint, userId, preferenceId) => {
      if(userId && preferenceId){
        return endpoint + 'users/' + userId + '/preferences/' + preferenceId;
      } else if(userId){
        return endpoint + 'users/' + userId + '/preferences'
      } return endpoint + 'users/' + userId + '/preferences/' + preferenceId;
    },

    DELETE_PROPERTY: (endpoint, userId, preferenceId, propertyId) => {
      if(userId && preferenceId && propertyId){
        return endpoint + 'users/' + userId + '/preferences/' + preferenceId + '/properties/' + propertyId;
      } else if(userId && preferenceId){
        return endpoint + 'users/' + userId + '/preferences/' + preferenceId + '/properties'
      } return endpoint + 'users/' + userId + '/preferences/' + preferenceId + '/properties/' + propertyId;
    }
};

export const UserPreferencesNames = {
    THEME_NAME : "themeType",
    THEME_DESCRIPTION: "Theme type user preference",
    I18N_NAME : "i18n",
    I18N_DESCRIPTION: "i18n user preference",
    JOURNEY_MENU_VIEW_MODE_NAME : "journeyMenuViewMode",
    JOURNEY_MENU_VIEW_MODE_DESCRIPTION: "Journey Menu view mode user preference",
    THEME_DIRECTION_NAME : "themeDirection",
    THEME_DIRECTION_DESCRIPTION: "Theme direction user preference",
};
