class EntitlementsVerifierService {

  constructor(){}

/**
 * [Sets the provider to use its mothods]
 * @param {[class]} entitlementsVerifierProvider [EntitlementsVerifierProviderCommon]
 */
  setEntitlementVerifierProvider(entitlementsVerifierProvider) {
      this.entitlementsVerifierProvider = entitlementsVerifierProvider;
  }

/**
 * [setStore used to call the provider's setStore method]
 * @param {[ReduxStore]} store [Store to be used]
 */
  setStore(store){
    return this.entitlementsVerifierProvider.setStore(store);
  }

/**
 * [validateEntitlements used to call the provider's validateEntitlements method]
 * @param  {[object]} entitlements [Entitlements to validate if allowed]
 * @return {[boolean]}
 */
  validateEntitlements(entitlements) {
    return this.entitlementsVerifierProvider.validateEntitlements(entitlements);
  }
  /**
 * [Makes a http request to get the entitlements and set the currentEntitlements.
 *  The URL resides in the shell store the property is entitlementsURL.
 * ]
 * @return {[promise]}
 */
  getEntitlementsByURL() {
    return this.entitlementsVerifierProvider.getEntitlementsByURL();
  }
}

const entitlementsVerifierService = new EntitlementsVerifierService();
export default entitlementsVerifierService;
