import React, { Component } from "react";
import ButtonsNavegationsWidgetRender from "./DiscardChangesButtonRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setDashboardEditMode } from "../../../../../actions/ui-actions";

class ButtonsNavegationsWidget extends Component {

    discardChanges = () => {
        //TODO: Implement Logic
        const { sendEventHandler, setDashboardEditMode } = this.props;
        setDashboardEditMode(false);
        sendEventHandler.handleDiscardLayout();
        sendEventHandler.handleEditableWidgets(false);
    };

    render() {
        const { i18nProvider, dashboardEditMode } = this.props
        return (
            <ButtonsNavegationsWidgetRender dashboardEditMode={dashboardEditMode} i18nProvider={i18nProvider} discardChanges={this.discardChanges} />
        );
    }
}

const mapStateToProps = ({ui: {dashboardEditMode}, i18n, shell: { i18nProvider }, canvas: { sendEventHandler } }) => ({ sendEventHandler, i18nProvider, i18n, dashboardEditMode });

const mapDispatchToProps = dispatch => bindActionCreators({
    setDashboardEditMode
},
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsNavegationsWidget);