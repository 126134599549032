import React, { Component } from "react";
import HeaderRender from "./HeaderRender";
import { connect } from "react-redux";

class Header extends Component {
    
    render() {
        return (
            <HeaderRender entitlementsMapper={this.props.entitlementsMapper} threesixtyConfigs={this.props.threesixtyConfigs}/>
        );
    }
}

const mapStateToProps = ({shell: {entitlementsMapper}, canvas: { config: { threesixtyConfigs }}}) => ({entitlementsMapper, threesixtyConfigs});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);