const ComponentName = "UfeAddWidget";
const styles = theme => ({
    iconClose : {
        marginLeft : 40
    },
    panelGrey : {
        backgroundColor : theme.palette.grey[100],
        padding : "24px 40px 24px 40px",
        marginBottom : 40
    },
    ButtonClear : {
        marginRight : 5
    },
    alignButtons : {
        display : "flex"
    },
    cardAlign : {
        display : "flex",
        flexWrap : "wrap"
    },
    container : {
        color : theme.palette.text.primary
    },
    categoriesContainer: {
        marginBottom: 20
    },
    titleContainer : {
        display : "inline-flex"
    },
    iconButtonClose : {
        float : "right",
        marginLeft : "50px"
    },
    iconClose : {
        height : "22px"
    }
});

export { styles };
export { ComponentName };