import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/**
 * Only renders an element. You should not use this to a block of multiple elements.
 * WARNING: be carefull with render on mouseouver and mouseleave.
 */
const PrivateModeVerifier = React => class extends React.Component {

	constructor(props) {
		super(props);
		/**
		 * [shouldRender will have true or false values depending on the
		 * canvas private mode being turned on or off]
		 * @type {[boolean]}
		 */
		let shouldRender = this.props.isPrivate;
		this.state = {
			r_render: shouldRender
		}
	}

	/**
	 * [The component will receive new props containing
	 * the private mode state, if the private mode
	 * changed then the r_render variable will be receiving
	 * a new value depending on the private mode state
	 * @param  {[type]} nextProps
	 * @return {[boolean]} [Sets the r_reder state to true of false]
	 */
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.isPrivate !== this.props.isPrivate)
			this.setState({ r_render: this.props.isPrivate})
	}
	/**
	 * Logic to render the component on Native and/or Web
	 */
	render() {
		return (
			this.state.r_render ? this.props.children : null
		);
	}
}

const mapStateToProps = ({shell: {isPrivate}}) => ({isPrivate});
const mapDispatchToProps = dispatch => ({});

PrivateModeVerifier.propTypes = {
    isPrivate : PropTypes.bool
};

PrivateModeVerifier.defaultProps = {
	isPrivate: false
};

const hoc = (PrivateModeVerifier, React, ReactNative) => connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(PrivateModeVerifier(React, ReactNative));
export const IPrivateModeVerifier = (React, ReactNative) => hoc(PrivateModeVerifier, React, ReactNative);
