import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LogoRender from "./LogoRender";
import { setCurrentInteraction } from '../../../actions/interactions-actions';

class Logo extends Component {

    logoClicked = () => {
        this.props.setCurrentInteraction("default");
    }

    render() {
        const {i18nProvider} = this.props;
        return (
            <LogoRender i18nProvider={i18nProvider} logoClicked={this.logoClicked}/>
        );
    }
}



const mapStateToProps = ({
    shell : {i18nProvider}
}) => ({
i18nProvider
});

const mapDispatchToProps = dispatch => bindActionCreators({ setCurrentInteraction },dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Logo);