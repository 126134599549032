import React from "react";
import Home from "./home";
import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";

/**
 * REDUCERS TO IMPORT
 */
import interactions from "omni-ufe-common/src/reducers/interactions";
import canvas from "omni-ufe-common/src/reducers/canvas";
import uiReducer from "omni-ufe-common/src/reducers/ui-reducer";

import { createStoreSingleton } from "omni-shell-common/src/store/store";
import { FinalShell } from "omni-shell-web";
import { canvasName, rootProject } from "../package.json";

let reducersToMerge = {
    interactions,
    canvas,
    ui : uiReducer
};
let Store = createStoreSingleton(reducersToMerge);

let HomeApp = FinalShell(React, Home, Store, canvasName, rootProject);

export default class App extends React.Component {

    render = () => {
        return (
            <Provider store={Store}>
                <BrowserRouter>
                    <Route path="/" component={HomeApp}/>
                </BrowserRouter>
            </Provider>
        );
    };
}
