/**
 * Created by NB22722 on 17/06/2019.
 */
const message = {
    type: '360',
    method: '',
    payload: undefined,
    id: -1,
    response: false
};

/**
 * Method to notify all 360's that the state of visibility changed 
 * 
 * @param {*} sendBridgeEvent 
 * @param {*} value 
 */
export const set360ToggleState = (sendBridgeEvent, value) => {
    let id = Math.random()*1000;
    message.method = "360ToggleState";
    message.payload = value;
    message.id = id;
    message.response = false;

    sendBridgeEvent(message);
}

/**
 * Bridge method to set the client on the 360. It will send the information to the 360 about the chosen client and will wait for the context.
 * 
 * @param {*} sendBridgeEvent 
 * @param {*} instance_id 
 * @param {*} payload 
 */
export const set360Client = (sendBridgeEvent, instance_id, payload) => {
    return new Promise(
        (resolve,reject) => {
            let id = Math.random()*1000;
            message.method = 'set360Client';
            message.payload = payload;
            message.id =  id;

            window.addEventListener(message.type+id, function set360ClientRequest(event) {
                let response = event.message;
                if(response.id === id){
                    if(response.result === 'error')
                        reject(response.payload);
                    else if(response.result === 'success'){
                        resolve(response.payload);
                    }
                    window.removeEventListener(message.type+id, set360ClientRequest);
                }
            }, false);

            sendBridgeEvent(message, instance_id);
        }
    );
}

/**
 * Bridge method to send a search to the 360. It will send the search payload to the 360 and wait for the response of the search.
 * 
 * @param {*} sendBridgeEvent 
 * @param {*} instance_id 
 * @param {*} search 
 */
export const send360Search = (sendBridgeEvent, instance_id, search) => {
    return new Promise(
        (resolve, reject) => {
            let id = Math.random()*1000;
            message.method = 'send360Search';
            message.payload = search;
            message.id = id;

            window.addEventListener(message.type+id, function send360SearchRequest(event) {
                let response = event.message;
                if(response.id === id){
                    if(response.result === 'error')
                        reject(response.payload);
                    else if(response.result === 'success'){
                        resolve(response.payload);
                    }
                    window.removeEventListener(message.type+id, send360SearchRequest);
                }
            }, false);

            sendBridgeEvent(message, instance_id);
        }
    )
}

export const close360View = (sendBridgeEvent, instance_id, payload) => {
    return new Promise(
        (resolve, reject) => {
            let id = Math.random()*1000;
            message.method = 'close360View';
            message.payload = payload;
            message.id = id;

            const send360CloseEvent = (event) => {
                let response = event.message;
                if(response.id === id){
                    if(response.result === 'error')
                        reject(response.payload);
                    else if(response.result === 'success'){
                        resolve(response.payload);
                    }
                    window.removeEventListener(message.type+id, send360CloseEvent);
                }
            }

            window.addEventListener(message.type+id, send360CloseEvent, false);
            
            sendBridgeEvent(message, instance_id);

            setTimeout(() => {                
                window.removeEventListener(message.type+id , send360CloseEvent);               
                reject("timeout");
            }, 5000);           
        }
    )
};
