import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./FooterStyles";
import Typography from "@ui-lib/core/Typography";

function FooterRender({classes,i18nProvider}) {
    return (
        <div className={classes.root}>
            <Typography varient={"caption"}>
                {i18nProvider.Labeli18n("footer_label")}
            </Typography>
        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(FooterRender);