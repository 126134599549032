import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./LogoStyles";

function LogoRender({ classes, i18nProvider, logoClicked, theme }) {
    return (
        <div id='UfeLogo' title={i18nProvider.Texti18n("product_name")} onClick={logoClicked} className={classes.logoRoot}>
            <img src={theme.customSettings.hostPort + theme.customSettings.contentUrl +
                "/foundation/v3/canvas/ufe/themes/default/images/" + theme.palette.type + "/logoHeader.svg"}
                height={"30px"}
            />
            <span className={classes.span}/>
        </div>
    );
}

export default withStyles(styles, { name: ComponentName, withTheme: true })(LogoRender);

