import React, { Component } from "react";
import ButtonsNavegationsWidgetRender from "./EditDashboardButtonRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setDashboardEditMode } from "../../../../actions/ui-actions";

class ButtonsNavegationsWidget extends Component {

    editDashboard = () => {
        const {setDashboardEditMode, sendEventHandler} = this.props;
        setDashboardEditMode(true);
        sendEventHandler.handleEditableWidgets(true);
    };

    render() {
        const {i18nProvider} = this.props
        return (
            <ButtonsNavegationsWidgetRender i18nProvider={i18nProvider} editDashboard={this.editDashboard}/>
        );
    }
}

const mapStateToProps = ({i18n, shell : {i18nProvider}, canvas: {sendEventHandler}}) => ({sendEventHandler, i18nProvider, i18n});
const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setDashboardEditMode
    },dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsNavegationsWidget);