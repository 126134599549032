import { fade } from "@ui-lib/core/styles/colorManipulator"

const ComponentName = "UfeMenuIcon";
const styles = theme => ({
    rootSelect: {
        height: 55, //SubHeader Height
        width: 48,
        marginTop: 6,
        borderTopLeftRadius: 14,
        borderTopRightRadius: 14,
        backgroundColor: theme.palette.grey[100],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid " + theme.palette.common.gre,
        borderBottomColor: theme.palette.grey[100],
        position: "relative",
        zIndex: 2000,
        marginBottom: "-2px"
    },
    selectedIcon: {
        color: theme.palette.text.primary,
        height: 16,
        width: 16,
        fontSize: 16,
    },
    menuOverlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "105px",
        zIndex: 1110,
        backgroundColor: fade(theme.palette.common.black, 0.7),
    },
});

export { styles };
export { ComponentName };