import React, { Component } from "react";
import JourneyInformationRender from "./JourneyInformationRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setTaskSwitcherModalOpen } from "../../../../actions/ui-actions";

class JourneyInformation extends Component {

    render() {
        const {journeyHistory, currentInteraction, currentJourney} = this.props;

        let type = currentJourney && currentJourney.subHeaderContent && currentJourney.subHeaderContent.style;
        type = type ? type : "primary";
        var journeysCount = journeyHistory.get(currentInteraction.instance_id) && journeyHistory.get(currentInteraction.instance_id).size;

        return (
            <JourneyInformationRender type={type} journeysCount={journeysCount}/>
        );
    }
}

const mapStateToProps = ({
 journeys : {journeyHistory, currentJourney}, 
 interactions : {currentInteraction}} ) => ({
    journeyHistory, currentInteraction, currentJourney
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setTaskSwitcherModalOpen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(JourneyInformation);