import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./JourneyInfoStyles";
import Typography from "@ui-lib/core/Typography";
import Icon from "@ui-lib/core/Icon";
import IconButton from "@ui-lib/core/IconButton";
import classNames from "@ui-lib/core/classNames";

function JourneyInfoRender({classes, currentJourney, i18nProvider, onClose, breadcrumbLevels, changeBreadcrumb}) {
    return (
        <div id='UfeJourneyInfo' className={classes.root}>
            {currentJourney &&
            <Typography className={classes.journeyTitle} variant={"h3"}>
                {<span className={classNames({[classes.breadcrumbClickable]: breadcrumbLevels})} onClick={() => {breadcrumbLevels ? changeBreadcrumb("root") : null}}>{i18nProvider.Labeli18n(currentJourney.i18NLabel)}</span>}
                {currentJourney.isDirty && "*"}
                {!currentJourney.breadcrumb && currentJourney.subtitle && <span className={classes.subtitleDivider}>/</span>}
                {!currentJourney.breadcrumb && currentJourney.subtitle}
                {!currentJourney.subtitle && currentJourney.breadcrumb && breadcrumbLevels && breadcrumbLevels.map((crumb,index) => {
                    let queryIndex = crumb.lastIndexOf("?");
                    return (
                        <React.Fragment key={crumb + index} >
                            <span className={classes.subtitleDivider}>/</span>
                            <span onClick={() => changeBreadcrumb(index)} className={classes.breadcrumbClickable}>{queryIndex === -1 ? i18nProvider.Labeli18n(crumb) : i18nProvider.Labeli18n(crumb.substring(0,queryIndex))}</span>
                        </React.Fragment>
                    )
                })}
                {!currentJourney.isHomePage &&
                <IconButton className={classes.iconButton} onClick={onClose}>
                    <Icon className={classNames("icon-close", classes.iconClose)}/>
                </IconButton>
                }
            </Typography>
            }

        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(JourneyInfoRender);