import React, { Component } from "react";
import NotificationsRender from "./NotificationsRender";

class Notifications extends Component {

    render() {
        const { dashboardEditMode} = this.props;
        return (
            <NotificationsRender dashboardEditMode={dashboardEditMode} />
        );
    }
}
export default Notifications;