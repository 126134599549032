import React from 'react';
import { ComponentName, styles } from './JourneysFavouriteCategorySectionStyles';
import { withStyles } from '@ui-lib/core/styles';
import JourneyCard from "../../../ShareComponents/JourneyCard";
import { MENU_LAYOUT_GRID } from "../../../../config";
import { IEntitlementsVerifier } from "omni-shell-common/src/entitlements-verifier";
import Collapse from '@ui-lib/core/Collapse';
import Typography from "@ui-lib/core/Typography";
import Icon from '@ui-lib/core/Icon';
import Button from '@ui-lib/core/Button';
import IconButton from '@ui-lib/core/IconButton';
import classNames from '@ui-lib/core/classNames';

function JourneysCategorySectionRender({ index, theme, classes, openJourneys, currentInteraction, journeyMenuViewMode, i18nProvider, changeActiveJourney, toggleFavourite, toggleVisibility, collapsed, favouriteMenuJourneys, journeysManifest, entitlementsMapper, configMenuFavourites, clearFavourites }) {
    let EntitlementsVerifier = IEntitlementsVerifier(React);

    return (
        <div id='UfeJourneyFavouriteCategorySection' className={collapsed ? classes.collapsed : classes.root}>
            <EntitlementsVerifier entitlement={entitlementsMapper.menuFavourites}>
                <React.Fragment>
                    <div className={classNames(classes.subtitleContainer, classes.categoryRoot)}>
                        <IconButton id='UfeJourneyFavouriteCategorySectionToggleCollapseButton' className={collapsed ? classes.toggleIcon : classes.collapsedCategory} onClick={() => toggleVisibility()}>
                            <Icon className={collapsed ? 'icon-chevron-down' : 'icon-chevron-up'} />
                        </IconButton>
                        <Typography variant="subtitle2">{i18nProvider.Texti18n("menu_favourites")}&nbsp;</Typography>
                        <Typography variant="overline" className={classes.countFont}>({favouriteMenuJourneys.size})</Typography>
                        <Button color={"primary"} className={classes.clearFavouritesButton} size={"large"} onClick={clearFavourites}><Typography variant="subtitle2">{i18nProvider.Texti18n("clear_all_favourites")}</Typography></Button>
                    </div>
                    <Collapse in={!collapsed}>
                        <div className={journeyMenuViewMode === MENU_LAYOUT_GRID ? classes.journeyContainerGrid : classes.journeyContainerList} xs={12}>
                            {favouriteMenuJourneys.size > 0 ? favouriteMenuJourneys.map(favourite => {
                                let journey = journeysManifest.find(e => e.id == favourite.value);

                                let countJourneyInstances = ((journeyId) => {
                                    if (!openJourneys)
                                        return 0;
                                    if (journey.type === "unique") {
                                        let uniqueJourneys = openJourneys.get("unique");
                                        return (uniqueJourneys && (uniqueJourneys.findIndex(j => j.id === journeyId) != -1)) ? 1 : 0;
                                    }
                                    if (journey.type === "context_unique") {
                                        let currentJourneys = openJourneys.get(currentInteraction.instance_id);
                                        return (currentJourneys && (currentJourneys.findIndex(j => j.id === journeyId) != -1)) ? 1 : 0;
                                    }

                                    let currentJourneys = openJourneys.get(currentInteraction.instance_id);
                                    return (currentJourneys && currentJourneys.size > 0) ? currentJourneys.count(journey => journey.id === journeyId) : 0;

                                })(journey.id);

                                return (
                                    <JourneyCard
                                        key={"favourite" + journey.id}
                                        callArg={journey.id}
                                        favourite={true}
                                        configMenuFavourites={configMenuFavourites}
                                        favouritesEntitlements={entitlementsMapper.menuFavourites}
                                        onClick={changeActiveJourney}
                                        badgeContent={countJourneyInstances}
                                        icon={journey.icon}
                                        listIcon={journey.listIcon}
                                        onFavoriteClick={(event) => toggleFavourite(journey.id, journey.i18NLabel, event)}
                                        title={i18nProvider.Texti18n(journey.i18NLabel)}
                                        hover={i18nProvider.Texti18n(journey.i18NLabel)}
                                        mode={journeyMenuViewMode === MENU_LAYOUT_GRID ? "GRID" : "LIST"}
                                    />
                                )
                            }) : <div className={classes.gridEmptyFavourites}>
                                    <div className={classes.imageDiv}>
                                        <img src={theme.customSettings.hostPort + theme.customSettings.contentUrl + "/foundation/v3/canvas/ufe/themes/default/images/" + theme.palette.type + "/empty_favourites.svg"}></img>
                                    </div>
                                    <div className={classes.emptyFavouritesText}>
                                        <Typography className={classes.emptyFavouritesHeaderText} variant="body2" noWrap>{i18nProvider.Labeli18n("empty_favourites")}</Typography>
                                        <Typography className={classes.emptyFavouritesSubHeaderText} variant="body2" noWrap>{i18nProvider.Labeli18n("empty_favourites_description")}</Typography>
                                    </div>
                                </div>
                            }
                        </div>
                    </Collapse>
                </React.Fragment>
            </EntitlementsVerifier>
        </div>
    )
}

export default withStyles(styles, { withName: ComponentName, withTheme: true })(JourneysCategorySectionRender)
