import { addNotification, removeNotification } from "./actions/notifications-actions";

const acceptedNotifications = ['error', 'info', 'success', 'warning'];

export default class NotificationHandler {
    constructor(Store) {
        this.Store = Store;
    }

    addNotification = (data) => {
        let payload = data.payload;
        let services = this.Store.getState().shell.services;
        let entitlementsMapper = this.Store.getState().shell.entitlementsMapper;
        if (!services.EntitlementsVerifier.validateEntitlements(entitlementsMapper.notifications.contextual)) {
            console.error("Entitlement for Contextual notifications not found");
            return
        }

        let notification = {};
        notification.key = Math.random() * 1000;
        notification.message = payload.content;

        notification.timeout = isNaN(parseInt(payload.timeout)) ? "" : payload.timeout;
        notification.severity = payload.level && acceptedNotifications.indexOf(payload.level.toLowerCase()) > -1 ? payload.level.toLowerCase() : "info";

        this.Store.dispatch(addNotification(notification));
        if ((payload.timeout === 0 || payload.timeout) && payload.timeout !== "*" && payload.timeout !== "") {
            setTimeout(() => this.Store.dispatch(removeNotification(notification.key)), parseInt(payload.timeout));
        }
    };
}