const ComponentName = "UfeMenuIconRoot";
const styles = theme => ({
    root : {
        backgroundColor : theme.palette.grey[900],
        display : "block",
        marginBottom : "18px",
        borderRadius : "50%",
        boxShadow : "0 1px 1px 0 rgba(51, 51, 51, 0.25)"

    },
    button : {
        "&:hover" : {
            backgroundColor : theme.palette.type === "light" ? `rgba(255, 255, 255, 0.2)` : `rgba(255, 255, 255, ${theme.palette.action.hoverOpacity})`
        }
    },
    icon : {
        color : theme.palette.common.white,
        fontSize : "24px",
        width : "24px",
        height : "24px"
    }
});

export { styles };
export { ComponentName };