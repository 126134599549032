class DeviceInfoService {
  constructor(){}

  setDeviceInfoProvider(deviceInfoProvider) {
      this.deviceInfoProvider = deviceInfoProvider;
  }

  getDeviceLocale() {
    return this.deviceInfoProvider.getDeviceLocale();
  }

}

const deviceInfoService = new DeviceInfoService();
export default deviceInfoService;
