import React, { Component } from "react";
import SearchActionsRender from "./SearchActionsRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class SearchActions extends Component {

    render() {
        const { addInteractionWithoutContext, data, i18nProvider, valueSearched } = this.props;
        return (
            <SearchActionsRender
                i18nProvider={i18nProvider}
                data={data}
                addInteractionWithoutContext={addInteractionWithoutContext}
                valueSearched={valueSearched}
            />
        );
    };
};

const mapStateToProps = ({ shell: { i18nProvider } }) =>
    ({ i18nProvider });

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchActions);