export const Config = {
    API_VERSION: '1.0',
    IS_AUTHENTICATED: false,
    SETTINGS_URL: (runMode) => {
        return '/foundation/v3/canvas/ufe/config/_' + runMode + '_settings.json'
    },
    THEME_URL: '/foundation/v3/canvas/ufe/config/themes.json',
    ENTITLEMENTS_URL: '/bin/mvc.do/foundation/v3/eam/entitlement',
    AUTHENTiCATION_URL: '/bin/mvc.do/foundation/v3/oauth/authorize',
    REFRESH_AUTHENTICATION_URL: '/bin/mvc.do/foundation/v3/oauth/token',
    LOG_OUT_URL: '/bin/mvc.do/foundation/v3/oauth/logout',
    LOCAL_STORAGE: {
      LOGGED_USER_ENTITLEMENTS: "logged_user_entitlements",
      CURRENT_LOGGED_USER: "currentLoggedUser",
      ACCESS_TOKEN: "accessToken",
      REFRESH_TOKEN: "refreshToken"
    },
    CLOSE_JOURNEY_SCOPE: {
      CURRENT_JOURNEY: "currentJourney",
      CURRENT_INTERACTION: "currentInteraction",
      MENU_INTERACTION: "menuInteraction",
      ALL_INTERACTIONS: "allInteractions",
      EXPLICIT_LOGOUT: "explicitLogout"
    }

};

export const MENU_LAYOUT_GRID = "MENU_LAYOUT_GRID";
export const MENU_LAYOUT_LIST = "MENU_LAYOUT_LIST";
