import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./MenuSearchStyles";
import TextField from "@ui-lib/core/TextField";
import InputAdornment from "@ui-lib/core/InputAdornment";
import IconButton from "@ui-lib/core/IconButton";
import Icon from "@ui-lib/core/Icon";
import classNames from "@ui-lib/core/classNames";

function MenuSearchRender({classes, isSearchOpen, toggleSearchOpen, changeSearchValue, searchValue, clearSearch, i18nProvider, textFieldOnFocus}) {
    let rootClasses = isSearchOpen ? classNames(classes.root, classes.rootSearchOpen) : classes.root;
    return (
        <div id='UfeMenuSearch' className={rootClasses}>
            {isSearchOpen ?
                <React.Fragment>
                    {/*Fix for unwanted auto complete in chrome*/}
                    <input style={{display : "none", width : 0, height : 0}} type="text" name="username" id={"username"}/>
                    <input style={{display : "none", width : 0, height : 0}} type="password" name="password" id="password"/>
                    {/* End Fix*/}
                    <TextField placeholder={i18nProvider.Texti18n("filter_journeys")}
                               value={searchValue}
                               fullWidth={true}
                               autoComplete={"off"}
                               name={"filter_journeys_menu"}
                               id={"filter_journeys_menu"}
                               autoFocus={true}
                               onChange={changeSearchValue}
                               onFocus={textFieldOnFocus}
                               InputProps={{
                                   endAdornment : (
                                       <InputAdornment position="start">
                                           {searchValue  && <IconButton onClick={clearSearch} title={i18nProvider.Texti18n("clean_journeys")}>
                                               <Icon className={classNames(`icon-close`, classes.iconClear)}/>
                                           </IconButton>}
                                             <IconButton onClick={toggleSearchOpen} title={i18nProvider.Texti18n("close")}>
                                               <Icon className={classNames(`icon-search`, classes.iconSearch)}/>
                                           </IconButton>
                                       </InputAdornment>
                                   )
                               }}/>
                </React.Fragment>
                :
                <IconButton id='UfeMenuSearchButton' onClick={toggleSearchOpen} title={i18nProvider.Texti18n("search_journeys")}>
                    <Icon className={`icon-search`}/>
                </IconButton>
            }

        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(MenuSearchRender);