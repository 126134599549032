import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./SettingsPanelStyles";
import Grid from "@ui-lib/core/Grid";
import Selector from "@ui-lib/custom-components/Selector";
import Select from "@ui-lib/core/Select";
import MenuItem from "@ui-lib/core/MenuItem";
import InputLabel from "@ui-lib/core/InputLabel";
import FormControl from "@ui-lib/core/FormControl";
import { IEntitlementsVerifier } from "omni-shell-common/src/entitlements-verifier";


function SettingsPanelRender(
    {
        classes, optionsDirection,
        optionsType, selectedType, selectedDirection, onTypeClick,
        onDirectionClick, currentLanguage, languages, onLanguageChange,
        openSelect, selectOpen, closeSelect, i18nProvider, entitlements
    }
) {
    let EntitlementsVerifier = IEntitlementsVerifier(React);
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="stretch"
            className={classes.root}
            spacing={40}
        >
            <Grid item xs={3} />
            <Grid item xs={3}>
                <EntitlementsVerifier entitlement={entitlements.header.userMenu.themeType}>
                    <Selector
                        label={i18nProvider.Labeli18n("theme_type")}
                        options={optionsType}
                        selectedValue={selectedType}
                        onClick={onTypeClick}
                        fullWidth={true}
                    />
                </EntitlementsVerifier>
            </Grid>
            <Grid item xs={3}>
                <EntitlementsVerifier entitlement={entitlements.header.userMenu.direction}>
                    <Selector
                        label={i18nProvider.Labeli18n("ufe_direction")}
                        options={optionsDirection}
                        selectedValue={selectedDirection}
                        onClick={onDirectionClick}
                        fullWidth={true}
                    />
                </EntitlementsVerifier>
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3} />
            <Grid item xs={3}>
                <FormControl fullWidth={true} className={classes.languageContainer}>
                    <InputLabel htmlFor="age-simple">{i18nProvider.Labeli18n("ufe_language")}</InputLabel>
                    <Select
                        value={currentLanguage}
                        fullWidth={true}
                        onOpen={openSelect}
                        onClose={closeSelect}
                        onChange={onLanguageChange}
                        open={selectOpen}
                    >
                        {languages.map((e, i) => {
                            return <MenuItem key={i} style={{zIndex : 1500}} value={e.code}>{e.name}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3} />
        </Grid>

    );
}

export default withStyles(styles, { name: ComponentName })(SettingsPanelRender);