import React from "react";

const EditModePlaceHolder = () => {
    let style = {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1110,
        display: "flex",
        opacity: 0.7,
        backgroundColor: "black",
        width: "100%",
        height: "100%"
    }

    return (
        <div style={style}></div>
    );
};

export default EditModePlaceHolder;
