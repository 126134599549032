class GeoLocationWatcherService {

    constructor(){}
  
    setGeoLocationWatcherProvider(geoLocationWatcherProvider) {
        this.geoLocationWatcherProvider = geoLocationWatcherProvider;
    }
  
    setWatcher(){
      return this.geoLocationWatcherProvider.setWatcher();
    }

    stopWatcher(){
      return this.geoLocationWatcherProvider.stopWatcher();
    }
  
    setStore(store){
      return this.geoLocationWatcherProvider.setStore(store);
    }
  }
  
  const geoLocationWatcherService = new GeoLocationWatcherService();
  export default geoLocationWatcherService;
  