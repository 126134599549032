import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserNameRender from "./UserNameRender";

class UserName extends Component {

    render() {
        return (
            <UserNameRender currentInteraction={this.props.currentInteraction} />
        );
    }
}

const mapStateToProps = ({ interactions: { currentInteraction } }) => ({ currentInteraction });
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserName);