import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./GlobalSearchIconStyles";
import GlobalSearchPanel from "../../../Panels/GlobalSearchPanel";
import RootQuickActionIcon from "../RootQuickActionIcon";

function GlobalSearchIconRender({i18nProvider}) {
    return (
        <RootQuickActionIcon id='UfeGlobalSearchIcon' title={i18nProvider.Texti18n("search_button")} component={<GlobalSearchPanel/>} icon={"icon-search"}/>
    );
}

export default withStyles(styles, {name : ComponentName})(GlobalSearchIconRender);