import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./OpenInteractionsMenuButtonStyles";
import Icon from '@ui-lib/core/Icon';
import classNames from "@ui-lib/core/classNames";

function OpenInteractionsMenuButtonRender({ classes, setMenuState, isInteractionsMenuOpen }) {
    return (
        <div id='UfeOpenInteractionsMenuButton' className={classNames(classes.root, isInteractionsMenuOpen ? classes.arrow : '')} onClick={setMenuState}>
            <Icon className={classNames("icon-chevron-down", classes.icon)} />
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(OpenInteractionsMenuButtonRender);