import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./UserAvatarStyles";
import Avatar from "@ui-lib/core/Avatar";

function UserAvatarRender({classes, hostPort, contentUrl}) {
    return (
        <div id='UfeUserAvatar' className={classes.root}>
            <Avatar alt="Remy Sharp" src={hostPort + contentUrl + "/foundation/v3/canvas/ufe/assets/images/person.svg"} className={classes.avatar} />
        </div>
    );
}

export default withStyles(styles, {name : ComponentName})(UserAvatarRender);