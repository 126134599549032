const ComponentName = "UfeGlobalSearchPanel";
const styles = theme => ({
    root : {
        height : "100%",
    },
    iconSearch : {
        width : "20px",
        height : "20px",
        fontSize : "20px"
    },
    optionsContainer : {
        position : "relative",
        maxHeight : "266px",
        overflowY : "auto"
    },
    input : {
        position : "absolute",
        top : "20%",
        left : "50%",
        width : "415px",
        marginLeft : "-208px"
    }
});

export { styles };
export { ComponentName };