import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./ContextSearchStyles";
import SearchList from "./SearchList";
import AddButton from "./AddButton"
import ClickAwayListener from "../../../ShareComponents/ClickAwayListener";

function ContextSearchRender({ classes, toggleSearchOpen, isSearchContextMenuOpen }) {
    let rootClasses = isSearchContextMenuOpen ? classes.rootSelected : '';
    if (!isSearchContextMenuOpen) {
        return <div id='UfeContextSearchMenuClosed' className={rootClasses}>
                <AddButton toggleSearchOpen={toggleSearchOpen} />
        </div>;
    } else
        return (
            <ClickAwayListener onClickAway={toggleSearchOpen}>
                <div id='UfeContextSearchMenuOpen' className={rootClasses}>
                    <AddButton isSearchContextMenuOpen={isSearchContextMenuOpen} toggleSearchOpen={toggleSearchOpen} />
                    <SearchList toggleSearchOpen={toggleSearchOpen} />
                </div>
            </ClickAwayListener>
        );
}

export default withStyles(styles, { name: ComponentName })(ContextSearchRender);
