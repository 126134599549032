import React, { Component } from "react";
import ContextContainerRender from "./ContextContainerRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class ContextContainer extends Component {

    state = {
        isInteractionsMenuOpen: false
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.interactionsHistory.size !== this.props.interactionsHistory.size && this.props.interactionsHistory.size === 0)
            this.setState({ isInteractionsMenuOpen: false })
            
        if (prevProps.currentInteraction.instance_id !== this.props.currentInteraction.instance_id)
            this.setState({ isInteractionsMenuOpen: false })
    }

    setMenuState = () => {
        this.setState({ isInteractionsMenuOpen: !this.state.isInteractionsMenuOpen })
    };

    render() {
        const { isInteractionsMenuOpen } = this.state;
        return (
            <ContextContainerRender interactionsHistory={this.props.interactionsHistory} setMenuState={this.setMenuState} isInteractionsMenuOpen={isInteractionsMenuOpen} />
        );
    }
}

const mapStateToProps = ({ interactions: { interactionsHistory, currentInteraction } }) => ({ interactionsHistory, currentInteraction });
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ContextContainer);