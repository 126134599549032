const ComponentName = 'UFEBase';
const styles = theme => ({
    root : {
        height : '100%',
        display : 'flex',
        flexDirection : 'column'
    },
    journeysContainer : {
        overflowY : 'auto'
    },
    editMode : {
        zIndex : 1120,
        position : 'relative'
    },
    invisible360 : {
        display : 'grid',
        height : '-webkit-fill-available !important',
        gridTemplateRows : '60px auto',
        gridTemplateColumns : 'auto auto 0px',
        '-ms-grid-columns' : '1fr',
        '-ms-grid-rows' : 'auto 60px 1fr 0px auto',
        gridTemplateAreas : '\'subheader subheader threesixty\' \'journeyplayer journeyplayer threesixty\''
    },
    firefoxFix : { //Fix for ie and firefox
        height : '100%'
    },
    fullScreenJourney : {
        zIndex : 999999
    },
    visible360 : {
        display : 'grid',
        height : '-webkit-fill-available !important',
        gridTemplateRows : '60px auto',
        gridTemplateColumns : 'auto auto 320px',
        '-ms-grid-columns' : '2fr 320px',
        '-ms-grid-rows' : 'auto 60px 1fr 0px auto',
        gridTemplateAreas : '\'subheader subheader threesixty\' \'journeyplayer journeyplayer threesixty\''
    },
    visibleHeader360Vertical : {
        display : 'grid',
        height : '-webkit-fill-available !important',
        gridTemplateRows : '60px auto',
        gridTemplateColumns : 'auto auto 320px',
        '-ms-grid-columns' : '2fr 320px',
        '-ms-grid-rows' : 'auto 60px 1fr 0px auto',
        gridTemplateAreas : '\'subheader subheader threesixty\' \'journeyplayer journeyplayer journeyplayer\''
    },
    visibleHeader360Horizontal : {
        display : 'grid',
        height : '-webkit-fill-available !important',
        gridTemplateRows : '60px auto',
        gridTemplateColumns : 'auto auto ' + window.THREESIXTY_PANELSIZE,
        '-ms-grid-columns' : '2fr ' + window.THREESIXTY_PANELSIZE,
        '-ms-grid-rows' : 'auto 60px 1fr 0px auto',
        gridTemplateAreas : '\'subheader subheader threesixty\' \'journeyplayer journeyplayer threesixty\''
    },
    threesixty : {
        gridArea : 'threesixty',
        '-ms-grid-row' : 2,
        '-ms-grid-row-span' : 2,
        '-ms-grid-column' : 2
    },
    threesixtyHiden : {
        gridArea : 'threesixty',
        '-ms-grid-row' : 2,
        '-ms-grid-row-span' : 2,
        '-ms-grid-column' : 2,
        display : 'none'
    },
    threesixtyHeader : {
        gridArea : 'threesixty',
        '-ms-grid-row' : 2,
        '-ms-grid-row-span' : 1,
        '-ms-grid-column' : 2
    },
    subheader : {
        gridArea : 'subheader',
        '-ms-grid-row' : 2,
        '-ms-grid-column' : 1
    },
    journeyplayer : {
        gridArea : 'journeyplayer',
        '-ms-grid-row' : 3,
        '-ms-grid-column' : 1
    },
    journeyplayerFull : {
        gridArea : 'journeyplayer',
        '-ms-grid-row' : 3,
        '-ms-grid-column' : 1,
        '-ms-grid-column-span' : 2
    },
    '@global' : {
        '::-webkit-scrollbar' : {
            width : '7px',
            height : '7px',
        },
        '::-webkit-scrollbar-track' : {
            backgroundColor : 'transparent',
        },
        '::-webkit-scrollbar-thumb' : {
            background : theme.palette.text.disabled,
            borderRadius : '10px'
        }
    }
});

export { styles };
export { ComponentName };