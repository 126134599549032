const ComponentName = "UfeContext";
const styles = theme => ({
    root : {
        display : "flex",
        alignItems : "center",
        height : "100%",
        cursor: "pointer"
    }
});

export { styles };
export { ComponentName };