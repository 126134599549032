import React, { Component } from "react";
import OpenInteractionsMenuRender from "./OpenInteractionsMenuRender";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class OpenInteractionsMenu extends Component {

    render() {
        const { interactionsHistory, setMenuState, currentInteraction } = this.props;
        return <OpenInteractionsMenuRender currentInteraction={currentInteraction} setMenuState={setMenuState} interactionHistory={interactionsHistory} />
    }
};

const mapStateToProps = ({ interactions: { interactionsHistory, currentInteraction } }) => ({ interactionsHistory, currentInteraction });

const mapDispatchToProps = dispatch => bindActionCreators(
    {
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(OpenInteractionsMenu);