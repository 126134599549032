import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./MenuTitleStyles";
import Typography from '@ui-lib/core/Typography';

function MenuTitleRender({ classes }) {
    return (
        <div id='UfeMenuTitle' className={classes.root}>
            <Typography variant="h3">Journeys</Typography>
        </div>
    );
}

export default withStyles(styles, { name: ComponentName })(MenuTitleRender);