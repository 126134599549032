import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./SaveDirtyJourneyStyles";
import Dialog from "@ui-lib/core/Dialog";
import DialogContent from "@ui-lib/core/DialogContent";
import DialogTitle from "@ui-lib/core/DialogTitle";
import DialogActions from "@ui-lib/core/DialogActions";
import classNames from "@ui-lib/core/classNames";
import Typography from "@ui-lib/core/Typography";
import IconButton from "@ui-lib/core/IconButton";
import Icon from "@ui-lib/core/Icon";
import Button from "@ui-lib/core/Button";

function SaveDirtyJourneydRender({classes, isDirtyJourneyOpen, onClose, onCloseSaveJourney, closeJourneyScope, onCloseDiscardJourney, maxWidth, fullWidth, i18nProvider}) {

    return (
        <Dialog
            id='UfeSaveDirtyJourneyModal'
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={isDirtyJourneyOpen}
            onClose={onClose}
            className={classes.root}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">
                <div className={classes.titleContainer}>
                    {i18nProvider.Labeli18n("task_switcher")}
                </div>
                <IconButton id="UfeSaveDirtyJourneyModalCloseIconBtn" onClick={onClose} className={classNames(classes.iconButtonClose, classes.titleContainer)} title={i18nProvider.Texti18n("close")}>
                    <Icon className={classNames("icon-close", classes.iconClose)}/>
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.contentPaddingFix}>
                {i18nProvider.Labeli18n("dirty_interaction_journeys_message")}
            </DialogContent>
            <DialogActions>
                <Button id="UfeSaveDirtyJourneyModalDiscordBtn" variant="contained" color="primary" title={"Button"} onClick={() => {
                    onCloseDiscardJourney(closeJourneyScope);
                }}>{i18nProvider.Labeli18n("discard_dirty_journey")}</Button>
                <Button id="UfeSaveDirtyJourneyModalSaveJourneyBtn" variant="contained" color="primary" title={"Button"} onClick={() => {
                    onCloseSaveJourney(closeJourneyScope);
                }}>{i18nProvider.Labeli18n("save_dirty_journey")}</Button>               
                <Button id="UfeSaveDirtyJourneyModalCancelBtn" variant="contained" color="primary" title={"Button"} onClick={onClose}>{i18nProvider.Labeli18n("cancel_dirty_journey")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default withStyles(styles, {name : ComponentName})(SaveDirtyJourneydRender);