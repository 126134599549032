import React from "react";
import { withStyles } from "@ui-lib/core/styles";
import { styles, ComponentName } from "./WidgetCardStyles";
import Divider from '@ui-lib/core/Divider';
import Card from '@ui-lib/core/Card';
import CardActionArea from '@ui-lib/core/CardActionArea';
import CardContent from '@ui-lib/core/CardContent';
import CardMedia from '@ui-lib/core/CardMedia';
import Typography from '@ui-lib/core/Typography';


function JourneyCard({classes, imgThumbnail, title, subtitle, onClick, hover, isSelected, id}) {
    return (
        <React.Fragment>
            <Card id={'UfeWidget_' + id} className={classes.card + " " + (isSelected && classes.selected)} onClick={onClick} title={title}>
                <CardActionArea className={classes.teste}>
                    <CardMedia
                    className={classes.media}
                    image={imgThumbnail}
                    />
                    <Divider className={classes.divider} />
                    <CardContent>
                        <Typography className={classes.title} variant="subtitle2" noWrap title={title}>{title}</Typography>
                        {subtitle && <Typography className={classes.subTitle} variant="body2" noWrap title={subtitle}>{subtitle}</Typography>}
                    </CardContent>
                </CardActionArea>
            </Card>
        </React.Fragment>
    );
}

export default withStyles(styles, {name : ComponentName})(JourneyCard);